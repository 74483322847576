import React, { useEffect, useMemo, useState } from 'react';
import Slider from 'react-slick';
import TypeFace from '../typography/Typefaces';
import './customstyles.css';
import useMediaQuery from 'src/hooks/useMediaQuery';
import { useDispatch, useSelector } from 'react-redux';
import { selectSearchObject } from 'src/redux/search/selectors';
import { algoliaFilterTextFromSearchObject } from 'src/data/algolia-data-adjust';
import { searchObjects } from 'src/algolia';
import Flexbox from '../wrapper/Flexbox';
import neighborhoodBackground from "src/assets/images/bg/bg-neighbourhood.jpg"
import useAreas from 'src/hooks/data/useAreas';
import CardSearchResultItemInHome from './search/CardSearchResultItemInHome';
import Button from '../button/Button';
import { storeSearchObject } from 'src/redux/search/actions';
import { useNavigate } from 'react-router';

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} slick-arrows`}
      style={style}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} slick-arrows`}
      style={style}
      onClick={onClick}
    />
  );
}

const HomeNeighborhoodPanel = () => {

  const { isMobile } = useMediaQuery()
  const { areas } = useAreas()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const currentSearchObject = useSelector(selectSearchObject)

  const [recommendedListings, setRecommendedListings] = useState([])

  const [selectedArea, setSelectedArea] = useState()

  const presentingAreas = useMemo(() => {
    return areas?.filter(area => area.name !== "Nassau County" && area.name !== "Suffolk County")
  }, [areas])

  const settings = {
    dots: true,
    infinite: false,
    slidesToShow: isMobile ? 1 : Math.min(recommendedListings.length, 3),
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />
  };

  const handleExploreAll = () => {
    if (currentSearchObject?.type === 'rental') {
      dispatch(storeSearchObject({
        type: 'rental',
        price: {}
      }))
    } else {
      dispatch(storeSearchObject({
        type: 'sale',
        price: {}
      }))
    }
    navigate('/search')
  }

  useEffect(() => {
    if (selectedArea) {
      const recommendedFilters = algoliaFilterTextFromSearchObject({
        neighborhood: selectedArea.name,
      });
      searchObjects('', recommendedFilters).then(res => {
        setRecommendedListings(res.hits)
      }).catch(err => {
        setRecommendedListings([])
      })
    }
  }, [selectedArea])

  useEffect(() => {
    if (presentingAreas && presentingAreas.length > 0) {
      setSelectedArea(presentingAreas[0])
    }
  }, [presentingAreas])

  return (
    <Flexbox style={{
      ...styles.container,
      height: isMobile ? 'unset' : 912,
    }}>
      <Flexbox style={{ maxWidth: 1178, width: '90%', alignItems: 'center' }}>
        <TypeFace bold size={24} style={{
          ...styles.headerText,
          textAlign: isMobile ? 'center' : 'start'
        }}>
          Take the Best From Your Favorite Neighborhood
        </TypeFace>
        <TypeFace semiBold size={19}>
          Uncover A Variety of Neighborhoods. Find your Perfect Fit!
        </TypeFace>
        <Flexbox row style={styles.neighborhoodTabs}>
          {presentingAreas?.filter(arr => !arr.isState)?.map(area => (
            <Flexbox
              key={area.name}
              onClick={() => setSelectedArea(area)}
              style={{
                ...styles.areaTabItem,
                borderBottom: selectedArea?.name === area.name ? '4px solid #3151B7' : 'none',
                marginRight: isMobile ? 20 : 0,
              }}
            >
              <TypeFace size={isMobile ? 18 : 23} bold style={{ cursor: 'pointer' }}>
                {area.name}
              </TypeFace>
            </Flexbox>
          ))}
        </Flexbox>
        <div style={{ width: '100%', height: 1, backgroundColor: '#D9D9D9' }} />
        <div style={{
          width: (!isMobile && recommendedListings.length > 0 && recommendedListings.length < 3) ? 388 * recommendedListings.length : '100%',
          marginTop: 20,
          alignSelf: 'flex-start'
        }}>
          {(recommendedListings && recommendedListings.length > 0) ? (
            <Slider {...settings}>
              {recommendedListings.map((data, index) => (
                <div key={`recommended-${index}`}>
                  <CardSearchResultItemInHome hit={data} />
                </div>
              ))}
            </Slider>
          ) : (
            <Flexbox style={{ height: 500, width: '100%', alignItems: 'center', justifyContent: 'center' }}>
              <TypeFace size={20} semiBold>No listings</TypeFace>
            </Flexbox>
          )}
        </div>
        <Button onClick={handleExploreAll} secondary style={{ marginTop: 40, height: 45 }} textSize={19}>
          Explore All
        </Button>
      </Flexbox>
    </Flexbox>
  );
}

export default HomeNeighborhoodPanel

const styles = {
  container: {
    width: '100%',
    height: 912,
    backgroundImage: `url(${neighborhoodBackground})`
  },
  headerText: {
    textAlign: 'start',
    marginTop: 30,
    marginBottom: 10
  },
  neighborhoodTabs: {
    width: '100%',
    height: 86,
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 20,
    overflow: 'auto'
  },
  areaTabItem: {
    height: 44,
    cursor: 'pointer',
    justifyContent: 'center',
  }
}
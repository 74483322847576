import { collection, doc, onSnapshot, orderBy, query, updateDoc, where } from 'firebase/firestore'
import moment from 'moment'
import React, { useEffect, useRef } from 'react'
import { useState } from 'react'
import Button from 'src/components/button/Button'
import TypeFace from 'src/components/typography/Typefaces'
import Flexbox from 'src/components/wrapper/Flexbox'
import { firestore } from 'src/firebase'
import { Colors } from 'src/theme/Colors'
import { extractStreetAddressFromFullAddress } from 'src/utils/geoUtils'

const reportReasonDescriptions = {
  "inappropriate": "Inappropriate property",
  "fake": "Fake",
  "wrong": "Wrong information",
  "others": "Others"
}

const AdminListingReports = () => {

  const unmountedRef = useRef(false)

  const [reports, setReports] = useState()

  const handleOpenListing = (listingId) => {

  }

  useEffect(() => {
    unmountedRef.current = false
    const reportsSnapshotsListner = onSnapshot(query(collection(firestore, 'reportListings'), orderBy('reported', 'desc')), (querySnapshot) => {
      if (!unmountedRef.current) {
        const d = [];
        querySnapshot.forEach((doc) => {
          d.push({
            id: doc.id,
            ...doc.data()
          });
        });
        setReports(d)
      }
    })
    return () => {
      unmountedRef.current = true
      reportsSnapshotsListner()
    }
  }, [])

  return (
    <Flexbox style={{ alignItems: 'flex-start', width: '100%' }}>
      <TypeFace bold>
        Reported listings
      </TypeFace>
      <Flexbox row style={{
        width: '100%',
        height: 30,
        marginTop: 20,
        backgroundColor: '#dfdfdf',
        alignItems: 'center',
      }}>
        <TypeFace size={10} style={{ flex: 1.5 }}>
          Property
        </TypeFace>
        <TypeFace size={10} style={{ flex: 1 }}>
          Name
        </TypeFace>
        <TypeFace size={10} style={{ flex: 1 }}>
          Email
        </TypeFace>
        <TypeFace size={10} style={{ flex: 1 }}>
          Reason
        </TypeFace>
        <TypeFace size={10} style={{ flex: 1.5 }}>
          Comment
        </TypeFace>
        <TypeFace size={10} style={{ flex: 1 }}>
          Reported at
        </TypeFace>
      </Flexbox>
      {reports?.map(report => (
        <Flexbox row key={report.id} style={{
          width: '100%',
          minHeight: 30,
          alignItems: 'center',
          borderBottom: '1px solid #dfdfdf'
        }}>
          <TypeFace size={10} style={{ flex: 1.5, cursor: 'pointer', color: Colors.primary }} onClick={() => handleOpenListing(report.listing)}>
            {extractStreetAddressFromFullAddress(report?.address)}, {report?.unit}
          </TypeFace>
          <TypeFace size={10} style={{ flex: 1 }}>
            {report.name}
          </TypeFace>
          <TypeFace size={10} style={{ flex: 1 }}>
            {report.email}
          </TypeFace>
          <TypeFace size={10} style={{ flex: 1 }}>
            {reportReasonDescriptions[report.reason]}
          </TypeFace>
          <TypeFace size={10} style={{ flex: 1.5 }}>
            {report.comment}
          </TypeFace>
          <TypeFace size={10} style={{ flex: 1 }}>
            {moment(new Date(report.reported)).format('MMM DD')}
          </TypeFace>
        </Flexbox>
      ))}
    </Flexbox>
  )
}

export default AdminListingReports

const styles = {
  contentContainer: {
    width: '100%',
    gap: 20,
    flexWrap: 'wrap',
  },
  contentItem: {
    alignItems: 'flex-start',
    justifyContent: 'center',
    width: 160,
    height: 80,
    border: '1px solid #ededed',
    marginBottom: 20,
    padding: 20,
    borderRadius: 10,   
  },
  listingContentItem: {
    alignItems: 'flex-start',
    justifyContent: 'center',
    width: 270,
    height: 60,
    border: '1px solid #ededed',
    marginBottom: 20,
    padding: 20,
    borderRadius: 10,   
  },
  listingFullWidth: {
    alignItems: 'flex-start',
    justifyContent: 'center',
    width: 600,
    height: 60,
    border: '1px solid #ededed',
    marginBottom: 20,
    padding: 20,
    borderRadius: 10,   
  }
}
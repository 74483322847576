import { collection, doc, getDoc, onSnapshot, orderBy, query, setDoc } from "firebase/firestore";
import { useEffect, useMemo, useRef, useState, useCallback } from "react";
import { getFirestoreDocs } from "src/data/dbWrappers";
import { firestore } from "src/firebase";

const useAreas = () => {
  const [areas, setAreas] = useState();
  const [subAreas, setSubAreas] = useState({});
  const subAreasRef = useRef({});
  const triggerRef = useRef(0);
  const subAreaSnapshotListeners = useRef({});

  const areaCategories = useMemo(() => ({
    manhattan: [],
    queens: [],
    bronx: [],
    brooklyn: [],
    "staten island": [],
    "suffolk county": [],
    "nassau county": []
  }), []);

  const areaMapping = useMemo(() => ({
    manhattan: "manhattan",
    queens: "queens",
    "staten island": "staten island",
    bronx: "bronx",
    brooklyn: "brooklyn",
    "nassau county": "nassau county",
    "suffolk county": "suffolk county"
  }), []);

  const capitalizeWords = useCallback((str) => {
    return str.replace(/\b\w/g, char => char.toUpperCase())
      .replace(/nc/, "Nassau County")
      .replace(/sc/, "Suffolk County")
      .replace(/si/, "Staten Island");
  }, []);

  const fetchSubAreas = useCallback(async (areaName) => {
    const docsSnapshot = await getFirestoreDocs(
      query(collection(firestore, `areas/${areaName}/subs`), orderBy("order"))
    );

    const d = [];
    const localAreaCategories = { ...areaCategories };

    docsSnapshot.forEach((doc) => {
      const data = doc.data();
      const parent = data.parent?.toLowerCase();
      const category = areaMapping[parent];

      if (areaName === "New York" && category) {
        localAreaCategories[category].push({
          name: doc.id,
          ...data
        });
      }

      d.push({
        name: doc.id,
        ...data
      });
    });

    subAreasRef.current = {
      ...subAreasRef.current,
      [areaName]: d,
      ...Object.fromEntries(Object.entries(localAreaCategories).map(([key, value]) => [capitalizeWords(key), value]))
    };

    setSubAreas({ ...subAreasRef.current });
  }, [areaCategories, areaMapping, capitalizeWords]);

  useEffect(() => {
    const fetchAreas = async () => {
      const docsSnapshot = await getFirestoreDocs(query(collection(firestore, "areas"), orderBy("order")));
      const d = [];
      docsSnapshot.forEach((doc) => {
        d.push({
          name: doc.id,
          ...doc.data()
        });
      });
      setAreas(d);
    };

    fetchAreas();

    return () => {
      Object.values(subAreaSnapshotListeners.current).forEach(unsubscriber => {
        unsubscriber?.();
      });
    };
  }, []);

  useEffect(() => {
    if (areas) {
      areas
        .filter(area => area.name === "New Jersey" || area.name === "New York")
        .forEach(area => {
          const areaName = area.name;

          if (!subAreaSnapshotListeners.current[areaName]) {
            fetchSubAreas(areaName);
          }
        });
    }
  }, [areas, fetchSubAreas]);

  return {
    areas,
    subAreas
  };
};

export default useAreas;

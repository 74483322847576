import { collection, doc, onSnapshot, orderBy, query } from 'firebase/firestore'
import moment from 'moment'
import React, { useEffect, useRef } from 'react'
import { useState } from 'react'
import TypeFace from 'src/components/typography/Typefaces'
import Flexbox from 'src/components/wrapper/Flexbox'
import { firestore } from 'src/firebase'

const AdminAgencyRequests = () => {

  const unmountedRef = useRef(false)

  const [agenciesList, setAgenciesList] = useState()

  useEffect(() => {
    unmountedRef.current = false
    const agencyRequestsSnapshotListener = onSnapshot(query(collection(firestore, 'agencyRequests'), orderBy('created', 'desc')), (querySnapshot) => {
      if (!unmountedRef.current) {
        const d = [];
        querySnapshot.forEach((doc) => {
          d.push({
            id: doc.id,
            ...doc.data()
          });
        });
        setAgenciesList(d)
      }
    })
    return () => {
      unmountedRef.current = true
      agencyRequestsSnapshotListener()
    }
  }, [])

  return (
    <Flexbox style={{ alignItems: 'flex-start', width: '100%' }}>
      <TypeFace bold>
        ListMeet Agencies Request
      </TypeFace>
      <Flexbox row style={{
        width: '100%',
        height: 30,
        marginTop: 20,
        backgroundColor: '#dfdfdf',
        alignItems: 'center',
      }}>
        <TypeFace size={10} style={{ flex: 1 }}>
          Name
        </TypeFace>
        <TypeFace size={10} style={{ flex: 1 }}>
          Email
        </TypeFace>
        <TypeFace size={10} style={{ flex: 2 }}>
          Address
        </TypeFace>
        <TypeFace size={10} style={{ flex: 2 }}>
          Comment
        </TypeFace>
        <TypeFace size={10} style={{ flex: 0.5 }}>
          Rental
        </TypeFace>
        <TypeFace size={10} style={{ flex: 0.5 }}>
          Sale
        </TypeFace>
        <TypeFace size={10} style={{ flex: 1 }}>
          Submitted
        </TypeFace>
      </Flexbox>
      {agenciesList?.map(agencyRequest => (
        <Flexbox row key={agencyRequest.id} style={{
          width: '100%',
          minHeight: 30,
          alignItems: 'center',
          borderBottom: '1px solid #dfdfdf'
        }}>
          <TypeFace size={10} style={{ flex: 1 }}>
            {agencyRequest.name}
          </TypeFace>
          <TypeFace size={10} style={{ flex: 1 }}>
            {agencyRequest.email}
          </TypeFace>
          <TypeFace size={10} style={{ flex: 2 }}>
            {agencyRequest.address}
          </TypeFace>
          <TypeFace size={10} style={{ flex: 2 }}>
            {agencyRequest.comment}
          </TypeFace>
          <TypeFace size={20} style={{ flex: 0.5 }}>
            {agencyRequest.isRental ? "☑" : ""}
          </TypeFace>
          <TypeFace size={20} style={{ flex: 0.5 }}>
            {agencyRequest.isSale ? "☑" : ""}
          </TypeFace>
          <TypeFace size={10} style={{ flex: 1 }}>
            {moment(new Date(agencyRequest.created)).format('MMM DD')}
          </TypeFace>
        </Flexbox>
      ))}
    </Flexbox>
  )
}

export default AdminAgencyRequests

const styles = {
  contentContainer: {
    width: '100%',
    gap: 20,
    flexWrap: 'wrap',
  },
  contentItem: {
    alignItems: 'flex-start',
    justifyContent: 'center',
    width: 160,
    height: 80,
    border: '1px solid #ededed',
    marginBottom: 20,
    padding: 20,
    borderRadius: 10,   
  },
  listingContentItem: {
    alignItems: 'flex-start',
    justifyContent: 'center',
    width: 270,
    height: 60,
    border: '1px solid #ededed',
    marginBottom: 20,
    padding: 20,
    borderRadius: 10,   
  },
  listingFullWidth: {
    alignItems: 'flex-start',
    justifyContent: 'center',
    width: 600,
    height: 60,
    border: '1px solid #ededed',
    marginBottom: 20,
    padding: 20,
    borderRadius: 10,   
  }
}
import React, { forwardRef, useEffect, useMemo, useState } from 'react';
import Modal from 'react-modal';
import Flexbox from 'src/components/wrapper/Flexbox';
import TypeFace from 'src/components/typography/Typefaces';
import ReactSelect from 'react-select';
import RoomsSelectBox from './RoomsSelectBox';
import PriceRangeSelectBox from './PriceRangeSelectBox';
import { additionalUnitAmenitiesForHouseMode, buildingAmenitiesSearchOptions, buildingTypeOptions, rentalMarketAsTypeOptions, salesMarketAsTypeOptions, salesTypeOptions, taxSearchOptions, unitAmenitiesSearchOptions, yearsSearchOptions } from 'src/model/types';
import TextInput from 'src/components/input/TextInput';
import ReactDatePicker from 'react-datepicker';
import Button from 'src/components/button/Button';
import moment from 'moment';
import { storeSearchObject } from 'src/redux/search/actions';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import useMediaQuery from 'src/hooks/useMediaQuery';
import IconClose from 'src/assets/images/icons/icon-close-black.svg'
import { components } from "react-select"

const HomeAdvancedSearchFilterPopup = (props) => {

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { isMobile } = useMediaQuery()

  const [isForSale, setIsForSale] = useState(false)
  // const [salesFilterOptions, setSalesFilterOptions] = useState({})
  // const [rentalFilterOptions, setRentalFilterOptions] = useState({})

  const [filterOptions, setFilterOptions] = useState({})

  const salesTaxValue = useMemo(() => {
    if (filterOptions.monthlyTax) {
      return taxSearchOptions.find(taxOption => taxOption.value === filterOptions.monthlyTax)
    } else {
      return taxSearchOptions[0]
    }
  }, [filterOptions])

  const maintenanceOptionValue = useMemo(() => {
    if (filterOptions.maintenance) {
      return taxSearchOptions.find(taxOption => taxOption.value === filterOptions.maintenance)
    } else {
      return taxSearchOptions[0]
    }
  }, [filterOptions])

  const buildingTypeSelections = useMemo(() => {
    return filterOptions.buildingTypes?.map(buildingType => {
      return buildingTypeOptions.find(option => option.value === buildingType)
    })
  }, [filterOptions])

  const marketAsSelections = useMemo(() => {
    return filterOptions.marketAs?.map(marketAsOption => {
      return (isForSale ? salesMarketAsTypeOptions : rentalMarketAsTypeOptions).find(option => option.value === marketAsOption)
    })
  }, [filterOptions, isForSale])
  
  const marketAsIncludedHouses = useMemo(() => {
    return marketAsSelections?.some(option => option.value === 'singleFamily' || option.value === 'townHouse' || option.value === 'multiFamily')
  }, [marketAsSelections])

  const unitAmenitiesAvailableSearchOptions = useMemo(() => {
    if (marketAsIncludedHouses) {
      return [...unitAmenitiesSearchOptions, ...additionalUnitAmenitiesForHouseMode]
    } else {
      return unitAmenitiesSearchOptions
    }
  }, [marketAsIncludedHouses])

  useEffect(() => {
    if (!marketAsIncludedHouses) {
      delete filterOptions.lotsize
      delete filterOptions.lotsizeOption
      setFilterOptions({
        ...filterOptions,
        unitAmenities: filterOptions.unitAmenities?.filter(amenity => !additionalUnitAmenitiesForHouseMode.some(option => option.value === amenity))
      })
    }
  }, [marketAsIncludedHouses])

  const selectedUnitAmenities = useMemo(() => {
    return filterOptions.unitAmenities?.map(unitAmenity => {
      return unitAmenitiesAvailableSearchOptions.find(option => option.value === unitAmenity)
    })
  }, [filterOptions, unitAmenitiesAvailableSearchOptions])

  const selectedBuildingAmenities = useMemo(() => {
    return filterOptions.buildingAmenities?.map(amenity => {
      return buildingAmenitiesSearchOptions.find(option => option.value === amenity)
    })
  }, [filterOptions])

  const salesTypeSelections = useMemo(() => {
    if (filterOptions.salesTypes) {
      return filterOptions.salesTypes?.map(saleType => {
        return salesTypeOptions.find(option => option.value === saleType)
      })
    } else {
      return []
    }
  }, [filterOptions])

  const builtYearsTo = useMemo(() => {
    return yearsSearchOptions.find(yearOption => yearOption.value === filterOptions.yearsTo) ?? {label: 'Any', value: 'any'}
  }, [filterOptions])

  const builtYearsFrom = useMemo(() => {
    return yearsSearchOptions.find(yearOption => yearOption.value === filterOptions.yearsFrom) ?? {label: 'Any', value: 'any'}
  }, [filterOptions])

  const Input = (props) => {
    if (props.isHidden) {
      return <components.Input {...props} />
    }

    return (
      <components.Input type="text" {...props} onKeyDown={(event) => {
        if (event.key !== "Tab" && event.key !== "Backspace" && event.key !== "ArrowLeft" && event.key !== "ArrowRight" && event.key !== "Delete" && !/[0-9]/.test(event.key)) {
          event.preventDefault()
        }
      }} />
    )
  }

  const DateCustomInput = forwardRef(({ value, style, onClick }, ref) => (
    <TextInput
      value={value}
      onClick={onClick} 
      ref={ref}
      style={style}
      placeholder="MM/DD/YYYY"
    />
  ));

  const closeModal = () => {
    props.closeModal()
    document.body.style.overflow = 'auto'
  }

  const handleSubmit = () => {
    dispatch(storeSearchObject({
      ...filterOptions,
      type: isForSale ? 'sale' : 'rental'
    }))
    closeModal()
  }

  const handleReset = () => {
    dispatch(storeSearchObject({
      type: isForSale ? 'sale' : 'rental',
      price: {}
    }))
  }

  useEffect(() => {
    if (props.filters) {
      setFilterOptions(props.filters)
      if (props.filters.type === 'sale') {
        setIsForSale(true)
      } else {
        setIsForSale(false)
      }
    }
  }, [props.filters])

  return (
    <Modal
      isOpen={props.modalIsOpen}
      onRequestClose={closeModal}
      className="mymodal"
      overlayClassName="myoverlay"
    >
      <Flexbox style={{
        ...styles.container,
        width: isMobile ? 'calc(100vw - 40px)' : 700,
        height: isMobile ? 'calc(100vh - 120px)' : 600
      }}>
        <TypeFace semiBold size={isMobile ? 16 : 18}>
          Advanced filtering
        </TypeFace>
        <div style={styles.separator} />
        <Flexbox style={{ width: 'calc(100% - 40px)'}}>
          <Flexbox row style={styles.tab}>
            <Flexbox onClick={() => {
              if (!isForSale) {
                setFilterOptions({
                  ...filterOptions,
                  price: {},
                  marketAs: []
                })
              }
              setIsForSale(true)
            }} style={{...styles.tabItem, backgroundColor: isForSale ? '#000' : 'transparent'}}>
              <TypeFace color={isForSale ? 'white' : 'black'} style={{ cursor: 'pointer' }}>
                For Sale
              </TypeFace>
            </Flexbox>
            <Flexbox onClick={() => {
              if (isForSale) {
                setFilterOptions({
                  ...filterOptions,
                  price: {},
                  marketAs: []
                })
              }
              setIsForSale(false)
            }} style={{...styles.tabItem, backgroundColor: !isForSale ? '#000' : 'transparent'}}>
              <TypeFace color={!isForSale ? 'white' : 'black'} style={{ cursor: 'pointer' }}>
                For Rent
              </TypeFace>
            </Flexbox>
          </Flexbox>
          <Flexbox style={{ 
            ...styles.row,
            gap: isMobile ? 5 : 15,
            flexDirection: isMobile ? 'column' : 'row',
            alignItems: isMobile ? 'flex-start' : 'center'
          }}>
            <TypeFace medium>
              Price
            </TypeFace>
            <div style={{ flex: 1 }} />
            <PriceRangeSelectBox
              isForSale={isForSale}
              minValue={filterOptions.price?.from}
              maxValue={filterOptions.price?.to}
              onValueChanged={(min, max) => {
                const priceValues = {}
                if (min) {
                  priceValues.from = min
                }
                if (max) {
                  priceValues.to = max
                }

                setFilterOptions({
                  ...filterOptions,
                  price: priceValues
                })
              }}
            />
            {!isForSale && (
              <Flexbox row style={{ gap: 5 }}>
                <input
                  type="checkbox"
                  id="no-fees"
                  checked={filterOptions?.noFees}
                  onChange={e => {
                    setFilterOptions({
                      ...filterOptions,
                      noFees: e.target.checked
                    })
                  }}
                />
                <TypeFace for="no-fees">
                  No fee only
                </TypeFace>
              </Flexbox>
            )}
          </Flexbox>
          <div style={styles.rowDivider} />
          {!isForSale && (
            <>
              <Flexbox row style={{
                ...styles.row,
                flexDirection: isMobile ? 'column' : 'row',
                alignItems: isMobile ? 'flex-start' : 'center',
                gap: isMobile ? 5 : 0,
              }}>
                <TypeFace>
                  Move In Date
                </TypeFace>
                <div style={{ flex: 1 }} />
                <div>
                  <ReactDatePicker
                    placeholderText='MM/DD/YYYY'
                    customInput={<DateCustomInput style={{ borderRadius: 5, height: 40 }} />}
                    selected={filterOptions.moveInDate ? new Date(filterOptions.moveInDate) : undefined}
                    onChange={(date) => {
                      setFilterOptions({
                        ...filterOptions,
                        moveInDate: moment(date).format('MM/DD/yyyy')
                      })
                    }}
                  />
                </div>
                <Flexbox row>
                  <Flexbox row style={{ gap: 3, marginLeft: isMobile ? 0 : 15 }}>
                    <input
                      type="radio"
                      id="before"
                      name="date-option"
                      checked={filterOptions.moveIn === undefined ? (filterOptions.moveInDate !== undefined) : filterOptions?.moveIn === 'before'}
                      onChange={e => {
                        if (e.target.checked) {
                          setFilterOptions({
                            ...filterOptions,
                            moveIn: 'before'
                          })
                        }
                      }}
                    />
                    <TypeFace for="before" size={12}>
                      Or before
                    </TypeFace>
                  </Flexbox>
                  <Flexbox row style={{ gap: 3, marginLeft: 10 }}>
                    <input
                      type="radio"
                      id="after"
                      name="date-option"
                      checked={filterOptions?.moveIn === 'after'}
                      onChange={e => {
                        if (e.target.checked) {
                          setFilterOptions({
                            ...filterOptions,
                            moveIn: 'after'
                          })
                        }
                      }}
                    />
                    <TypeFace for="after" size={12}>
                      Or after
                    </TypeFace>
                  </Flexbox>
                </Flexbox>
              </Flexbox>
              <div style={styles.rowDivider} />
            </>
          )}
          <Flexbox row style={styles.row}>
            <TypeFace>
              Bedroom
            </TypeFace>
            <div style={{ flex: 1 }} />
            <RoomsSelectBox
              lastValue={5}
              selected={filterOptions.bedRooms ?? []}
              onUpdateSelection={rooms => {
                setFilterOptions({
                  ...filterOptions,
                  bedRooms: rooms
                })
              }}
            />
          </Flexbox>
          <div style={styles.rowDivider} />
          <Flexbox row style={{
            ...styles.row,
            flexDirection: isMobile ? 'column' : 'row',
            alignItems: isMobile ? 'flex-start' : 'center',
            gap: isMobile ? 5 : 0,
          }}>
            <TypeFace>
              Bathroom
            </TypeFace>
            <div style={{ flex: 1 }} />
            <RoomsSelectBox
              lastValue={5}
              selected={filterOptions.bathRooms ?? []}
              onUpdateSelection={rooms => {
                setFilterOptions({
                  ...filterOptions,
                  bathRooms: rooms
                })
              }}
            />
          </Flexbox>
          <div style={styles.rowDivider} />
          <Flexbox row style={{
            ...styles.row,
            flexDirection: isMobile ? 'column' : 'row',
            alignItems: isMobile ? 'flex-start' : 'center',
            gap: isMobile ? 5 : 0,
          }}>
            <TypeFace>
              Unit Amenities
            </TypeFace>
            <div style={{ flex: 1 }} />
            <div style={{ width: isMobile ? '100%' : 400 }}>
              <ReactSelect
                placeholder="Select multiple"
                options={unitAmenitiesAvailableSearchOptions}
                isMulti
                value={selectedUnitAmenities ?? []}
                onChange={(val) => {
                  setFilterOptions({
                    ...filterOptions,
                    unitAmenities: val.map(item => item.value)
                  })
                }}
              />
            </div>
          </Flexbox>
          <div style={styles.rowDivider} />
          <Flexbox row style={{
            ...styles.row,
            flexDirection: isMobile ? 'column' : 'row',
            alignItems: isMobile ? 'flex-start' : 'center',
            gap: isMobile ? 5 : 0,
          }}>
            <TypeFace>
              Building Amenities
            </TypeFace>
            <div style={{ flex: 1 }} />
            <div style={{ width: isMobile ? '100%' : 400 }}>
              <ReactSelect
                placeholder="Select multiple"
                options={buildingAmenitiesSearchOptions}
                isMulti
                value={selectedBuildingAmenities ?? []}
                onChange={(val) => {
                  setFilterOptions({
                    ...filterOptions,
                    buildingAmenities: val.map(item => item.value)
                  })
                }}
              />
            </div>
          </Flexbox>
          <div style={styles.rowDivider} />
          <Flexbox row style={styles.row}>
            <TypeFace>
              Pets
            </TypeFace>
            <div style={{ flex: 1 }} />
            <Flexbox row style={{ gap: 5 }}>
              <input
                type="checkbox"
                id="pets-allowed"
                checked={filterOptions.pets}
                onChange={e => {
                  setFilterOptions({
                    ...filterOptions,
                    pets: e.target.checked 
                  })
                }}
              />
              <TypeFace for="pets-allowed">
                Pets allowed
              </TypeFace>
            </Flexbox>
          </Flexbox>
          {!isForSale && (
            <Flexbox row style={styles.row}>
              <TypeFace>
                Furnished
              </TypeFace>
              <div style={{ flex: 1 }} />
              <Flexbox row style={{ gap: 5 }}>
                <input
                  type="checkbox"
                  id="furnished"
                  checked={filterOptions.furnished}
                  onChange={e => {
                    setFilterOptions({
                      ...filterOptions,
                      furnished: e.target.checked 
                    })
                  }}
                />
                <TypeFace for="furnished">
                  Furnished
                </TypeFace>
              </Flexbox>
            </Flexbox>
          )}
          <div style={styles.rowDivider} />
          <Flexbox style={{
            ...styles.row,
            flexDirection: isMobile ? 'column' : 'row',
            alignItems: isMobile ? 'flex-start' : 'center',
            gap: isMobile ? 5 : 0,
          }}>
            <TypeFace>
              Building Type
            </TypeFace>
            <div style={{ flex: 1 }} />
            <div style={{ width: 300 }}>
              <ReactSelect
                options={isForSale ?  salesMarketAsTypeOptions : rentalMarketAsTypeOptions}
                isMulti
                value={marketAsSelections ?? []}
                onChange={(val) => {
                  setFilterOptions({
                    ...filterOptions,
                    marketAs: val.map(item => item.value)
                  })
                }}
              />
            </div>
          </Flexbox>
          <div style={styles.rowDivider} />
          {isForSale && (
            <>
              <Flexbox row style={styles.row}>
                <TypeFace>
                  Tax
                </TypeFace>
                <div style={{ flex: 1 }} />
                <div style={{ width: 200 }}>
                  <ReactSelect
                    options={taxSearchOptions}
                    value={salesTaxValue}
                    components={{ Input }}
                    onChange={value => {
                      console.log("value.value=",value.value)
                      setFilterOptions({
                        ...filterOptions,
                        monthlyTax: value.value
                      })
                    }}
                  />
                </div>
              </Flexbox>
              <div style={styles.rowDivider} />
              <Flexbox row style={{
                ...styles.row,
                flexDirection: isMobile ? 'column' : 'row',
                alignItems: isMobile ? 'flex-start' : 'center',
                gap: isMobile ? 5 : 0,
              }}>
                <TypeFace>
                  Maintenance (HOA Fees)
                </TypeFace>
                <div style={{ flex: 1 }} />
                <div style={{ width: 200 }}>
                  <ReactSelect
                    options={taxSearchOptions}
                    value={maintenanceOptionValue}
                    components={{ Input }}
                    onChange={value => {
                      setFilterOptions({
                        ...filterOptions,
                        maintenance: value.value
                      })
                    }}
                  />
                </div>
              </Flexbox>
              
              <div style={styles.rowDivider} />
            </>
          )}
          <Flexbox style={{
            ...styles.row,
            flexDirection: isMobile ? 'column' : 'row',
            alignItems: isMobile ? 'flex-start' : 'center',
            gap: isMobile ? 5 : 0,
          }}>
            <TypeFace>
              Year Built
            </TypeFace>
            <div style={{ flex: 1 }} />
            <Flexbox row>
              <ReactSelect
                options={yearsSearchOptions}
                defaultValue={{label: 'Any', value: 'any'}}
                value={builtYearsFrom}
                onChange={value => {
                  setFilterOptions({
                    ...filterOptions,
                    yearsFrom: value.value
                  })
                }}
              />
              <TypeFace style={{ marginLeft: 10, marginRight: 10 }}>~</TypeFace>
              <ReactSelect
                options={yearsSearchOptions}
                defaultValue={{label: 'Any', value: 'any'}}
                value={builtYearsTo}
                onChange={value => {
                  setFilterOptions({
                    ...filterOptions,
                    yearsTo: value.value
                  })
                }}
              />
            </Flexbox>
          </Flexbox>
          <div style={styles.rowDivider} />
          {isForSale && (
            <>
              <Flexbox row style={{
                ...styles.row,
                flexDirection: isMobile ? 'column' : 'row',
                alignItems: isMobile ? 'flex-start' : 'center',
                gap: isMobile ? 5 : 0,
              }}>
                <TypeFace>
                  Type of Sale
                </TypeFace>
                <div style={{ flex: 1 }} />
                <div style={{ width: 300 }}>
                  <ReactSelect
                    options={salesTypeOptions}
                    isMulti
                    value={salesTypeSelections}
                    onChange={value => {
                      setFilterOptions({
                        ...filterOptions,
                        salesTypes: value.map(val => val.value)
                      })
                    }}
                  />
                </div>
              </Flexbox>
              <div style={styles.rowDivider} />
            </>
          )}
          {marketAsIncludedHouses && (
            <>
              <div style={styles.rowDivider} />
              <Flexbox row style={styles.row}>
                <TypeFace>
                  Lot Size
                </TypeFace>
                <div style={{ flex: 1 }} />
                <Flexbox row style={{ gap: 3, marginLeft: 15 }}>
                  <input
                    type="radio"
                    id="under"
                    name="lotsize-option"
                    checked={filterOptions.lotsizeOption === undefined ? (filterOptions.lotsize !== undefined && filterOptions.lotsize > 0) : filterOptions?.lotsizeOption === 'under'}
                    onChange={e => {
                      if (e.target.checked) {
                        setFilterOptions({
                          ...filterOptions,
                          lotsizeOption: 'under'
                        })
                      }
                    }}
                  />
                  <TypeFace for="under" size={12}>
                    Under
                  </TypeFace>
                </Flexbox>
                <Flexbox row style={{ gap: 3, marginLeft: 10, marginRight: 10 }}>
                  <input
                    type="radio"
                    id="over"
                    name="lotsize-option"
                    checked={filterOptions?.lotsizeOption === 'over'}
                    onChange={e => {
                      if (e.target.checked) {
                        setFilterOptions({
                          ...filterOptions,
                          lotsizeOption: 'over'
                        })
                      }
                    }}
                  />
                  <TypeFace for="over" size={12}>
                    Over
                  </TypeFace>
                </Flexbox>
                <TextInput
                  style={{ width: 120, height: 40 }}
                  placeholder="Lot Sq Footage"
                  value={filterOptions.lotsize}
                  onChange={e => {
                    setFilterOptions({
                      ...filterOptions,
                      lotsize: e.target.value
                    })
                  }}
                />
              </Flexbox>
            </>
          )}
          <Flexbox row style={styles.buttons}>
            <Button secondary onClick={handleReset} style={styles.resetButton}>
              RESET
            </Button>
            <Button primary onClick={handleSubmit} style={styles.submitButton}>
              SUBMIT
            </Button>
          </Flexbox>
        </Flexbox>
        <Flexbox onClick={props.closeModal} style={styles.closeButton}>
          <img src={IconClose} width={24} height={24} />
        </Flexbox>
      </Flexbox>
    </Modal>
  )
}

export default HomeAdvancedSearchFilterPopup

const styles = {
  container: {
    width: 700,
    height: 600,
    padding: '20px 0',
  },
  separator: {
    height: 1,
    width: '100%',
    backgroundColor: '#DFDFDF',
    marginTop: 20,
    marginBottom: 20
  },
  tab: {
    height: 40,
    border: '1px solid #7f7f7f',
    width: '100%',
    marginBottom: 20,
  },
  tabItem: {
    flex: 1,
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer'
  },
  row: {
    width: '100%',
    paddingTop: 15,
    paddingBottom: 15,
    alignItems: 'center',
  },
  rowDivider: {
    height: 1,
    width: '100%',
    backgroundColor: '#DFDFDF',
  },
  buttons: {
    marginTop: 50,
    marginBottom: 50,
    width: '100%',
    gap: 20
  },
  resetButton: {
    flex: 1,
    height: 44,
  },
  submitButton: {
    flex: 1,
    height: 44,
  },
  closeButton: {
    position: 'absolute',
    right: 0,
    top: 0,
    width: 44,
    height: 44,
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer'
  }
}
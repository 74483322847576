import { collection, doc, getDocs, onSnapshot, orderBy, query, updateDoc, where } from 'firebase/firestore'
import moment from 'moment'
import React, { useEffect, useRef } from 'react'
import { useState } from 'react'
import { useNavigate } from 'react-router'
import { updateAlogliaObject } from 'src/algolia'
import Button from 'src/components/button/Button'
import TypeFace from 'src/components/typography/Typefaces'
import Flexbox from 'src/components/wrapper/Flexbox'
import { firestore } from 'src/firebase'

const statusLabels = {
  submitted: 'Waiting for approve',
  approved: 'Active',
  blocked: 'Blocked'
}

const AdminAgenciesList = () => {

  const unmountedRef = useRef(false)

  const navigate = useNavigate()

  const [agencies, setAgencies] = useState()

  const handleApprove = (agencyId, brokerId) => {
    updateDoc(doc(firestore, `agencies/${agencyId}`), {
      status: 'approved',
    })

    updateDoc(doc(firestore, `profiles/${brokerId}`), {
      agencyApproved: true,
      agencyDeclined: false,
      profileBlocked: false,
      agencyReleased: false,
      releasedDate: '',
      activatedDate: new Date().getTime(),
    })

    const q = query(collection(firestore, `listing`), where('by', '==', brokerId))
    getDocs(q).then(docs => {
      docs.forEach(docData => {
        const listingData = docData.data()
        updateDoc(doc(firestore, `listing/${listingData.id}`), {
          blocked: false
        })

        updateAlogliaObject(listingData.algoliaID, {
          status: listingData.status
        })
      })
    })
  }

  const handleBlock = (agencyId, brokerId) => {
    updateDoc(doc(firestore, `agencies/${agencyId}`), {
      status: 'blocked'
    })
    updateDoc(doc(firestore, `profiles/${brokerId}`), {
      agencyApproved: false,
      agencyDeclined: true,
      profileBlocked: true,
      activatedDate: '',
    })

    const q = query(collection(firestore, `listing`), where('by', '==', brokerId))
    getDocs(q).then(docs => {
      docs.forEach(docData => {
        const listingData = docData.data()
        updateDoc(doc(firestore, `listing/${listingData.id}`), {
          blocked: true
        })

        updateAlogliaObject(listingData.algoliaID, {
          status: 'Blocked'
        })
      })
    })
  }

  const handleViewAgency = (agencyId) => {
    navigate(`/admin/board/agencies/${agencyId}`)
  }

  const handleViewListing = (creatorId) => {
    navigate(`/admin/board/propertyListing/${creatorId}`)
  }

  useEffect(() => {
    unmountedRef.current = false
    const snapshotListener = onSnapshot(query(collection(firestore, 'agencies'), orderBy('createdAt', 'desc')), (querySnapshot) => {
      if (!unmountedRef.current) {
        const d = [];
        //const sortedData = querySnapshot.docs?.sort((a,b)=> a.createdAt - b.createdAt);
        querySnapshot.forEach((doc) => {
          d.push({
            id: doc.id,
            ...doc.data()
          });
        });
        setAgencies(d)
      }
    })
    return () => {
      unmountedRef.current = true
      snapshotListener()
    }
  }, [])

  return (
    <Flexbox style={{ alignItems: 'flex-start', width: '100%' }}>
      <TypeFace bold>
        All Agencies
      </TypeFace>
      <Flexbox row style={{
        width: '100%',
        height: 30,
        marginTop: 20,
        backgroundColor: '#dfdfdf',
        alignItems: 'center',
      }}>
        <TypeFace size={10} style={{ flex: 1 }}>
          Name
        </TypeFace>
        <TypeFace size={10} style={{ flex: 1 }}>
          License number
        </TypeFace>
        <TypeFace size={10} style={{ flex: 1 }}>
          Broker name
        </TypeFace>
        <TypeFace size={10} style={{ flex: 1 }}>
          Broker email
        </TypeFace>
        <TypeFace size={10} style={{ flex: 1 }}>
          Status
        </TypeFace>
        <TypeFace size={10} style={{ flex: 1 }}>
          Created
        </TypeFace>
        <TypeFace size={10} style={{ flex: 1 }}>
          Actions
        </TypeFace>
        <TypeFace size={10} style={{ flex: 1 }}>
          Listings
        </TypeFace>
      </Flexbox>
      {agencies?.map(agency => (
        <Flexbox row key={agency.id} style={{
          width: '100%',
          minHeight: 30,
          alignItems: 'center',
          borderBottom: '1px solid #dfdfdf'
        }}>
          <TypeFace size={10} style={{ flex: 1 }}>
            {agency.name}
          </TypeFace>
          <TypeFace size={10} style={{ flex: 1 }}>
            {agency.id}
          </TypeFace>
          <TypeFace size={10} style={{ flex: 1 }}>
            {agency.brokerName}
          </TypeFace>
          <TypeFace size={10} style={{ flex: 1 }}>
            {agency.brokerEmail}
          </TypeFace>
          <TypeFace size={10} style={{ flex: 1 }}>
            {statusLabels[agency.status]}
          </TypeFace>
          <TypeFace size={10} style={{ flex: 1 }}>
            {moment(new Date(agency.createdAt)).format('MMM DD, yyyy')}
          </TypeFace>
          <Flexbox row style={{ flex: 1, gap: 5, justifyContent: 'center' }}>
            {(!agency.status || agency.status === "" || agency.status === 'submitted') && (
              <>
                <Button textSize={10} primary style={{ width: 60}} onClick={() => handleApprove(agency.id, agency.creator)}>
                  APPROVE
                </Button>
                <Button textSize={10} primary style={{ backgroundColor: 'red', width: 60}} onClick={() => handleBlock(agency.id, agency.creator)}>
                  DECLINE
                </Button>
              </>
            )}
            {agency.status === 'approved' && (
              <>
                <Button textSize={10} onClick={() => handleViewAgency(agency.id)}>
                  View
                </Button>
                <Button textSize={10} primary style={{ backgroundColor: 'red', width: 44}} onClick={() => handleBlock(agency.id, agency.creator)}>
                  BLOCK
                </Button>
              </>
            )}
            {agency.status === 'blocked' && (
              <>
                <Button textSize={10} primary style={{ width: 60}} onClick={() => handleApprove(agency.id, agency.creator)}>
                  APPROVE
                </Button>
              </>
            )}
          </Flexbox>
          <Flexbox row style={{ flex: 1, gap: 5, justifyContent: 'center' }}>
          <Button textSize={10} onClick={() => handleViewListing(agency.creator)}>
                  View
           </Button>
          </Flexbox>
        </Flexbox>
      ))}
    </Flexbox>
  )
}

export default AdminAgenciesList

const styles = {
  contentContainer: {
    width: '100%',
    gap: 20,
    flexWrap: 'wrap',
  },
  contentItem: {
    alignItems: 'flex-start',
    justifyContent: 'center',
    width: 160,
    height: 80,
    border: '1px solid #ededed',
    marginBottom: 20,
    padding: 20,
    borderRadius: 10,   
  },
  listingContentItem: {
    alignItems: 'flex-start',
    justifyContent: 'center',
    width: 270,
    height: 60,
    border: '1px solid #ededed',
    marginBottom: 20,
    padding: 20,
    borderRadius: 10,   
  },
  listingFullWidth: {
    alignItems: 'flex-start',
    justifyContent: 'center',
    width: 600,
    height: 60,
    border: '1px solid #ededed',
    marginBottom: 20,
    padding: 20,
    borderRadius: 10,   
  }
}
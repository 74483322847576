import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Colors } from 'src/theme/Colors';
import Modal from 'react-modal';
import Flexbox from 'src/components/wrapper/Flexbox';
import TypeFace from 'src/components/typography/Typefaces';
import Button from 'src/components/button/Button';
import useAreas from 'src/hooks/data/useAreas';
import { splitArrayIntoChunksArray } from 'src/utils/arrays';
import useMediaQuery from 'src/hooks/useMediaQuery';

const SelectNeighborhoodsModal = (props) => {

  const { areas, subAreas } = useAreas()
  const { isMobile } = useMediaQuery()

  const [selectedArea, setSelectedArea] = useState()

  const [selectedSubAreaNames, setSelectedSubAreaNames] = useState([])

  const handleApply = () => {
    props.onApply(selectedSubAreaNames)
    closeModal()
  }

  const selectedSubAreas = useMemo(() => {
    if (subAreas && selectedArea) {
      return subAreas[selectedArea.name]
    } else {
      return []
    }
  }, [subAreas, selectedArea])

  const subAreasChunks = useMemo(() => {
    if (selectedSubAreas) {
      return splitArrayIntoChunksArray(selectedSubAreas, 3)
    } else {
      return []
    }
  }, [selectedSubAreas])

  const selectedSubAreasCountForArea = useCallback((area) => {
    return selectedSubAreaNames.filter(a => a.area === area.name)?.length ?? 0
  }, [selectedSubAreaNames])

  useEffect(() => {
    if (areas && areas.length > 0) {
      setSelectedArea(areas[0])
    }
  }, [areas])

  const afterOpenModal = () => {
    setSelectedSubAreaNames(props.selection ?? [])
  }

  function closeModal() {
    props.closeModal()
    document.body.style.overflow = 'auto'
  }

  const handleClear = () => {
    setSelectedSubAreaNames([])
  }

  return (
    <Modal
      isOpen={props.modalIsOpen}
      onRequestClose={closeModal}
      onAfterOpen={afterOpenModal}
      className="mymodal"
      overlayClassName="myoverlay"
    >
      <Flexbox style={styles.container}>
        <Flexbox style={styles.titleHeader}>
          <Button onClick={handleClear} secondary style={styles.clearButton} textColor='white'>
            CLEAR
          </Button>
          <TypeFace bold color="white">
            Select Your Neighborhoods{selectedSubAreaNames.length > 0 ? `(${selectedSubAreaNames.length})` : ""}
          </TypeFace>
          <Button onClick={handleApply} secondary style={styles.applyButton} textColor='white'>
            APPLY
          </Button>
        </Flexbox>
        <Flexbox row style={styles.areas}>
          {areas?.map((area, idx) => (
            <Flexbox onClick={() => {
              setSelectedArea(area)
            }} key={`area-${idx}`} style={{
              backgroundColor: area.name === selectedArea?.name ? Colors.optionBack : 'transparent',
              ...styles.areaButton,
            }}>
              <TypeFace semiBold size={14} style={styles.areaText}>
                {area.name}{selectedSubAreasCountForArea(area) !== 0 ? `(${selectedSubAreasCountForArea(area)})` : ''}
              </TypeFace>
            </Flexbox>
          ))}
        </Flexbox>
        <Flexbox style={styles.subAreasBox}>
          {subAreasChunks?.map((subAreaChunk, index) => (
            <Flexbox row key={`chunks-${index}`} style={{width: 'calc(100% - 20px)'}}>
              {subAreaChunk.map((subArea, idx) => (
                <Flexbox row style={styles.subAreaItem} key={`subArea-${subArea.name}`}>
                  <input type="checkbox" id={`check${subArea.name}`} checked={selectedSubAreaNames.some(a => a.subArea === subArea.name)} onChange={e => {
                    if (e.target.checked) {
                      setSelectedSubAreaNames([...selectedSubAreaNames, {
                        area: selectedArea.name,
                        subArea: subArea.name,
                        bbox: subArea.component.bbox
                      }])
                    } else {
                      setSelectedSubAreaNames([...selectedSubAreaNames.filter(a => a.subArea !== subArea.name)])
                    }
                  }} />
                  <TypeFace for={`check${subArea.name}`}>
                    {subArea.name}
                  </TypeFace>
                </Flexbox>
              ))}
            </Flexbox>
          ))}
        </Flexbox>
      </Flexbox>
    </Modal>
  )
}

export default SelectNeighborhoodsModal

const styles = {
  container: {
    width: 1200,
    height: 600,
  },
  mobileContainer: {
    width: 'calc(100% - 40px)',
    height: 'calc(100% - 40px)',
  },
  separator: {
    height: 1,
    width: '100%',
    backgroundColor: '#DFDFDF',
    marginTop: 20,
    marginBottom: 20
  },
  titleHeader: {
    width: '100%',
    height: 60,
    backgroundColor: Colors.primary,
    justifyContent: 'center',
  },
  applyButton: {
    position: 'absolute',
    width: 120,
    height: 44,
    right: 20,
    top: 8,
    backgroundColor: '#0000',
    borderRadius: 0,
    border: '1px solid #fff'
  },
  clearButton: {
    position: 'absolute',
    width: 120,
    height: 44,
    left: 20,
    top: 8,
    backgroundColor: '#0000',
    borderRadius: 0,
  },
  areas: {
    width: 'calc(100% - 40px)',
    marginTop: 20,
    height: 60,
    border: '1px solid #dfdfdf',
    borderRight: 'none',
  },
  areaButton: {
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    borderRight: '1px solid #dfdfdf',
    cursor: 'pointer',
  },
  areaText: {
    textTransform: 'uppercase',
    cursor: 'pointer'
  },
  subAreasBox: {
    border: '1px solid #dfdfdf',
    width: 'calc(100% - 40px)',
    marginTop: 20,
    height: 410,
    overflow: 'scroll',
    alignItems: 'center'
  },
  subAreaItem: {
    flex: 1,
    height: 40,
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: 10,
  }
}
import React, { useState } from 'react'
import useMediaQuery from 'src/hooks/useMediaQuery';
import HomeSpecialActionCard from './HomeSpecialActionCard';
import { useDispatch } from 'react-redux';
import { storeSearchObject } from 'src/redux/search/actions';
import { useNavigate } from 'react-router';
import Flexbox from '../wrapper/Flexbox';
import HomeAgencyModal from './HomeAgencyModal';

const HomeSpecialActionsPanel = () => {

  const { isMobile } =  useMediaQuery()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [agencyModalOpened, setAgencyModalOpened] = useState(false)
  
  const handleSearchRentals = () => {
    dispatch(storeSearchObject({
      type: 'rental',
      price: {}
    }))
    navigate('/search')
  }

  const handleSearchSales = () => {
    dispatch(storeSearchObject({
      type: 'sale',
      price: {}
    }))
    navigate('/search')
  }

  const handleAgencySubmit = () => {
    setAgencyModalOpened(true)
  }

  return (
    <Flexbox style={{ 
      marginTop: 64, 
      width: '100%', 
      justifyContent: 'space-between', 
      flexDirection: isMobile ? 'column' : 'row',
      gap: isMobile ? 20 : 0,
    }}>
      <HomeSpecialActionCard
        style={styles.actionCardStyle}
        title="Rent a Property"
        desc="Discover New Yorks Finest Properties For Rent"
        buttonText="Search Rentals"
        onAction={handleSearchRentals}
      />
      <HomeSpecialActionCard
        style={styles.actionCardStyle}
        title="Buy a Property"
        desc="Discover New Yorks Finest Properties For Sale"
        buttonText="Search Sales"
        onAction={handleSearchSales}
      />
      <HomeSpecialActionCard
        style={styles.actionCardStyle}
        title="Sell a Property"
        desc="Get Connected With Your Neighborhoods Real Estate Professional"
        buttonText="Submit Info"
        onAction={handleAgencySubmit}
      />

      <HomeAgencyModal
        modalIsOpen={agencyModalOpened}
        onCloseModal={() => setAgencyModalOpened(false)}
      />
    </Flexbox>
  )
}

export default HomeSpecialActionsPanel

const styles = {
  container: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'center'
  },
  actionCardStyle: {
    width: 335,
    height: 264,
  }
}
import React, { useState } from 'react'
import Loading from 'react-fullscreen-loading';
import { useNavigate } from 'react-router-dom';
import { auth } from 'src/firebase';
import { Colors } from 'src/theme/Colors';
import TypeFace from '../typography/Typefaces';

const AccountBlueMenuBar = (props) => {

  const navigate = useNavigate()

  const [processing, setProcessing] = useState(false)

  const handleSignout = () => {
    setProcessing(true)
    auth.signOut()
      .then(() => {
        setProcessing(false)
        navigate('/home')
      })
      .catch(err => {
        setProcessing(false)
        navigate('/home')
      })
  }

  return (
    <div style={styles.container}>
      <TypeFace
        medium={props.selected === 0 ? false: true}
        semiBold={props.selected === 0 ? true: false}
        size={props.selected === 0 ? 16 : 14}
        style={styles.textButton}
        onClick={() => props.onTabSelected(0)}
        color={'white'}
      >
        Basic Profile
      </TypeFace>
      <TypeFace
        medium={props.selected === 1 ? false: true}
        semiBold={props.selected === 1 ? true: false}
        size={props.selected === 1 ? 16 : 14}
        style={styles.textButton}
        onClick={() => props.onTabSelected(1)}
        color={'white'}
      >
        My Listings
      </TypeFace>
      <div style={{ flex: 1 }} />
      <TypeFace medium onClick={handleSignout} size={16} color={'white'} style={styles.textButton}>
        Sign out
      </TypeFace>
      {processing && <Loading loading background="#0005" loaderColor="white" />}
    </div>
  )
}

export default AccountBlueMenuBar

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    height: 44,
    alignItems: 'center',
    padding: "0 30px",
    backgroundColor: Colors.primary,
    gap: 30,
  },
  textButton: {
    cursor: 'pointer',
  }
}
import { Colors } from "src/theme/Colors"
import TypeFace from "../typography/Typefaces"
import Flexbox from "../wrapper/Flexbox"
import IconEdit from "src/assets/images/icons/icon-edit.svg"
import IconDelete from "src/assets/images/icons/icon-delete-red.svg"
import IconPropertyBlue from 'src/assets/images/icons/menu/icon-properties-blue.svg'
import { useNavigate } from "react-router-dom"
import { useEffect, useRef, useState } from "react"
import { addDoc, collection, deleteDoc, doc, onSnapshot, updateDoc } from "firebase/firestore"
import { firestore } from "src/firebase"
import IconCalendar from "src/assets/images/icons/icon-calendar.svg"
import moment from "moment"
import { priceFormat, scheduleFromToString } from "src/utils/strings"
import { useAlert } from "src/hooks/alert/AlertContext"
import { extractStreetAddressFromFullAddress } from "src/utils/geoUtils"
import useMediaQuery from "src/hooks/useMediaQuery"
import { propertyStatusLabelFromOption, propertyStatusLabelFromOptionRented } from "src/model/types"
import AddOpenHouseModal from "../listing/AddOpenHouseModal"
import { getFirestoreAgencyDoc } from "src/data/dbWrappers"
import { updateAlogliaObject } from "src/algolia"
import { format } from 'date-fns'

const MyActivePropertyCell = ({listing, editAvailable}) => {

  const navigate = useNavigate()

  const { showAlert } = useAlert()
  const { isMobile } = useMediaQuery()

  const unsubscribeRef = useRef()
  const unmountedRef = useRef(false)

  const [openHouseSchedules, setOpenHouseSchedules] = useState([])

  const [addOpenHouseOpening, setAddOpenHouseOpening] = useState(false)
  const [editingSchedule, setEditingSchedule] = useState()
  const [listingAgency, setListingAgency] = useState()

  const handleClickEdit = () => {
    navigate(`/edit/listing/${listing.id}`)
  }

  const handleClickView = () => {
    navigate(`/listing/${listing.id}`)
  }

  const handleCancelSchedule = (schedule) => {
    deleteDoc(doc(firestore, `listing/${listing.id}/schedules/${schedule.id}`))
  }

  const handleEditSchedule = (schedule) => {
    setEditingSchedule(schedule)
    setAddOpenHouseOpening(true)
  }

  const handleEditOpenHouse = async (scheduleId, date, startHour, startMin, endHour, endMin, byAppointmentOnly) => {
    if(JSON.stringify(endHour).length < 2) {
      endHour = '0' + endHour
    }
    if(JSON.stringify(endMin).length < 2) {
      endMin = endMin + '0'
    }
    const record = {
      date: date.getTime(),
      scheduleDateTime: format(date, 'yyyy-MM-dd') + " " + endHour + ":" + endMin,
      start: {
        h: startHour,
        m: startMin,
      },
      end: {
        h: endHour,
        m: endMin,
      },
      appointmentOnly: byAppointmentOnly ?? false
    }

    updateDoc(doc(firestore, `listing/${listing.id}/schedules/${scheduleId}`), record)
  }

  const handleAddOpenHouse = async (date, startHour, startMin, endHour, endMin, byAppointmentOnly) => {
    if(JSON.stringify(endHour).length < 2) {
      endHour = '0' + endHour
    }
    if(JSON.stringify(endMin).length < 2) {
      endMin = endMin + '0'
    }
    const record = {
      date: date.getTime(),
      scheduleDateTime: format(date, 'yyyy-MM-dd') + " " + endHour + ":" + endMin,
      start: {
        h: startHour,
        m: startMin,
      },
      end: {
        h: endHour,
        m: endMin,
      },
      appointmentOnly: byAppointmentOnly ?? false
    }

    const docRef = await addDoc(collection(firestore, `listing/${listing.id}/schedules`), record)
    updateDoc(docRef, { id: docRef.id })
    record.id= docRef.id

    setOpenHouseSchedules([...openHouseSchedules, record])
  }

  const handleClickDelete = () => {
    showAlert(true, 'Are you sure you want to delete the listing?', [
      {
        title: "Yes",
        onClick: () => {
          showAlert(false)
          updateDoc(doc(firestore, `listing/${listing.id}`), {
            status: 'Deleted',
          })
          if (listing.algoliaID) {
            updateAlogliaObject(listing.algoliaID, {
              status: 'Deleted'
            })
          }
        }
      },
      {
        title: "No",
        onClick: () => {
          showAlert(false)
        }
      },
    ])
  }

  useEffect(() => {
    if (listing.id) {
      unsubscribeRef.current = onSnapshot(collection(firestore, `listing/${listing.id}/schedules`), querySnapshot => {
        let schedules = []
        querySnapshot.forEach(doc => {
         // schedules.push(doc.data())
          const scheduledDateTime = new Date(doc.data().scheduleDateTime); 
          if (scheduledDateTime >= new Date()) {
            schedules.push(doc.data())
          }
        })
        setOpenHouseSchedules(schedules)
      })
    }
  }, [listing.id])

  useEffect(() => {
    if (listing.agency) {
      getFirestoreAgencyDoc(listing.agency).then(agencyDoc => {
        if (!unmountedRef.current) {
          setListingAgency(agencyDoc.data())
        }
      })
    }
  }, [listing.agency])

  useEffect(() => {
    unmountedRef.current = false
    return () => {
      unmountedRef.current = true
      unsubscribeRef.current?.()
    }
  }, []);

  return (
    <Flexbox style={styles.container}>
      <Flexbox row style={{
        ...styles.contentRow,
        backgroundColor: listing.status === 'Active' ? 'white' : Colors.gray3
      }}>
        <Flexbox style={styles.address}>
          <Flexbox row style={{ flexDirection: isMobile ? 'column' : 'row', alignItems: isMobile ? 'flex-start' : 'center', gap: 5 }}>
            {listing.status !== 'Active' && (
              <Flexbox style={styles.statusBadge}>
                <TypeFace bold size={11}>
                  {listing.type === 'rental' ? propertyStatusLabelFromOptionRented(listing.status) : propertyStatusLabelFromOption(listing.status)}
                </TypeFace>
              </Flexbox>
            )}
            {listing.unit !== '' && (
              <TypeFace semiBold color={Colors.gray1}>
              Unit {listing.unit}{isMobile ? '' : `,`}
              </TypeFace>
            )}
            <TypeFace color={Colors.gray1} size={14}>
              {extractStreetAddressFromFullAddress(listing.address.full)}
            </TypeFace>
          </Flexbox>
          {listing.unitInfo && (
            <Flexbox row>
              <TypeFace size={12}>
                {listing.unitInfo?.totalRooms} rooms
              </TypeFace>
              {(!isMobile && listing.type === 'rental') && (
                <TypeFace size={12}>
                  <TypeFace size={9} color="#9f9f9f">
                    &nbsp;&nbsp;|&nbsp;&nbsp;
                  </TypeFace>
                  Available from {moment(listing.unitInfo?.dateAvailable).format('MM/DD/yyyy')}
                </TypeFace>
              )}
              <TypeFace size={12}>
                <TypeFace size={9} color="#9f9f9f">
                  &nbsp;&nbsp;|&nbsp;&nbsp;
                </TypeFace>
                {listing.unitInfo?.size} ft²
              </TypeFace>
            </Flexbox>
          )}
          {editAvailable && (
            <Flexbox row>
              <Flexbox onClick={handleClickEdit} row style={{ ...styles.rowButtons, marginRight: 20, marginTop: 10 }}>
                <img src={IconEdit} width={12} height={12} alt="edit-icon" />
                <TypeFace bold size={10} color={Colors.primary} style={{ cursor: 'pointer' }}>
                  EDIT
                </TypeFace>
              </Flexbox>
              <Flexbox onClick={handleClickView} row style={{ ...styles.rowButtons, marginRight: 20, marginTop: 10 }}>
                <img src={IconPropertyBlue} width={12} height={12} alt="view-icon" />
                <TypeFace bold size={10} color={Colors.primary} style={{ cursor: 'pointer' }}>
                  VIEW
                </TypeFace>
              </Flexbox>
              <Flexbox onClick={handleClickDelete} row style={{ ...styles.rowButtons, marginRight: 20, marginTop: 10 }}>
                <img src={IconDelete} width={12} height={12} alt="delete-icon" />
                <TypeFace bold size={10} color={'red'} style={{ cursor: 'pointer' }}>
                  DELETE
                </TypeFace>
              </Flexbox>
            </Flexbox>
          )}
        </Flexbox>
        <Flexbox style={{ gap: 2, marginRight: 20 }}>
        { listing?.status === 'Sold' ?  <TypeFace semiBold size={isMobile ? 24 : 32} color={Colors.primary}>
            ${priceFormat(listing.soldPrice)}
          </TypeFace>
           :  
           listing?.status === 'Rented' ?   
           <TypeFace semiBold size={isMobile ? 24 : 32} color={Colors.primary}>
              ${priceFormat(listing.rentedPrice)}
           </TypeFace>
           :
          <TypeFace semiBold size={isMobile ? 24 : 32} color={Colors.primary}>
            ${listing.type === 'rental' ? priceFormat(listing.grossRent) : priceFormat(listing.price)}
          </TypeFace>
        }
         
          <TypeFace medium size={12}>
            {listingAgency?.name}
          </TypeFace>
        </Flexbox>
      </Flexbox>
      {listing?.status === 'Active' && (
        <Flexbox style={styles.schedules}>
          {openHouseSchedules.map((schedule, index) => (
            <Flexbox key={index} row style={styles.scheduleRow}>
              <img src={IconCalendar} width={16} height={16} alt="calendar-icon" />
              <TypeFace size={isMobile ? 10 : 12}>
                Open house: {moment(new Date(schedule.date)).format('ddd, MMM D')} ({scheduleFromToString(schedule)})
              </TypeFace>
              <div style={{ flex: 1 }} />
              <TypeFace bold size={10} color={Colors.primary} style={{ cursor: 'pointer' }} onClick={() => handleEditSchedule(schedule)}>
                Edit
              </TypeFace>
              <TypeFace bold size={10} color={'red'} style={{ cursor: 'pointer' }} onClick={() => handleCancelSchedule(schedule)}>
                Cancel
              </TypeFace>
            </Flexbox>
          ))}
        </Flexbox>
      )}
      <AddOpenHouseModal
        modalIsOpen={addOpenHouseOpening}
        schedule={editingSchedule}
        onEditOpenHouse={handleEditOpenHouse}
        onAddOpenHouse={handleAddOpenHouse}
        closeModal={() => setAddOpenHouseOpening(false)}
      />
    </Flexbox>
  )
}

export default MyActivePropertyCell

const styles = {
  container: {
    boxShadow: '0 0 3px #3151B73F',
    backgroundColor: Colors.gray3,
    width: '100%',
    marginBottom: 20,
  },
  horSeparator: {
    backgroundColor: '#efefef',
    width: '100%',
    height: 1,   
  },
  statusRow: {
    width: '100%',
    height: 60,
    backgroundColor: 'white',
    gap: 20
  },
  contentRow: {
    width: '100%',
    backgroundColor: 'white',
    gap: 20,
    paddingTop: 15,
    paddingBottom: 15,
  },
  draftBadge: {
    backgroundColor: Colors.gray2,
    width: 60,
    height: 32,
    justifyContent: 'center',
    alignItems: 'center',
  },
  rowButtons: {
    gap: 5,
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  address: {
    flex: 1,
    alignItems: 'flex-start',
    marginLeft: 20,
    gap: 5,
  },
  schedules: {
    width: '100%',
    alignItems: 'flex-start',
  },
  scheduleRow: {
    alignItems: 'center',
    height: 44,
    marginLeft: 20,
    width: 'calc(100% - 40px)',
    gap: 10
  },
  statusBadge: {
    backgroundColor: Colors.gray2,
    paddingLeft: 10,
    paddingRight: 10,
    height: 32,
    justifyContent: 'center',
    alignItems: 'center',
  },
}
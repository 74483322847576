import debounce from "lodash.debounce"
import { forwardRef, useEffect, useMemo, useState } from "react"
import ReactDatePicker from "react-datepicker"
import OptionSelectButton from "../button/OptionSelectButton"
import NetEffectiveRentPanel from "../cards/NetEffectiveRentPanel"
import TextInput from "../input/TextInput"
import TypeFace from "../typography/Typefaces"
import Flexbox from "../wrapper/Flexbox"
import useMediaQuery from "src/hooks/useMediaQuery"
import { rentalMarketAsTypeOptions } from "src/model/types"

const EditListingUnitRentInformation = ({
  highlightErrorFields,
  listing,
  onUpdateRootFields,
  onUpdateUnitInfoFields,
  onMarketAsChanged,
  rentedSelected,
}) => {

  // const { id } = useParams()
  // const listing = props.listing
  const { isMobile } = useMediaQuery()

  const [dateAvailable, setDateAvailable] = useState()

  const [rentedDate, setRentedDate] = useState()

  const [concession, setConcession] = useState()
  const [furnishedOption, setFurnishedOption] = useState()
  const [furnishedRents, setFurnishedRents] = useState()

  const DateCustomInput = forwardRef(({ value, style, onClick }, ref) => (
    <TextInput value={value} onClick={onClick} ref={ref} style={style} readonly/>
  ));

  const [marketAs, setMarketAs] = useState('')
  const [grossRent, setGrossRent] = useState()

  const [rentedPrice, setRentedPrice] = useState()

  const changeUnitNumber = (event) => {
    const changedValue = event.target.value
    onUpdateRootFields({
      'unit': changedValue
    })
  }

  const debouncedChangeUnitHandler = useMemo(() => {
    return debounce(changeUnitNumber, 500);
  }, []);


  const changePrice = (event) => {
    const changedValue = event.target.value
    setGrossRent(changedValue)
    onUpdateRootFields({
      'grossRent': changedValue
    })
  }

  const changeRentedPrice = (event) => {
    const changedValue = event.target.value
    setRentedPrice(changedValue)
    onUpdateRootFields({
      'rentedPrice': changedValue
    })
  }

  const debouncedChangePriceHandler = useMemo(() => {
    return debounce(changePrice, 500);
  }, []);

  const debouncedChangeRentedPriceHandler = useMemo(() => {
    return debounce(changeRentedPrice, 500);
  }, []);

  const changeFurnishedRentPrice = (event) => {
    const changedValue = event.target.value
    setFurnishedRents(changedValue)
    onUpdateUnitInfoFields({
      'furnishedRents': changedValue
    })
  }

  const debouncedChangeFurnishedRentPriceHandler = useMemo(() => {
    return debounce(changeFurnishedRentPrice, 500);
  }, []);

  useEffect(() => {
    console.log('rentSelected=',rentedSelected)
    console.log('marketAs=',marketAs)
    if (listing) {
      setMarketAs(listing.unitInfo?.marketAs)
      setGrossRent(listing.grossRent)
      setFurnishedRents(listing.unitInfo?.furnishedRents)
      setDateAvailable(listing.unitInfo?.dateAvailable ? new Date(listing.unitInfo.dateAvailable) : undefined)
      setConcession(listing.unitInfo?.concession)
      setFurnishedOption(listing.unitInfo?.furnished)
      setRentedDate(listing.rentedDate)
      // if (listing.unitInfo?.furnished === 1) {
      //   setTimeout(() => {
      //     document.getElementById("input-furnished-rent").value = listing.unitInfo?.furnishedRents ?? ""
      //   }, 500)
      // }
      document.getElementById('input-gross-rent').value = listing.grossRent ?? ''
      if(listing.soldPrice !== undefined && listing.status === 'Rented') {
        document.getElementById('input-rent-price').value = listing.rentedPrice ?? ''
      }
     setTimeout(() => {
     
      if (listing.unitInfo?.marketAs === 'condo' || listing.unitInfo?.marketAs === 'coop' || listing.unitInfo?.marketAs === 'building') {
         document.getElementById('input-unit').value = listing.unit ?? ''
      }
      //console.log('soldDate=',soldDate)
    }, 100)
    }
  }, [listing])


  return (
    <Flexbox style={{ width: '100%', alignItems: 'flex-start' }}>
      <TypeFace medium size={20}>
        Rent Information
      </TypeFace>
      <Flexbox row style={{ alignItems: 'flex-start', marginTop: 10 }}>
        <Flexbox style={{ alignItems: 'flex-start' }}>
          <TypeFace bold size={12} color={highlightErrorFields && !marketAs ? 'red' : 'black'}>
            MARKET AS<TypeFace>*</TypeFace>
          </TypeFace>
          <Flexbox row style={{ alignItems: 'flex-start', marginTop: 5 }}>
            <select value={marketAs} style={{...styles.dropdown, borderColor: highlightErrorFields && !marketAs ? 'red' : 'black'}} onChange={e => {
              setMarketAs(e.target.value)
              onMarketAsChanged(e.target.value)
              onUpdateUnitInfoFields({
                'marketAs': e.target.value
              })
            }}>
              <option value=""></option>
              {rentalMarketAsTypeOptions.map(option => (
                <option value={option.value}>{option.label}</option>
              ))}
            </select>
          </Flexbox>
        </Flexbox>
        <div style={{ width: 30 }} />
        {(marketAs === 'building' || marketAs === 'condo' || marketAs === 'coop') && (
        <Flexbox style={{ alignItems: 'flex-start', marginTop: -5  }}>
          <TypeFace bold size={12} color={highlightErrorFields && !listing.unit ? 'red' : 'black'} style={{marginLeft: 20}}>
            UNIT #<TypeFace size={16}>*</TypeFace>
          </TypeFace>
          <Flexbox row style={{ alignItems: 'flex-start', marginTop: 5 }}>
          <TextInput
              id='input-unit'
              style={{
                ...styles.unitInput,
                borderColor: highlightErrorFields && !listing.unit ? 'red' : 'black'
              }}
              placeholder='Unit'
              onChange={debouncedChangeUnitHandler}
              />
          </Flexbox>
        </Flexbox>
        )}
      </Flexbox>
      
      <Flexbox style={{ alignItems: 'flex-start', marginTop: 30 }}>
        <TypeFace bold size={12} color={highlightErrorFields && !dateAvailable ? 'red' : 'black'}>
          When is the unit available?<TypeFace>*</TypeFace>
        </TypeFace>
        <ReactDatePicker
          placeholderText='MM/DD/YYYY'
          style={{
            height: 36,
            width: 150,
          }}
          customInput={<DateCustomInput style={{ marginTop: 5, borderColor: highlightErrorFields && !dateAvailable ? 'red' : 'black' }} />}
          selected={dateAvailable}
          onChange={(date) => {
            onUpdateUnitInfoFields({
              'dateAvailable': date.getTime()
            })
            setDateAvailable(date)
          }}
          onChangeRaw={(event) => {
            
          }}
        />
      </Flexbox>

      {rentedSelected === true && <Flexbox row style={{ alignItems: 'flex-start', gap: 30, marginTop: 20}}>
      <Flexbox style={{ alignItems: 'flex-start' }}>
          <TypeFace bold size={12} style={{ marginTop: 0 }} color={highlightErrorFields && !listing.soldPrice ? 'red' : 'black'}>
            RENTED PRICE<TypeFace size={16}>*</TypeFace>
          </TypeFace>
          <TextInput
            id='input-rent-price'
            style={{ ...styles.input, borderColor: highlightErrorFields && !listing.rentedPrice ? 'red' : 'black'}}
            placeholder="$"
            onChange={debouncedChangeRentedPriceHandler}
            onKeyDown={(event) => {
              if (event.key !== "Tab" && event.key !== "Tab" && event.key !== "." && event.key !== "Backspace" && event.key !== "ArrowLeft" && event.key !== "ArrowRight" && event.key !== "Delete" && !/[0-9]/.test(event.key)) {
                event.preventDefault()
              }
            }}
          />
        </Flexbox>
        <Flexbox style={{ alignItems: 'flex-start' }}>
          <TypeFace bold size={12} color={(highlightErrorFields && !rentedDate) ? "red" : "black"}>
            RENTED DATE <TypeFace size={16}>*</TypeFace>
          </TypeFace>
          <ReactDatePicker
            id='input-sold-date'
            placeholderText='MM/DD/YYYY'
            style={{ ...styles.smallInput, borderColor: highlightErrorFields && !listing.rentedDate ? 'red' : 'black'}}
            selected={rentedDate}
            customInput={<DateCustomInput style={{ ...styles.smallInput,marginTop: 5, width: 150 , borderColor: highlightErrorFields && !rentedDate ? 'red' : 'black'}} />}
            onChange={(date) => {
              onUpdateRootFields({
                'rentedDate': date.getTime()
              })
              setRentedDate(date)
            }}
          />
        </Flexbox>
      </Flexbox>}
      <div style={styles.separator} />
      
     {/*  <TypeFace bold size={11} style={{ marginTop: 30 }}>
        Help renters understand what they'll pay.
      </TypeFace>
      <TypeFace size={11} style={{ marginTop: 5, textAlign: 'start', width: '100%' }}>
        Renters are more likely to submit if you display gross rent.<br />
        To show them net effective rent as well, enter it as a Concession below.
      </TypeFace> */}

      <TypeFace bold size={12} style={{ marginTop: 20 }} color={highlightErrorFields && !listing.grossRent ? 'red' : 'black'}>
        GROSS RENT<TypeFace>*</TypeFace>
      </TypeFace>
      <Flexbox row style={{
        marginTop: 5,
        alignItems: 'center',
        gap: 5,
        justifyContent: 'flex-start'
      }}>
        <TextInput 
          id='input-gross-rent'
          style={{
            ...styles.input,
            marginTop: 0,
            marginRight: 15,
            borderColor: highlightErrorFields && !listing.grossRent ? 'red' : 'black',
          }}
          placeholder="Gross Rent"
          onChange={debouncedChangePriceHandler}
          onKeyDown={(event) => {
            if (event.key !== "Tab" && event.key !== "." && event.key !== "Backspace" && event.key !== "ArrowLeft" && event.key !== "ArrowRight" && event.key !== "Delete" && !/[0-9]/.test(event.key)) {
              event.preventDefault()
            }
          }}
        />
        {/* <input type="checkbox" id="no-fees" />
        <TypeFace for="no-fees">No Fee</TypeFace> */}
      </Flexbox>
      <TypeFace semiBold size={10}>
        Minimum rent is $500
      </TypeFace>
      
      <TypeFace bold size={12} style={{ marginTop: 20 }} color={highlightErrorFields && concession === undefined ? 'red' : 'black'}>
        CONCESSION<TypeFace>*</TypeFace>
      </TypeFace>
      <OptionSelectButton
        selected={concession}
        onSelected={(val) => {
          setConcession(val)
          onUpdateUnitInfoFields({
            'concession': val
          })
        }} />
      {concession === 1 && (
        <NetEffectiveRentPanel
          highlightErrorFields={highlightErrorFields}
          listing={listing}
          grossRent={grossRent}
          style={{marginTop: 30}}
          onUpdateUnitInfoFields={onUpdateUnitInfoFields}
        />
      )}
      <div style={styles.separator} />
      
      <Flexbox row style={{ justifyContent: 'flex-start', marginTop: 20, gap: 20, flexDirection: isMobile ? 'column' : 'row', alignItems: isMobile ? 'flex-start' : 'center' }}>
        <Flexbox style={{ alignItems: 'flex-start' }}>
          <TypeFace bold size={12} color={highlightErrorFields && furnishedOption === undefined ? 'red' : 'black'}>
            IS THE APARTMENT FURNISHED?<TypeFace>*</TypeFace>
          </TypeFace>
          <OptionSelectButton
            options={["No", "Yes"]}
            selected={furnishedOption}
            onSelected={(val) => {
              setFurnishedOption(val)
              onUpdateUnitInfoFields({
                'furnished': val
              })
              // if (val === 1) {
              //   setFurnishedRents("")
              // }
            }}
          />
        </Flexbox>
        {/* {furnishedOption === 1 && (
          <Flexbox style={{ alignItems: 'flex-start' }}>
            <TypeFace bold size={12}>
              FURNISHED RENT<TypeFace>*</TypeFace>
            </TypeFace>
            <TextInput 
              id='input-furnished-rent'
              placeholder="$"
              style={styles.furnishedRent}
              onChange={debouncedChangeFurnishedRentPriceHandler}
            />
          </Flexbox>
        )} */}
      </Flexbox>
    </Flexbox>
  )
}

export default EditListingUnitRentInformation

const styles = {
  container: {
    padding: "30px 0",
    alignItems: 'flex-start'
  },
  dropdown: {
    height: 36,
    width: 150,
  },
  separator: {
    height: 1,
    width: '100%',
    backgroundColor: '#AFAFAF',
    marginTop: 30,
  },
  input: {
    width: 150,
    height: 36,
    marginTop: 5,
    borderRadius: 0,
  },
  unitInput: {
    width: 150,
    height: 36,
    marginLeft: 20,
    borderRadius: 0
  },
  smallInput: {
    width: 60,
    height: 36,
    marginTop: 5,
    borderRadius: 0,
  },
  furnishedRent: {
    width: 200,
    marginTop: 5,
    borderRadius: 0,
    height: 40
  }
}
import { collection, doc, getDocs, limit, onSnapshot, orderBy, query, updateDoc, where } from 'firebase/firestore'
import moment from 'moment'
import React, { useEffect, useRef } from 'react'
import { useState } from 'react'
import { useNavigate } from 'react-router'
import Button from 'src/components/button/Button'
import TypeFace from 'src/components/typography/Typefaces'
import Flexbox from 'src/components/wrapper/Flexbox'
import { firestore } from 'src/firebase'
import { useFirestore } from 'src/hooks/firestore/FirestoreContext'

const AdminCustomerList = () => {

  const { userProfile } = useFirestore()

  const unmountedRef = useRef(false)

  const navigate = useNavigate()

  const [showAnonymous, setShowAnonymous] = useState(false)
  const [customers, setCustomers] = useState()

  const handleApprove = (agencyId, brokerId) => {
    updateDoc(doc(firestore, `agencies/${agencyId}`), {
      status: 'approved'
    })

    updateDoc(doc(firestore, `profiles/${brokerId}`), {
      agencyApproved: true,
      agencyDeclined: false,
      agencyReleased: false,
      releasedDate: '',
    })
  }

  const handleBlock = (profileId) => {
    updateDoc(doc(firestore, `profiles/${profileId}`), {
      profileBlocked: true,
      blockedBy: userProfile?.userId,
      blockedAt: new Date().getTime()
    }).then(() => {
      const customerIndex = customers.findIndex(c => c.userId === profileId)
      customers[customerIndex].profileBlocked = true
      customers[customerIndex].blockedBy = userProfile?.userId
      customers[customerIndex].blockedAt = new Date().getTime()
      setCustomers([...customers])
    })
  }

  const handleUnblock = (profileId) => {
    updateDoc(doc(firestore, `profiles/${profileId}`), {
      profileBlocked: false,
      unblockedBy: userProfile?.userId,
      unblockedAt: new Date().getTime()
    }).then(() => {
      const customerIndex = customers.findIndex(c => c.userId === profileId)
      customers[customerIndex].profileBlocked = false
      customers[customerIndex].unblockedBy = userProfile?.userId
      customers[customerIndex].unblockedAt = new Date().getTime()
      setCustomers([...customers])
    })
  }

  const loadCustomers = () => {
    let q
    if (showAnonymous) {
      q = query(collection(firestore, 'profiles'), where('role', '==', 'customer'), orderBy('created', 'desc'), limit(50))
    } else {
      q = query(collection(firestore, 'profiles'), where('role', '==', 'customer'), where('profileCreated', '==', true), orderBy('created', 'desc'), limit(50))
    }
    getDocs(q)
      .then((querySnapshot) => {
        if (!unmountedRef.current) {
          const d = [];
          querySnapshot.forEach((doc) => {
            d.push({
              id: doc.id,
              ...doc.data()
            });
          });
          setCustomers(d)
        }
      })
  }

  useEffect(() => {
    loadCustomers()
  }, [showAnonymous])

  useEffect(() => {
    unmountedRef.current = false
    return () => {
      unmountedRef.current = true
    }
  }, [])

  return (
    <Flexbox style={{ alignItems: 'flex-start', width: '100%' }}>
      <Flexbox row style={{ width: '100%', justifyContent: 'flex-start', alignItems: 'center'}}>
        <TypeFace bold>
          Customers
        </TypeFace>
        <div style={{ flex: 1}} />
        <input type="checkbox" id="anonymous" style={{ cursor: 'pointer'}} checked={showAnonymous} onChange={e => setShowAnonymous(e.target.checked)}/>
        <TypeFace for="anonymous" size={11} style={{ cursor: 'pointer', marginLeft: 3 }}>
          Show anonymous customers
        </TypeFace>
      </Flexbox>
      <Flexbox row style={{
        width: '100%',
        height: 30,
        marginTop: 20,
        backgroundColor: '#dfdfdf',
        alignItems: 'center',
      }}>
        <TypeFace size={10} style={{ flex: 1 }}>
          Name
        </TypeFace>
        <TypeFace size={10} style={{ flex: 1 }}>
          Email
        </TypeFace>
        <TypeFace size={10} style={{ flex: 1 }}>
          Phone
        </TypeFace>
        {showAnonymous && (
          <TypeFace size={10} style={{ flex: 1 }}>
            Is anonymous?
          </TypeFace>
        )}
        <TypeFace size={10} style={{ flex: 1 }}>
          Status
        </TypeFace>
        <TypeFace size={10} style={{ flex: 1 }}>
          Created
        </TypeFace>
        <TypeFace size={10} style={{ width: 80 }}>
          Actions
        </TypeFace>
      </Flexbox>
      {customers?.map(customer => (
        <Flexbox row key={customer.userId} style={{
          width: '100%',
          minHeight: 30,
          alignItems: 'center',
          borderBottom: '1px solid #dfdfdf'
        }}>
          <TypeFace size={10} style={{ flex: 1 }}>
            {customer.name}
          </TypeFace>
          <TypeFace size={10} style={{ flex: 1 }}>
            {customer.email}
          </TypeFace>
          <TypeFace size={10} style={{ flex: 1 }}>
            {customer.phone}
          </TypeFace>
          {showAnonymous && (
            <TypeFace size={10} style={{ flex: 1 }}>
              {customer.profileCreated ? '' : 'Anonymous'}
            </TypeFace>
          )}
          <TypeFace size={10} style={{ flex: 1 }}>
            {customer.profileBlocked ? `Blocked` : 'Active'}
          </TypeFace>
          <TypeFace size={10} style={{ flex: 1 }}>
            {moment(new Date(customer.created)).format('MMM DD')}
          </TypeFace>
          <Flexbox row style={{ width: 80, gap: 5, justifyContent: 'center' }}>
            {customer.profileBlocked ? (
              <Button textSize={10} style={{ width: 60}} onClick={() => handleUnblock(customer.userId)}>
                Unblock
              </Button>
            ) : (
              <Button textSize={10} primary style={{ backgroundColor: 'red', width: 44}} onClick={() => handleBlock(customer.userId)}>
                BLOCK
              </Button>
            )}
          </Flexbox>
        </Flexbox>
      ))}
    </Flexbox>
  )
}

export default AdminCustomerList

const styles = {
  contentContainer: {
    width: '100%',
    gap: 20,
    flexWrap: 'wrap',
  },
  contentItem: {
    alignItems: 'flex-start',
    justifyContent: 'center',
    width: 160,
    height: 80,
    border: '1px solid #ededed',
    marginBottom: 20,
    padding: 20,
    borderRadius: 10,   
  },
  listingContentItem: {
    alignItems: 'flex-start',
    justifyContent: 'center',
    width: 270,
    height: 60,
    border: '1px solid #ededed',
    marginBottom: 20,
    padding: 20,
    borderRadius: 10,   
  },
  listingFullWidth: {
    alignItems: 'flex-start',
    justifyContent: 'center',
    width: 600,
    height: 60,
    border: '1px solid #ededed',
    marginBottom: 20,
    padding: 20,
    borderRadius: 10,   
  }
}

const getStripe = () => {
  return require('stripe')(process.env.REACT_APP_stripe_secret_key);
}

export const createStripeAccount = ({
  email,
  callback,
  errorCallback,
}) => {
  const stripe = getStripe()

  stripe.accounts.create({
    type: 'express',
    country: 'US',
    email,
    capabilities: {
      card_payments: {requested: true},
      transfers: {requested: true},
    },
  })
  .then(account => {
    callback(account)
  })
  .catch(err => {
    errorCallback(err)
  });
}

export const createAccountOnboardingLink = ({
  accountId,
  callback,
  errorCallback
}) => {
  const stripe = getStripe()
  stripe.accountLinks.create({
    account: accountId,
    refresh_url: process.env.REACT_APP_stripe_account_refresh_url,
    return_url: process.env.REACT_APP_stripe_account_return_url,
    type: 'account_onboarding',
  }).then(accountLink => {
    callback(accountLink)
  }).catch(err => {
    errorCallback(err)
  });
}

export const retrieveAccount = ({
  accountId,
  callback,
  errorCallback
}) => {
  const stripe = getStripe()
  stripe.accounts.retrieve(accountId)
  .then(account => callback(account))
  .catch(err => errorCallback(err))
}

export const createLoginLink = ({
  accountId,
  callback,
  errorCallback
}) => {
  const stripe = getStripe()
  stripe.accounts.createLoginLink(accountId)
    .then(loginLink => callback(loginLink))
    .catch(err => errorCallback(err))
}

export const retrieveBalance = ({
  accountId,
  callback,
  errorCallback,
}) => {
  const stripe = getStripe()
  stripe.balance.retrieve({
    stripeAccount: accountId,
  })
  .then(balance => callback(balance))
  .catch(err => errorCallback(err))
}
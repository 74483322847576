import { useEffect, useState } from "react"
import { Colors } from "src/theme/Colors"
import TypeFace from "../typography/Typefaces"
import Flexbox from "../wrapper/Flexbox"
import useMediaQuery from "src/hooks/useMediaQuery"

const EditiListingSectionBar = (props) => {

  const { isMobile } = useMediaQuery()

  const [current, setCurrent] = useState(props.selectedIndex)

  const sectionNames = ["MANAGEMENT", "MEET NOW", "BUILDING", "UNIT", "PHOTOS & MEDIA", "MARKETING"]

  useEffect(() => {
    setCurrent(props.selectedIndex)
  }, [props.selectedIndex])

  return (
    <Flexbox row style={{
      ...styles.sectionsBar,
      marginTop: isMobile ? 0 : 20,
    }}>
      {sectionNames.map((sectionName, index) => (
        <div key={index} style={styles.sectionItem}>
          <TypeFace
            key={index}
            bold
            size={16}
            style={{
              cursor: 'pointer',
              paddingBottom: 10,
              borderBottom: current === index ? '4px solid #3151B7' : 'none',
            }}
            onClick={() => props.onSelectTab(index)}
          >
            {sectionName}
          </TypeFace>
        </div>
      ))}
    </Flexbox>
  )
}

export default EditiListingSectionBar

const styles = {
  sectionsBar: {
    // backgroundColor: Colors.primary,
    height: 74,
    width: '100%',
    marginTop: 20,
    whiteSpace: 'nowrap',
    overflowX: 'scroll',
    borderBottom: '1px solid #dfdfdf',
  },
  sectionItem: {
    marginRight: 30,
    cursor: 'pointer',
    display: 'flex'
  }
}
import React, { useEffect, useMemo, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Colors } from 'src/theme/Colors';
import TypeFace from '../typography/Typefaces';
import Flexbox from '../wrapper/Flexbox';
import { storeSearchObject } from 'src/redux/search/actions';
import { selectSearchObject } from 'src/redux/search/selectors';
import Button from '../button/Button';
import HomeAdvancedSearchFilterPopup from '../home/search/HomeAdvancedSearchFilterPopup';
import SelectNeighborhoodsModal from '../home/search/SelectNeighborhoodsModal';
import useMediaQuery from 'src/hooks/useMediaQuery';
import IconFilter from 'src/assets/images/icons/icon-filter-blue.svg'
import SelectNeighborhoodsModalMobile from '../home/search/SelectNeighborhoodsModalMobile';
import ReactSelect, { components } from 'react-select'
import useAreas from 'src/hooks/data/useAreas';
import PriceRangeSelectBox from '../home/search/PriceRangeSelectBox';
import IconMapNeighborhoods from 'src/assets/images/icons/icon-map-neighborhoods-2.svg'
import { Loader } from "@googlemaps/js-api-loader";
import Script from 'react-load-script';
import { appendFilteredDataToFirstArray } from './../../data/helper'
import { uniqBy } from "lodash"

const SearchOptionsBar = () => {

  const [userSelectedLocation, setUserSelectedLocation] = useState([])
  const dispatch = useDispatch()
  const currentSearchObject = useSelector(selectSearchObject)
  const { isMobile } = useMediaQuery()
  const { areas: _areas, subAreas } = useAreas()
  const areas = _areas?.filter((arr) => arr.isState);

  const [isOpeningAdvancedFilters, setIsOpeningAdvancedFilters] = useState(false)
  const [isOpeningNeighborhoodsModal, setIsOpeningNeighborhoodsModal] = useState(false)
  const [currentSelectedNeiborhoods, setCurrentSelectedNeighborhoods] = useState([])

  let placesApiClient;


  const [value, setValue] = useState("");
  const [fetchingPredictions, setFetchingPredictions] = useState(false);
  const [fetchingDetails, setFetchingDetails] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [placeDetail, setPlaceDetail] = useState();


  const [tags, setTags] = useState([])




  const getLocationBoundingBox = async (strPropertyAddress) => {
    //console.log("address=", strPropertyAddress)
    //console.log("data-array=",userSelectedLocation);
    let apiURL = ""
    apiURL = `https://nominatim.openstreetmap.org/search.php?q=${strPropertyAddress}&polygon_geojson=1&format=json`

    fetch(apiURL)
      .then((res) => {
        return res.json();
      })
      .then(async data => {
        console.log('data-TARAN=', data)
        let locationArray = []
        if (userSelectedLocation.length !== 0) {
          locationArray = userSelectedLocation
        }


        let coordinatesArray = []
        //console.log("data-boundingbox=",data[0]['boundingbox']);

        if (data[0]['geojson']['type'] == 'Polygon') {
          let arr_Coordinates = data[0]['geojson']['coordinates']
          console.log('arr_Coordinates=', arr_Coordinates)

          locationArray.push({
            label: strPropertyAddress,
            value: {
              area: strPropertyAddress,
              subArea: strPropertyAddress,
              polygon: coordinatesArray,
              bbox: null
            }
          })

          console.log('coordinatesArray=', coordinatesArray)
        } else {

          let arr_bBox = data[0]['boundingbox']
          var nums_Bbox = arr_bBox.map(function (str) {
            return parseFloat(str);
          });

          locationArray.push({
            label: strPropertyAddress,
            value: {
              area: strPropertyAddress,
              subArea: strPropertyAddress,
              polygon: null,
              bbox: nums_Bbox
            }
          })
          //console.log('locationArray=',locationArray)
        }


        // for 
        setUserSelectedLocation(locationArray)
        console.log("userSelectedLocation=", userSelectedLocation);

        setCurrentSelectedNeighborhoods(locationArray)
        const neighborhoods = locationArray.map(val => val.value)
        console.log('neighborhoods=', neighborhoods)
        const filtered = appendFilteredDataToFirstArray(neighborhoods, subAreas);
        dispatch(storeSearchObject({
          ...currentSearchObject,
          locations: filtered,
          neighborhoods
        }))

      });
    // console.log('res-pushNotification=',res)

  }

  const areasGroupOptions = useMemo(() => {
    let groupOptions = []
    areas?.forEach(area => {

      let options = []




      subAreas?.[area.name]?.forEach(subArea => {
        options.push({
          label: subArea.name,
          value: {
            area: area.name,
            subArea: subArea.name,
            bbox: subArea.component.bbox
          }
        })
      })

      let option = {
        label: area.name,
        options
      }

      groupOptions.push(option)
    })
    return groupOptions
  }, [areas, subAreas])

  const handleApplyNeighborSelections = (selections) => {
    setCurrentSelectedNeighborhoods(selections.map(v => ({
      label: v.subArea,
      value: v
    })))

    const filtered = appendFilteredDataToFirstArray(selections, subAreas);
    dispatch(storeSearchObject({
      ...currentSearchObject,
      locations: filtered,
      neighborhoods: selections
    }))
  }

  useEffect(() => {
    if (currentSearchObject?.neighborhoods) {
      setCurrentSelectedNeighborhoods(currentSearchObject.neighborhoods.map(v => ({
        label: v.subArea,
        value: v
      })))
      /* if(currentSearchObject?.neighborhoods.length > 0) {
        let str_PropertyAddress = currentSearchObject?.neighborhoods[0].subArea.replace(', United States', '')
        setTags([str_PropertyAddress])
        //setValue(str_PropertyAddress);
      } */


    }
  }, [currentSearchObject?.neighborhoods])

  const handleAdvancedOptions = () => {
    setIsOpeningAdvancedFilters(true)
  }



  const CustomGroupHeading = (props) => {
    const handleClick = () => {
      const data = [{
        label: props.children,
        value: {
          area: props.children,
          bbox: [],
          subArea: props.children,
        }
      }]
      setCurrentSelectedNeighborhoods(data)
      const neighborhoods = data.map(val => val.value)
      const filtered = appendFilteredDataToFirstArray(neighborhoods, subAreas);
      const locations = currentSearchObject?.locations?.length ? [...filtered, ...currentSearchObject.locations] : filtered;

      const filteredNeigh = currentSearchObject.neighborhoods?.filter(({ area }) => area !== props.children);

      dispatch(storeSearchObject({
        ...currentSearchObject,
        locations: locations,
        neighborhoods: !!filteredNeigh ? [...filteredNeigh, ...neighborhoods] : neighborhoods
      }))
    };

    return (
      <div
        onClick={handleClick}
        style={{
          height: 44,
          display: 'flex',
          alignItems: 'center',
          backgroundColor: '#F1F1F1',
          fontWeight: 'bold',
          cursor: 'pointer', padding: '8px'
        }}
      >
        {props.children}
      </div>
    );
  };

  return (
    <Flexbox style={styles.container}>
      <Flexbox style={{ width: '90%', maxWidth: 1178 }}>
        <Flexbox row style={{
          ...styles.content,
          alignItems: 'flex-end',
        }}>
          <Flexbox style={{ alignItems: 'flex-start', flex: 1, maxWidth: 460 }}>

            <TypeFace size={18} semiBold>
              Location
            </TypeFace>
            <div style={{ ...styles.locationInput, width: '100%' }}>
              <ReactSelect
                options={areasGroupOptions}
                components={{
                  GroupHeading: CustomGroupHeading,
                }}
                style={{ width: '100%' }}
                placeholder="Select your neighborhood"
                isMulti
                value={currentSelectedNeiborhoods}
                onChange={values => {
                  setCurrentSelectedNeighborhoods(values)
                  const neighborhoods = values.map(val => val.value)
                  const filtered = appendFilteredDataToFirstArray(neighborhoods, subAreas);
                  dispatch(storeSearchObject({
                    ...currentSearchObject,
                    locations: filtered,
                    neighborhoods
                  }))
                }}
                styles={{
                  placeholder: (base) => ({
                    ...base,
                    textAlign: 'start'
                  }),
                  valueContainer: (base) => ({
                    ...base,
                    textAlign: 'start'
                  }),
                  control: (base) => ({
                    ...base,
                    border: 'none',
                    borderRadius: 0
                  }),
                  container: (base) => ({
                    ...base,
                    border: '1px solid black',
                    boxShadow: 'none'
                  }),
                  option: (base) => ({
                    ...base,
                    textAlign: 'start'
                  }),
                  groupHeading: (base) => ({
                    ...base,
                    height: 44,
                    display: 'flex',
                    alignItems: 'center',
                    backgroundColor: '#F1F1F1',
                    fontWeight: 'bold'
                  })
                }}
              />
              {/*   <Script
          url="https://maps.googleapis.com/maps/api/js?key=AIzaSyChrrGNLT2GcGAZH1nwQ1urEveT-dhX3Rs&libraries=places"
        />
          <div style={styles.tagInputContainer}>
            { tags.map((tag, index) => (
                <div style={styles.tagItem} key={index}>
                    <span>{tag}</span>
                    <span style={styles.tagItemClose} onClick={() => removeTag(index)}>x</span>
                </div>
            )) }
            {tags.length === 0 && (
           <input id="location-input"
          style={styles.tagsInput}
          //onKeyDown={handleKeyDown} 
          placeholder="City, Neighborhood, ZIP"
          onChange={handleChange}
          autocomplete="off"
          value={value}/>)}
        </div>
        {fetchingPredictions && <p>Searching for locations...</p>}
        {fetchingDetails && <p>Searching for locations...</p>}

        {suggestions.length > 0 && (
          <div style={{ position: 'absolute',  zIndex: '5000 !important', backgroundColor:'#fff'}}>
            <ul style={{ listStyleType: "none", padding: "0", boxShadow: '0 0 6px #aaaaaa'}} role="listbox">
              {suggestions.map((suggestion) => (
                 <li
                 key={suggestion.id}
                 style={{
                   fontSize: "16px",
                   padding: "0.5rem 0.75rem",
                   margin: "0.25rem 0",
                   backgroundColor: "rgba(255, 255, 255, 0.15)",
                   borderBottom: '0.5px solid #aaaaaa',
                   cursor: "pointer",
                 }}
                 tabIndex={-1}
                 role="option"
                 aria-selected="false"
                 onClick={() => handleSuggestionSelected(suggestion)}
               >
                 {suggestion.properties.full_address.replace(', United States', '')}
               </li>
              ))}
            </ul>
          </div>
        )}
        <div id="googlemaps-attribution-container"></div> */}

            </div>
          </Flexbox>
          {!isMobile && (
            <>
              {currentSearchObject?.price && (
                <Flexbox style={{ alignItems: 'flex-start' }}>
                  <TypeFace size={18} semiBold>
                    Price Range
                  </TypeFace>
                  <PriceRangeSelectBox
                    isForSale={currentSearchObject?.type === 'sale'}
                    minValue={currentSearchObject?.price?.from}
                    maxValue={currentSearchObject?.price?.to}
                    optionWidth={140}
                    onValueChanged={(min, max) => {
                      const priceValues = {}
                      if (min) {
                        priceValues.from = min
                      }
                      if (max) {
                        priceValues.to = max
                      }

                      dispatch(storeSearchObject({
                        ...currentSearchObject,
                        price: priceValues
                      }))
                    }}
                  />
                </Flexbox>
              )}
            </>
          )}
          {isMobile ? (
            <img src={IconFilter} width={30} height={30} onClick={handleAdvancedOptions} style={{ marginBottom: 5 }} />
          ) : (
            <Button secondary icon={<img src={IconFilter} width={20} height={20} />} onClick={handleAdvancedOptions} style={{ width: 97, height: 40, borderRadius: 0 }}>
              Filter
            </Button>
          )}
        </Flexbox>
        {/*  <Flexbox onClick={() => {
          document.body.style.overflow = 'hidden'
          setIsOpeningNeighborhoodsModal(true)
        }} row style={{ cursor: 'pointer', alignSelf: 'flex-start', alignItems: 'center', height: 36, gap: 10 }}>
          <img src={IconMapNeighborhoods} width={18} height={18} />
          <TypeFace semiBold size={14} style={{ cursor: 'pointer' }}>
            See All Neighborhoods
          </TypeFace>
        </Flexbox> */}
      </Flexbox>
      <HomeAdvancedSearchFilterPopup
        modalIsOpen={isOpeningAdvancedFilters}
        filters={currentSearchObject}
        closeModal={() => setIsOpeningAdvancedFilters(false)}
      />
      {isMobile ? (
        <SelectNeighborhoodsModalMobile
          modalIsOpen={isOpeningNeighborhoodsModal}
          onApply={handleApplyNeighborSelections}
          selection={currentSelectedNeiborhoods.map(v => v.value)}
          closeModal={() => setIsOpeningNeighborhoodsModal(false)}
        />
      ) : (
        <SelectNeighborhoodsModal
          modalIsOpen={isOpeningNeighborhoodsModal}
          onApply={handleApplyNeighborSelections}
          selection={currentSelectedNeiborhoods.map(v => v.value)}
          closeModal={() => setIsOpeningNeighborhoodsModal(false)}
        />
      )}
    </Flexbox>
  )
}

export default SearchOptionsBar

const styles = {
  container: {
    width: '100%',
    position: 'relative',
    borderBottom: '1px solid #ddd',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 16,
    paddingBottom: 16
  },
  content: {
    width: '100%',
    height: '100%',
    gap: 10,
    alignItems: 'flex-end'
  },
  optionBox: {
    marginTop: 5,
    width: 200,
  },
  locationInput: {
    marginTop: 5,
    width: 260,
    shadowColor: Colors.gray1,
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.2,
    shadowRadius: 3,
    borderRadius: 5
    //backgroundColor: 'red',
  },
  mapIconContainer: {
    cursor: 'pointer',
    display: 'flex',
    width: 30,
    height: 30,
    alignItems: 'center',
    justifyContent: 'center',
  },
  tagInputContainer: {
    border: '1px solid black',
    padding: '.5em',
    borderRadius: '3px',
    width: '95%',
    //width: 'min(80vw, 600px)',
    //marginTop: '1em',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: '.5em',
    fontSize: '12px',
  },
  tagItem: {
    backgroundColor: '#F1F1F1',
    display: 'inline-block',
    padding: '.5em .75em',
    //border: '1px solid black',
    // boxShadow: 'none'
  },
  tagItemClose: {
    height: '10px',
    width: '10px',
    fontWeight: 'bold',
    color: '#000',
    borderRadius: '50%',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '.5em',
    fontSize: '15px',
    cursor: 'pointer',
  },
  tagsInput: {
    flexGrow: 1,
    padding: '.5em 0',
    border: 'none',
    outline: 'none',
    fontSize: '14px',
  }
}
import Flexbox from "src/components/wrapper/Flexbox"
import TypeFace from "src/components/typography/Typefaces"
import { Colors } from "src/theme/Colors"
import { auth } from "src/firebase"
import { useNavigate } from "react-router-dom"
import { useEffect, useRef } from "react"

const AdminSideMenu = (props) => {
  const navigate = useNavigate()
  const unmountedRef = useRef(false)

  const handleLogout = () => {
    auth.signOut()
    navigate('/')
  }

  useEffect(() => {
    unmountedRef.current = false
    return () => {
      unmountedRef.current = true
    }
  }, [])

  return (
    <div style={styles.menu}>
      <Flexbox row style={styles.menuItem} onClick={() => props.onSelect('dashboard')}>
        <TypeFace size={14} semiBold color={props.selected === 'dashboard' ? Colors.primary : "#7f7f7f"} style={{ cursor: 'pointer' }}>
          Dashboard
        </TypeFace>
      </Flexbox>
      <Flexbox row style={styles.menuItem} onClick={() => props.onSelect('payments')}>
        <TypeFace size={14} semiBold color={props.selected === 'payments' ? Colors.primary : "#7f7f7f"} style={{ cursor: 'pointer' }}>
          Payments
        </TypeFace>
      </Flexbox>
      <Flexbox row style={styles.menuItem} onClick={() => props.onSelect('customers')}>
        <TypeFace size={14} semiBold color={props.selected === 'customers' ? Colors.primary : "#7f7f7f"} style={{ cursor: 'pointer' }}>
          Customers
        </TypeFace>
      </Flexbox>
      <Flexbox row style={styles.menuItem} onClick={() => props.onSelect('agencies')}>
        <TypeFace size={14} semiBold color={props.selected === 'agencies' ? Colors.primary : "#7f7f7f"} style={{ cursor: 'pointer' }}>
          Licensed Brokerages
        </TypeFace>
      </Flexbox>
      <Flexbox row style={styles.menuItem} onClick={() => props.onSelect('managementCompanies')}>
        <TypeFace size={14} semiBold color={props.selected === 'managementCompanies' ? Colors.primary : '#7f7f7f'} style={{ cursor: 'pointer' }}>
          Management Companies
        </TypeFace>
      </Flexbox>
      <Flexbox row style={styles.menuItem} onClick={() => props.onSelect('releasedAgents')}>
        <TypeFace size={14} semiBold color={props.selected === 'releasedAgents' ? Colors.primary : "#7f7f7f"} style={{ cursor: 'pointer' }}>
          Released Agents
        </TypeFace>
      </Flexbox>
      <Flexbox row style={styles.menuItem} onClick={() => props.onSelect('listings')}>
        <TypeFace size={14} semiBold color={props.selected === 'listings' ? Colors.primary : "#7f7f7f"} style={{ cursor: 'pointer' }}>
          Listings
        </TypeFace>
      </Flexbox>
      <Flexbox row style={styles.menuItem} onClick={() => props.onSelect('agencyRequests')}>
        <TypeFace size={14} semiBold color={props.selected === 'agencyRequests' ? Colors.primary : "#7f7f7f"} style={{ cursor: 'pointer' }}>
          Sales/Rental Leads
        </TypeFace>
      </Flexbox>
      <Flexbox row style={styles.menuItem} onClick={() => props.onSelect('reports')}>
        <TypeFace size={14} semiBold color={props.selected === 'reports' ? Colors.primary : "#7f7f7f"} style={{ cursor: 'pointer' }}>
          Customer Reports
        </TypeFace>
      </Flexbox>
      <Flexbox row style={styles.menuItem} onClick={() => props.onSelect('reviews')}>
        <TypeFace size={14} semiBold color={props.selected === 'reviews' ? Colors.primary : "#7f7f7f"} style={{ cursor: 'pointer' }}>
          Customer Reviews
        </TypeFace>
      </Flexbox>
      <Flexbox row style={styles.menuItem} onClick={handleLogout}>
        <TypeFace size={14} semiBold color={"#7f7f7f"} style={{ cursor: 'pointer' }}>
          Log out
        </TypeFace>
      </Flexbox>
    </div>
  )
}

export default AdminSideMenu

const styles = {
  menu: {
    width: 180,
    display: 'flex',
    flexDirection: 'column',
    padding: '0px 0px',
  },
  menuItem: {
    alignItems: 'center',
    height: 50,
    gap: 10,
    cursor: 'pointer'
  },
  iconBox: {
    width: 30,
    height: 30,
    justifyContent: 'center',
    alignItems: 'center'
  },
  unreadBadge: {
    width: 20,
    height: 20,
    justifyContent: 'center',
    borderRadius: 10,
    backgroundColor: 'red'
  }
}
import React, { useMemo, useRef, useState } from 'react';
import Modal from 'react-modal';
import { useAlert } from 'src/hooks/alert/AlertContext';
import Loading from 'react-fullscreen-loading';
import { useFirestore } from 'src/hooks/firestore/FirestoreContext';
import Flexbox from 'src/components/wrapper/Flexbox';
import TypeFace from 'src/components/typography/Typefaces';
import Button from 'src/components/button/Button';
import Calendar from 'react-calendar';
import moment from 'moment';
import TextInput from 'src/components/input/TextInput';
import 'react-calendar/dist/Calendar.css';
import './MeetLaterProcessModal.css'
import { validateEmailAddress } from 'src/utils/strings';
import { addDoc, arrayUnion, collection, doc, getDocs, increment, query, setDoc, updateDoc, where, getDoc } from 'firebase/firestore';
import { auth, firestore } from 'src/firebase';
import { signInAnonymously,signInWithPhoneNumber,
  RecaptchaVerifier } from 'firebase/auth';
import ReactSelect from 'react-select';
import useMediaQuery from 'src/hooks/useMediaQuery';
import VerificationModal from 'src/components/auth/VerificationModal';
import { sendPushNotificationForChat } from 'src/utils/strings';

export const configureTimeSearchOptions = () => {
  let options = []
  for (let h = 7; h <= 21; h++) {
    for (let m = 0; m < 60; m += 15) {
      const value = `${h}:${m}`
      options = [...options, {label: moment(value, 'H:m').format("h:mm a"), value}]
    }
  }

  return options
}

const MeetLaterProcessModal = ({
  modalIsOpen,
  onCloseModal,
  listingData,
  onSubmitted,
}) => {

  var recaptchaWrapperRef;

  const { isMobile } = useMediaQuery()
  const { userProfile } = useFirestore()

  const {showAlert} = useAlert()

  const [meetLaterDate, setMeetLaterDate] = useState(null)
  const [meetLaterTime, setMeetLaterTime] = useState(null)
  const [name, setName] = useState(userProfile?.name ?? "")
  const [email, setEmail] = useState(userProfile?.email ?? "")
  const [phoneNumber, setPhoneNumber] = useState(userProfile?.phone ?? "")
  const [signUpPhoneNumber, setSignUpPhoneNumber] = useState("")
  const [error, setError] = useState("")


  const [processing, setProcessing] = useState(false)
  const [confirmResult, setConfirmResult] = useState('')
  const [isVerificationModalOpen, setIsVerificationModalOpen] = React.useState(false);

  const refContainer = useRef(null)

  const submitEnabled = useMemo(() => {
    return meetLaterDate && meetLaterTime && name !== "" && email !== ""
  }, [name, email, meetLaterDate, meetLaterTime])

  const timeOptions = configureTimeSearchOptions()

  function afterOpenModal() {
    document.body.style.overflow = 'hidden'

    setTimeout(() => {
      if (refContainer.current) {
        refContainer.current.focus()
      } else {
        console.log("ASDFASDFASDFASDFASDFASDFASDFASDFASDFASDFADSF")
      }
    }, 500)
  }

  function closeModal() {
    document.body.style.overflow = 'auto'
    setMeetLaterDate(null)
    setMeetLaterTime(null)
    onCloseModal()
  }

  const processScheduleAndMessages = (userId, meetTime, listingAgentId, listingId) => {

    const message = {
      at: new Date().getTime(),
      by: listingData.by,
      msg: `Meeting scheduled at ${moment(new Date(meetTime.getTime())).format('LT')} ${moment(new Date(meetTime.getTime())).format('LL')}`,
      extra: {
        type: 'schedule'
      },
     // unread: [listingData.by]
     unread: listingData.team
    }

    var sender_array = []
    listingData?.team?.map((teamId) => {
      sender_array.push(teamId)
    })
    sender_array.push(userId)

    console.log('sender_array=',sender_array)

    addDoc(collection(firestore, 'messages'), {
      listing: listingData.id,
      agency: listingData.agency,
      lm: message,
      senders: sender_array,
      //senders: [listingData.by, userId],
    }).then(messageRef => {
      
      const messageThreadId = messageRef.id
      
      addDoc(collection(firestore, `messages/${messageThreadId}/chat`), message).then(chatRef => {

        listingData?.team?.map((teamId) => {
          updateDoc(doc(firestore, `messages/${messageRef.id}`), {
            [`unreads.${teamId}`]: arrayUnion(chatRef.id)
          })

          // send push notification on Meet Later

          getDoc(doc(firestore, `profiles/${teamId}`)).then(profileDoc => {
            const myProfile = profileDoc.data()
            sendPushNotificationForChat("Meet Later Request","You have received a new meet later request.",myProfile.deviceToken,"NewChat")
          })

        })

        setDoc(doc(firestore, `schedules/${messageThreadId}`), {
          listingAgentId,
          team: listingData.team,
          listingId,
          threadId: messageThreadId,
          agency: listingData.agency,
          customer: {
            id: userId,
            email,
            phone: phoneNumber,
            name,
            when: meetTime.getTime(),
          },
        }).then(() => {

          listingData?.team?.map((teamId) => {
              updateDoc(doc(firestore, `profiles/${teamId}`), {
                "notifications.schedule": arrayUnion(messageThreadId),
                "notifications.message": arrayUnion(messageThreadId),
              })
              .then(() => {})
              .catch(err => {
                console.log("Notifying to agent failed with Error => ", err)
              })
          })

          updateDoc(doc(firestore, `profiles/${userId}`), {
            "notifications.schedule": arrayUnion(messageThreadId),
          })
          .then(() => {})
          .catch(err => {
            console.log("Notifying to agent failed with Error => ", err)
          })

          listingData?.team?.map((teamId) => {
            addDoc(collection(firestore, `profiles/${teamId}/notifications`), {
              type: 'meet_later_schedule',
              body: `New meeting was scheduled`,
              when: new Date().getTime(),
              extra: {
                listingId,
                messageThreadId,
                customer: userId,
                scheduleWhen: meetTime.getTime(),
              }
            })
            .then(() => {
            })
            .catch(err => {
              console.log("Notification adding failed ", err)
            })
          })
          
          
          setProcessing(false)
          onSubmitted({
            meetTime: meetTime.getTime(),
            messageThreadId,
          })
          closeModal()

        }).catch(err => {
          setProcessing(false)
          console.log("Error in adding schedule => ", err)
        })
      })
    }).catch(err => {
      setProcessing(false)
      console.log("Error in adding message => ", err)
    })

    
  }

  const handleSubmit = async () => {   
    if (!validateEmailAddress(email)) {
      //&& email.includes("@")
      setError("Email is invalid")
      return
    }

    setError("")
    setProcessing(true)

    const meetTime = moment(moment(meetLaterDate).format('YYYY-MM-DD') + moment(meetLaterTime).format('h:mm a'), "YYYY-MM-DDh:mm a").toDate()
    const listingAgentId = listingData.by
    const listingId = listingData.id

    const q = query(
      collection(firestore, 'schedules'),
      where("listingAgentId", "==", listingAgentId),
      // where("listingId", "==", listingId)
    )
    const scheduledDocs = await getDocs(q)
    let schedulesList = []
    scheduledDocs.forEach(doc => {
      schedulesList.push({
        ...doc.data(),
        id: doc.id
      })
    })

    const scheduleConflicted = schedulesList.some(schedule => {
      const minuteDiff = (schedule.customer.when - meetTime.getTime()) / 1000 / 60
      console.log("Minute diff => ", minuteDiff)
      return minuteDiff >= -15 && minuteDiff <= 15
    })

    if (scheduleConflicted) {
      setProcessing(false)
      showAlert(true, "Agent is not available at that time. Please choose another timeslots")
    } else {
      if (userProfile) {
        processScheduleAndMessages(userProfile.userId, meetTime, listingAgentId, listingId)
      } else {
        //if(signUpPhoneNumber === '') {
          signInAnonymously(auth)
          .then(userCredential => {
            const userId = userCredential.user.uid
            const profileDoc = doc(firestore, 'profiles', userId)
            setDoc(profileDoc, {
              userId,
              created: new Date().getTime(),
              name: name,
              email: email,
              phone: phoneNumber,
              nameL: name.toLowerCase(),
              emailL: email.toLowerCase(),
              role: "customer",
              profileCreated: false,
            }).then(res => {
              processScheduleAndMessages(userId, meetTime, listingAgentId, listingId)
            }).catch(err => {
              setProcessing(false)
              console.log("Error => err in profile doc set", err)
              setProcessing(false)
            })
          })
          .catch(err => {
            setProcessing(false)
            console.log("Error in anom sign in => ", err)
          })
       /*  }
        else {
          signInUserWithPhoneNumber()
        } */
        
      }
    }
  }

  const handleCancel = () => {
    closeModal()
  }

  const sendDataToParent = (uid) => { 
    const meetTime = moment(moment(meetLaterDate).format('YYYY-MM-DD') + moment(meetLaterTime).format('h:mm a'), "YYYY-MM-DDh:mm a").toDate()
    const listingAgentId = listingData.by
    const listingId = listingData.id
    const userId = uid
    const profileDoc = doc(firestore, 'profiles', userId)
    setDoc(profileDoc, {
      userId,
      created: new Date().getTime(),
      name: name,
      email: email,
      phone: phoneNumber,
      nameL: name.toLowerCase(),
      emailL: email.toLowerCase(),
      role: "customer",
      profileCreated: true,
      profileCreatedAt: new Date().getTime(),
    }).then(res => {
      processScheduleAndMessages(userId, meetTime, listingAgentId, listingId)
    }).catch(err => {
      setProcessing(false)
      console.log("Error => err in profile doc set", err)
      setProcessing(false)
    })
           
  }

  const setUpRecaptcha = () => {
    window.recaptchaVerifier = new RecaptchaVerifier(
      "recaptcha-container",
      {
      size: "invisible", // this property is important otherwise the captcha will be displayed on the screen
      },
      auth
      );
     window.recaptchaVerifier.verify();
  }; 

  function signInUserWithPhoneNumber() {
          
    fetch('https://us-central1-listmeetdev.cloudfunctions.net/checkPhoneNumberExists', {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        phoneNumber: signUpPhoneNumber,
      })
    })
    .then(response => response.json())
    .then(async res => {
      console.log('resPHONEExist=',res)
      if(res.phoneNumberExists === false) {
        setUpRecaptcha();
        const appVerifier = await window.recaptchaVerifier;
        console.log("appVerifier",appVerifier)
        try {
          const response = await signInWithPhoneNumber(auth, signUpPhoneNumber, appVerifier);
          setProcessing(false)
          setConfirmResult(response);
          console.log(response.verificationId) 
          setIsVerificationModalOpen(true)  
          if (appVerifier) {
            appVerifier.clear();
            document.getElementById('recaptchaWrapper').innerHTML = `<div id="recaptcha-container"></div>`
          }
        }
        catch (e) {
          console.log(e);
          setIsVerificationModalOpen(false) 
          setError('Please enter valid phone number to signup')
          if (appVerifier) {
            appVerifier.clear();
            document.getElementById('recaptchaWrapper').innerHTML = `<div id="recaptcha-container"></div>`
          }
          setProcessing(false);
        }
      }
      else {
          setProcessing(false)
          setError("Phone number already in use. Please login instead.")
      }
      
    })
    .catch(err => {
      setProcessing(false)
    })
}

  const phoneNumberAutoFormat = (phoneNumber) => {
    const number = phoneNumber.trim().replace(/[^0-9]/g, "");
  
    if (number.length < 4) return number;
    if (number.length < 7) return `(${number.slice(0, 3)}) ${number.slice(3)}`; 
    return `(${number.slice(0, 3)}) ${number.slice(3, 6)}-${number.slice(6, 10)}`; 
  };

 

  const onChangeEmailPhone= (e) => {
    const emailPhone = e.target.value
    if(emailPhone.length === 0) {
      setSignUpPhoneNumber("");
      setEmail("");
      setError("");
    }
    if(emailPhone.includes('@') === false) {
      if(/^\d+$/.test(emailPhone)){
        if(emailPhone.length === 10) {
          const targetValue = phoneNumberAutoFormat(emailPhone);
          const phoneOnly = emailPhone.replace('(','').replace(')','').replace('-','').replace(' ','')
          const countryCodetargetValue = '+1' + phoneOnly;
          console.log('countryCodetargetValue=',countryCodetargetValue);
          setSignUpPhoneNumber(countryCodetargetValue);
          setEmail(targetValue);
        }
        else if(email.length > 10) {
          setEmail(email) 
        }else
        {
          setEmail(emailPhone);
        }
      }
      else
       {
          if(email.includes('(') && emailPhone.length > 14){
            setEmail(emailPhone.slice(0,14))
          }else{
            setEmail(emailPhone)
          }
      }
    }
    else {
      setEmail(emailPhone)
    }
  };

  const onChangePhone= (e) => {
    const enteredPhone = e.target.value
    if(enteredPhone.length === 0) {
      setPhoneNumber("");
      setError("");
    }
    if(/^\d+$/.test(enteredPhone)){
      if(enteredPhone.length === 10) {
        const targetValue = phoneNumberAutoFormat(enteredPhone);
        setPhoneNumber(targetValue);
      }
      else if(phoneNumber.length > 10) {
        setPhoneNumber(phoneNumber) 
      }else
      {
        setPhoneNumber(enteredPhone);
      }
    }
    else
     {
        if(phoneNumber.includes('(') && enteredPhone.length > 14){
          setPhoneNumber(enteredPhone.slice(0,14))
        }else{
          setPhoneNumber(enteredPhone)
        }
    }
  };

  return (
    <>
    <div ref={ref => recaptchaWrapperRef = ref} id='recaptchaWrapper'>
      <div id="recaptcha-container"></div>
    </div>
    <Modal
      isOpen={modalIsOpen}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      className="mymodal"
      overlayClassName="myoverlay"
    >
      <div
        ref={refContainer}
        style={{
          ...styles.container,
          display: 'flex',
          width: isMobile ? 'calc(100vw - 80px)' : 360,
          height: isMobile ? 'unset' : 640,
          padding: isMobile ? '20px 16px 16px' : '20px 30px 30px',
        }}
        tabIndex={-1}
        onKeyDown={event => {
          if (event.key === 'Enter') {
            if (submitEnabled) {
              handleSubmit()
            }
            return
          }
        }}
      >
        <Flexbox style={styles.content}>
          <TypeFace bold size={18}>
            Schedule a Showing
          </TypeFace>
          <div style={{ height: 20 }} />
          <Flexbox style={{
            border: '1px solid #d6d6d6',
            paddingBottom: 10,
          }}>
            <Calendar
              minDate={new Date()}
              value={meetLaterDate}
              calendarType={'US'}
              onChange={(value, event) => setMeetLaterDate(value)}
            />
            <Flexbox row style={{ 
              width: 'calc(100% - 40px)', marginTop: 20, gap: 20,
            }}>
              <TypeFace medium>
                Date: 
              </TypeFace>
              <TypeFace semiBold>
                {meetLaterDate ? moment(meetLaterDate).format('MM/DD/YYYY') : 'MM/DD/YYYY'}
              </TypeFace>
            </Flexbox>
            <Flexbox row style={{ width: 'calc(100% - 40px)', marginTop: 10, gap: 20 }}>
              <TypeFace medium>
                Time: 
              </TypeFace>
              <div style={{ width: isMobile ? 200 : 300}}>
                <ReactSelect
                  options={timeOptions}
                  components={{ DropdownIndicator: () => null, IndicatorSeparator:() => null }}
                  onChange={value => {
                    setMeetLaterTime(moment(value.value, 'H:m').toDate())
                  }}
                />
              </div>
            </Flexbox>
          </Flexbox>
          
          <TypeFace medium style={{ marginTop: 15, alignSelf: 'flex-start' }}>
            Name
          </TypeFace>
          <TextInput
            style={styles.textInput}
            value={name}
            onChange={e => setName(e.target.value)}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                if (submitEnabled) {
                  handleSubmit()
                }
                return
              }
            }}
          />
          <TypeFace medium style={{ marginTop: 10, alignSelf: 'flex-start' }}>
            Email
          </TypeFace>
          <TextInput
            style={styles.textInput}
            value={email}
            //onChange={onChangeEmailPhone}
            onChange={e => setEmail(e.target.value)}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                if (submitEnabled) {
                  handleSubmit()
                }
                return
              }
            }}
          />
          <TypeFace medium style={{ marginTop: 10, alignSelf: 'flex-start' }}>
            Phone(Optional)
          </TypeFace>
          <TextInput
            style={styles.textInput}
            value={phoneNumber}
            //onChange={e => setPhoneNumber(e.target.value)}
            onChange={onChangePhone}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                if (submitEnabled) {
                  handleSubmit()
                }
                return
              }
              if (event.key !== "Tab" && event.key !== "Backspace" && event.key !== "ArrowLeft" && event.key !== "ArrowRight" && event.key !== "Delete" && !/[0-9]/.test(event.key)) {
                event.preventDefault()
              }
            }}
          />
          {!!error && (
            <TypeFace color='red' size={11}>
              {error}
            </TypeFace>
          )}
          <Flexbox row style={styles.buttons}>
            <Button disabled={!submitEnabled} primary onClick={handleSubmit} style={styles.loginButton}>
              Submit
            </Button>
            <Button secondary onClick={handleCancel} style={styles.loginButton}>
              Cancel
            </Button>
          </Flexbox>
        </Flexbox>
        
      </div>
      {processing && <Loading loading background="#0005" loaderColor="white" />}
    </Modal>

    <VerificationModal
        modalIsOpen={isVerificationModalOpen}
        phoneNumber = {signUpPhoneNumber}
        sendDataToParent = {sendDataToParent} 
        confirmationResult={confirmResult} 
        closeModal={() => setIsVerificationModalOpen(false)}
      />
    </>
  )
}

export default MeetLaterProcessModal

const styles = {
  container: {
    width: 360,
    height: 640,
    // overflow: 'auto',
    padding: '20px 30px 30px',
  },
  content: {
    width: '100%',
    alignItems: 'center',
  },
  loginButton: {
    flex: 1,
    height: 40,
  },
  buttons: {
    width: '100%',
    marginTop: 20,
    justifyContent: 'space-between',
    gap: 20,
  },
  licenseNumberInput: {
    width: '100%',
    marginTop: 5,
  },
  textInput: {
    marginTop: 5,
    width: '100%',
    borderRadius: 0,
    border: '1px solid #000',
    height: 40,
  },
  passwordInput: {
    width: '100%',
    marginTop: 10,
  }
}
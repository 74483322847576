import React from 'react'
import IconBell from 'src/assets/images/icons/icon-bell.svg'
import IconHeart from 'src/assets/images/icons/icon-clock.svg'
import IconMessage from 'src/assets/images/icons/icon-message.svg'
import IconHomeProperty from 'src/assets/images/icons/icon-home-property.svg'
import TypeFace from '../typography/Typefaces'
import useMediaQuery from 'src/hooks/useMediaQuery'
import Flexbox from '../wrapper/Flexbox'

const HomeBuildProfilePanel = () => {

  const { isMobile } = useMediaQuery()

  return (
    <Flexbox style={{ flexDirection: isMobile ? 'column' : 'row', width: '100%', justifyContent: 'space-between', marginTop: 64, marginBottom: 40 }}>
      <div style={styles.actionItem}>
        <img src={IconHeart} width={40} height={40} />
        <TypeFace bold size={isMobile ? 14 : 18} color='black' style={styles.actionItemLabel}>
          On Demand<br />Showings
        </TypeFace>
      </div>
      <div style={styles.actionItem}>
        <img src={IconBell} width={48} height={48} />
        <TypeFace bold size={isMobile ? 14 : 18} color='black' style={styles.actionItemLabel}>
          New Listing<br />Notifications
        </TypeFace>
      </div>
      <div style={styles.actionItem}>
        <img src={IconMessage} width={48} height={48} />
        <TypeFace bold size={isMobile ? 14 : 18} color='black' style={styles.actionItemLabel}>
          Realtime Visit<br />Confirmation
        </TypeFace>
      </div>
      <div style={styles.actionItem}>
        <img src={IconHomeProperty} width={48} height={48} />
        <TypeFace bold size={isMobile ? 14 : 18} color='black' style={styles.actionItemLabel}>
          Get the Right Property
        </TypeFace>
      </div>
    </Flexbox>
  )
}

export default HomeBuildProfilePanel

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: "80px 20px",
    width: '100%',
    alignItems: 'center',
    gap: 10
  },
  actionCardStyle: {
    flex: 1,
    display: 'flex',
    height: 320,
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    width: '90%',
    paddingTop: 30,
    maxWidth: 1178,
    gap: 30,
  },
  actionItem: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    width: 268,
    height: 268,
    backgroundColor: 'white',
    borderRadius: 16,
    boxShadow: '0 0 6px #aaaaaa',
  },
  actionItemLabel: {
    marginTop: 30,
  }
}
import React, { useEffect, useState } from 'react';
import HomeHeaderDesktop from 'src/components/home/HomeHeaderDesktop';
import { useAuth } from 'src/hooks/auth/AuthContext';
import { useNavigate, useParams } from 'react-router-dom';
import useMediaQuery from 'src/hooks/useMediaQuery';
import Sidebar from 'src/components/sidebar/Sidebar';
import AdminSideMenu from './AdminSideMenu';
import AdminDashboard from './components/dashboard/AdminDashboard';
import AdminAgencyRequests from './components/agencyRequests/AdminAgencyRequests';
import AdminManagementCompanies from './components/managementCompanies/AdminManagementCompanies';
import AdminListingReports from './components/reports/AdminListingReports';
import AdminListingReviews from './components/reviews/AdminListingReviews';
import AdminAgenciesList from './components/adminAgencies/AdminAgenciesList';
import { useFirestore } from 'src/hooks/firestore/FirestoreContext';
import AdminAgencyDetails from './components/adminAgencies/AdminAgencyDetails';
import AdminCustomerList from './components/customers/AdminCustomerList';
import AdminListings from './components/listings/AdminListings';
import AdminListingDetails from './components/listings/AdminListingDetails';
import AdminPayments from './components/payments/AdminPayments';
import AdminReleasedAgents from './components/releasedAgents/AdminReleasedAgents';
import PropertyListing from './components/listings/PropertyListing';

const AdminBoard = () => {  

  const { page, subPage } = useParams()
  const { isAuthInitialized, userProfile } = useFirestore()

  const navigate = useNavigate()

  const [section, setSection] = useState(page ?? 'dashboard')
  const [mobileMenuOpened, setMobileMenuOpened] = useState(false)

  const { isMobile } = useMediaQuery()

  const handleSelectSubPage = (subPage) => {
    setMobileMenuOpened(false)
    navigate('/admin/board/' + subPage)
  }

  useEffect(() => {
    setSection(page ?? 'dashboard')
  }, [page])

  useEffect(() => {
    if (isAuthInitialized) {
      if (userProfile?.role !== 'admin') {
        navigate('/')
      }
    }
  }, [userProfile, isAuthInitialized])
  
  return (
    <div className="App">
      {/* {isMobile && (
        <Sidebar
          isOpen={mobileMenuOpened}
          pageWrapId={"page-wrap"}
          outerContainerId={"App"}
          selected={section}
          onSelect={handleSelectSubPage}
          onStateChange={state => setMobileMenuOpened(state.isOpen)}
        />
      )} */}
      <div id="page-wrap">
        <HomeHeaderDesktop />
        <div style={styles.container}>
          <div style={styles.content}>
            {!isMobile && (
              <AdminSideMenu selected={section} onSelect={handleSelectSubPage} />
            )}
            <div style={styles.vertSeparator} />
            <div style={styles.contentArea}>
              {section === 'dashboard' && <AdminDashboard />}
              {section === 'payments' && <AdminPayments />}
              {section === 'agencyRequests' && <AdminAgencyRequests />}
              {section === 'managementCompanies' && <AdminManagementCompanies />}
              {section === 'reports' && <AdminListingReports />}
              {section === 'reviews' && <AdminListingReviews />}
              {section === 'customers' && <AdminCustomerList />}
              {section === 'releasedAgents' && <AdminReleasedAgents />}
              {section === 'listings' && (
                <>
                  {!!subPage ? (
                    <AdminListingDetails listingId={subPage} />
                  ) : (
                    <AdminListings />
                  )}
                </>
              )}
              {section === 'agencies' && (
                <>
                  {!!subPage ? (
                    <AdminAgencyDetails agencyId={subPage} />
                  ) : (
                    <AdminAgenciesList />
                  )}
                </>
              )}
              {section === 'propertyListing' && (
                <>
                  {!!subPage ? (
                    <PropertyListing creatorId={subPage} />
                  ) : (
                    <PropertyListing />
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AdminBoard

const styles = {
  container: {
    width: '100vw',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    height: 'calc(100vh - 70px)',
    width: '90%',
    maxWidth: 1178,
    borderTop: '1px solid #dfdfdf',
  },
  contentArea: {
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    padding: '20px 16px'
  },
  vertSeparator: {
    width: 1,
    height: '100%',
    backgroundColor: '#DFDFDF'
  }
}
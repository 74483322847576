import { Colors } from "src/theme/Colors"
import TypeFace from "../typography/Typefaces"
import Flexbox from "../wrapper/Flexbox"
import IconDelete from "src/assets/images/icons/icon-delete-red.svg"
import IconEdit from "src/assets/images/icons/icon-edit.svg"
import { useNavigate } from "react-router-dom"
import { useEffect, useRef, useState } from "react"
import { addDoc, collection, deleteDoc, doc, onSnapshot, updateDoc } from "firebase/firestore"
import { firestore } from "src/firebase"
import IconCalendar from "src/assets/images/icons/icon-calendar.svg"
import moment from "moment"
import { scheduleFromToString } from "src/utils/strings"
import { useAlert } from "src/hooks/alert/AlertContext"
import useMediaQuery from "src/hooks/useMediaQuery"
import { extractStreetAddressFromFullAddress } from "src/utils/geoUtils"
import AddOpenHouseModal from "../listing/AddOpenHouseModal"
import { getFirestoreAgencyDoc } from "src/data/dbWrappers"
import { deleteAlgoliaObject } from "src/algolia"
import { format } from 'date-fns'

const MyDraftPropertyCell = ({listing, editAvailable}) => {

  const navigate = useNavigate()

  const { showAlert } = useAlert()

  const { isMobile } = useMediaQuery()

  const unmountedRef = useRef(false)
  const unsubscribeRef = useRef()

  const [openHouseSchedules, setOpenHouseSchedules] = useState([])

  const [addOpenHouseOpening, setAddOpenHouseOpening] = useState(false)
  const [editingSchedule, setEditingSchedule] = useState()
  const [listingAgency, setListingAgency] = useState()

  const handleClickEdit = () => {
    navigate(`/edit/listing/${listing.id}`)
  }

  const handleClickDelete = () => {
    showAlert(true, 'Are you sure you want to delete the listing?', [
      {
        title: "Yes",
        onClick: () => {
          showAlert(false)
          deleteDoc(doc(firestore, `listing/${listing.id}`))
          if (listing.algoliaID) {
            deleteAlgoliaObject(listing.algoliaID)
          }
        }
      },
      {
        title: "No",
        onClick: () => {
          showAlert(false)
        }
      },
    ])
  }

  const handleCancelSchedule = (schedule) => {
    deleteDoc(doc(firestore, `listing/${listing.id}/schedules/${schedule.id}`))
  }

  const handleEditSchedule = (schedule) => {
    setEditingSchedule(schedule)
    setAddOpenHouseOpening(true)
  }

  const handleEditOpenHouse = async (scheduleId, date, startHour, startMin, endHour, endMin, byAppointmentOnly) => {
    if(JSON.stringify(endHour).length < 2) {
      endHour = '0' + endHour
    }
    if(JSON.stringify(endMin).length < 2) {
      endMin = endMin + '0'
    }
    const record = {
      date: date.getTime(),
      scheduleDateTime: format(date, 'yyyy-MM-dd') + " " + endHour + ":" + endMin,
      start: {
        h: startHour,
        m: startMin,
      },
      end: {
        h: endHour,
        m: endMin,
      },
      appointmentOnly: byAppointmentOnly ?? false
    }

    updateDoc(doc(firestore, `listing/${listing.id}/schedules/${scheduleId}`), record)
  }

  const handleAddOpenHouse = async (date, startHour, startMin, endHour, endMin, byAppointmentOnly) => {
    if(JSON.stringify(endHour).length < 2) {
      endHour = '0' + endHour
    }
    if(JSON.stringify(endMin).length < 2) {
      endMin = endMin + '0'
    }
    const record = {
      date: date.getTime(),
      scheduleDateTime: format(date, 'yyyy-MM-dd') + " " + endHour + ":" + endMin,
      start: {
        h: startHour,
        m: startMin,
      },
      end: {
        h: endHour,
        m: endMin,
      },
      appointmentOnly: byAppointmentOnly ?? false
    }

    const docRef = await addDoc(collection(firestore, `listing/${listing.id}/schedules`), record)
    updateDoc(docRef, { id: docRef.id })
    record.id= docRef.id

    setOpenHouseSchedules([...openHouseSchedules, record])
  }

  useEffect(() => {
    if (listing.id) {
      unsubscribeRef.current = onSnapshot(collection(firestore, `listing/${listing.id}/schedules`), querySnapshot => {
        let schedules = []
        querySnapshot.forEach(doc => {
          //schedules.push(doc.data())
          const scheduledDateTime = new Date(doc.data().scheduleDateTime); 
          if (scheduledDateTime >= new Date()) {
            schedules.push(doc.data())
          }
        })
        setOpenHouseSchedules(schedules)
      })
    }
  }, [listing.id])

  useEffect(() => {
    if (listing.agency) {
      getFirestoreAgencyDoc(listing.agency).then(agencyDoc => {
        if (!unmountedRef.current) {
          setListingAgency(agencyDoc.data())
        }
      })
    }
  }, [listing.agency])

  useEffect(() => {
    unmountedRef.current = false
    return () => {
      unmountedRef.current = true
      unsubscribeRef.current?.()
    }
  }, []);

  return (
    <Flexbox style={styles.container}>
      <div style={styles.horSeparator} />
      <Flexbox row style={styles.contentRow}>
        <Flexbox style={styles.address}>
        {listing.unit !== '' && (
          <TypeFace semiBold color={Colors.primary}>
            Unit {listing.unit}
          </TypeFace>
        )}
          <TypeFace color={Colors.primary} size={14}>
            {extractStreetAddressFromFullAddress(listing.address.full)}
          </TypeFace>
          <TypeFace size={11}>
            {listingAgency?.name}
          </TypeFace>
        </Flexbox>
        {editAvailable && (
          <Flexbox row style={{ gap: isMobile ? 10 : 20, marginRight: 20, flexDirection: isMobile ? 'column' : 'row', alignItems: isMobile ? 'flex-start' : 'center' }}>
            <Flexbox onClick={handleClickEdit} row style={styles.rowButtons}>
              <img src={IconEdit} width={12} height={12} />
              <TypeFace bold size={10} color={Colors.primary} style={{ cursor: 'pointer' }}>
                EDIT
              </TypeFace>
            </Flexbox>
            <Flexbox onClick={handleClickDelete} row style={styles.rowButtons}>
              <img src={IconDelete} width={12} height={12} />
              <TypeFace bold size={10} color={'red'} style={{ cursor: 'pointer' }}>
                DELETE
              </TypeFace>
            </Flexbox>
          </Flexbox>
        )}
      </Flexbox>
      <Flexbox style={styles.schedules}>
        {openHouseSchedules.map(schedule => (
          <Flexbox row style={{
            ...styles.scheduleRow,
            marginLeft: isMobile ? 10 : 20,
            gap: isMobile ? 5 : 10
          }}>
            <img src={IconCalendar} width={16} height={16} />
            <TypeFace size={isMobile ? 10 : 12}>
              Open house: {moment(new Date(schedule.date)).format('ddd, MMM D')} ({scheduleFromToString(schedule)})
            </TypeFace>
            <div style={{ flex: 1 }} />
            <TypeFace bold size={10} color={Colors.primary} style={{ cursor: 'pointer' }} onClick={() => handleEditSchedule(schedule)}>
              Edit
            </TypeFace>
            <TypeFace bold size={10} color={'red'} style={{ cursor: 'pointer' }} onClick={() => handleCancelSchedule(schedule)}>
              Cancel
            </TypeFace>
          </Flexbox>
        ))}
      </Flexbox>
      <AddOpenHouseModal
        modalIsOpen={addOpenHouseOpening}
        schedule={editingSchedule}
        onEditOpenHouse={handleEditOpenHouse}
        onAddOpenHouse={handleAddOpenHouse}
        closeModal={() => setAddOpenHouseOpening(false)}
      />
    </Flexbox>
  )
}

export default MyDraftPropertyCell

const styles = {
  container: {
    boxShadow: '0 0 3px #CFCFCF3F',
    backgroundColor: Colors.gray3,
    width: '100%',
    marginBottom: 20,
  },
  horSeparator: {
    backgroundColor: '#efefef',
    width: '100%',
    height: 1,   
  },
  statusRow: {
    width: '100%',
    height: 60,
    backgroundColor: 'white',
    gap: 20
  },
  contentRow: {
    width: '100%',
    height: 80,
    backgroundColor: 'white',
    gap: 20
  },
  draftBadge: {
    backgroundColor: Colors.gray2,
    width: 60,
    height: 32,
    justifyContent: 'center',
    alignItems: 'center',
  },
  rowButtons: {
    gap: 5,
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  address: {
    flex: 1,
    alignItems: 'flex-start',
    marginLeft: 20,
  },
  schedules: {
    width: '100%',
    alignItems: 'flex-start',
  },
  scheduleRow: {
    width: 'calc(100% - 40px)',
    alignItems: 'center',
    height: 44,
    marginLeft: 20,
    gap: 10
  }
}
import React, { useEffect, useMemo, useRef, useState } from 'react'
import TypeFace from 'src/components/typography/Typefaces'
import Flexbox from 'src/components/wrapper/Flexbox'
import { useFirestore } from 'src/hooks/firestore/FirestoreContext'
import AdminListingCell from './AdminListingCell'
import Button from 'src/components/button/Button'
import { searchObjects } from 'src/algolia'

const AdminListings = () => {

  const { userProfile } = useFirestore()

  const unmountedRef = useRef(false)

  const [rentals, setRentals] = useState(true)
  const [sales, setSales] = useState(true)
  const [actives, setActives] = useState(true)
  const [underContracts, setUnderContracts] = useState(false)
  const [offMarkets, setOffMarkets] = useState(false)
  const [delisted, setDelisted] = useState(false)
  const [blocked, setBlocked] = useState(false)

  const [hits, setHits] = useState()

  const handleUpdate = () => {
    let filters = ""
    if (rentals && !sales) {
      filters = "type:rental "
    } else if (!rentals && sales) {
      filters = "type:sale "
    } else {
      filters = ""
    }

    if (actives || underContracts || offMarkets || delisted) {
      filters += (rentals && sales) ? "" : "AND ("

      let including = false
      if (actives) {
        filters += "status:Active"
        including = true
      }

      if (underContracts) {
        if (including) {
          filters += " OR status:Contract"
        } else {
          filters += "status:Contract"
        }
        including = true
      }

      if (offMarkets) {
        if (including) {
          filters += " OR status:OffMarket"
        } else {
          filters += "status:OffMarket"
        }
        including = true
      }

      if (delisted) {
        if (including) {
          filters += " OR status:Delisted"
        } else {
          filters += "status:Delisted"
        }
        including = true
      }

      if (blocked) {
        if (including) {
          filters += " OR status:Blocked"
        } else {
          filters += "status:Blocked"
        }
        including = true
      }

      filters += (rentals && sales) ? "" : ")"
    }

    searchObjects('', filters).then(res => {
      setHits(res.hits)
    })
  }

  const fetchAvailable = useMemo(() => {
    if (!sales && !rentals) {
      return false
    }

    if (!actives && !underContracts && !offMarkets && !delisted && !blocked) {
      return false
    }

    return true
  }, [sales, rentals, actives, underContracts, offMarkets, delisted, blocked])

  useEffect(() => {
    unmountedRef.current = false

    handleUpdate()
    return () => {
      unmountedRef.current = true
    }
  }, [])

  return (
    <Flexbox style={{ alignItems: 'flex-start', width: '100%' }}>
      <Flexbox row style={{ width: '100%', justifyContent: 'flex-start', alignItems: 'center'}}>
        <TypeFace bold>
          Listings
        </TypeFace>
      </Flexbox>
      <Flexbox row style={{ marginTop: 10, marginLeft: 16, gap: 20 }}>
        <TypeFace size={11}>
          Type:
        </TypeFace>
        <Flexbox row style={{ gap: 5 }}>
          <input type="checkbox" id="type-rental" checked={rentals} onChange={e => setRentals(e.target.checked)}/>
          <TypeFace for="type-rental" size={11}>
            RENTALS
          </TypeFace>
        </Flexbox>
        <Flexbox row style={{ gap: 5 }}>
          <input type="checkbox" id="type-sales" checked={sales} onChange={e => setSales(e.target.checked)} />
          <TypeFace for="type-sales" size={11}>
            SALES
          </TypeFace>
        </Flexbox>
      </Flexbox>
      <Flexbox row style={{ marginTop: 10, marginLeft: 16, gap: 20 }}>
        <TypeFace size={11}>
          Status:
        </TypeFace>
        <Flexbox row style={{ gap: 5 }}>
          <input type="checkbox" id="status-active" checked={actives} onChange={e => setActives(e.target.checked)} />
          <TypeFace for="status-active" size={11}>
            Actives
          </TypeFace>
        </Flexbox>
        <Flexbox row style={{ gap: 5 }}>
          <input type="checkbox" id="status-contracts" checked={underContracts} onChange={e => setUnderContracts(e.target.checked)} />
          <TypeFace for="status-contracts" size={11}>
            Under contracts
          </TypeFace>
        </Flexbox>
        <Flexbox row style={{ gap: 5 }}>
          <input type="checkbox" id="status-offmarkets" checked={offMarkets} onChange={e => setOffMarkets(e.target.checked)} />
          <TypeFace for="status-offmarkets" size={11}>
            Off markets
          </TypeFace>
        </Flexbox>
        <Flexbox row style={{ gap: 5 }}>
          <input type="checkbox" id="status-delisted" checked={delisted} onChange={e => setDelisted(e.target.checked)} />
          <TypeFace for="status-delisted" size={11}>
            Delisted
          </TypeFace>
        </Flexbox>
        <Flexbox row style={{ gap: 5 }}>
          <input type="checkbox" id="status-blocked" checked={blocked} onChange={e => setBlocked(e.target.checked)} />
          <TypeFace for="status-blocked" size={11}>
            Blocked
          </TypeFace>
        </Flexbox>
      </Flexbox>
      <Button secondary style={{ marginTop: 10, marginLeft: 16 }} onClick={handleUpdate} disabled={!fetchAvailable}>
        Fetch
      </Button>
      <Flexbox row style={styles.headerRow}>
        <TypeFace size={10} style={{ flex: 1.4 }}>
          Address
        </TypeFace>
        <TypeFace size={10} style={{ flex: 1 }}>
          Type
        </TypeFace>
        <TypeFace size={10} style={{ flex: 1 }}>
          Status
        </TypeFace>
        <TypeFace size={10} style={{ flex: 1 }}>
          Price
        </TypeFace>
        <TypeFace size={10} style={{ flex: 1 }}>
          Agency
        </TypeFace>
        <TypeFace size={10} style={{ width: 120 }}>
          Actions
        </TypeFace>
      </Flexbox>
      {hits?.map((item, index) => (
        <AdminListingCell
          key={index}
          algoliaListingData={item}
        />
      ))}
    </Flexbox>
  )
}

export default AdminListings

const styles = {
  headerRow: {
    width: '100%',
    height: 30,
    marginTop: 20,
    backgroundColor: '#dfdfdf',
    alignItems: 'center',
  }
}
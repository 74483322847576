import Flexbox from "src/components/wrapper/Flexbox"
import TypeFace from "src/components/typography/Typefaces"
import IconAgentsGray from 'src/assets/images/icons/menu/icon-agents-gray.svg'
import IconAgentsBlue from 'src/assets/images/icons/menu/icon-agents-blue.svg'
import IconPropertyGray from 'src/assets/images/icons/menu/icon-properties-gray.svg'
import IconPropertyBlue from 'src/assets/images/icons/menu/icon-properties-blue.svg'
import IconSettingsGray from 'src/assets/images/icons/menu/icon-settings-gray.svg'
import IconSettingsBlue from 'src/assets/images/icons/menu/icon-settings-blue.svg'
import IconChatsGray from 'src/assets/images/icons/menu/icon-chats-gray.svg'
import IconChatsBlue from 'src/assets/images/icons/menu/icon-chats-blue.svg'
import IconScheduleGray from 'src/assets/images/icons/menu/icon-schedule-gray.svg'
import IconScheduleBlue from 'src/assets/images/icons/icon-calendar.svg'
import IconLogout from 'src/assets/images/icons/icon-menu-logout.svg'
import { Colors } from "src/theme/Colors"
import { auth } from "src/firebase"
import { useNavigate } from "react-router-dom"
import { useEffect, useRef, useMemo } from "react"
import { useFirestore } from "src/hooks/firestore/FirestoreContext"
import { get } from "lodash"
import { UserRoleValue } from "src/model/types"

const ManageSideMenu = (props) => {
  const { userProfile,myAgencyAgentsList } = useFirestore()
  const navigate = useNavigate()
  const unmountedRef = useRef(false)

  const handleLogout = () => {
    auth.signOut()
    navigate('/')
  }

  useEffect(() => {
    unmountedRef.current = false
    return () => {
      unmountedRef.current = true
    }
  }, [])

  const messages = get(userProfile, "notifications.message", [])
  const schedules = get(userProfile, "notifications.schedule", [])

  const agentsList = useMemo(() => {
    return myAgencyAgentsList?.filter(agent => (agent.role === UserRoleValue.Agent || agent.role === UserRoleValue.SalesPerson) && agent.agencyApproved === false && (agent.agencyDeclined === false || agent.agencyDeclined === undefined))
  }, [myAgencyAgentsList])

  return (
    <div style={styles.menu}>
      <Flexbox row style={styles.menuItem} onClick={() => props.onSelect('settings')}>
        <Flexbox style={styles.iconBox}>
          <img src={props.selected === 'settings' ? IconSettingsBlue : IconSettingsGray} width={30} height={30} />
        </Flexbox>
        <TypeFace semiBold color={props.selected === 'settings' ? Colors.primary : "#7f7f7f"} style={{ cursor: 'pointer' }}>
          My Profile
        </TypeFace>
      </Flexbox>
      {userProfile?.role === 'broker' && (
        
        <Flexbox row style={styles.menuItem} onClick={() => props.onSelect('agents')}>
          <Flexbox style={styles.iconBox}>
            <img src={props.selected === 'agents' ? IconAgentsBlue : IconAgentsGray} width={26} height={26} />
          </Flexbox>
          <TypeFace semiBold color={props.selected === 'agents' ? Colors.primary : "#7f7f7f"} style={{ cursor: 'pointer' }}>
            Agents
          </TypeFace>
          {agentsList?.length > 0 && (
          <Flexbox style={styles.unreadBadge}>
            <TypeFace bold size={11} color="white">
              {agentsList?.length}
            </TypeFace>
          </Flexbox>  
        )}
        </Flexbox>
       
         
        
      )}
      <Flexbox row style={styles.menuItem} onClick={() => props.onSelect('properties')}>
        <Flexbox style={styles.iconBox}>
          <img src={props.selected === 'properties' ? IconPropertyBlue : IconPropertyGray} width={26} height={26} />
        </Flexbox>
        <TypeFace semiBold color={props.selected === 'properties' ? Colors.primary : "#7f7f7f"} style={{ cursor: 'pointer' }}>
          Listings
        </TypeFace>
      </Flexbox>
      <Flexbox row style={styles.menuItem} onClick={() => props.onSelect('schedules')}>
        <Flexbox style={styles.iconBox}>
          <img src={props.selected === 'schedules' ? IconScheduleBlue : IconScheduleGray} width={30} height={30} />
        </Flexbox>
        <TypeFace semiBold color={props.selected === 'schedules' ? Colors.primary : '#7f7f7f'} style={{ cursor: 'pointer' }}>
          My Schedules
        </TypeFace>
        {(schedules.length > 0 && !userProfile?.profileBlocked) && (
          <Flexbox style={styles.unreadBadge}>
            <TypeFace bold size={11} color="white">
              {schedules.length}
            </TypeFace>
          </Flexbox>  
        )}
      </Flexbox>
      <Flexbox row style={styles.menuItem} onClick={() => props.onSelect('messages')}>
        <Flexbox style={styles.iconBox}>
          <img src={props.selected === 'messages' ? IconChatsBlue : IconChatsGray} width={30} height={30} />
        </Flexbox>
        <TypeFace semiBold color={props.selected === 'messages' ? Colors.primary : "#7f7f7f"} style={{ cursor: 'pointer' }}>
          Messages
        </TypeFace>
        {(messages.length > 0 && !userProfile?.profileBlocked) && (
          <Flexbox style={styles.unreadBadge}>
            <TypeFace bold size={11} color="white">
              {messages.length}
            </TypeFace>
          </Flexbox>  
        )}
      </Flexbox>
      <Flexbox row style={styles.menuItem} onClick={handleLogout}>
        <Flexbox style={styles.iconBox}>
          <img src={IconLogout} width={30} height={30} />
        </Flexbox>
        <TypeFace semiBold color={"#7f7f7f"} style={{ cursor: 'pointer' }}>
          Log out
        </TypeFace>
      </Flexbox>
    </div>
  )
}

export default ManageSideMenu

const styles = {
  menu: {
    width: 200,
    display: 'flex',
    flexDirection: 'column',
    padding: '20px 30px',
  },
  menuItem: {
    alignItems: 'center',
    height: 60,
    gap: 10,
    cursor: 'pointer'
  },
  iconBox: {
    width: 30,
    height: 30,
    justifyContent: 'center',
    alignItems: 'center'
  },
  unreadBadge: {
    width: 20,
    height: 20,
    justifyContent: 'center',
    borderRadius: 10,
    backgroundColor: 'red'
  }
}
import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import { Colors } from 'src/theme/Colors';
import useMediaQuery from 'src/hooks/useMediaQuery';
import { algoliaFilterTextFromSearchObject } from 'src/data/algolia-data-adjust';
import { searchObjects } from 'src/algolia';
import TypeFace from 'src/components/typography/Typefaces';
import Flexbox from 'src/components/wrapper/Flexbox';
import CardSearchResultItemInHome from 'src/components/home/search/CardSearchResultItemInHome';

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} slick-arrows`}
      style={style}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} slick-arrows`}
      style={style}
      onClick={onClick}
    />
  );
}

const ListingSimilarProperties = ({
  listingId,
  type,
  location,
  neighborhood,
}) => {

  
  const { isMobile } =  useMediaQuery()

  const [recommendedListings, setRecommendedListings] = useState([])

  const settings = {
    dots: true,
    infinite: false,
    slidesToShow: isMobile ? 1 : Math.min(recommendedListings.length, 3),
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />
  };

  useEffect(() => {
    if (listingId) {
      let similarListingsFilters = algoliaFilterTextFromSearchObject({
        type,
      })
      similarListingsFilters += ` AND NOT dataId:${listingId}`
      let propertyLocation =   location.lat + "," + location.lng
      searchObjects('', similarListingsFilters, 'newest',[], propertyLocation, 4828).then(res => {
        setRecommendedListings(res.hits)
      })
    }
  }, [listingId, type])

  if (!recommendedListings || recommendedListings.length === 0) {
    return null
  }

  return (
    <div style={styles.container}>
      <Flexbox style={{ maxWidth: 1178, width: '90%', alignItems: 'flex-start' }}>
        <TypeFace semiBold size={20} style={styles.headerText} type='div'>
          Similar Properties
        </TypeFace>
        <div style={{
          width: (!isMobile && recommendedListings.length > 0 && recommendedListings.length < 3) ? 388 * recommendedListings.length : '100%',
          alignSelf: 'flex-start'
        }}>
          <Slider {...settings}>
            {recommendedListings.map((data, index) => (
              <div key={`similar-${index}`}>
                <CardSearchResultItemInHome hit={data} />
              </div>  
            ))}
          </Slider>
        </div>
      </Flexbox>
    </div>
  );
}

export default ListingSimilarProperties

const styles = {
  container: {
    paddingBottom: 0,
    backgroundColor: Colors.background1,
    display: 'flex',
    justifyContent: 'center'
  },
  headerText: {
    textAlign: 'start',
    marginTop: 30,
    marginBottom: 20
  }
}
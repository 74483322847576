import { collection, doc, getDoc, getDocs, onSnapshot, orderBy, query, updateDoc, where } from 'firebase/firestore'
import moment from 'moment'
import React, { useEffect, useRef } from 'react'
import { useState } from 'react'
import { updateAlogliaObject } from 'src/algolia'
import Button from 'src/components/button/Button'
import TypeFace from 'src/components/typography/Typefaces'
import Flexbox from 'src/components/wrapper/Flexbox'
import { firestore } from 'src/firebase'
import { roleLabelFromValue } from 'src/model/types'
import { Colors } from 'src/theme/Colors'

const AdminReleasedAgents = () => {

  const unmountedRef = useRef(false)
  const [agencies, setAgencies] = useState()


  useEffect(() => {
    unmountedRef.current = false
    const snapshotListener = onSnapshot(query(collection(firestore, 'profiles'), orderBy('releasedDate', 'desc'), where('role', 'in', ['agent','salesperson']), where('agencyReleased', '==', true)), (querySnapshot) => {
      if (!unmountedRef.current) {
        const d = [];
        querySnapshot.forEach((doc) => {
          console.log('doc=',doc.data())
          d.push({
            id: doc.id,
            ...doc.data()
          });
        });
        setAgencies(d)
      }
    })
    return () => {
      unmountedRef.current = true
      snapshotListener()
    }
  }, [])

  return (
    <Flexbox style={{ alignItems: 'flex-start', width: '100%' }}>
      <TypeFace bold>
        {agencies?.name ?? "Released Agents"}
      </TypeFace>
      <Flexbox row style={{
        width: '100%',
        height: 30,
        marginTop: 20,
        backgroundColor: '#dfdfdf',
        alignItems: 'center',
      }}>
        <TypeFace size={10} style={{ flex: 1 }}>
          Name
        </TypeFace>
        <TypeFace size={10} style={{ flex: 1 }}>
          Email
        </TypeFace>
        <TypeFace size={10} style={{ flex: 1 }}>
          License Number
        </TypeFace>
        <TypeFace size={10} style={{ flex: 1 }}>
          License Name
        </TypeFace>
        <TypeFace size={10} style={{ flex: 1 }}>
          Role
        </TypeFace>
        <TypeFace size={10} style={{ flex: 1 }}>
          Status
        </TypeFace>
        <TypeFace size={10} style={{ flex: 1 }}>
          Released Date
        </TypeFace>
       
      </Flexbox>
      {agencies?.map(agency => (
        <Flexbox row key={agency.id} style={{
          width: '100%',
          minHeight: 30,
          alignItems: 'center',
          borderBottom: '1px solid #dfdfdf'
        }}>
          <TypeFace size={10} style={{ flex: 1 }}>
            {agency.name}
          </TypeFace>
          <TypeFace size={10} style={{ flex: 1 }}>
            {agency.email}
          </TypeFace>
          <TypeFace size={10} style={{ flex: 1 }}>
            {agency.licenseNumber}
          </TypeFace>
          <TypeFace size={10} style={{ flex: 1 }}>
            {agency.licenseName}
          </TypeFace>
          <TypeFace size={10} style={{ flex: 1 }}>
            {roleLabelFromValue(agency.role)}
          </TypeFace>
          <TypeFace size={10} style={{ flex: 1 }}>
            Released
          </TypeFace>
          <TypeFace size={10} style={{ flex: 1 }}>
            {moment(new Date(agency.releasedDate)).format('MMM DD, yyyy')}
          </TypeFace>
         
        </Flexbox>
      ))}
    </Flexbox>
  )
}

export default AdminReleasedAgents

const styles = {
  contentContainer: {
    width: '100%',
    gap: 20,
    flexWrap: 'wrap',
  },
  contentItem: {
    alignItems: 'flex-start',
    justifyContent: 'center',
    width: 160,
    height: 80,
    border: '1px solid #ededed',
    marginBottom: 20,
    padding: 20,
    borderRadius: 10,   
  },
  listingContentItem: {
    alignItems: 'flex-start',
    justifyContent: 'center',
    width: 270,
    height: 60,
    border: '1px solid #ededed',
    marginBottom: 20,
    padding: 20,
    borderRadius: 10,   
  },
  listingFullWidth: {
    alignItems: 'flex-start',
    justifyContent: 'center',
    width: 600,
    height: 60,
    border: '1px solid #ededed',
    marginBottom: 20,
    padding: 20,
    borderRadius: 10,   
  }
}
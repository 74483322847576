import { useEffect, useMemo, useRef, useState } from "react"
import Button from "src/components/button/Button"
import AddressUnitInputModal from "src/components/listing/AddressUnitInputModal"
import TypeFace from "src/components/typography/Typefaces"
import Flexbox from "src/components/wrapper/Flexbox"
import { useAuth } from "src/hooks/auth/AuthContext"
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { auth, firestore } from "src/firebase"
import MyDraftPropertyCell from "src/components/properties/MyDraftPropertyCell"
import { useNavigate } from "react-router-dom"
import AccountBasic from "src/pages/account/AccountBasic"

const Settings = () => {

  const { user } = useAuth()
  const navigate = useNavigate()

  const handleLogout = () => {
    auth.signOut()
    navigate('/')
  }

  return (
    <Flexbox style={{ alignItems: 'flex-start' }}>
      {/* <TypeFace>
        Settings
      </TypeFace> */}
      <AccountBasic />
      {/* <Button secondary onClick={handleLogout} style={{
        marginTop: 40,
        height: 44,
      }}>
        Log out
      </Button> */}
    </Flexbox>
  )
}

export default Settings

const styles = {
  tabBarSection: {
    width: '100%',
    marginTop: 20
  },
  sectionButtons: {
    height: 40,
    border: '1px solid #dfdfdf',
    cursor: 'pointer',
  },
  sectionButtonItem: {
    width: 160,
    height: 40,
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  vertSeparator: {
    backgroundColor: '#dfdfdf',
    width: 1,
    height: '100%',
  },
  tabBarItemText: {
    cursor: 'pointer',
  },
  createButton: {
    height: 40,
    borderRadius: 0
  },
  horSeparator: {
    backgroundColor: '#efefef',
    width: '100%',
    height: 1,   
  }
}
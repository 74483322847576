import React, { useMemo, useState } from 'react';
import Button from '../button/Button';
import TextInput from '../input/TextInput';
import TypeFace from '../typography/Typefaces';
import Flexbox from '../wrapper/Flexbox';
import Modal from 'react-modal';
import { useNavigate } from "react-router-dom";
import Loading from 'react-fullscreen-loading';
import PlacesAutocomplete from '../placeAutoComplete/PlaceAutoComplete';
import { createNewListing } from 'src/data/createListing';
import { useAuth } from 'src/hooks/auth/AuthContext';
import { useFirestore } from 'src/hooks/firestore/FirestoreContext';
import useMediaQuery from 'src/hooks/useMediaQuery';
import { UserRoleValue } from 'src/model/types';

const AddressUnitInputModal = (props) => {

  const navigate = useNavigate();

  const { userProfile, myAgency } = useFirestore()

  const { isMobile } = useMediaQuery()

  const { user } = useAuth()
  const [address, setAddress] = useState('')
  const [addressComponents, setAddressComponents] = useState({})
  const [latitude, setLatitude] = useState(0)
  const [longitude, setLongitude] = useState(0)
  const [unit, setUnit] = useState('')
  
  const [processing, setProcessing] = useState(false)

  const createButtonEnabled = useMemo(() => {
    return address !== "" && latitude !== 0 && longitude !== 0 
    //&& unit !== ""
  }, [address, latitude, longitude])

  const closeModal = () => {
    props.closeModal()
    document.body.style.overflow = 'auto'
  }

  const handleCreate = () => {
    setProcessing(true)
    createNewListing(user.uid, props.type, address, latitude, longitude, unit, addressComponents, userProfile.role === UserRoleValue.ManageMent ? userProfile.userId : myAgency.id, userProfile.role === UserRoleValue.ManageMent)
      .then(docId => {
        setProcessing(false)
        props.closeModal()
        document.body.style.overflow = 'auto'
        props.onCreateNewListing?.(address, latitude, longitude, unit)
        navigate(`/edit/listing/${docId}`)
      })
      .catch(err => {
        props.closeModal()
        document.body.style.overflow = 'auto'
        props.onCreateNewListing?.(address, latitude, longitude, unit)
        setProcessing(false)
      })
  }

  return (
    <Modal
      isOpen={props.modalIsOpen}
      onRequestClose={closeModal}
      className="mymodal"
      overlayClassName="myoverlay"
    >
      <Flexbox style={{ ...styles.container, width: isMobile ? 300 : 600 }}>
        <TypeFace bold size={18}>
          Address & Unit
        </TypeFace>
        <TypeFace size={14}>
          Enter address and unit for the listing
        </TypeFace>
        <PlacesAutocomplete isReverseGeo style={styles.emailInput} onSelectAddress={data => {
          console.log("Selected data => ", data)
          setAddressComponents({
            ...data.components,
            components: data.components.components.map(comp => ({
              types: comp.types,
              text: comp.text
            }))
          })
          setAddress(data.address)
          setLatitude(data.lat)
          setLongitude(data.lng)
        }}/>
        <TextInput
          placeholder="Unit"
          style={styles.passwordInput}
          value={unit}
          onChange={e => setUnit(e.target.value)}
        />
        <Flexbox row style={styles.buttons}>
          <Button primary disabled={!createButtonEnabled} onClick={handleCreate} style={styles.createButton}>
            CREATE
          </Button>
          <Button onClick={closeModal} style={styles.cancelButton}>
            CANCEL
          </Button>
        </Flexbox>
      </Flexbox>
      {processing && <Loading loading background="#0005" loaderColor="white" />}
    </Modal>
  )
}

export default AddressUnitInputModal

const styles = {
  container: {
    width: 600,
    padding: '40px 30px 30px',
  },
  buttons: {
    width: '100%',
    height: 50,
    marginTop: 30,
    gap: 30
  },
  createButton: {
    flex: 1,
    height: 50,
  },
  cancelButton: {
    height: 50,
    flex: 1,
    border: 'none'
  },
  emailInput: {
    width: '100%',
    marginTop: 30,
  },
  passwordInput: {
    width: '100%',
    marginTop: 10,
  }
}
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from 'src/components/button/Button';
import TypeFace from 'src/components/typography/Typefaces';
import Flexbox from 'src/components/wrapper/Flexbox';
import { useAlert } from 'src/hooks/alert/AlertContext';
import { useFirestore } from 'src/hooks/firestore/FirestoreContext';
import { Colors } from 'src/theme/Colors';

const AccountMyListings = () => {

  const { userProfile, myAgency } = useFirestore()
  const navigate = useNavigate()

  const { showAlert } = useAlert()

  const handleCreateNewSale = () => {
    if (myAgency) {
      navigate('/new-listing')
    } else {
      showAlert(true, 'Please set up agency first')
    }
  }
  
  return (
    <Flexbox style={styles.container}>
      <Flexbox style={styles.content}>
        <Flexbox style={{ width: '100%', gap: 10, alignItems: 'flex-start' }}>
          {userProfile?.license ? (
            <>
              {userProfile.license.approved ? (
                <>
                  <Button primary onClick={handleCreateNewSale} style={{
                    height: 40
                  }}>
                    Create New Sales
                  </Button>
                </>
              ) : (
                <>
                  <TypeFace semiBold size={14} color='#5f5f5f'>
                    Your license information is under review
                  </TypeFace>
                  <TypeFace medium size={14} color='#5f5f5f' style={{ textAlign: 'start'}}>
                    You will get notified once your license information get approved and then you can start listing your properties.
                  </TypeFace>
                </>
              )}
            </>
          ) : (
            <>
              <TypeFace semiBold size={14} color='#5f5f5f'>
                Do you want to sell your house?
              </TypeFace>
              <TypeFace medium size={14} color='#5f5f5f' style={{ textAlign: 'start'}}>
                In order to list your properties, please submit your license information.<br />
                Once you submit your license information and get approved, you can start selling your house
              </TypeFace>
              <TypeFace color={Colors.primary} bold size={14} style={{ marginTop: 20, cursor: 'pointer' }}>
                Submit a request
              </TypeFace>
            </>
          )}
        </Flexbox>
      </Flexbox>
    </Flexbox>
  )
}

export default AccountMyListings

const styles = {
  container: {
    width: '100%',
    alignItems: 'center',
  },
  content: {
    width: 600,
    alignItems: 'center',
    padding: 50,
  },
}
import React from "react";
import "./Modal.css";
import ReactModal from "react-modal";
import { useAlert } from "src/hooks/alert/AlertContext";
import Flexbox from "../wrapper/Flexbox";
import TypeFace from "../typography/Typefaces";
import Button from "../button/Button";

const AlertModal = () => {

  const { alertShown, showAlert, alertTitle, alertDesc, alertButtons, alertButtonsDirection } = useAlert()

  return (
    <ReactModal
      isOpen={alertShown}
      onAfterOpen={() => {}}
      onRequestClose={() => showAlert(false)}
      className="mymodal"
      overlayClassName="prompt"
    >
      <Flexbox style={styles.container}>
        <TypeFace medium size={14}>
          {alertTitle}
        </TypeFace>
        {!!alertDesc && (
          <TypeFace size={12} style={{ marginTop: 10 }}>
            {alertDesc}
          </TypeFace>
        )}
        {alertButtons.length === 0 ? (
          <Button secondary style={styles.okButton} onClick={() => showAlert(false)}>
            Ok
          </Button>
        ) : (
          <Flexbox row={alertButtonsDirection === 0} style={styles.buttonsRow}>
            {alertButtons.map((button, index) => (
              <Button
                key={`alert-button-${index}`}
                primary={index === 0}
                third={index !== 0}
                onClick={button.onClick}
                style={alertButtonsDirection === 0 ? styles.buttonItem : styles.buttonItemForColumn}
              >
                {button.title}
              </Button>
            ))}
          </Flexbox>
        )}
      </Flexbox>
    </ReactModal>
  )
}

export default AlertModal

const styles = {
  container: {
    width: 300,
    padding: 30,
    backgroundColor: 'white',
    alignItems: 'flex-start',
  },
  okButton: {
    width: '100%',
    height: 40,
    marginTop: 30,
  },
  buttonsRow: {
    width: '100%',
    gap: 10,
    marginTop: 30,
  },
  buttonItem: {
    flex: 1,
    height: 44,
  },
  buttonItemForColumn: {
    width: '100%',
    height: 44,
  }
}
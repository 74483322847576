import { useEffect, useMemo, useState } from "react"
import { buildingTypeOptions, configureYearsSearchOptions } from "src/model/types"
import TypeFace from "../typography/Typefaces"
import Flexbox from "../wrapper/Flexbox"
import useMediaQuery from "src/hooks/useMediaQuery"
import ReactSelect from "react-select/creatable"
import { components } from "react-select"

const EditListingBuilding = ({
  highlightErrorFields,
  listing,
  onUpdateBuildingFields,
}) => {

  // const { id } = useParams()
  const { isMobile } = useMediaQuery()
  
  const [yearsBuilt, setYearsBuilt] = useState()
  const [buildingType, setBuildingType] = useState()
  const [petsAllowedOption, setPetsAllowedOption] = useState()
  const [doorManOption, setDoorManOption] = useState()
  const [outdoorSpace, setOutdoorSpace] = useState([])
  const [features, setFeatures] = useState([])
  const [featureLocations, setFeatureLocations] = useState([])
  const [parking, setParking] = useState([])
  const [storage, setStorage] = useState([])
  const [sharedSpaces, setSharedSpaces] = useState([])

  const yearsSearchOptions = configureYearsSearchOptions(false)

  const selectOptionValue = useMemo(() => {
    if (yearsBuilt) {
      return yearsSearchOptions.find(option => option.value === yearsBuilt) ?? {label: `${yearsBuilt}`, value: yearsBuilt}
    }
  }, [yearsBuilt])

  const Input = (props) => {
    if (props.isHidden) {
      return <components.Input {...props} />
    }

    return (
      <components.Input type="text" {...props} onKeyDown={(event) => {
        if (event.key !== "Tab" && event.key !== "Backspace" && event.key !== "ArrowLeft" && event.key !== "ArrowRight" && event.key !== "Delete" && !/[0-9]/.test(event.key)) {
          event.preventDefault()
        }
      }} />
    )
  }

  useEffect(() => {
    if (listing) {
      setBuildingType(listing.building?.type)
      setYearsBuilt(Number(listing.building?.year ?? 0))
      setPetsAllowedOption(listing.building?.pets)
      setDoorManOption(listing.building?.doorMan)
      setOutdoorSpace(listing.building?.outdoor ?? [])
      setFeatures(listing.building?.features ?? [])
      setFeatureLocations(listing.building?.locations ?? [])
      setParking(listing.building?.parking ?? [])
      setStorage(listing.building?.storage ?? [])
      setSharedSpaces(listing.building?.shared ?? [])
    }
  }, [listing])

    return (
    <Flexbox style={styles.container}>
      <TypeFace medium size={20}>
        Building Facts
      </TypeFace>
      <Flexbox row style={{ alignItems: 'flex-start', marginTop: 10 }}>
        <Flexbox style={{ alignItems: 'flex-start' }}>
          <TypeFace bold size={12} color={(highlightErrorFields && !yearsBuilt) ? "red" : "black"}>
            Year Built<TypeFace size={16}>*</TypeFace>
          </TypeFace>
          <Flexbox row style={{ alignItems: 'flex-start', marginTop: 5 }}>
            <div style={{ width: 120 }}>
              <ReactSelect
                options={yearsSearchOptions}
                value={selectOptionValue}
                components={{ Input }}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: (highlightErrorFields && !yearsBuilt) ? 'red' : 'black'
                  })
                }}
                getOptionLabel={(option) => {
                  if (option.__isNew__) {
                    return `${option.value}`
                  } else {
                    return option.label
                  }
                }}
                onChange={value => {
                  setYearsBuilt(value.value)
                  onUpdateBuildingFields({
                    year: value.value
                  })
                }}
              />
            </div>
          </Flexbox>
        </Flexbox>
        <div style={{ width: 30 }} />
        <Flexbox style={{ alignItems: 'flex-start' }}>
          <TypeFace bold size={12} color={(highlightErrorFields && !buildingType) ? 'red' : 'black'}>
            Building Type<TypeFace size={16}>*</TypeFace>
          </TypeFace>
          <Flexbox row style={{ alignItems: 'flex-start', marginTop: 5 }}>
            <select value={buildingType} style={{...styles.dropdown, borderColor: (highlightErrorFields && !buildingType) ? 'red' : 'black'}} onChange={e => {
              setBuildingType(e.target.value)
              onUpdateBuildingFields({
                'type': e.target.value
              })
            }}>
              <option value=""></option>
              {buildingTypeOptions.map((option, index) => {
                return (listing?.type === "rental" || (listing?.type === "sale" && option.value !== "rental")) ? <option key={index} value={option.value}>{option.label}</option> : undefined
              })}
            </select>
          </Flexbox>
        </Flexbox>
      </Flexbox>
      <TypeFace medium size={20} style={{ marginTop: 60 }}>
        Building Amenities
      </TypeFace>
      <TypeFace medium size={16} style={{ marginTop: 30, marginLeft: 10 }}>
        Pet Policy
      </TypeFace>
      <TypeFace size={15} style={{ marginTop: 20, marginLeft: 20 }}>
        PETS ALLOWED
      </TypeFace>
      <Flexbox row style={{ marginTop: 10, marginLeft: 20, gap: isMobile ? 10 : 20, alignItems: isMobile ? 'flex-start' : 'center', flexDirection: isMobile ? 'column' : 'row' }}>
        <Flexbox row style={{ alignItems: 'center' }}>
          <input type="radio" id="no_pets_allowed" name="pets" checked={petsAllowedOption === 'no'} onChange={e => {
            onUpdateBuildingFields({
              'pets': 'no',
            })
            setPetsAllowedOption('no')
          }} />
          <TypeFace for="no_pets_allowed" medium size={12} style={{ marginLeft: 3 }}>
            No Pets Allowed
          </TypeFace>
        </Flexbox>
        <Flexbox row style={{ alignItems: 'center' }}>
          <input type="radio" id="cats_dogs" name="pets" checked={petsAllowedOption === 'cats_dogs'} onChange={e => {
            onUpdateBuildingFields({
              'pets': 'cats_dogs',
            })
            setPetsAllowedOption('cats_dogs')
          }}/>
          <TypeFace for="cats_dogs" medium size={12} style={{ marginLeft: 3 }}>
            Cats and Dogs Allowed
          </TypeFace>
        </Flexbox>
        <Flexbox row style={{ alignItems: 'center' }}>
          <input type="radio" id="cats_only" name="pets" checked={petsAllowedOption === 'cats'} onChange={e => {
            if (e.target.checked) {
              onUpdateBuildingFields({
                'pets': 'cats',
              })
              setPetsAllowedOption('cats')
            }
          }}/>
          <TypeFace for="cats_only" medium size={12} style={{ marginLeft: 3 }}>
            Cats Only - No Dogs
          </TypeFace>
        </Flexbox>
      </Flexbox>
      <div style={styles.separator} />
      <TypeFace medium size={16} style={{ marginTop: 50, marginLeft: 10 }}>
        Outdoor Space
      </TypeFace>
      <Flexbox row style={{ marginTop: 10, marginLeft: 20, alignItems: 'center' }}>
        <input type="checkbox" id="courtyard" name="outdoor_space" checked={outdoorSpace.includes('courtyard')} onChange={e => {
          if (e.target.checked) {
            onUpdateBuildingFields({
              'outdoor': [...outdoorSpace, 'courtyard']
            })
            setOutdoorSpace([...outdoorSpace, 'courtyard'])
          } else {
            const newOutdoorSpace = [...outdoorSpace.filter(i => i !== 'courtyard')]
            onUpdateBuildingFields({
              'outdoor': newOutdoorSpace
            })
            setOutdoorSpace(newOutdoorSpace)
          }
        }} />
        <TypeFace for="courtyard" medium size={12} style={{ marginLeft: 3 }}>
          Courtyard
        </TypeFace>
        <input type="checkbox" id="roof_deck" name="outdoor_space" checked={outdoorSpace.includes('roof')} style={{ marginLeft: 20}} onChange={e => {
          if (e.target.checked) {
            onUpdateBuildingFields({
              'outdoor': [...outdoorSpace, 'roof']
            })
            setOutdoorSpace([...outdoorSpace, 'roof'])
          } else {
            onUpdateBuildingFields({
              'outdoor': [...outdoorSpace.filter(i => i !== 'roof')]
            })
            setOutdoorSpace([...outdoorSpace.filter(i => i !== 'roof')])
          }
        }} />
        <TypeFace for="roof_deck" medium size={12} style={{ marginLeft: 3 }}>
          Roof Deck
        </TypeFace>
      </Flexbox>
      <div style={styles.separator} />
      <TypeFace medium size={16} style={{ marginTop: 50, marginLeft: 10 }}>
        Features
      </TypeFace>
      <Flexbox row style={{ marginTop: 10, marginLeft: 20, gap: isMobile ? 10 : 20, flexDirection: isMobile ? 'column' : 'row', alignItems: isMobile ? 'flex-start' : 'center' }}>
        <Flexbox row style={{ alignItems: 'center'}}>
          <input type="checkbox" id="concierge" name="features" checked={features.includes('concierge')} onChange={e => {
            if (e.target.checked) {
              onUpdateBuildingFields({
                'features': [...features, 'concierge']
              })
              setFeatures([...features, 'concierge'])
            } else {
              onUpdateBuildingFields({
                'features': [...features.filter(i => i !== 'concierge')]
              })
              setFeatures([...features.filter(i => i !== 'concierge')])
            }
          }} />
          <TypeFace for="concierge" medium size={12} style={{ marginLeft: 3 }}>
            Concierge
          </TypeFace>
        </Flexbox>
        <Flexbox row style={{ alignItems: 'center'}}>
          <input type="checkbox" id="elevator" name="features" checked={features.includes('elevator')} onChange={e => {
            if (e.target.checked) {
              onUpdateBuildingFields({
                'features': [...features, 'elevator']
              })
              setFeatures([...features, 'elevator'])
            } else {
              onUpdateBuildingFields({
                'features': [...features.filter(i => i !== 'elevator')]
              })
              setFeatures([...features.filter(i => i !== 'elevator')])
            }
          }}/>
          <TypeFace for="elevator" medium size={12} style={{ marginLeft: 3 }}>
            Elevator
          </TypeFace>
        </Flexbox>
        <Flexbox row style={{ alignItems: 'center' }}>
          <input type="checkbox" id="laundry" name="features" checked={features.includes('laundry')} onChange={e => {
            if (e.target.checked) {
              onUpdateBuildingFields({
                'features': [...features, 'laundry']
              })
              setFeatures([...features, 'laundry'])
            } else {
              onUpdateBuildingFields({
                'features': [...features.filter(i => i !== 'laundry')]
              })
              setFeatures([...features.filter(i => i !== 'laundry')])
            }
          }}/>
          <TypeFace for="laundry" medium size={12} style={{ marginLeft: 3 }}>
            Laundry in Building
          </TypeFace>
        </Flexbox>
        <Flexbox row style={{ alignItems: 'center' }}>
          <input type="checkbox" id="live-in-super" name="features" checked={features.includes('live-in-super')} onChange={e => {
            if (e.target.checked) {
              onUpdateBuildingFields({
                'features': [...features, 'live-in-super']
              })
              setFeatures([...features, 'live-in-super'])
            } else {
              onUpdateBuildingFields({
                'features': [...features.filter(i => i !== 'live-in-super')]
              })
              setFeatures([...features.filter(i => i !== 'live-in-super')])
            }
          }}/>
          <TypeFace for="live-in-super" medium size={12} style={{ marginLeft: 3 }}>
            Live in super
          </TypeFace>
        </Flexbox>
        <Flexbox row style={{ alignItems: 'center' }}>
          <input type="checkbox" id="smoke-free" name="features" checked={features.includes('smoke-free')} onChange={e => {
            if (e.target.checked) {
              onUpdateBuildingFields({
                'features': [...features, 'smoke-free']
              })
              setFeatures([...features, 'smoke-free'])
            } else {
              onUpdateBuildingFields({
                'features': [...features.filter(i => i !== 'smoke-free')]
              })
              setFeatures([...features.filter(i => i !== 'smoke-free')])
            }
          }}/>
          <TypeFace for="smoke-free" medium size={12} style={{ marginLeft: 3 }}>
            Smoke-free
          </TypeFace>
        </Flexbox>
        <Flexbox row style={{ alignItems: 'center' }}>
          <input type="checkbox" id="wheelchair-access" name="features" checked={features.includes('wheelchair-access')} onChange={e => {
            if (e.target.checked) {
              onUpdateBuildingFields({
                'features': [...features, 'wheelchair-access']
              })
              setFeatures([...features, 'wheelchair-access'])
            } else {
              onUpdateBuildingFields({
                'features': [...features.filter(i => i !== 'wheelchair-access')]
              })
              setFeatures([...features.filter(i => i !== 'wheelchair-access')])
            }
          }}/>
          <TypeFace for="wheelchair-access" medium size={12} style={{ marginLeft: 3 }}>
            Wheelchair Access
          </TypeFace>
        </Flexbox>
      </Flexbox>
      <TypeFace semiBold size={11} style={{ marginTop: 20, marginLeft: 20, }}>
        DOORMAN
      </TypeFace>
      <Flexbox row style={{ marginTop: 10, marginLeft: 20, gap: isMobile ? 10 : 20, alignItems: isMobile ? 'flex-start' : 'center', flexDirection: isMobile ? 'column' : 'row' }}>
        <Flexbox row style={{ alignItems: 'center' }}>
          <input type="radio" id="no-doorman" name="doorman" checked={doorManOption==='no'} onChange={e => {
            onUpdateBuildingFields({
              'doorMan': 'no',
            })
            setDoorManOption('no')
          }}  />
          <TypeFace for="no-doorman" medium size={12} style={{ marginLeft: 3 }}>
            No Doorman
          </TypeFace>
        </Flexbox>
        <Flexbox row style={{ alignItems: 'center' }}>
          <input type="radio" id="full-time" name="doorman" checked={doorManOption === 'full-time'} onChange={e => {
            onUpdateBuildingFields({
              'doorMan': 'full-time',
            })
            setDoorManOption('full-time')
          }}/>
          <TypeFace for="full-time" medium size={12} style={{ marginLeft: 3 }}>
            Full-time
          </TypeFace>
        </Flexbox>
        <Flexbox row style={{ alignItems: 'center' }}>
          <input type="radio" id="part-time" name="doorman" checked={doorManOption === 'part-time'} onChange={e => {
            onUpdateBuildingFields({
              'doorMan': 'part-time',
            })
            setDoorManOption('part-time')
          }}/>
          <TypeFace for="part-time" medium size={12} style={{ marginLeft: 3 }}>
            Part-time
          </TypeFace>
        </Flexbox>
        <Flexbox row style={{ alignItems: 'center' }}>
          <input type="radio" id="virtual" name="doorman" checked={doorManOption === 'virtual'} onChange={e => {
            onUpdateBuildingFields({
              'doorMan': 'virtual',
            })
            setDoorManOption('virtual')
          }}/>
          <TypeFace for="virtual" medium size={12} style={{ marginLeft: 3 }}>
            Virtual
          </TypeFace>
        </Flexbox>
      </Flexbox>
      <TypeFace semiBold size={11} style={{ marginTop: 20, marginLeft: 20, }}>
        LOCATION
      </TypeFace>
      <Flexbox row style={{ marginTop: 10, marginLeft: 20, gap: isMobile ? 10 : 20, alignItems: isMobile ? 'flex-start' : 'center', flexDirection: isMobile ? 'column' : 'row' }}>
        <Flexbox row style={{ alignItems: 'center' }}>
          <input type="checkbox" id="transportation" name="locations" checked={featureLocations.includes('transportation')} onChange={e => {
            if (e.target.checked) {
              onUpdateBuildingFields({
                'locations': [...featureLocations, 'transportation']
              })
              setFeatureLocations([...featureLocations, 'transportation'])
            } else {
              onUpdateBuildingFields({
                'locations': [...featureLocations.filter(i => i !== 'transportation')]
              })
              setFeatureLocations([...featureLocations.filter(i => i !== 'transportation')])
            }
          }} />
          <TypeFace for="transportation" medium size={12} style={{ marginLeft: 3 }}>
            Close to public transportation
          </TypeFace>
        </Flexbox>
        <Flexbox row style={{ alignItems: 'center' }}>
          <input type="checkbox" id="school" name="locations" checked={featureLocations.includes('school')} onChange={e => {
            if (e.target.checked) {
              onUpdateBuildingFields({
                'locations': [...featureLocations, 'school']
              })
              setFeatureLocations([...featureLocations, 'school'])
            } else {
              onUpdateBuildingFields({
                'locations': [...featureLocations.filter(i => i !== 'school')]
              })
              setFeatureLocations([...featureLocations.filter(i => i !== 'school')])
            }
          }} />
          <TypeFace for="school" medium size={12} style={{ marginLeft: 3 }}>
            Close to school
          </TypeFace>
        </Flexbox>
        <Flexbox row style={{ alignItems: 'center' }}>
          <input type="checkbox" id="shops" name="locations" checked={featureLocations.includes('shops')} onChange={e => {
            if (e.target.checked) {
              onUpdateBuildingFields({
                'locations': [...featureLocations, 'shops']
              })
              setFeatureLocations([...featureLocations, 'shops'])
            } else {
              onUpdateBuildingFields({
                'locations': [...featureLocations.filter(i => i !== 'shops')]
              })
              setFeatureLocations([...featureLocations.filter(i => i !== 'shops')])
            }
          }} />
          <TypeFace for="shops" medium size={12} style={{ marginLeft: 3 }}>
            Close to shops
          </TypeFace>
        </Flexbox>
        <Flexbox row style={{ alignItems: 'center' }}>
          <input type="checkbox" id="parks" name="locations" checked={featureLocations.includes('parks')} onChange={e => {
            if (e.target.checked) {
              onUpdateBuildingFields({
                'locations': [...featureLocations, 'parks']
              })
              setFeatureLocations([...featureLocations, 'parks'])
            } else {
              onUpdateBuildingFields({
                'locations': [...featureLocations.filter(i => i !== 'parks')]
              })
              setFeatureLocations([...featureLocations.filter(i => i !== 'parks')])
            }
          }} />
          <TypeFace for="parks" medium size={12} style={{ marginLeft: 3 }}>
            Close to parks
          </TypeFace>
        </Flexbox>
      </Flexbox>
      <div style={styles.separator} />
      <TypeFace medium size={16} style={{ marginTop: 50, marginLeft: 10 }}>
        Parking
      </TypeFace>
      <Flexbox row style={{ marginTop: 10, marginLeft: 20, gap: isMobile ? 10 : 20, alignItems: isMobile ? 'flex-start' : 'center', flexDirection: isMobile ? 'column' : 'row' }}>
        <Flexbox row style={{ alignItems: 'center' }}>
          <input type="checkbox" id="garage" name="parking" checked={parking.includes('garage')} onChange={e => {
            if (e.target.checked) {
              onUpdateBuildingFields({
                'parking': [...parking, 'garage']
              })
              setParking([...parking, 'garage'])
            } else {
              onUpdateBuildingFields({
                'parking': [...parking.filter(i => i !== 'garage')]
              })
              setParking([...parking.filter(i => i !== 'garage')])
            }
          }}/>
          <TypeFace for="garage" medium size={12} style={{ marginLeft: 3 }}>
            Garage Parking
          </TypeFace>
        </Flexbox>
        <Flexbox row style={{ alignItems: 'center' }}>
          <input type="checkbox" id="valet" name="parking" checked={parking.includes('valet')} onChange={e => {
            if (e.target.checked) {
              onUpdateBuildingFields({
                'parking': [...parking, 'valet']
              })
              setParking([...parking, 'valet'])
            } else {
              onUpdateBuildingFields({
                'parking': [...parking.filter(i => i !== 'valet')]
              })
              setParking([...parking.filter(i => i !== 'valet')])
            }
          }}/>
          <TypeFace for="valet" medium size={12} style={{ marginLeft: 3 }}>
            Valet Parking
          </TypeFace>
        </Flexbox>
        <Flexbox row style={{ alignItems: 'center' }}>
          <input type="checkbox" id="private" name="parking" checked={parking.includes('private')} onChange={e => {
            if (e.target.checked) {
              onUpdateBuildingFields({
                'parking': [...parking, 'private']
              })
              setParking([...parking, 'private'])
            } else {
              onUpdateBuildingFields({
                'parking': [...parking.filter(i => i !== 'private')]
              })
              setParking([...parking.filter(i => i !== 'private')])
            }
          }}/>
          <TypeFace for="private" medium size={12} style={{ marginLeft: 3 }}>
            Private Driveway
          </TypeFace>
        </Flexbox>
        <Flexbox row style={{ alignItems: 'center' }}>
          <input type="checkbox" id="other" name="parking" checked={parking.includes('other')} onChange={e => {
            if (e.target.checked) {
              onUpdateBuildingFields({
                'parking': [...parking, 'other']
              })
              setParking([...parking, 'other'])
            } else {
              onUpdateBuildingFields({
                'parking': [...parking.filter(i => i !== 'other')]
              })
              setParking([...parking.filter(i => i !== 'other')])
            }
          }}/>
          <TypeFace for="valet" medium size={12} style={{ marginLeft: 3 }}>
            Other
          </TypeFace>
        </Flexbox>
      </Flexbox>
      <div style={styles.separator} />
      <TypeFace medium size={16} style={{ marginTop: 50, marginLeft: 10 }}>
        Storage
      </TypeFace>
      <Flexbox style={{ marginTop: 10, marginLeft: 20, gap: isMobile ? 10 : 20, alignItems: isMobile ? 'flex-start' : 'center', flexDirection: isMobile ? 'column' : 'row' }}>
        <Flexbox row style={{ alignItems: 'center' }}>
          <input type="checkbox" id="bike" name="storage" checked={storage.includes('bike')} onChange={e => {
            if (e.target.checked) {
              onUpdateBuildingFields({
                'storage': [...storage, 'bike']
              })
              setStorage([...storage, 'bike'])
            } else {
              onUpdateBuildingFields({
                'storage': [...storage.filter(i => i !== 'bike')]
              })
              setStorage([...storage.filter(i => i !== 'bike')])
            }
          }} />
          <TypeFace for="bike" medium size={12} style={{ marginLeft: 3 }}>
            Bike Room
          </TypeFace>
        </Flexbox>
        <Flexbox row style={{ alignItems: 'center' }}>
          <input type="checkbox" id="cold" name="storage" checked={storage.includes('cold')} onChange={e => {
            if (e.target.checked) {
              onUpdateBuildingFields({
                'storage': [...storage, 'cold']
              })
              setStorage([...storage, 'cold'])
            } else {
              onUpdateBuildingFields({
                'storage': [...storage.filter(i => i !== 'cold')]
              })
              setStorage([...storage.filter(i => i !== 'cold')])
            }
          }}/>
          <TypeFace for="cold" medium size={12} style={{ marginLeft: 3 }}>
            Cold Storage
          </TypeFace>
        </Flexbox>
        <Flexbox row style={{ alignItems: 'center' }}>
          <input type="checkbox" id="locker" name="storage" checked={storage.includes('locker')} onChange={e => {
            if (e.target.checked) {
              onUpdateBuildingFields({
                'storage': [...storage, 'locker']
              })
              setStorage([...storage, 'locker'])
            } else {
              onUpdateBuildingFields({
                'storage': [...storage.filter(i => i !== 'locker')]
              })
              setStorage([...storage.filter(i => i !== 'locker')])
            }
          }}/>
          <TypeFace for="locker" medium size={12} style={{ marginLeft: 3 }}>
            Locker/Cage
          </TypeFace>
        </Flexbox>
        <Flexbox row style={{ alignItems: 'center' }}>
          <input type="checkbox" id="package" name="storage" checked={storage.includes('package')} onChange={e => {
            if (e.target.checked) {
              onUpdateBuildingFields({
                'storage': [...storage, 'package']
              })
              setStorage([...storage, 'package'])
            } else {
              onUpdateBuildingFields({
                'storage': [...storage.filter(i => i !== 'package')]
              })
              setStorage([...storage.filter(i => i !== 'package')])
            }
          }}/>
          <TypeFace for="package" medium size={12} style={{ marginLeft: 3 }}>
            Package Room
          </TypeFace>
        </Flexbox>
      </Flexbox>
      <div style={styles.separator} />
      <TypeFace medium size={16} style={{ marginTop: 50, marginLeft: 10 }}>
        Shared Spaces
      </TypeFace>
      <Flexbox style={{ marginTop: 10, marginLeft: 20, gap: isMobile ? 10 : 20, alignItems: isMobile ? 'flex-start' : 'center', flexDirection: isMobile ? 'column' : 'row' }}>
        <Flexbox row style={{ alignItems: 'center' }}>
          <input type="checkbox" id="children" name="shared_spaces" checked={sharedSpaces.includes('children')} onChange={e => {
            if (e.target.checked) {
              onUpdateBuildingFields({
                'shared': [...sharedSpaces, 'children']
              })
              setSharedSpaces([...sharedSpaces, 'children'])
            } else {
              onUpdateBuildingFields({
                'shared': [...sharedSpaces.filter(i => i !== 'children')]
              })
              setSharedSpaces([...sharedSpaces.filter(i => i !== 'children')])
            }
          }}/>
          <TypeFace for="children" medium size={12} style={{ marginLeft: 3 }}>
            Children's Playroom
          </TypeFace>
        </Flexbox>
        <Flexbox row style={{ alignItems: 'center' }}>
          <input type="checkbox" id="gym" name="shared_spaces" checked={sharedSpaces.includes('gym')} onChange={e => {
            if (e.target.checked) {
              onUpdateBuildingFields({
                'shared': [...sharedSpaces, 'gym']
              })
              setSharedSpaces([...sharedSpaces, 'gym'])
            } else {
              onUpdateBuildingFields({
                'shared': [...sharedSpaces.filter(i => i !== 'gym')]
              })
              setSharedSpaces([...sharedSpaces.filter(i => i !== 'gym')])
            }
          }}/>
          <TypeFace for="gym" medium size={12} style={{ marginLeft: 3 }}>
            Gym
          </TypeFace>
        </Flexbox>
        <Flexbox row style={{ alignItems: 'center' }}>
          <input type="checkbox" id="media" name="shared_spaces" checked={sharedSpaces.includes('media')} onChange={e => {
            if (e.target.checked) {
              onUpdateBuildingFields({
                'shared': [...sharedSpaces, 'media']
              })
              setSharedSpaces([...sharedSpaces, 'media'])
            } else {
              onUpdateBuildingFields({
                'shared': [...sharedSpaces.filter(i => i !== 'media')]
              })
              setSharedSpaces([...sharedSpaces.filter(i => i !== 'media')])
            }
          }}/>
          <TypeFace for="media" medium size={12} style={{ marginLeft: 3 }}>
            Media Room
          </TypeFace>
        </Flexbox>
        <Flexbox row style={{ alignItems: 'center' }}>
          <input type="checkbox" id="swimming" name="shared_spaces" checked={sharedSpaces.includes('swimming')} onChange={e => {
            if (e.target.checked) {
              onUpdateBuildingFields({
                'shared': [...sharedSpaces, 'swimming']
              })
              setSharedSpaces([...sharedSpaces, 'swimming'])
            } else {
              onUpdateBuildingFields({
                'shared': [...sharedSpaces.filter(i => i !== 'swimming')]
              })
              setSharedSpaces([...sharedSpaces.filter(i => i !== 'swimming')])
            }
          }}/>
          <TypeFace for="swimming" medium size={12} style={{ marginLeft: 3 }}>
            Swimming Pool
          </TypeFace>
        </Flexbox>
      </Flexbox>
    </Flexbox>
  )
}

export default EditListingBuilding

const styles = {
  container: {
    padding: "30px 0",
    alignItems: 'flex-start'
  },
  dropdown: {
    height: 36,
    width: 150,
  },
  separator: {
    height: 1,
    width: '100%',
    backgroundColor: '#AFAFAF',
    marginTop: 30,
  }
}
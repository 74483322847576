import { useEffect, useMemo, useRef, useState } from "react"
import Button from "src/components/button/Button"
import AddressUnitInputModal from "src/components/listing/AddressUnitInputModal"
import TypeFace from "src/components/typography/Typefaces"
import Flexbox from "src/components/wrapper/Flexbox"
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { firestore } from "src/firebase"
import { useFirestore } from "src/hooks/firestore/FirestoreContext"
import { useAlert } from "src/hooks/alert/AlertContext"
import MyDraftProperties from "src/components/properties/MyDraftProperties"
import MyActivePropertyCell from "src/components/properties/MyActivePropertyCell"
import useMediaQuery from "src/hooks/useMediaQuery"
import { UserRoleValue } from "src/model/types"
import { Colors } from "src/theme/Colors"

const MyProperties = () => {

  const { userProfile, myAgency } = useFirestore()
  const { isMobile } = useMediaQuery()

  const { showAlert } = useAlert()

  const [section, setSection] = useState(0)
  const [createModalOpening, setCreateModelOpening] = useState(false)

  const [myListings, setMyListings] = useState([])

  const unsubscribeRef = useRef()

  const handleCreate = () => {

    if (userProfile.profileBlocked) {
      showAlert(true, 'Your profile is under review, contact support@listmeet.com to reinstate your profile')
      return
    }

    if (userProfile.role === UserRoleValue.ManageMent) {
      if (userProfile.profileStatus !== 'approved') {
        showAlert(true, 'Listmeet has not approved your company yet.')
      } else {
        setCreateModelOpening(true)
      }
    } else {
      if (userProfile.agencyApproved === false) {
        showAlert(true, userProfile.role === UserRoleValue.Broker ? 'Listmeet has not approved your brokerage yet.' : 'Your agency license has not been approved, yet')
      } else if (userProfile.suspended === true) {
        showAlert(true, userProfile.role === UserRoleValue.Broker ? 'Your brokerage has been suspended.' : 'Contact your broker to reinstate your account.')
      } else {
        if (myAgency) {
          setCreateModelOpening(true)
        } else {
          showAlert(true, 'Please set up agency first')
        }
      }
    }
  }
  const draftListings = useMemo(() => {
    return myListings?.filter(listing => {
      if (section === 2) {
        return !listing.managing && (listing.agency !== myAgency?.id && listing.status === 'Draft')
      } else {
        if (listing.managing) {
          if (listing.status === 'Draft') {
            if (section === 0) {
              return listing.type === 'sale'
            } else {
              return listing.type === 'rental'
            }  
          } else {
            return false
          }
        } else {
          if (listing.agency === myAgency?.id && listing.status === 'Draft') {
            if (section === 0) {
              return listing.type === 'sale'
            } else {
              return listing.type === 'rental'
            }
          } else {
            return false
          }
        }
      }
    })
  }, [myListings, section])

  const salesActiveListingCount = useMemo(() => {
    return myListings.filter(listing => {
      return listing.status === 'Active' && listing.type === 'sale' && listing.agency === myAgency?.id
    })?.length
  }, [myListings])

  const rentalsActiveListingCount = useMemo(() => {
    return myListings.filter(listing => {
      return listing.status === 'Active' && listing.type === 'rental' && listing.agency === myAgency?.id
    })?.length
  }, [myListings, myAgency])

  const activeListings = useMemo(() => {
    return myListings?.filter(listing => {
      if (section === 2) {
        return !listing.managing && listing.status === 'Active' && listing.agency !== myAgency?.id
      } else {
        if (listing.managing) {
          if (listing.status === 'Active') {
            if (section === 0) {
              return listing.type === 'sale'
            } else {
              return listing.type === 'rental'
            }  
          } else {
            return false
          }
        } else {
          if (listing.agency !== myAgency?.id) {
            return false
          }
          return listing.status === 'Active' && ((section === 0 && listing.type === 'sale') || (section === 1 && listing.type === 'rental'))
        }
      }
    })
  }, [myListings, section, myAgency])

  const otherListings = useMemo(() => {
    return myListings?.filter(listing => {
      if (section === 2) {
        return !listing.managing && (listing.status !== 'Active' && listing.status !== 'Draft') && listing.agency !== myAgency?.id
      } else {
        if (listing.managing) {
          if (listing.status !== 'Active' && listing.status !== 'Draft') {
            if (section === 0) {
              return listing.type === 'sale'
            } else {
              return listing.type === 'rental'
            }  
          } else {
            return false
          }
        } else {
          if (listing.agency !== myAgency?.id) {
            return false
          }
          return (listing.status !== 'Active' && listing.status !== 'Draft') && ((section === 0 && listing.type === 'sale') || (section === 1 && listing.type === 'rental'))
        }
      }
    })
  }, [myListings, section, myAgency])

  useEffect(() => {
    if (userProfile?.userId) {
      const q = query(collection(firestore, 'listing'), where("team", "array-contains", userProfile.userId))
      unsubscribeRef.current = onSnapshot(q, querySnapshot => {
        let listings = []
        querySnapshot.forEach(doc => {
          const data = doc.data()
          if (data.status !== "Deleted") {
            listings.push(data)
          }
        })
        setMyListings(listings)
      })
    }
  }, [userProfile])

  useEffect(() => {
    return () => {
      unsubscribeRef.current?.()
    }
  }, []);

  return (
    <Flexbox style={{ alignItems: 'flex-start' }}>
      <Flexbox row style={{ width: '100%' }}>
        <Flexbox style={{ alignItems: 'flex-start' }}>
          <TypeFace bold>
            My Listings
          </TypeFace>
          {userProfile?.profileBlocked && (
            <TypeFace size={12} color="red">
              Your profile is under review, contact support@listmeet.com to reinstate your profile
            </TypeFace>
          )}
        </Flexbox>
        {isMobile && (
          <>
            <div style={{ flex: 1 }} />
            <Button onClick={handleCreate} primary style={{ ...styles.createButton, height: 30 }} textSize={12}>
              {section === 0 ? 'NEW SALE' : 'NEW RENTAL'}
            </Button>
          </>
        )}
      </Flexbox>
      <Flexbox row style={styles.tabBarSection}>
        <Flexbox row style={styles.sectionButtons}>
          <Flexbox
            onClick={() => setSection(0)}
            style={{
              ...styles.sectionButtonItem,
              borderBottom: section === 0 ? '4px solid #3151B7' : 'none'
            }}
          >
            <TypeFace size={12} bold style={styles.tabBarItemText}>
              Sales{salesActiveListingCount > 0 ? `(${salesActiveListingCount})` : ''}
            </TypeFace>
          </Flexbox>
          <Flexbox
            onClick={() => setSection(1)}
            style={{ ...styles.sectionButtonItem, borderBottom: section === 1 ? '4px solid #3151B7' : 'none' }}
          >
            <TypeFace size={12} bold style={styles.tabBarItemText}>
              Rentals{rentalsActiveListingCount > 0 ? `(${rentalsActiveListingCount})` : ''}
            </TypeFace>
          </Flexbox>
          {/* <Flexbox
            onClick={() => setSection(2)}
            style={{ ...styles.sectionButtonItem, borderBottom: section === 2 ? '4px solid #3151B7' : 'none' }}
          >
            <TypeFace size={12} semiBold style={styles.tabBarItemText}>
              Archived
            </TypeFace>
          </Flexbox> */}
        </Flexbox>
        {!isMobile && (
          <>
            <div style={{ flex: 1 }} />
            <Button textSize={14} onClick={handleCreate} primary style={styles.createButton}>
              {section === 0 ? 'Create New Sale' : 'Create New Rental'}
            </Button>
          </>
        )}
      </Flexbox>
      <div style={{...styles.horSeparator, marginTop: 30, marginBottom: 30}} />

      {(draftListings?.length ?? 0) > 0 && <MyDraftProperties section={section} draftListings={draftListings} />}

      {/* <TypeFace>
        {(activeListings?.length ?? 0) === 0 ? 'No' : `${activeListings.length}`} active {section === 0 ? 'sale' : 'rental'}{activeListings?.length > 1 ? 's' : ''}
      </TypeFace> */}
      {activeListings?.length === 0 ?  
      <TypeFace style={styles.noDataText} semiBold color={Colors.gray1}>
        No Listings
      </TypeFace>
        : 
      <TypeFace>
      {(activeListings?.length ?? 0) === 0 ? 'No' : `${activeListings.length}`} active {section === 0 ? 'sale' : 'rental'}{activeListings?.length > 1 ? 's' : ''}
      </TypeFace> 
    }
     
      <Flexbox style={styles.contentRow}>
        {activeListings?.map((listing, index) => (
          <MyActivePropertyCell
            listing={listing}
            key={`active-${index}-${listing.id}`}
            editAvailable={true}
          />
        ))}
      </Flexbox>

      {(otherListings?.length ?? 0) > 0 && (
        <>
          <TypeFace>
            {(otherListings?.length ?? 0) === 0 ? 'No' : `${otherListings.length}`} other {section === 0 ? 'sale' : 'rental'}{otherListings?.length > 1 ? 's' : ''}
          </TypeFace>
          
          <Flexbox style={styles.contentRow}>
            {otherListings?.map((listing, index) => (
              <MyActivePropertyCell
                listing={listing}
                key={`active-${index}-${listing.id}`}
                editAvailable={true}
              />
            ))}
          </Flexbox>
        </>
      )}

      <AddressUnitInputModal
        type={section === 0 ? 'sale' : 'rental'}
        modalIsOpen={createModalOpening}
        closeModal={() => setCreateModelOpening(false)}
      />
    </Flexbox>
  )
}

export default MyProperties

const styles = {
  tabBarSection: {
    width: '100%',
    marginTop: 20
  },
  sectionButtons: {
    height: 40,
    cursor: 'pointer',
  },
  sectionButtonItem: {
    width: 120,
    height: 40,
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  vertSeparator: {
    backgroundColor: '#dfdfdf',
    width: 1,
    height: '100%',
  },
  tabBarItemText: {
    cursor: 'pointer',
  },
  createButton: {
    height: 40,
    borderRadius: 0
  },
  horSeparator: {
    backgroundColor: '#efefef',
    width: '100%',
    height: 1,   
  },
  contentRow: {
    width: '100%',
  },
  noDataText: {
    alignSelf: 'center',
    marginTop: 40,
    textAlign: "center",
  }
}
import React from "react";
import { slide as Menu } from "react-burger-menu";
import "./Sidebar.css"
import ManageSideMenu from "src/pages/manage/ManageSideMenu";


const Sidebar = props => {
  return (
    <Menu {...props}>

      <ManageSideMenu selected={props.selected} onSelect={props.onSelect} />

      {/* <div style={styles.menu}>
        <Flexbox row style={styles.menuItem} onClick={() => props.onSelect('settings')}>
          <Flexbox style={styles.iconBox}>
            <img src={props.selected === 'settings' ? IconSettingsBlue : IconSettingsGray} width={30} height={30} />
          </Flexbox>
          <TypeFace semiBold color={props.selected === 'settings' ? Colors.primary : "#7f7f7f"} style={{ cursor: 'pointer' }}>
            My Profile
          </TypeFace>
        </Flexbox>
      </div>

      <Typography variant="h1">h1. heading</Typography>

      <a className="menu-item" href="/about">
        About
      </a>

      <a className="menu-item" href="/services">
        Services
      </a>

      <a className="menu-item" href="/contact">
        Contact us
      </a> */}
    </Menu>
  );
};

export default Sidebar

const styles = {
  menu: {
    width: 200,
    display: 'flex',
    flexDirection: 'column',
    padding: '20px 30px',
  },
  menuItem: {
    alignItems: 'center',
    height: 60,
    gap: 20,
    cursor: 'pointer'
  },
  iconBox: {
    width: 30,
    height: 30,
    justifyContent: 'center',
    alignItems: 'center'
  }
}
import { collection, doc, onSnapshot, query, updateDoc, where, orderBy } from 'firebase/firestore'
import moment from 'moment'
import React, { useEffect, useRef } from 'react'
import { useState } from 'react'
import Button from 'src/components/button/Button'
import TypeFace from 'src/components/typography/Typefaces'
import Flexbox from 'src/components/wrapper/Flexbox'
import { firestore } from 'src/firebase'
import { Colors } from 'src/theme/Colors'
import { useNavigate } from 'react-router'

const AdminManagementCompanies = () => {

  const unmountedRef = useRef(false)
  const navigate = useNavigate()

  const [managementCompanies, setManagementCompanies] = useState()

  const handleApproveCompany = (company) => {
    updateDoc(doc(firestore, `profiles/${company.userId}`), {
      profileStatus: 'approved',
      profileBlocked: false,
      activatedDate: new Date().getTime(),
    })
  }

  const handleBlockCompany = (company) => {
    updateDoc(doc(firestore, `profiles/${company.userId}`), {
      profileStatus: 'blocked',
      profileBlocked: true,
      activatedDate: '',
    })
  }

  const handleViewListing = (creatorId) => {
    navigate(`/admin/board/propertyListing/${creatorId}`)
  }

  useEffect(() => {
    unmountedRef.current = false
    const managementCompaniesSnapshotListner = onSnapshot(query(collection(firestore, 'profiles'),orderBy('created', 'desc'), where('role', '==', 'management')), (querySnapshot) => {
      if (!unmountedRef.current) {
        const d = [];
        querySnapshot.forEach((doc) => {
          d.push({
            id: doc.id,
            ...doc.data()
          });
        });
        setManagementCompanies(d)
      }
    })
    return () => {
      unmountedRef.current = true
      managementCompaniesSnapshotListner()
    }
  }, [])

  return (
    <Flexbox style={{ alignItems: 'flex-start', width: '100%' }}>
      <TypeFace bold>
        Management Companies
      </TypeFace>
      <Flexbox row style={{
        width: '100%',
        height: 30,
        marginTop: 20,
        backgroundColor: '#dfdfdf',
        alignItems: 'center',
      }}>
        <TypeFace size={10} style={{ flex: 1 }}>
          Name
        </TypeFace>
        <TypeFace size={10} style={{ flex: 1 }}>
          Email
        </TypeFace>
        <TypeFace size={10} style={{ flex: 1 }}>
          Phone
        </TypeFace>
        <TypeFace size={10} style={{ flex: 1.5 }}>
          Bio
        </TypeFace>
        <TypeFace size={10} style={{ flex: 2 }}>
          Point of Contact
        </TypeFace>
        <TypeFace size={10} style={{ flex: 1 }}>
          Created
        </TypeFace>
        <TypeFace size={10} style={{ flex: 1.5 }}>
          Actions
        </TypeFace>
        <TypeFace size={10} style={{ flex: 1.5 }}>
          Listings
        </TypeFace>
      </Flexbox>
      {managementCompanies?.map(agencyRequest => (
        <Flexbox row key={agencyRequest.id} style={{
          width: '100%',
          minHeight: 30,
          alignItems: 'center',
          borderBottom: '1px solid #dfdfdf'
        }}>
          <TypeFace bold size={10} style={{ flex: 1 }}>
            {agencyRequest.name}
          </TypeFace>
          <TypeFace size={10} style={{ flex: 1 }}>
            {agencyRequest.email}
          </TypeFace>
          <TypeFace size={10} style={{ flex: 1 }}>
            {agencyRequest.phone}
          </TypeFace>
          <TypeFace size={10} style={{ flex: 1.5 }}>
            {agencyRequest.bio}
          </TypeFace>
          <TypeFace size={11} style={{ flex: 2 }}>
            {agencyRequest.contact?.name}<br />{agencyRequest.contact?.email}<br/>{agencyRequest.contact?.number}
          </TypeFace>
          <TypeFace size={10} style={{ flex: 1 }}>
            {moment(new Date(agencyRequest.created)).format('MMM DD, yyyy')}
          </TypeFace>
          <Flexbox row style={{ flex: 1.5, gap: 5, justifyContent: 'center' }}>
            {(!agencyRequest.profileStatus || agencyRequest.profileStatus === "") && (
              <>
                <Button textSize={10} primary style={{ width: 60}} onClick={() => handleApproveCompany(agencyRequest)}>
                  APPROVE
                </Button>
                <Button textSize={10} primary style={{ backgroundColor: 'red', width: 60}} onClick={() => handleBlockCompany(agencyRequest)}>
                  DECLINE
                </Button>
              </>
            )}
            {agencyRequest.profileStatus === 'approved' && (
              <>
                <TypeFace size={10} bold color={Colors.primary}>
                  APPROVED
                </TypeFace>
                <Button textSize={10} primary style={{ backgroundColor: 'red', width: 60}} onClick={() => handleBlockCompany(agencyRequest)}>
                  BLOCK
                </Button>
              </>
            )}
            {agencyRequest.profileStatus === 'blocked' && (
              <>
                <TypeFace size={10} bold color="red">
                  BLOCKED
                </TypeFace>
                <Button textSize={10} primary style={{ width: 60}} onClick={() => handleApproveCompany(agencyRequest)}>
                  APPROVE
                </Button>
              </>
            )}
          </Flexbox>
          <Flexbox row style={{ flex: 1, gap: 5, justifyContent: 'center' }}>
          <Button textSize={10} onClick={() => handleViewListing(agencyRequest?.id)}>
                  View
           </Button>
          </Flexbox>
        </Flexbox>
      ))}
    </Flexbox>
  )
}

export default AdminManagementCompanies

const styles = {
  contentContainer: {
    width: '100%',
    gap: 20,
    flexWrap: 'wrap',
  },
  contentItem: {
    alignItems: 'flex-start',
    justifyContent: 'center',
    width: 160,
    height: 80,
    border: '1px solid #ededed',
    marginBottom: 20,
    padding: 20,
    borderRadius: 10,   
  },
  listingContentItem: {
    alignItems: 'flex-start',
    justifyContent: 'center',
    width: 270,
    height: 60,
    border: '1px solid #ededed',
    marginBottom: 20,
    padding: 20,
    borderRadius: 10,   
  },
  listingFullWidth: {
    alignItems: 'flex-start',
    justifyContent: 'center',
    width: 600,
    height: 60,
    border: '1px solid #ededed',
    marginBottom: 20,
    padding: 20,
    borderRadius: 10,   
  }
}
import { doc, updateDoc } from 'firebase/firestore';
import React, { useEffect, useMemo, useState } from 'react';
import Button from 'src/components/button/Button';
import TextInput from 'src/components/input/TextInput';
import TypeFace from 'src/components/typography/Typefaces';
import Flexbox from 'src/components/wrapper/Flexbox';
import { firestore, storage } from 'src/firebase';
import { useFirestore } from 'src/hooks/firestore/FirestoreContext';
import StripeBoard from './StripeBoard';
import IconAvatarPlaceholder from 'src/assets/images/icons/icon-avatar-placeholder-gray.svg'
import useMediaQuery from 'src/hooks/useMediaQuery';
import shortid from 'shortid';
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";  
import LoaderGif from 'src/assets/images/loader3.gif';
import AccountAgency from './AccountAgency';
import { UserRoleValue, roleLabelFromValue } from 'src/model/types';
import AccountCompanyPointOfContact from './AccountCompanyPointOfContact';

const AccountBasic = (props) => {

  
  const { userProfile, myAgency } = useFirestore()
  const { isMobile } = useMediaQuery()

  const [newName, setNewName] = useState(userProfile?.name ?? '')
  const [phoneNumber, setPhoneNumber] = useState(userProfile?.phone ?? '')
  const [bio, setBio] = useState(userProfile?.bio ?? '')

  const [isUploadingPhoto, setIsUploadingPhoto] = useState(false)

  const updateEnabled = useMemo(() => {
    return (userProfile?.name ?? '') !== newName || (userProfile?.phone ?? '') !== phoneNumber || (userProfile?.bio ?? '') !== bio
  }, [userProfile, newName, phoneNumber, bio])

  const onUpdateProfile = () => {
    updateDoc(doc(firestore, 'profiles', userProfile.userId), {
      name: newName,
      nameL: newName.toLowerCase(),
      phone: phoneNumber,
      bio
    })
  }

  const onCancelUpdateProfile = () => {
    setNewName(userProfile?.name ?? '')
    setPhoneNumber(userProfile?.phone ?? '')
    setBio(userProfile?.bio ?? '')
  }

  const uploadFileProcess = (file, kind) => {
    setIsUploadingPhoto(true)
    const fileId = shortid.generate()
    const storageRef = ref(storage,`/profiles/${userProfile.userId}/${fileId}/`)
    const uploadTask = uploadBytesResumable(storageRef, file);
    uploadTask.on(
      'state_changed',
      snapshot => {
        const percent = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100)
        console.log("uploading file => ", percent)
      },
      err => {
        console.log("uploading file error => ", err)
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then(url => {
          const photoData = {
            id: fileId,
            url,
          }
          updateDoc(doc(firestore, `profiles/${userProfile.userId}`), {
            photo: photoData
          })
          setIsUploadingPhoto(false)
        })
      }
    )
  }

  const phoneNumberAutoFormat = (phoneNumber) => {
    const number = phoneNumber.trim().replace(/[^0-9]/g, "");
  
    if (number.length < 4) return number;
    if (number < 7) return `(${number.slice(0, 3)}) ${number.slice(3)}`; 
    return `(${number.slice(0, 3)}) ${number.slice(3, 6)}-${number.slice(6, 10)}`; 
  };

  
  const onPhoneNumberChange= (e) => {
    const contactPhone = e.target.value
    if(contactPhone.length === 0) {
      setPhoneNumber("");
    }
    if(contactPhone.includes('@') === false) {
      if(/^\d+$/.test(contactPhone)){
        if(contactPhone.length === 10) {
          const targetValue = phoneNumberAutoFormat(contactPhone);
          setPhoneNumber(targetValue);
        }
        else if(phoneNumber.length > 10) {
          setPhoneNumber(phoneNumber) 
        }else
        {
          setPhoneNumber(contactPhone);
        }
      }
      else
       {
          if(phoneNumber.includes('(') && contactPhone.length > 14){
            setPhoneNumber(contactPhone.slice(0,14))
          }else{
            setPhoneNumber(contactPhone)
          }
      }
    }
    else {
      setPhoneNumber(contactPhone)
    }
  };
  
  const photoFileSelectHandler = (e) => {
    console.log("================")
    const file = e.target.files[0]
    uploadFileProcess(file, 'photo')
  }

  useEffect(() => {
    setNewName(userProfile?.name ?? '')
    setPhoneNumber(userProfile?.phone ?? '')
    setBio(userProfile?.bio ?? '')
  }, [userProfile])
  
  return (
    <Flexbox style={styles.container}>
      <TypeFace bold size={16}>
        {roleLabelFromValue(userProfile?.role)?.toUpperCase()} Profile
      </TypeFace>
      {userProfile?.profileBlocked && (
        <TypeFace size={12} color="red">
          Your profile is under review, contact support@listmeet.com to reinstate your profile
        </TypeFace>
      )}
      <Flexbox style={{
        ...styles.content,
        marginTop: 20,
        width: isMobile ? '100%' : 600,
        padding: isMobile ? 0 : 20,
      }}>
        <Flexbox style={{
          ...styles.basicProfilePanel,
          flexDirection: isMobile ? 'column' : 'row',
          gap: 50,
        }}>
          <Flexbox style={{
            ...styles.basicProfileContent,
            width: isMobile ? '100%' : 200,
            alignItems: isMobile ? 'center' : 'flex-start'
          }}>
            <TypeFace bold size={12}>
              HEADSHOT
            </TypeFace>
            <Flexbox style={styles.avatar}>
              {!!userProfile?.photo?.url ? (
                <img
                  src={userProfile.photo.url}
                  style={styles.avatar}
                />
              ) : (
                <img
                  src={IconAvatarPlaceholder}
                  style={styles.avatar}
                />
              )}
              {isUploadingPhoto && (
                <Flexbox style={styles.avatarLoader}>
                  <img src={LoaderGif} style={{ width: 30, height: 30 }} />
                </Flexbox>
              )}
            </Flexbox>
            <Flexbox row style={{ gap: 10 }}>
              <Button secondary style={styles.avatarButtons} onClick={() => {
                if (isUploadingPhoto) {
                  return
                }
                document.getElementById("selectPhoto").click()
              }}>
                UPLOAD
              </Button>
              <Button secondary style={styles.avatarButtons} onClick={() => {
                updateDoc(doc(firestore, `profiles/${userProfile.userId}`), {
                  photo: {
                    id: "",
                    url: ""
                  }
                })
              }}>
                REMOVE
              </Button>
            </Flexbox>
            <TypeFace medium size={12}>
              Headshot guideline:
            </TypeFace>
            <input id='selectPhoto' hidden type="file" onChange={photoFileSelectHandler} accept="image/jpeg, image/png" />
            <TypeFace medium size={11} style={{ textAlign: 'start', marginTop: -15 }} color={'#5f5f5f'}>
              Please upload a high-resolution, professional photo. Make sure your face is centered.
            </TypeFace>
          </Flexbox>
          <Flexbox style={{
            ...styles.basicInforPanel,
            width: isMobile ? '100%' : 'unset'
          }}>
            <TypeFace semiBold size={12}>
              {userProfile?.role === "management" ? "Company Name" : "FULL NAME"}
            </TypeFace>
            <TextInput
              value={newName}
              onChange={e => setNewName(e.target.value)}
              style={styles.textInputBasic}
            />
            <div style={{ height: 20 }} />
            <TypeFace semiBold size={12}>
              {userProfile?.role === "management" ? "Company Email" : "EMAIL"}
            </TypeFace>
            <TextInput
              disabled
              value={userProfile?.email}
              style={styles.textInputBasic}
            />
            <div style={{ height: 20 }} />
            {(userProfile?.role === UserRoleValue.Agent || userProfile?.role === UserRoleValue.SalesPerson) && (
              <>
                <TypeFace semiBold size={12}>
                  LICENSE NUMBER
                </TypeFace>
                <TextInput
                  value={userProfile?.licenseNumber}
                  disabled
                  style={{
                    ...styles.textInputBasic,
                    backgroundColor: '#ededed',
                    // border: (userProfile?.agencyApproved === false || userProfile?.suspended === true) ? '1px solid red' : 'none'
                    border: 'none'
                  }}
                  onKeyDown={(event) => {
                    if (event.key !== "Tab" && event.key !== "Backspace" && event.key !== "ArrowLeft" && event.key !== "ArrowRight" && event.key !== "Delete" && !/[0-9]/.test(event.key)) {
                      event.preventDefault()
                    }
                  }}
                />
                {userProfile?.agency === undefined || userProfile?.agency === "" ? (
                  <TypeFace size={10} style={{ textAlign: 'start' }}>
                    {myAgency?.status === 'submitted' ? 'Agency has released your license' : ''}
                  </TypeFace>
                ) : (
                  <>
                    {userProfile?.agencyApproved === false && (
                      <TypeFace size={10} style={{ textAlign: 'start' }}>
                        Agency has not approved your license, yet
                      </TypeFace>
                    )}
                    {userProfile?.suspended === true && (
                      <TypeFace size={10} style={{ textAlign: 'start' }}>
                        Agency has suspended your license
                      </TypeFace>
                    )}
                  </>
                )}
                {!userProfile?.licenseNumber && (
                  <TypeFace size={10} style={{ textAlign: 'start' }}>
                    Please choose your brokerage below Agency panel and submit your license number
                  </TypeFace>
                )}
              </>
            )}
            <div style={{ height: 20 }} />
            <TypeFace semiBold size={12}>
              {userProfile?.role === "management" ? "Phone Number" : "PHONE NUMBER"}
            </TypeFace>
            <TextInput
              value={phoneNumber}
              onChange={onPhoneNumberChange}
              style={styles.textInputBasic}
              onKeyDown={(event) => {
                if (event.key !== "Tab" && event.key !== "Backspace" && event.key !== "ArrowLeft" && event.key !== "ArrowRight" && event.key !== "Delete" && !/[0-9]/.test(event.key)) {
                  event.preventDefault()
                }
              }}
            />
            <div style={{ height: 20 }} />
            <TypeFace semiBold size={12}>
              BIO
            </TypeFace>
            <textarea
              value={bio}
              onChange={e => setBio(e.target.value)}
              style={styles.bioInput}
            />
            <Flexbox row style={{ gap: 10, marginTop: 20 }}>
              <Button primary disabled={!updateEnabled} onClick={onUpdateProfile} style={styles.profileButtons}>
                SAVE
              </Button>
              <Button secondary onClick={onCancelUpdateProfile} style={styles.profileButtons}>
                CANCEL
              </Button>
            </Flexbox>
          </Flexbox>
        </Flexbox>
        <div style={styles.divider} />
        {userProfile?.role !== 'management' ? (
          <AccountAgency />
        ) : <AccountCompanyPointOfContact />}
        <div style={styles.divider} />
        <StripeBoard />
      </Flexbox>
    </Flexbox>
  )
}

export default AccountBasic

const styles = {
  container: {
    width: '100%',
    alignItems: 'flex-start',
  },
  content: {
    width: 600,
    alignItems: 'center',
    padding: 30,
  },
  avatar: {
    width: 160,
    height: 160,
    borderRadius: 80,
    objectFit: 'cover',
    position: 'relative'
  },
  avatarButtons: {
    height: 32,
  },
  basicInforPanel: {
    flex: 1,
    alignItems: 'flex-start',
  },
  textInputBasic: {
    width: '100%',
    marginTop: 5,
  },
  bioInput: {
    width: '100%',
    marginTop: 5,
    height: 200
  },
  profileButtons: {
    width: 120,
    height: 40
  },
  divider: { width: '100%', height: 1, backgroundColor: '#afafaf', marginTop: 40, marginBottom: 40 },
  basicProfilePanel: { width: '100%', alignItems: 'flex-start' },
  basicProfileContent: { alignItems: 'flex-start', gap: 20, width: 200 },
  avatarLoader: {
    position: 'absolute',
    width: 160,
    height: 160,
    left: 0,
    top: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  }
}
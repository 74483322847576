import React, { useMemo, useState } from 'react';
import Modal from 'react-modal';
import { useAlert } from 'src/hooks/alert/AlertContext';
import Loading from 'react-fullscreen-loading';
import { useFirestore } from 'src/hooks/firestore/FirestoreContext';
import Flexbox from 'src/components/wrapper/Flexbox';
import TypeFace from 'src/components/typography/Typefaces';
import Button from 'src/components/button/Button';
import TextInput from 'src/components/input/TextInput';
import { validateEmailAddress } from 'src/utils/strings';
import { addDoc, collection, getDocs, query, where } from 'firebase/firestore';
import { firestore } from 'src/firebase';
import ReactSelect from 'react-select';
import useMediaQuery from 'src/hooks/useMediaQuery';

const reportReasonOptions = [
  {label: 'Inappropriate property', value: 'inappropriate'},
  {label: 'Fake', value: 'fake'},
  {label: 'Wrong information', value: 'wrong'},
  {label: 'Others', value: 'others'},
]

const ListingDetailsReportModal = ({
  modalIsOpen,
  onCloseModal,
  listingId,
  propertyAddress,
  propertyUnit,
}) => {

  const { userProfile } = useFirestore()
  const {showAlert} = useAlert()
  const {isMobile} = useMediaQuery()

  const [name, setName] = useState(userProfile?.name ?? "")
  const [email, setEmail] = useState(userProfile?.email ?? "")
  const [reason, setReason] = useState("")
  const [comment, setComment] = useState("")
  const [error, setError] = useState("")

  const [processing, setProcessing] = useState(false)

  const submitEnabled = useMemo(() => {
    return name !== "" && email !== "" && reason !== ""
  }, [name, email, reason])

  const afterOpenModal = () => {
    // references are now sync'd and can be accessed.
    document.body.style.overflow = 'hidden'
  }

  const closeModal = () => {
    document.body.style.overflow = 'auto'
    onCloseModal()
  }

  const handleSubmit = async () => {
    if (!validateEmailAddress(email)) {
      setError("Enter a valid email address")
      return
    }


    setProcessing(true)

    getDocs(query(collection(firestore, 'reportListings'), where('email', '==', email.toLowerCase()), where('listing', '==', listingId))).then(docs => {
      if (!docs.empty) {
        setProcessing(false)
        showAlert(true, 'You have already reported')
      } else {
        addDoc(collection(firestore, "reportListings"), {
          email: email.toLowerCase(),
          name,
          reason,
          comment,
          listing: listingId,
          address: propertyAddress,
          unit: propertyUnit,
          reported: new Date().getTime()
        })
        .then(() => {
          setProcessing(false)
          closeModal()
        })
        .catch(err => {
          console.log("Error => ", error)
          setProcessing(false)
          closeModal()
        })
      }
    })
  }

  const handleCancel = () => {
    closeModal()
  }

  return (
    <Modal
      isOpen={modalIsOpen}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      className="mymodal"
      overlayClassName="myoverlay"
    >
      <Flexbox style={styles.container}>
        <Flexbox style={styles.content}>
          <TypeFace bold size={18}>
            Report Property
          </TypeFace>
          <TypeFace medium style={{ marginTop: 20, alignSelf: 'flex-start' }}>
            Name
          </TypeFace>
          <TextInput
            style={styles.textInput}
            value={name}
            placeholder="First, last name"
            onChange={e => setName(e.target.value)}
          />
          <TypeFace medium style={{ marginTop: 20, alignSelf: 'flex-start' }}>
            Email
          </TypeFace>
          <TextInput
            style={styles.textInput}
            value={email}
            placeholder="jondae@email.com"
            onChange={e => setEmail(e.target.value)}
          />
          <TypeFace medium style={{ marginTop: 20, alignSelf: 'flex-start' }}>
            Reason
          </TypeFace>
          <div style={{
            marginTop: 5,
            width: '100%',
            borderRadius: 0,
            height: 44,
          }}>
            <ReactSelect
              options={reportReasonOptions}
              isSearchable={false}
              onChange={v => {
                setReason(v.value)
              }}
            />
          </div>
          <TypeFace medium style={{ marginTop: 20, alignSelf: 'flex-start' }}>
            Comment
          </TypeFace>
          <textarea
            style={styles.commentInput}
            placeholder="Type message here"
            onChange={(e) => setComment(e.target.value)}
          />
          {!!error && (
            <TypeFace color='red' size={11}>
              {error}
            </TypeFace>
          )}
          <Flexbox row style={styles.buttons}>
            <Button disabled={!submitEnabled} primary onClick={handleSubmit} style={styles.loginButton}>
              Submit
            </Button>
            <Button secondary onClick={handleCancel} style={styles.loginButton}>
              Cancel
            </Button>
          </Flexbox>
        </Flexbox>
        
      </Flexbox>
      {processing && <Loading loading background="#0005" loaderColor="white" />}
    </Modal>
  )
}

export default ListingDetailsReportModal

const styles = {
  container: {
    width: 360,
    height: 600,
    overflow: 'auto',
    padding: '30px 30px 30px',
  },
  content: {
    width: '100%',
    alignItems: 'center',
  },
  loginButton: {
    flex: 1,
    height: 40,
  },
  buttons: {
    width: '100%',
    marginTop: 30,
    justifyContent: 'space-between',
    gap: 20,
  },
  licenseNumberInput: {
    width: '100%',
    marginTop: 5,
  },
  textInput: {
    marginTop: 5,
    width: '100%',
    borderRadius: 0,
    border: '1px solid #000',
    height: 44,
  },
  commentInput: {
    width: 'calc(100% - 20px)',
    height: 120,
    marginTop: 10,
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 10,
  }
}
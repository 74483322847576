import moment from "moment"


const buildingAmenitiesArray = [
  {
    key: "outdoor",
    all: ["curtyard", "roof"]
  },
  {
    key: "features",
    all: ["concierge", "elevator", "laundry", "live-in-super", "smoke-free", "wheelchair-access"]
  },
  {
    key: "parking",
    all: ["garage", "valet","private"],
  },
  {
    key: "storage",
    all: ["bike", "cold", "locker", "package"],
  },
  {
    key: "shared",
    all: ["children","gym","media","recreation","swimming"],
  },
  {
    key: "locations",
    all: ["transportation","school","shops","parks"],
  },
]

const unitAmenitiesArray = [
  {
    key: "outdoor",
    all: ["balcony", "garden", "private_roof_deck", "roof_rights", "terrace","private_backyard"]
  },
  {
    key: "features",
    all: ["central_air", "dishwasher", "hardwood_floors", "washer"]
  },
  {
    key: "views",
    all: ["city","park","skyline","water", "garden-view"],
  },
  {
    key: "basements",
    all: ["full-base","partial-base","crawl-base","finished-base", "unfinished-base", "partially-finished-base"],
  },
  {
    key: "attics",
    all: ["full-attic","partial-attic","finished-attic","unfinished-attic", "partially-finished-attic"],
  },
  {
    key: "heatingTypes",
    all: ['electric-heating', 'baseboard', 'radiator']
  },
  {
    key: "heatingFuels",
    all: ['natural_gas', 'electric-fuel', 'oil']
  }
]

export const exportPartialListingDataToAlgolia = (listingData) => {
  
}

export const exportListingToAlgolia = (listingData, agencyData) => {
  const algoliaExportData = {}
  algoliaExportData.address = {
    full: listingData.address.full,
    geo: listingData.address.geo,
    components: listingData.address.components.map(component => component.text)
  }
  algoliaExportData.unit = listingData.unit
  if (listingData.address.neighborhood) {
    algoliaExportData.address.neighborhood = listingData.address.neighborhood
  }
  algoliaExportData.agency = agencyData.name
  algoliaExportData.type = listingData.type
  algoliaExportData.created = listingData.created

  algoliaExportData.unitInfo = {
    ...listingData.unitInfo,
  }

  if (listingData.type === 'rental') {
    algoliaExportData.grossCommission = Number(listingData.grossCommission)
    algoliaExportData.coBrokeCommission = Number(listingData.coBrokeCommission)
    algoliaExportData.building = {
      ...listingData.building,
      year: Number(listingData.building.year)
    }
    algoliaExportData.grossRent = Number(listingData.grossRent)
    algoliaExportData.rentedPrice = listingData.rentedPrice
    if (listingData.unitInfo.furnishedRents !== undefined && listingData.unitInfo.furnishedRents !== "") {
      algoliaExportData.unitInfo.furnishedRents = Number(listingData.unitInfo.furnishedRents)
    }
  } else {
    algoliaExportData.price = Number(listingData.price)
    algoliaExportData.soldPrice = Number(listingData.soldPrice)
    const marketAs = listingData.unitInfo?.marketAs
    if (marketAs === 'coop') {
      algoliaExportData.maintenance = Number(listingData.maintenance)
    }

    if (marketAs === 'other') {
      algoliaExportData.otherMarketAs = listingData.unitInfo?.otherMarketAs ?? ''
    }

    if (marketAs === 'condo') {
      algoliaExportData.commonCharges = Number(listingData.commonCharges)
    }

    if (marketAs === 'building' || marketAs === 'condo' || marketAs === 'singleFamily' || marketAs === 'multiFamily' || marketAs === 'townHouse') {
      algoliaExportData.monthlyTax = Number(listingData.monthlyTax)
    }

    algoliaExportData.maxFinancing = Number(listingData.maxFinancing)

    algoliaExportData.building = {
      ...listingData.building,
      year: Number(listingData.building?.year ?? 0)
    }
  }

  if (listingData.unitInfo) {
    if (listingData.unitInfo.totalRooms !== undefined && listingData.unitInfo.totalRooms !== "") {
      algoliaExportData.unitInfo.totalRooms = Number(listingData.unitInfo.totalRooms)
    }
    if (listingData.unitInfo.halfBaths !== undefined && listingData.unitInfo.halfBaths !== "") {
      algoliaExportData.unitInfo.halfBaths = Number(listingData.unitInfo.halfBaths)
    }
    if (listingData.unitInfo.fullBaths !== undefined && listingData.unitInfo.fullBaths !== "") {
      algoliaExportData.unitInfo.fullBaths = Number(listingData.unitInfo.fullBaths)
    }
    if (listingData.unitInfo.legalBeds !== undefined && listingData.unitInfo.legalBeds !== "") {
      algoliaExportData.unitInfo.legalBeds = Number(listingData.unitInfo.legalBeds)
    }
  }

  const marketAs = listingData.unitInfo?.marketAs
  if (marketAs === 'singleFamily' || marketAs === 'townHouse' || marketAs === 'multiFamily') {
    if (listingData.unitInfo.lotSize !== undefined && listingData.unitInfo.lotSize !== "") {
      algoliaExportData.unitInfo.lotSize = Number(listingData.unitInfo.lotSize)
    }
  }

  algoliaExportData.status = listingData.status
  algoliaExportData.start = listingData.start
  algoliaExportData.end = listingData.end
  algoliaExportData.soldDate = listingData.soldDate
  algoliaExportData.dataId = listingData.id
  algoliaExportData.creatorId = listingData.by
  algoliaExportData.rentedDate = listingData.rentedDate

  if (listingData.algoliaID) {
    algoliaExportData.objectID = listingData.algoliaID
  }

  algoliaExportData["_geoloc"] = listingData.address.geo

  return algoliaExportData
}

export const algoliaFilterTextFromSearchObject = (searchObject) => {

  console.log("=====================", searchObject)

  if (!searchObject) {
    return ''
  }

  let filters = ""

  if (!!searchObject.type) {
    filters = `type:${searchObject.type} AND status:Active`
  } else {
    filters = `status:Active`
  }

  if (!!searchObject.neighborhood) {
    filters += ` AND address.components:${searchObject.neighborhood}`
  }
  

  if ((searchObject.type ?? 'rental') === 'rental') {
    if (searchObject.price) {
      if (searchObject.price.from) {
        filters += ` AND grossRent >= ${searchObject.price.from}`
      }

      if (searchObject.price.to) {
        filters += ` AND grossRent <= ${searchObject.price.to}`
      }
    }

    if (searchObject.moveInDate) {
      const moveIn = searchObject.moveIn ?? 'before'
      const timeStamp = moment(searchObject.moveInDate).toDate().getTime()
      if (moveIn === 'before') {
        filters += ` AND unitInfo.dateAvailable <= ${timeStamp}`
      } else {
        filters += ` AND unitInfo.dateAvailable >= ${timeStamp}`
      }
    }

  } else {
    
    if (searchObject.price) {
      if (searchObject.price.from) {
        filters += ` AND price >= ${searchObject.price.from}`
      }

      if (searchObject.price.to) {
        filters += ` AND price <= ${searchObject.price.to}`
      }
    }

    /*
    auction
    building
    condo
    condop
    coop
    timeshare
    house
    multiFamily
    rentalUnit
    townHouse
    other
    */

   /*
    tax options: building condo house multiFamily townHouse
       NOT options: auction condop coop timeshare rentalUnit other
    maintenance options: condop coop
   */

    if (searchObject.monthlyTax && searchObject.monthlyTax !== "any") {
      filters += ` AND monthlyTax <= ${searchObject.monthlyTax}`
    }
    console.log('here-tax=',filters)

    if (searchObject.maintenance && searchObject.maintenance !== "any") {
      filters += ` AND maintenance <= ${searchObject.maintenance}`
    }

    if (searchObject.salesTypes && searchObject.salesTypes.length > 0) {
      filters += " AND ("
      searchObject.salesTypes.forEach((saleType, index) => {
        filters += ` unitInfo.saleType:${saleType}`
        if (index < searchObject.salesTypes.length - 1) {
          filters += " OR"
        }
      })
      filters += ")"
    }
  }

  if (searchObject.bedRooms && searchObject.bedRooms.length > 0) {
    filters += " AND ("
    searchObject.bedRooms.forEach((room, index) => {
      if (room === 5) {
        filters += ` unitInfo.legalBeds >= ${room}`
      } else {
        filters += ` unitInfo.legalBeds = ${room}`
      }
      if (index < searchObject.bedRooms.length - 1) {
        filters += " OR"
      }
    })
    filters += ")"
  }

  if (searchObject.yearsFrom) {
    filters += ` AND building.year >= ${searchObject.yearsFrom}`
  }

  if (searchObject.yearsTo) {
    filters += ` AND building.year <= ${searchObject.yearsTo}`
  }

  if (searchObject.bathRooms && searchObject.bathRooms.length > 0) {
    filters += " AND ("
    searchObject.bathRooms.forEach((room, index) => {
      if (room === 10) {
        filters += ` unitInfo.fullBaths >= ${room}`
      } else {
        filters += ` unitInfo.fullBaths = ${room}`
      }
      if (index < searchObject.bathRooms.length - 1) {
        filters += " OR"
      }
    })
    filters += ")"
  }

  if (searchObject.pets) {
    filters += " AND NOT building.pets:no"
  }


  // if (searchObject.buildingTypes && searchObject.buildingTypes.length > 0) {
  //   filters += " AND ("
  //   searchObject.buildingTypes.forEach((buildingType, index) => {
  //     filters += ` building.type:${buildingType}`
  //     if (index < searchObject.buildingTypes.length - 1) {
  //       filters += " OR"
  //     }
  //   })
  //   filters += ")"
  // }

  if (searchObject.furnished) {
    filters += " AND NOT unitInfo.furnished:0"
  }

  if (searchObject.marketAs && searchObject.marketAs.length > 0) {
    filters += " AND ("
    searchObject.marketAs.forEach((buildingType, index) => {
      filters += ` unitInfo.marketAs:${buildingType}`
      if (index < searchObject.marketAs.length - 1) {
        filters += " OR"
      }
    })
    filters += ")"
  }

  if (searchObject.lotsize !== undefined && searchObject.lotsize !== "") {
    const lotSize = Number(searchObject.lotsize)
    if (lotSize > 0) {
      const lotSizeOption = searchObject.lotsizeoption ?? 'under'

      if (lotSizeOption === 'under') {
        filters += ` AND (unitInfo.lotSize <= ${lotSize})`
      } else {
        filters += ` AND (unitInfo.lotSize >= ${lotSize})`
      }
    }
  }

  if (searchObject.unitAmenities && searchObject.unitAmenities.length > 0) {

    let filteringAmenityValues = []

    unitAmenitiesArray.forEach(amenities => {
      const values = searchObject.unitAmenities.filter(amenity => amenities.all.includes(amenity))
      if (values.length > 0) {
        filteringAmenityValues.push({
          key: amenities.key,
          values
        })
      }
    })

    filteringAmenityValues.forEach(amenityValue => {
      if (amenityValue.values && amenityValue.values.length > 0) {
        filters += " AND ("
        amenityValue.values.forEach((value, index) => {
          filters += ` unitInfo.${amenityValue.key}:${value}`
          if (index < amenityValue.values.length - 1) {
            filters += " OR"
          }
        })
        filters += ")"
      }
    })

    if (searchObject.unitAmenities.includes('fireplace')) {
      const allAvailabeFireplaces = ['decorative', 'gas', 'wood']

      filters += " AND ("
      allAvailabeFireplaces.forEach((fire, index) => {
        filters += ` unitInfo.features:${fire}`
        if (index < allAvailabeFireplaces.length - 1) {
          filters += " OR"
        }
      })
      filters += ")"
    }

    if (searchObject.unitAmenities.includes('central') || searchObject.unitAmenities.includes('window_units')) {
        //TODO: Adding A/C type values checking
    }
  }

  if (searchObject.buildingAmenities && searchObject.buildingAmenities.length > 0) {
    
    let filteringAmenityValues = []

    buildingAmenitiesArray.forEach(amenities => {
      const values = searchObject.buildingAmenities.filter(amenity => amenities.all.includes(amenity))
      if (values.length > 0) {
        filteringAmenityValues.push({
          key: amenities.key,
          values
        })
      }
    })

    filteringAmenityValues.forEach(amenityValue => {
      if (amenityValue.values && amenityValue.values.length > 0) {
        filters += " AND ("
        amenityValue.values.forEach((value, index) => {
          filters += ` building.${amenityValue.key}:${value}`
          if (index < amenityValue.values.length - 1) {
            filters += " OR"
          }
        })
        filters += ")"
      }
    })

    if (searchObject.buildingAmenities.includes('doorman')) {
      filters += " AND NOT building.doorMan:no"
    }
  }
  return filters
  /*
    type:rental
    AND grossRent <= 2100
    AND unitInfo.dateAvailable >= 1677128400000
    AND ( unitInfo.legalBeds = 4 OR unitInfo.legalBeds = 3)
    AND building.year >= 1900
    AND NOT building.pets:no
    AND ( building.type:coop OR building.type:multi-family)
    AND ( unitInfo.outdoor:garden OR unitInfo.outdoor:roof_rights OR unitInfo.outdoor:terrace)
    AND ( unitInfo.views:city OR unitInfo.views:park OR unitInfo.views:skyline OR unitInfo.views:water)
    AND ( unitInfo.features:central_air OR unitInfo.features:dishwasher OR unitInfo.features:hardwood_floors OR unitInfo.features:washer)
    AND ( building.outdoor:curtyard OR building.outdoor:roof)
    AND ( building.features:concierge OR building.features:elevator OR building.features:laundry OR building.features:live-in-super OR building.features:smoke-free OR building.features:wheelchair-access)
    AND ( building.parking:garage OR building.parking:valet)
    AND ( building.storage:bike OR building.storage:cold OR building.storage:locker OR building.storage:package)
    AND ( building.shared:gym OR building.shared:recreation OR building.shared:children OR building.shared:media OR building.shared:swimming)
    AND NOT building.doorMan:no
  */

  /*
    type:sale
    AND price >= 200000 AND price <= 700000
    AND monthlyTax >= 8000
    AND maintenance >= 1500
    AND (unitInfo.saleType:sponsorUnit OR unitInfo.saleType:auction_sale)
    AND ( unitInfo.legalBeds = 4 OR unitInfo.legalBeds = 3)
    AND building.year >= 1957
    AND ( unitInfo.fullBaths = 8 OR unitInfo.fullBaths = 1)
    AND NOT building.pets:no
    AND ( building.type:coop OR building.type:multi-family OR building.type:condo)
    AND ( unitInfo.outdoor:garden OR unitInfo.outdoor:roof_rights OR unitInfo.outdoor:terrace)
    AND ( unitInfo.features:central_air OR unitInfo.features:dishwasher OR unitInfo.features:hardwood_floors OR unitInfo.features:washer)
    AND ( building.outdoor:curtyard OR building.outdoor:roof)
    AND ( building.features:concierge OR building.features:elevator OR building.features:laundry OR building.features:live-in-super OR building.features:smoke-free OR building.features:wheelchair-access)
    AND ( building.parking:garage OR building.parking:valet)
    AND ( building.storage:bike OR building.storage:cold OR building.storage:locker OR building.storage:package)
    AND ( building.shared:gym OR building.shared:recreation OR building.shared:children OR building.shared:media OR building.shared:swimming)
    AND NOT building.doorMan:no
  /*
    {
      "noFees": true,
    }
    */

    /*
    {
      "salesTypes": [
        "foreclosure",
        "short_sale"
      ],
    }
    */
}
import { doc, getDoc } from 'firebase/firestore'
import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router'
import Button from 'src/components/button/Button'
import TypeFace from 'src/components/typography/Typefaces'
import Flexbox from 'src/components/wrapper/Flexbox'
import { getFirestoreAgencyDoc, getFirestoreProfileDoc } from 'src/data/dbWrappers'
import { firestore } from 'src/firebase'

const AdminListingDetails = ({
  listingId
}) => {

  const unmountedRef = useRef(false)

  const [listingData, setListingData] =  useState()
  const [listingAgency, setListingAgency] = useState()
  const [listingCreator, setListingCreator] = useState()

  const loadListingDetails = () => {
    getDoc(doc(firestore, `listing/${listingId}`)).then(doc => {
      if (!unmountedRef.current) {
        setListingData(doc.data())
      }
    })
  }

  useEffect(() => {
    if (!!listingData?.agency) {
      getFirestoreAgencyDoc(listingData.agency).then(doc => {
        if (!unmountedRef.current) {
          setListingAgency(doc.data())
        }
      })
    }

    if (!!listingData?.by) {
      getFirestoreProfileDoc(listingData.by).then(doc => {
        if (!unmountedRef.current) {
          setListingCreator(doc.data())
        }
      })
    }
  }, [listingData])

  useEffect(() => {
    unmountedRef.current = false
    loadListingDetails()
    return () => {
      unmountedRef.current = true
    }
  }, [listingId])

  return (
    <Flexbox style={{ alignItems: 'flex-start', width: '100%' }}>
      <TypeFace bold>
        Listing Details
      </TypeFace>
      <TypeFace style={{ marginTop: 20 }} semiBold>
        {listingData?.unit}, {listingData?.address?.full}
      </TypeFace>
      <TypeFace>
        {listingAgency?.name}
      </TypeFace>
      <TypeFace>
        Created by {listingCreator?.name}
      </TypeFace>
      <Button secondary style={{ marginTop: 20 }} onClick={() => {
        window.open(`/listing/${listingId}`, '_blank', 'noreferrer')
      }}>
        Open listing page
      </Button>
    </Flexbox>
  )
}

export default AdminListingDetails

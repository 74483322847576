import { collection, onSnapshot, orderBy, query } from 'firebase/firestore'
import moment from 'moment'
import React, { useEffect, useRef } from 'react'
import { useState } from 'react'
import TypeFace from 'src/components/typography/Typefaces'
import Flexbox from 'src/components/wrapper/Flexbox'
import { firestore } from 'src/firebase'
import { Colors } from 'src/theme/Colors'
import { extractStreetAddressFromFullAddress } from 'src/utils/geoUtils'
import { useNavigate } from 'react-router-dom'
import StarRate from './StarRate'

const AdminListingReviews = () => {
  const navigate = useNavigate()
  const unmountedRef = useRef(false)
  const [reviews, setReviews] = useState()

  const handleOpenListing = (listingId) => {
    // navigate(`/listing/${listingId}`)
  }

  useEffect(() => {
    unmountedRef.current = false
    const reviewsSnapshotsListner = onSnapshot(query(collection(firestore, 'reviews'), orderBy('when', 'desc')), (querySnapshot) => {
      if (!unmountedRef.current) {
        const d = [];
        querySnapshot.forEach((doc) => {
          d.push({
            id: doc.id,
            ...doc.data()
          });
        });
        setReviews(d)
      }
    })
    return () => {
      unmountedRef.current = true
      reviewsSnapshotsListner()
    }
  }, [])

  return (
    <Flexbox style={{ alignItems: 'flex-start', width: '100%' }}>
      <TypeFace bold>
        Reported listings
      </TypeFace>
      <Flexbox row style={{
        width: '100%',
        height: 30,
        marginTop: 20,
        backgroundColor: '#dfdfdf',
        alignItems: 'center',
      }}>
        <TypeFace size={10} style={{ flex: 1.5 }}>
          Property
        </TypeFace>
        <TypeFace size={10} style={{ flex: 1 }}>
          Customer
        </TypeFace>
        <TypeFace size={10} style={{ flex: 1 }}>
          Agent
        </TypeFace>
        <TypeFace size={10} style={{ flex: 1 }}>
          Score
        </TypeFace>
        <TypeFace size={10} style={{ flex: 1.5 }}>
          Comment
        </TypeFace>
        <TypeFace size={10} style={{ flex: 1 }}>
          Reviewed at
        </TypeFace>
      </Flexbox>
      {reviews?.map(review => (
        <Flexbox row key={review.id} style={{
          width: '100%',
          minHeight: 30,
          alignItems: 'center',
          borderBottom: '1px solid #dfdfdf'
        }}>
          <TypeFace size={10} style={{ flex: 1.5, cursor: 'pointer', color: Colors.primary }} onClick={() => handleOpenListing(review.listing.id)}>
            {extractStreetAddressFromFullAddress(review?.listing.address)}, {review?.listing.unit}
          </TypeFace>
          <TypeFace size={10} style={{ flex: 1 }}>
            {review?.customer.name}
          </TypeFace>
          <TypeFace size={10} style={{ flex: 1 }}>
            {review?.agent.name}
          </TypeFace>
          <TypeFace size={10} style={{ flex: 1 }}>
            <StarRate value={review?.mark} size={16} />
          </TypeFace>
          <TypeFace size={10} style={{ flex: 1.5 }}>
            {review.comment}
          </TypeFace>
          <TypeFace size={10} style={{ flex: 1 }}>
            {moment(new Date(review.when)).format('MMM DD')}
          </TypeFace>
        </Flexbox>
      ))}
    </Flexbox>
  )
}

export default AdminListingReviews

const styles = {
  contentContainer: {
    width: '100%',
    gap: 20,
    flexWrap: 'wrap',
  },
  contentItem: {
    alignItems: 'flex-start',
    justifyContent: 'center',
    width: 160,
    height: 80,
    border: '1px solid #ededed',
    marginBottom: 20,
    padding: 20,
    borderRadius: 10,   
  },
  listingContentItem: {
    alignItems: 'flex-start',
    justifyContent: 'center',
    width: 270,
    height: 60,
    border: '1px solid #ededed',
    marginBottom: 20,
    padding: 20,
    borderRadius: 10,   
  },
  listingFullWidth: {
    alignItems: 'flex-start',
    justifyContent: 'center',
    width: 600,
    height: 60,
    border: '1px solid #ededed',
    marginBottom: 20,
    padding: 20,
    borderRadius: 10,   
  }
}
import algoliasearch from "algoliasearch";

const algoliaClient = algoliasearch(process.env.REACT_APP_algolia_app_id, 'faa9f886ecdab379f964a1ee83f957ab');
const algoliaIndex = algoliaClient.initIndex(process.env.REACT_APP_algolia_index);

export const testMethod = (object) => {
  algoliaIndex
    .saveObjects(object)
    .wait()
    .then((response) => {
      console.log(response);
      algoliaIndex.search("Fo")
        .then((objects) => {
          console.log(objects)
        })
        .catch(err => {
          console.log(err)
        });
    })
    .catch(error => {
      console.log(error)
    })
}

export const saveAlgoliaObjects = (objects) => {
  return algoliaIndex.saveObjects(objects).wait()
}

export const saveAlgoliaObject = (object) => {
  return algoliaIndex.saveObject(object, {
    autoGenerateObjectIDIfNotExist: true
  }).wait()
}

export const searchObjects = (query, filters, sortOption, insideBoundingBox, userLocation, userRadius) => {
  const indexMap = {
    'newest': 'newest-dev',
    'price-high': 'priceHighest-dev',
    'price-low': 'priceLowest-dev'
  };

  const indexName = indexMap[sortOption] || 'newest-dev';
  const replicaAlgoliaIndex = algoliaClient.initIndex(indexName);

  const params = {
    filters,
    aroundLatLng: userLocation,
    aroundRadius: userRadius,
    hitsPerPage: 20000
  };

  if (insideBoundingBox?.length > 0) {
    params.insideBoundingBox = insideBoundingBox;
  }

  if (!!query && [...query].length) {
    const neighborhoodFilter = [...query]
      .map(n => `address.components:"${n}" OR address.neighborhood:"${n}"`)
      .join(' OR ');

    params.filters = params.filters
      ? `${params.filters} AND (${neighborhoodFilter})`
      : `(${neighborhoodFilter})`;
  }
  return replicaAlgoliaIndex.search('', params);
};

export const filterDataByUserId = (userId) => {
  return algoliaIndex.search('', {
    filters: `creatorId:${userId} AND status:Active`
  })
}

export const getAlgoliaObjects = (objectIDs) => {
  return algoliaIndex.getObjects(objectIDs)
}

export const deleteAlgoliaObject = (objectId) => {
  algoliaIndex.deleteObject(objectId)
}

export const updateAlogliaObject = (objectID, updateData) => {
  algoliaIndex.partialUpdateObject({
    ...updateData,
    objectID
  })
}
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useAreas from 'src/hooks/data/useAreas';
import useMediaQuery from 'src/hooks/useMediaQuery';
import { storeSearchObject } from 'src/redux/search/actions';
import { selectSearchObject } from 'src/redux/search/selectors';
import { Colors } from 'src/theme/Colors';
import TypeFace from '../typography/Typefaces';
import Flexbox from '../wrapper/Flexbox';
import HomeDownloadAppPanel from 'src/components/home/HomeDownloadAppPanel';
import { appendFilteredDataToFirstArray } from '../../data/helper';

const HomeFooter = (props) => {

  const { isMobile } = useMediaQuery()

  const { areas, subAreas } = useAreas()

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const currentSearchObject = useSelector(selectSearchObject)

  const [areasExpanded, setAreasExpanded] = useState({})

  const handleHeader = (area) => {
    const neighborhood = {
      area: area.name,
      subArea: area.name,
      bbox: []
    }

    const filtered = appendFilteredDataToFirstArray([neighborhood], subAreas);
    dispatch(storeSearchObject({
      type: currentSearchObject.type ?? 'rental',
      locations: filtered,
      neighborhoods: [neighborhood]
    }))

    navigate('/search')

  }

  const handleSubAreaSearch = (area, subArea) => {

    const neighborhood = {
      area: area.name,
      subArea: subArea.name,
      bbox: subArea.component.bbox
    }

    const filtered = appendFilteredDataToFirstArray([neighborhood], subAreas);
    dispatch(storeSearchObject({
      type: currentSearchObject?.type ?? 'rental',
      locations: filtered,
      neighborhoods: [neighborhood]
    }))

    navigate('/search')
  }

  const handleSeeMore = (area) => {
    if (areasExpanded[area.name]) {
      setAreasExpanded({
        ...areasExpanded,
        [area.name]: false
      })
    } else {
      setAreasExpanded({
        ...areasExpanded,
        [area.name]: true
      })
    }
  }

  return (
    <div style={styles.container}>
      <div style={styles.contentBox}>
        <div style={styles.content}>
          {areas
            ?.filter(arr => arr.name !== "New York").map((area, index) => (
              <div key={`footer-${index}`} style={styles.list}>
                <TypeFace bold size={18} color="black" style={{ alignSelf: 'flex-start' }}
                  onClick={() => area.isState ? true : handleHeader(area)}
                >
                  {area.name}
                </TypeFace>
                {(areasExpanded[area.name]
                  ? subAreas?.[area.name]?.sort((a, b) => a.name.localeCompare(b.name)) // Sort subAreas alphabetically if expanded
                  : subAreas?.[area.name]
                    ?.sort((a, b) => a.name.localeCompare(b.name)) // Sort subAreas alphabetically
                    ?.slice(0, 10)
                )?.map((subArea, itemIndex) => (
                  <TypeFace
                    size={16}
                    onClick={() => handleSubAreaSearch(area, subArea)}
                    key={`item-${index}-${itemIndex}`}
                    color="black"
                    style={{ cursor: 'pointer', marginTop: 5, alignSelf: 'flex-start' }}
                  >
                    {subArea.name}
                  </TypeFace>
                ))}
                <TypeFace
                  semiBold
                  size={16}
                  style={{ cursor: 'pointer', marginTop: 10, alignSelf: 'flex-start' }}
                  color="black"
                  onClick={() => handleSeeMore(area)}
                >
                  {areasExpanded[area.name] ? 'less' : '+See More'}
                </TypeFace>
              </div>
            ))}
        </div>
        {props.isHome ? (
          <Flexbox row style={styles.download}>
            <img src={require('src/assets/images/appstore.png')} style={styles.button} />
            <img src={require('src/assets/images/googleplay.png')} style={styles.button} />
          </Flexbox>
        ) : (
          <HomeDownloadAppPanel />
        )}
        <img
          src={require('src/assets/images/bg/img-home-footer.jpg')}
          style={{
            ...styles.imageFooter,
            width: isMobile ? '100%' : '75%',
            height: isMobile ? 120 : 240,
          }}
        />
      </div>
    </div>
  )
}

export default HomeFooter

const styles = {
  container: {
    display: 'flex',
    backgroundColor: 'white',
    flexDirection: 'column',
    paddingBottom: 30,
    alignItems: 'center'
  },
  contentBox: {
    width: '90%',
    maxWidth: 1178,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    shadowColor: Colors.primary,
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.6,
    shadowRadius: 6,
    borderRadius: 10,
  },
  imageFooter: {
    marginTop: 30,
    width: '75%',
    height: 240,
    objectFit: 'contain',
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    flexWrap: 'wrap',
    gap: 20,
  },
  list: {
    marginTop: 50,
    display: 'flex',
    minWidth: 120,
    marginRight: 20,
    maxWidth: 240,
    flexDirection: 'column',
  },
  lastPart: {
    width: 160,
    display: 'flex',
    flexDirection: 'column',
  },
  download: {
    marginTop: 60,
    justifyContent: "space-between"
  },
  button: {
    width: "48%",
    maxWidth: 204,
    // height: 59
  }
}
import React, { useEffect, useMemo, useRef, useState } from 'react';
import HomeHeaderDesktop from 'src/components/home/HomeHeaderDesktop';
import Flexbox from 'src/components/wrapper/Flexbox';
import TypeFace from 'src/components/typography/Typefaces';
import { unstable_usePrompt, useNavigate, useParams } from 'react-router-dom';
import { Colors } from 'src/theme/Colors';
import EditiListingSectionBar from 'src/components/listing/EditiListingSectionBar';
import EditListingManagement from 'src/components/listing/EditListingManagementV2';
import EditListingBuilding from 'src/components/listing/EditListingBuildingV2';
import EditListingUnit from 'src/components/listing/EditListingUnitV2';
import EditListingMarketing from 'src/components/listing/EditListingMarketingV2';
import EditListingPhotosMedia from 'src/components/listing/EditListingPhotosMediaV3';
import Button from 'src/components/button/Button';
import { addDoc, arrayUnion, collection, doc, getDoc, getDocs } from 'firebase/firestore';
import { firestore } from 'src/firebase';
import IconArrowDown from 'src/assets/images/icons/icon-arrow-down.svg';
import IconArrowUp from 'src/assets/images/icons/icon-arrow-up.svg';
import LoaderGif from 'src/assets/images/loader3.gif';
import { updateListing } from 'src/data/createListing';
import { exportListingToAlgolia } from 'src/data/algolia-data-adjust';
import { saveAlgoliaObject } from 'src/algolia';
import EditListingMeetNow from 'src/components/listing/EditListingMeetNowV2';
import { extractStreetAddressFromFullAddress } from 'src/utils/geoUtils';
import useMediaQuery from 'src/hooks/useMediaQuery';
import { checkingListingErrors, compareIfListingDataUpdated } from 'src/utils/business';
import { UserRoleValue, propertyStatusLabelFromOption, propertyStatusOptions, propertyStatusOptionsRented } from 'src/model/types';
import { useFirestore } from 'src/hooks/firestore/FirestoreContext';

const EditListing = () => {  

  const { id } = useParams()
  const { isMobile } = useMediaQuery()
  const navigate = useNavigate()

  const { userProfile, myAgency } = useFirestore()

  const [processing, setProcessing] = useState(false)
  const [selectedSection, setSelectedSection] = useState(0)

  /* From firestore */
  const [listingData, setListingData] = useState()

  /* While updating */
  const [draftListing, setDraftListing] = useState()
  const draftListingRef = useRef()

  const [forceSaving, setForceSaving] = useState(false)

  useEffect(() => {
    draftListingRef.current = draftListing
  }, [draftListing])

  const [errorsList, setErrorList] = useState([])
  const [showErrors, setShowErrors] = useState(false)
  const [highlightErrorFields, setHighlightErrorFields] = useState(false)
  const [isSoldSelected, setIsSoldSelected] = useState(false)
  const [isRentSelected, setIsRentSelected] = useState(false)

  const saveAvailable = useMemo(() => {
    return compareIfListingDataUpdated(listingData, draftListing) || forceSaving
  }, [listingData, draftListing, forceSaving])

  const listingStatusOptions = useMemo(() => {
    if (listingData?.type === 'sale') {
      return propertyStatusOptions
    } else {
      return propertyStatusOptionsRented
    }
  }, [listingData])

  const handleUpdateRootFields = (dataObject) => {
    setDraftListing({
      ...draftListingRef.current,
      ...dataObject
    })
  }

  const handleUpdateBuildingFields = (buildingDataObject) => {
    setDraftListing({
      ...draftListingRef.current,
      building: {
        ...draftListingRef.current.building,
        ...buildingDataObject
      }
    })
  }

  const handleUpdateUnitFields = (unitDataObject) => {
    setDraftListing({
      ...draftListingRef.current,
      unitInfo: {
        ...draftListingRef.current.unitInfo,
        ...unitDataObject
      }
    })
  }

  const checkPriceChange = () => {
    const oldPrice = listingData.type === 'sale' ? listingData.price : listingData.grossRent
    const newPrice = draftListing.type === 'sale' ? draftListing.price : draftListing.grossRent

    return {
      changed: newPrice !== oldPrice,
      newPrice,
    }
  }

  const handleMakeActive = () => {
    setErrorList([])
    getDocs(collection(firestore, `listing/${id}/photos`)).then(snapshot => {
      let photosData = []
      snapshot.forEach(doc => {
        photosData.push(doc.data())
      })
      const errors = checkingListingErrors(photosData, draftListing)
      setErrorList(errors)
      if (errors.length === 0) {
        setHighlightErrorFields(false)
        setProcessing(true)
        const activatedTime = new Date().getTime()
        updateListing(id, {
          ...draftListing,
          status: 'Active',
          activatedAt: arrayUnion(activatedTime)
        }).then(() => {

          const algoliaObject = exportListingToAlgolia({
            ...draftListing,
            status: 'Active',
          }, userProfile.role === UserRoleValue.ManageMent ? userProfile : myAgency)

          saveAlgoliaObject(algoliaObject).then(response => {
            updateListing(id, {
              algoliaID: response.objectID
            })

            const newDraftListingData = {
              ...draftListing,
              status: 'Active',
              algoliaID: response.objectID,
              activatedAt: !draftListing.activatedAt ? [new Date().getTime()] : [...(draftListing.activatedAt), new Date().getTime()]
            }
            
            setDraftListing(newDraftListingData)
            setListingData({...newDraftListingData})
            setForceSaving(false)
            setProcessing(false)

            setTimeout(() => {
              navigate('/manage/properties')
            }, 500)
          })
        })

        addDoc(collection(firestore, `listing/${id}/prices`), {
          at: new Date().getTime().toFixed(),
          by: userProfile?.userId ?? '',
          price: draftListing.type === 'sale' ? draftListing.price : draftListing.grossRent
        }).then(res => {
          console.log("RES => ", res)
        }).catch(err => {
          console.log("ERROR => ", err)
        })
      } else {
        setHighlightErrorFields(true)
      }
    })
  }

  const handleSave = async () => {
    if(draftListing.type === 'rental') {
      if (draftListing.status !== 'Rented') {
        draftListing.rentedPrice = ''
        draftListing.rentedDate = ''
      }
    }
    else {
      if (draftListing.status !== 'Sold') {
        draftListing.soldPrice = ''
        draftListing.soldDate = ''
      }
    }
    let savingListing = {...draftListing}
    if ((listingData?.status === 'Active' && draftListing?.status !== 'Active') || (listingData?.status !== 'Active' && draftListing?.status === 'Active')) {
      savingListing.activatedAt = !savingListing.activatedAt ? [new Date().getTime()] : [...savingListing.activatedAt, new Date().getTime()]
    }
      
    setErrorList([])
    getDocs(collection(firestore, `listing/${id}/photos`)).then(snapshot => {
      let photosData = []
      snapshot.forEach(doc => {
        photosData.push(doc.data())
      })

      let saveAvailable = false
      if (savingListing.status === 'Draft') {
        saveAvailable = true
      } else {
        const errors = checkingListingErrors(photosData, savingListing)
        setErrorList(errors)
        saveAvailable = errors.length === 0
      }


      if (saveAvailable) {
        setHighlightErrorFields(false)
        const priceChanges = checkPriceChange()
        if (priceChanges.changed) {
          console.log("Price changed, need to save it with ", priceChanges.newPrice)
  
          addDoc(collection(firestore, `listing/${id}/prices`), {
            at: new Date().getTime().toFixed(),
            by: userProfile?.userId ?? '',
            price: priceChanges.newPrice
          })
        }

        setProcessing(true)
        updateListing(id, savingListing).then(() => {
          if (savingListing.status !== 'Draft' || listingData.status !== 'Draft') {
            const algoliaObject = exportListingToAlgolia(savingListing, userProfile.role === UserRoleValue.ManageMent ? userProfile : myAgency)
            saveAlgoliaObject(algoliaObject).then(response => {
              updateListing(id, {
                algoliaID: response.objectID
              })
              setListingData({...savingListing})
              setForceSaving(false)
              setProcessing(false)
            })
          } else {
            setListingData({...savingListing})
            setForceSaving(false)
            setProcessing(false)
          }
        })
      } else {
        setHighlightErrorFields(true)
        setProcessing(false)
      }
    })
    // }
  }

  const handleView = () => {
    navigate(`/listing/${id}`)
  }

  const handleChangeStatus = (event) => {
    const newStatus = event.target.value
    console.log('newStatus=',newStatus)
    if(newStatus === 'Sold') {
      setIsSoldSelected(true)
    }
    else {
      setIsSoldSelected(false)
    }

    if(newStatus === 'Rented') {
      console.log('newStatus1=',newStatus)
      setIsRentSelected(true)
    }
    else {
      setIsRentSelected(false)
    }
    
    setDraftListing({
      ...draftListing,
      status: newStatus
    })
  }

  unstable_usePrompt({
    when: saveAvailable,
    message: "You have unsaved changes.  Are you sure to leave the form without saving them?"
  });

  useEffect(() => {
    if (id) {
      getDoc(doc(firestore, "listing", id)).then((docSnap) => {
        const data = docSnap.data()
        setListingData(data)
        setDraftListing({...data})
        console.log('data=',data.status)
        if (data?.status === 'Sold') {
          setIsSoldSelected(true)
        }
        else {
          setIsSoldSelected(false)
        }

        if (data?.status === 'Rented') {
          setIsRentSelected(true)
        }
        else {
          setIsRentSelected(false)
        }
      })      
    }
  }, [id])

  // show view button when listing is saved as active
  const possibleViewListing = useMemo(() => {
    return (listingData && listingData.status === "Active" && !processing)
  }, [listingData, processing])

  return (
    <div className="App">
      <HomeHeaderDesktop />
      <Flexbox id="aaa" style={styles.contents}>
        {(listingData && draftListing) ? (
          <Flexbox style={{ width: '100%', alignItems: isMobile ? 'flex-start' : 'center' }}>
            {isMobile && (
              <Flexbox row style={{ alignSelf: 'flex-start'}}>
                {draftListing?.unit !== '' && 
                  <TypeFace bold>
                   Unit {draftListing?.unit},&nbsp;
                  </TypeFace>
                }
                <TypeFace medium>
                  {extractStreetAddressFromFullAddress(draftListing?.address.full)}
                </TypeFace>
              </Flexbox>
            )}            
            <Flexbox row style={{
              ...styles.titleRowBox,
              marginTop: isMobile ? 5 : 20,
            }}>
              {listingData?.status !== 'Active' && (
                <Flexbox style={styles.draftBadge}>
                  <TypeFace bold size={11}>
                    {propertyStatusLabelFromOption(listingData.status)}
                  </TypeFace>
                </Flexbox>
              )}
              {!isMobile && (
                <Flexbox row style={{ marginLeft: isMobile ? 10 : 20, flexDirection: isMobile ? 'column' : 'row', alignItems: isMobile ? 'flex-start' : 'center' }}>
                 {draftListing?.unit !== '' && 
                  <TypeFace bold>
                     Unit {draftListing?.unit},&nbsp;
                  </TypeFace>
                  } 
                  <TypeFace medium>
                    {extractStreetAddressFromFullAddress(draftListing?.address.full)}
                  </TypeFace>
                </Flexbox>
              )}
              <div style={{ flex: 1 }} />
              {listingData?.status === 'Draft' ? (
                <Button onClick={handleMakeActive} secondary style={{ height: 36 }}>
                  {isMobile ? 'Activate' : 'Make Active'}
                </Button>
              ) : (
                <>
                  <TypeFace>
                    Status&nbsp;
                  </TypeFace>
                  <select
                    value={draftListing?.status}
                    style={styles.statusDropdown}
                    onChange={handleChangeStatus}
                  >
                    {listingStatusOptions.map((option, index) => (
                      <option key={index} value={option.value}>{option.label}</option>
                    ))}
                  </select>
                </>
              )}
              <Button
                onClick={handleSave}
                primary
                disabled={(!saveAvailable && errorsList.length === 0) || processing}
                style={{ marginLeft: 10,  height: 36 }}
              >
                SAVE
              </Button>
              {possibleViewListing && (
                <Button
                  onClick={handleView}
                  primary
                  style={{ marginLeft: 10,  height: 36 }}
                >
                  VIEW
                </Button>
              )}
            </Flexbox>
          </Flexbox>
        ) : (
          <Flexbox row={true} style={styles.titleRowBox}>
            <TypeFace>Loading...</TypeFace>
          </Flexbox>
        )}

        {processing && (
          <Flexbox
            row
            style={styles.processingRow}
          >
            <img src={LoaderGif} style={{ width: 30, height: 30 }} alt="loader-gif" />
          </Flexbox>
        )}
        
        {(errorsList?.length ?? 0) > 0 && (
          <>
            <Flexbox style={styles.errorBox}>
              <Flexbox onClick={() => setShowErrors(!showErrors)} row style={styles.errorTitleBox}>
                <TypeFace semiBold size={12} color="red" style={{ cursor: 'pointer' }}>
                  Please resolve {errorsList?.length ?? 0} errors
                </TypeFace>
                <img src={showErrors ? IconArrowUp : IconArrowDown} width={16} height={16} style={{ color: 'red'}} alt="arrow-icon" />
              </Flexbox>
              {showErrors && (
                <>
                  {errorsList?.map((error, index) => (
                    <TypeFace size={11} color="red" style={{ marginLeft: 10, marginBottom: index === errorsList?.length - 1 ? 10 : 0 }}>{error}</TypeFace>
                  ))}
                </>
              )}
            </Flexbox>
          </>
        )}
        <EditiListingSectionBar
          selectedIndex={selectedSection}
          onSelectTab={setSelectedSection}
        />
        {selectedSection === 0 && (
          <EditListingManagement
            highlightErrorFields={highlightErrorFields}
            listing={draftListing}
            onUpdate={handleUpdateRootFields}
          />
        )}
        {selectedSection === 1 && (
          <EditListingMeetNow
            highlightErrorFields={highlightErrorFields}
            listing={draftListing}
            onUpdate={handleUpdateRootFields}
          />
        )}
        {selectedSection === 2 && (
          <EditListingBuilding
            highlightErrorFields={highlightErrorFields}
            listing={draftListing}
            onUpdateBuildingFields={handleUpdateBuildingFields}
          />
        )}
        {selectedSection === 3 && (
          <EditListingUnit
            highlightErrorFields={highlightErrorFields}
            listing={draftListing}
            onUpdateRootFields={handleUpdateRootFields}
            onUpdateUnitInfoFields={handleUpdateUnitFields}
            soldSelected={isSoldSelected}
            rentSelected={isRentSelected}
          />
        )}
        {selectedSection === 4 && (
          <EditListingPhotosMedia
            listing={draftListing}
            highlightErrorFields={highlightErrorFields}
            onUpdateRootFields={handleUpdateRootFields}
            onForceSave={() => setForceSaving(true)}
          />
        )}
        {selectedSection === 5 && (
          <EditListingMarketing
            listing={draftListing}
            onUpdateRootFields={handleUpdateRootFields}
            highlightErrorFields={highlightErrorFields}
            onForceSave={() => setForceSaving(true)}
          />
        )}
      </Flexbox>
    </div>
  )
}

export default EditListing

const styles = {
  contents: {
    width: '90%',
    maxWidth: 1178,
    padding: 10,
    margin: 'auto',
    alignItems: 'flex-start'
  },
  titleRowBox: {
    width: "100%", alignItems: 'center', marginTop: 20
  },
  statusDropdown: {
    height: 30,
    width: 160,
    marginLeft: 20,
  },
  draftBadge: {
    backgroundColor: Colors.gray2,
    paddingLeft: 10,
    paddingRight: 10,
    height: 32,
    justifyContent: 'center',
    alignItems: 'center',
  },
  errorBox:{
    backgroundColor: '#f001',
    border: '1px solid #f00',
    width: '100%',
    alignItems: 'flex-start',
    marginTop: 10,
  },
  errorTitleBox: {
    alignItems: 'flex-start',
    padding: 10,
    gap: 10,
    cursor: 'pointer'
  },
  processingRow: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center'
  },
  saveCancelDraft: {
    width: '100%', justifyContent: 'flex-end', gap: 10, marginTop: 15
  },
  alertModal: {
    container: {
      width: 300,
      padding: 30,
      backgroundColor: 'white',
      alignItems: 'flex-start',
    },
    okButton: {
      width: '100%',
      height: 40,
      marginTop: 30,
    },
    buttonsRow: {
      width: '100%',
      gap: 10,
      marginTop: 30,
    },
    buttonItem: {
      flex: 1,
      height: 44,
    }
  }
}
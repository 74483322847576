import React from 'react'
import Logo from 'src/assets/images/lm-lg-logo.png';
import TypeFace from '../typography/Typefaces';
import { Colors } from 'src/theme/Colors';
import { useAuth } from 'src/hooks/auth/AuthContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { useFirestore } from 'src/hooks/firestore/FirestoreContext';
import useMediaQuery from 'src/hooks/useMediaQuery';
import LoginModal from '../auth/LoginModal';
import { useAlert } from 'src/hooks/alert/AlertContext';
import ConnectProfileModal from '../auth/ConnectProfileModal';
import Flexbox from '../wrapper/Flexbox';

const headerHeight = 70

const HomeHeaderDesktop = (props) => {

  const { user } = useAuth()
  const { isAuthInitialized, userProfile } = useFirestore()
  const { showAlert } = useAlert()
  const { isMobile } = useMediaQuery();
  const location = useLocation()
  
  const navigate = useNavigate();
  const [isLoginModalOpen, setIsLoginModalOpen] = React.useState(false);
  const [isConnectProfileModalOpen, setIsConnectProfileModalOpen] = React.useState(false);

  const openLoginModal = () => {
    if (userProfile) {
      showAlert(true, 
        'You have some unsaved schedule data. Once you login to another account, you will loose them. Please create profile to save unsaved data.',
        [
          {
            title: "Create a Profile",
            onClick: () => {
              setIsConnectProfileModalOpen(true)
              showAlert(false)
            }
          },
          {
            title: "Continue Login",
            onClick: () => {
              showAlert(false)
              document.body.style.overflow = 'hidden'
              setIsLoginModalOpen(true)
            }
          },
        ], 1)
    } else {
      document.body.style.overflow = 'hidden'
      setIsLoginModalOpen(true)
    }
  }
  
  return (
    <div style={styles.container}>
      <Flexbox row style={{ width: '90%', maxWidth: 1178, alignItems: 'center' }}>
        <img src={Logo} style={styles.logo} onClick={() => navigate('/')}/>
        <div style={{ flex: 1 }} />
        {isAuthInitialized && (
          <>
            {!isMobile && userProfile?.role === 'admin' ? (
              <TypeFace
                onClick={() => navigate('/admin/board/dashboard')}
                size={isMobile ? 14 : 16}
                color={Colors.primary}
                style={{ cursor: 'pointer', marginRight: 20 }}
              >
                Admin
              </TypeFace>
            ) : (
              <>
                {(!isMobile || !location.pathname?.includes('manage')) && (
                  <>
                    {(user === null) ? (
                      <TypeFace size={12}>
                        <TypeFace
                          semiBold
                          onClick={openLoginModal}
                          size={isMobile ? 14 : 18}
                          color={Colors.primary}
                          style={{ cursor: 'pointer' }}
                        >
                          Sign In / Register
                        </TypeFace>
                      </TypeFace>
                    ) : (
                      <div style={styles.accountButtonContainer}>
                        <TypeFace
                          semiBold
                          size={18}
                          color={Colors.primary}
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            if (userProfile.role === 'customer') {
                              navigate(`/my/profile`)
                            } else {
                              if (location.pathname.includes('edit/listing/')) {
                                navigate("/manage/properties")
                              } else {
                                navigate("/manage")
                              }
                            }
                          }}
                        >
                          {location.pathname.includes('edit/listing/') ? "Properties" : "Account"}
                        </TypeFace>
                      </div>
                    )}
                  </>
                )}
              </>
            )}
          </>
        )}
        <ConnectProfileModal
          modalIsOpen={isConnectProfileModalOpen}
          closeModal={() => {
            setIsConnectProfileModalOpen(false)
          }}
        />
        <LoginModal
          modalIsOpen={isLoginModalOpen}
          closeModal={() => setIsLoginModalOpen(false)}
          onSignup={() => setIsLoginModalOpen(false)}
        />
      </Flexbox>
    </div>
  )
}

export default HomeHeaderDesktop

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    height: headerHeight,
    alignItems: 'center',
    justifyContent: 'center'
  },
  logo: {
    height: 44,
    cursor: 'pointer',
  },
  accountButtonContainer: {
    position: 'relative',
    height: headerHeight,
    display: 'flex',
    alignItems: 'center'
  }
}
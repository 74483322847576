import { collection, doc, onSnapshot, orderBy, query, where } from 'firebase/firestore'
import moment from 'moment'
import React, { useEffect, useMemo, useRef } from 'react'
import { useState } from 'react'
import TypeFace from 'src/components/typography/Typefaces'
import Flexbox from 'src/components/wrapper/Flexbox'
import { firestore } from 'src/firebase'
import { priceFormat } from 'src/utils/strings'

const AdminPayments = () => {

  const unmountedRef = useRef(false)

  const [agenciesList, setAgenciesList] = useState()

  const [completedPayments, setCompletedPayments] = useState()

  const totalFeesCollected = useMemo(() => {
    if (completedPayments) {
      return completedPayments.reduce((acc, cur) => {
        if (cur.fee) {
          return acc + cur.fee
        } else {
          return acc + Math.round((cur.amount * 0.15) * 100) / 100
        }
      }, 0)
    } else {
      return 0
    }
  }, [completedPayments])

  useEffect(() => {
    unmountedRef.current = false
    const listener = onSnapshot(query(collection(firestore, 'payments'), where('status', '==', 'completed'), orderBy('created', 'desc')), (querySnapshot) => {
      if (!unmountedRef.current) {
        const d = []
        querySnapshot.forEach(doc => {
          d.push({
            id: doc.id,
            ...doc.data()
          })
        })
        setCompletedPayments(d)
      }
    })
    return () => {
      unmountedRef.current = true
      listener()
    }
  }, [])

  return (
    <Flexbox style={{ alignItems: 'flex-start', width: '100%' }}>
      <TypeFace bold>
        Payments
      </TypeFace>
      <TypeFace style={{ marginTop: 10, marginLeft: 16 }}>
        Total fees collected: <TypeFace bold>${priceFormat(totalFeesCollected / 100)}</TypeFace>
      </TypeFace>
      <Flexbox row style={{
        width: '100%',
        height: 30,
        marginTop: 20,
        backgroundColor: '#dfdfdf',
        alignItems: 'center',
      }}>
        <TypeFace size={10} style={{ flex: 1 }}>
          Type
        </TypeFace>
        <TypeFace size={10} style={{ flex: 1 }}>
          Amount
        </TypeFace>
        <TypeFace size={10} style={{ flex: 1 }}>
          Fee
        </TypeFace>
        <TypeFace size={10} style={{ flex: 1 }}>
          Paid by
        </TypeFace>
        <TypeFace size={10} style={{ flex: 1 }}>
          Paid to
        </TypeFace>
        <TypeFace size={10} style={{ flex: 2 }}>
          Comment
        </TypeFace>
        <TypeFace size={10} style={{ flex: 1 }}>
          When
        </TypeFace>
      </Flexbox>
      {completedPayments?.map(item => (
        <Flexbox row key={item.intent} style={{
          width: '100%',
          minHeight: 30,
          alignItems: 'center',
          borderBottom: '1px solid #dfdfdf'
        }}>
          <TypeFace size={10} style={{ flex: 1 }}>
            {item.type ?? 'Commission'}
          </TypeFace>
          <TypeFace size={10} style={{ flex: 1 }}>
            ${priceFormat(item.amount / 100)}
          </TypeFace>
          <TypeFace size={10} style={{ flex: 1 }}>
            ${item.fee ? priceFormat(item.fee / 100) : priceFormat(item.amount * 0.15 / 100)}
          </TypeFace>
          <TypeFace size={10} style={{ flex: 1 }}>
            {item.paidBy ?? "Justin"}
          </TypeFace>
          <TypeFace size={10} style={{ flex: 1 }}>
            {item.paidTo ?? 'ListMeet LLC'}
          </TypeFace>
          <TypeFace size={10} style={{ flex: 2 }}>
            {item.message}
          </TypeFace>
          <TypeFace size={10} style={{ flex: 1 }}>
            {moment(new Date(item.created)).format('MMM DD')}
          </TypeFace>
        </Flexbox>
      ))}
    </Flexbox>
  )
}

export default AdminPayments

const styles = {
  contentContainer: {
    width: '100%',
    gap: 20,
    flexWrap: 'wrap',
  },
  contentItem: {
    alignItems: 'flex-start',
    justifyContent: 'center',
    width: 160,
    height: 80,
    border: '1px solid #ededed',
    marginBottom: 20,
    padding: 20,
    borderRadius: 10,   
  },
  listingContentItem: {
    alignItems: 'flex-start',
    justifyContent: 'center',
    width: 270,
    height: 60,
    border: '1px solid #ededed',
    marginBottom: 20,
    padding: 20,
    borderRadius: 10,   
  },
  listingFullWidth: {
    alignItems: 'flex-start',
    justifyContent: 'center',
    width: 600,
    height: 60,
    border: '1px solid #ededed',
    marginBottom: 20,
    padding: 20,
    borderRadius: 10,   
  }
}
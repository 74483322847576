import React, { useState } from 'react';
import AccountBlueMenuBar from 'src/components/account/AccountBlueMenuBar';
import SubmitLicenseModal from 'src/components/account/SubmitLicenseModal';
import HomeHeaderDesktop from 'src/components/home/HomeHeaderDesktop';
import Sidebar from 'src/components/sidebar/Sidebar';
import useMediaQuery from 'src/hooks/useMediaQuery';
import AccountBasic from './AccountBasic';
import AccountMyListings from './AccountMyListings';

const Account = () => {

  const { isMobile } =  useMediaQuery()
  const [tabSelectedIdx, setTabSelectedIdx] = useState(0)
  const [submitLicenseRequestModalOpened, setSubmitLicenseRequestModalOpened] = React.useState(false);

  function openSubmitLicenseRequestModal() {
    document.body.style.overflow = 'hidden'
    setSubmitLicenseRequestModalOpened(true);
  }

  return (
    <div className="App">
      {isMobile && <Sidebar pageWrapId={"page-wrap"} outerContainerId={"App"} />}
      <div id="page-wrap">
        <div className="home-container">
          <HomeHeaderDesktop />
          <AccountBlueMenuBar selected={tabSelectedIdx} onTabSelected={setTabSelectedIdx} />
          {tabSelectedIdx === 0 && (
            <AccountBasic onSubmitLicenseRequest={openSubmitLicenseRequestModal} />
          )}
          {tabSelectedIdx === 1 && (
            <AccountMyListings />
          )}
        </div>
      </div>
      <SubmitLicenseModal
        modalIsOpen={submitLicenseRequestModalOpened}
        closeModal={() => setSubmitLicenseRequestModalOpened(false)}
      />
    </div>
  )
}

export default Account

import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { searchObjects } from 'src/algolia';
import HomeBlueMenuBar from 'src/components/home/HomeBlueMenuBar';
import HomeHeaderDesktop from 'src/components/home/HomeHeaderDesktop';
import SearchOptionsBar from 'src/components/search/SearchOptionsBarV2';
import TypeFace from 'src/components/typography/Typefaces';
import Flexbox from 'src/components/wrapper/Flexbox';
import { algoliaFilterTextFromSearchObject } from 'src/data/algolia-data-adjust';
import { selectSearchObject } from 'src/redux/search/selectors';
import LoaderGif from 'src/assets/images/loader3.gif';
import CardSearchResultItem from 'src/components/home/search/CardSearchResultItem';
import useMediaQuery from 'src/hooks/useMediaQuery';
import ReactSelect from 'react-select';
import HomeFooter from 'src/components/home/HomeFooter';
import HomeOtherLinks from 'src/components/home/HomeOtherLinks';
import HomeBlueCopyRightFooter from 'src/components/home/HomeBlueCopyRightFooter';
import { uniqBy } from "lodash"
import { useAlert } from 'src/hooks/alert/AlertContext';

const sortOptions = [
  { label: 'Newest', value: 'newest' },
  { label: 'Highest price', value: 'price-high' },
  { label: 'Lowest price', value: 'price-low' },
]


const SearchResults = () => {

  const { showAlert } = useAlert()

  var userLocation = ""
  var locationAccess = "No"
  //const [userLocation, setUserLocation] = useState("");

  const { isMobile } = useMediaQuery()
  const currentSearchObject = useSelector(selectSearchObject)

  const [searchResults, setSearchResults] = useState()

  const [isSearching, setIsSearching] = useState(false)

  const [selectedSortOption, setSelectedSortOption] = useState(sortOptions[0])

  const searchProperties = () => {
    if (!currentSearchObject) return
    const filters = algoliaFilterTextFromSearchObject(currentSearchObject)
    console.log('currentSearchObject-ON SEARCH-HERE=', currentSearchObject)
    var boundingBox = null
    // var polygonBox = null
    // var polygonArr = []
    // if (currentSearchObject?.neighborhoods !=null && currentSearchObject?.neighborhoods.length > 0 && currentSearchObject?.neighborhoods[0].bbox != null) {
    //   console.log('boundingBox=',currentSearchObject?.neighborhoods[0].bbox)
    //   //  boundingBox = currentSearchObject?.neighborhoods?.map(neighborhood => (
    //   //   [
    //   //     neighborhood.bbox[0],
    //   //     neighborhood.bbox[2],
    //   //     neighborhood.bbox[1],
    //   //     neighborhood.bbox[3],
    //   //     // 41.1263340128,-73.8010202721,41.1550220201,-73.7606020644
    //   //     //41.1801231318,
    //   //     //-73.8074320927,41.2084740772,-73.7853102386,41.1857811825,-73.7508881092,41.2231300106, -73.7101993337,41.1003351054,-73.7337430939,41.165190526,-73.6946260929
    //   //   ]

    //   // ))

    //   boundingBox = 
    //     [[
    //       currentSearchObject?.neighborhoods[0].bbox[0],
    //       currentSearchObject?.neighborhoods[0].bbox[2],
    //       currentSearchObject?.neighborhoods[0].bbox[1],
    //       currentSearchObject?.neighborhoods[0].bbox[3]
    //     ]]



    //   console.log('boundingBoxNew=',boundingBox)
    // }
    // else if (currentSearchObject?.neighborhoods !=null && currentSearchObject?.neighborhoods.length > 0 && currentSearchObject?.neighborhoods[0].polygon != null) {
    //  console.log('polygon')
    //   // polygonBox = currentSearchObject?.neighborhoods?.map(neighborhood => (
    //   //   neighborhood.polygon.map(coordArr => (
    //   //     polygonArr.push(coordArr)
    //   //   ))

    //   // ))

    //   polygonBox = currentSearchObject?.neighborhoods[0].polygon.map(coordArr => (
    //       polygonArr.push(coordArr)
    //    ))
    // }

    // console.log('polygonArr=' + polygonArr)
    let addedAreas = new Set(); // Set to track added areas

    boundingBox = currentSearchObject?.locations?.map((neighborhood) => {
      const area = neighborhood.subArea?.trim(); // Trim to avoid extra spaces causing issues

      if (!addedAreas.has(area)) {
        addedAreas.add(area);
      }

      return [
        neighborhood.bbox[1],
        neighborhood.bbox[0],
        neighborhood.bbox[3],
        neighborhood.bbox[2],
      ];
    });



    //console.log('boundingBox=',boundingBox) 
    if (locationAccess === "Yes") {
      if (boundingBox !== undefined && boundingBox.length > 0) {
        searchObjects(addedAreas, filters, selectedSortOption.value, '').then(res => {
          setIsSearching(false)
          setSearchResults(uniqBy(res.hits, "dataId"))
        })
      }
      else {
        searchObjects(addedAreas, filters, selectedSortOption.value, '', userLocation, 4828).then(res => {
          setIsSearching(false)
          setSearchResults(uniqBy(res.hits, "dataId"))
        })
      }

    }
    else {
      if (boundingBox !== undefined && boundingBox.length > 0) {
        searchObjects(addedAreas, filters, selectedSortOption.value, '').then(res => {
          setIsSearching(false)
          setSearchResults(uniqBy(res.hits, "dataId"))
        })
      }
      else {
        searchObjects(addedAreas, filters, selectedSortOption.value, '', "00.000,-00.0000", 4828).then(res => {
          setIsSearching(false)
          setSearchResults(uniqBy(res.hits, "dataId"))
        })
      }
    }

    //console.log('boundingBox-ONSEARCH=',boundingBox)
  }

  const searchResultsText = useMemo(() => {
    let resultsText = ""
    const resultsCount = searchResults?.length ?? 0
    if (resultsCount > 0) {
      resultsText += `${resultsCount}`
    } else {
      resultsText += `No`
    }

    if (currentSearchObject?.type) {
      if (currentSearchObject.type === 'sale') {
        resultsText += ` Sale${resultsCount === 1 ? '' : 's'}`
      } else {
        resultsText += ` Rental ${resultsCount === 1 ? 'Property' : 'Properties'}`
      }
    }

    if (currentSearchObject?.neighborhoods && currentSearchObject.neighborhoods.length > 0) {
      let str_PropertyAddress = currentSearchObject.neighborhoods[0].subArea?.replace(', United States', '')

      if (currentSearchObject.neighborhoods.length === 1) {
        resultsText += ` in ${str_PropertyAddress}`
      } else {
        resultsText += ` in ${str_PropertyAddress} and ${currentSearchObject.neighborhoods.length - 1} more area${currentSearchObject.neighborhoods.length - 1 === 1 ? '' : 's'}`
      }
    }
    else {
      if (locationAccess === "No") {
        resultsText += ` near your area`
      }
    }
    return resultsText
  }, [searchResults, currentSearchObject])

  useEffect(() => {
    if (currentSearchObject) {
      setIsSearching(true)
      handleLocationClick()
    }
  }, [currentSearchObject, selectedSortOption])

  useEffect(() => {
    //handleLocationClick()
  }, [])
  function handleLocationClick() {
    try {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(success, error);
      } else {
        console.log("Geolocation not supported");
      }
    } catch (err) {
      error();
    } finally {
      searchProperties()
    }
  }
  function success(position) {
    const latitude = position.coords.latitude;
    const longitude = position.coords.longitude;
    console.log("LatitudeLong=", latitude + "," + longitude);
    //setUserLocation(latitude+ "," + longitude);
    userLocation = latitude + "," + longitude
    locationAccess = "Yes"
    searchProperties()
    // setLocation({ latitude, longitude });
    console.log(`Latitude: ${latitude}, Longitude: ${longitude}`);
  }
  function error() {
    locationAccess = "No"
    searchProperties()
    console.log("Unable to retrieve your location");
    console.log("currentSearchObject.neighborhoods.length=", currentSearchObject.neighborhood);
    if (currentSearchObject.neighborhoods) {
      if (currentSearchObject.neighborhoods.length === 0) {
        showOffLocationAlert()
      }
    }
    else {
      showOffLocationAlert()
    }

  }
  const showOffLocationAlert = () => {
    showAlert(true, 'Enable location services to search by your current location.', [
      {
        title: "Ok",
        onClick: () => {
          showAlert(false)
        }
      },
    ])
  }

  return (
    <div className="App">
      <div id="page-wrap">
        <div className="home-container">
          <HomeHeaderDesktop />
          {/* {isMobile ? <BlueMenuBarWithBackButton /> : <HomeBlueMenuBar />} */}
          <HomeBlueMenuBar />
          <SearchOptionsBar />
          <Flexbox style={{ width: '100%' }}>
            {isSearching ? (
              <img src={LoaderGif} style={{ width: 30, height: 30, marginTop: 40 }} />
            ) : (
              <Flexbox style={{
                width: '90%',
                maxWidth: 1178,
                alignItems: 'flex-start'
              }}>
                <Flexbox row style={{ marginTop: 20, marginBottom: 20, gap: 20 }}>
                  <TypeFace bold>
                    {searchResultsText}
                  </TypeFace>
                  <Flexbox row style={{ gap: 10 }}>
                    <TypeFace medium size={12}>
                      Sort by
                    </TypeFace>
                    <div style={{ width: 160 }}>
                      <ReactSelect
                        isSearchable={false}
                        options={sortOptions}
                        value={selectedSortOption}
                        onChange={value => {
                          setSelectedSortOption(value)
                        }}
                      />
                    </div>
                  </Flexbox>
                </Flexbox>
                <Flexbox style={{
                  width: '100%',
                  flexWrap: 'wrap',
                  flexDirection: isMobile ? 'column' : 'row'
                }}>
                  {searchResults?.map((hit, index) => (
                    <CardSearchResultItem key={index} hit={hit} />
                  ))}
                </Flexbox>
              </Flexbox>
            )}
          </Flexbox>
          <HomeFooter />
          <HomeOtherLinks />
          <HomeBlueCopyRightFooter />
        </div>
      </div>
    </div>
  )
}

export default SearchResults

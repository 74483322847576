import { doc, updateDoc } from 'firebase/firestore'
import React, { useState } from 'react'
import { useNavigate } from 'react-router'
import { updateAlogliaObject } from 'src/algolia'
import TypeFace from 'src/components/typography/Typefaces'
import Flexbox from 'src/components/wrapper/Flexbox'
import { firestore } from 'src/firebase'
import { propertyStatusOptions } from 'src/model/types'
import { extractStreetAddressFromFullAddress } from 'src/utils/geoUtils'
import { priceFormat } from 'src/utils/strings'

const AdminListingCell = ({
  algoliaListingData
}) => {

  const navigate = useNavigate()

  const [changedStatus, setChangedStatus] = useState()

  const handleChangeStatus = (event) => {
    const newStatus = event.target.value
    setChangedStatus(newStatus)

    updateDoc(doc(firestore, `listing/${algoliaListingData.dataId}`), {
      status: newStatus
    })

    updateAlogliaObject(algoliaListingData.objectID, {
      status: newStatus
    })
  }

  const handleViewDetails = () => {
    navigate(`/admin/board/listings/${algoliaListingData.dataId}`)
  }
  
  return (
    <Flexbox row style={styles.row}>
      <TypeFace size={10} style={{ flex: 1.4, cursor: 'pointer' }}>
      {algoliaListingData.unit !== '' ?
            <>
            {algoliaListingData.unit}, {extractStreetAddressFromFullAddress(algoliaListingData.address.full)}
            </> : 
            <>
           {extractStreetAddressFromFullAddress(algoliaListingData.address.full)}
            </>}
      </TypeFace>
      <TypeFace size={10} style={{ flex: 1, cursor: 'pointer' }}>
        {algoliaListingData.type}
      </TypeFace>
      <TypeFace size={10} style={{ flex: 1, cursor: 'pointer' }}>
        {changedStatus ? changedStatus : algoliaListingData.status}
      </TypeFace>
      <TypeFace size={10} style={{ flex: 1, cursor: 'pointer' }}>
        {algoliaListingData.type === 'sale' ? `$${priceFormat(algoliaListingData.price)}` : `$${priceFormat(algoliaListingData.grossRent)}`}
      </TypeFace>
      <TypeFace size={10} style={{ flex: 1, cursor: 'pointer' }}>
        {algoliaListingData.agency}
      </TypeFace>
      <Flexbox style={{ width: 120, gap: 5, justifyContent: 'center' }}>
        <select
          value={algoliaListingData.status}
          style={styles.statusDropdown}
          onChange={handleChangeStatus}
        >
          {propertyStatusOptions.filter(option => option.value !== "Draft").map(option => (
            <option value={option.value}>{option.label}</option>
          ))}
          <option value={'Blocked'}>Blocked</option>
        </select>
      </Flexbox>
    </Flexbox>
  )
}

export default AdminListingCell

const styles = {
  row: {
    width: '100%',
    alignItems: 'center',
    borderBottom: '1px solid #cdcdcd',
    padding: '5px 0px',
    cursor: 'pointer'
  },
  statusDropdown: {
    height: 20,
    width: 80,
  },
}
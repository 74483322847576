import { useJsApiLoader } from '@react-google-maps/api';

const jsApiLoaderOptions = {
  id: 'google-map-script',
  googleMapsApiKey: process.env.REACT_APP_googleApiKey,
  libraries: ['places'],
};

export default function useGoogleMapsLoader() {
  // TODO @vince type this correctly
  return useJsApiLoader(jsApiLoaderOptions);
}

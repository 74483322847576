import './App.css';
import { Route, createBrowserRouter, createRoutesFromElements, RouterProvider } from "react-router-dom";
import Home from './pages/home/Home'
import Search from './pages/search/Search'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "src/components/modal/Modal.css"
import ReactModal from 'react-modal';
import { AuthProvider } from './hooks/auth/AuthProvider';
import { AlertProvider } from './hooks/alert/AlertProvider';
import AlertModal from './components/modal/AlertModal';
import Account from './pages/account/Account';
import { FirestoreProvider } from './hooks/firestore/FirestoreProvider';
import NewListing from './pages/newListing/NewListing';
import { Provider as ReduxProvider } from "react-redux";
import { store } from './redux/store';
import EditListing from './pages/newListing/EditListingV2';
import Manage from './pages/manage/Manage';
import TempAreasInput from './pages/areas/TempAreasInput';
import ListingDetails from './pages/listing/ListingDetails';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import StripeAccountRefresh from './pages/stripe/StripeAccountRefresh';
import StripeAccountReturn from './pages/stripe/StripeAccountReturn';
import Profile from './pages/profile/Profile';
import SavedProperties from './pages/saved/SavedProperties';
import CustomerSchedules from './pages/schedules/CustomerSchedules';
import CustomerProfile from './pages/profile/CustomerProfile';
import CustomerPaymentCompleted from './pages/payment/CustomerPaymentCompleted';
import { AppProtectProvider } from './hooks/auth/AppProtectProvider';
import AdminBoard from './pages/admin/AdminBoard';
import Terms from './pages/terms/Terms';
import Cookie from './pages/cookie/Cookie';
import Privacy from './pages/privacy/Privacy';
import About from './pages/about/About';
import Procedure from './pages/procedure/Procedure';
import FairHousing from './pages/fairHousing/FairHousing';
import Support from './pages/support/Support';
import SupportApp from './pages/appSupport/SupportApp';
import PrivacyApp from './pages/appPrivacy/PrivacyApp';
import TermsApp from './pages/appTerms/TermsApp';

function App() {

  ReactModal.setAppElement('#root');

  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/account" element={<Account />} />
        <Route path="/new-listing" element={<NewListing />} />
        <Route path="/edit/listing/:id" element={<EditListing />} />
        <Route path="/manage" element={<Manage />} />
        <Route path="/manage/:page/:messageId?" element={<Manage />} />
        <Route path="/temps/areas" element={<TempAreasInput />} />
        <Route path="/search" element={<Search />} />
        <Route path="/saved" element={<SavedProperties />} />
        <Route path="/schedules" element={<CustomerSchedules />} />
        <Route path="/listing/:id" element={<ListingDetails />} />
        <Route path="/stripe/account/refresh" element={<StripeAccountRefresh />}/>
        <Route path="/stripe/account/return" element={<StripeAccountReturn />}/>
        <Route path="/profile/:profileId" element={<Profile />} />
        <Route path="/my/profile" element={<CustomerProfile />} />
        <Route path="/my/profile/:mode" element={<CustomerProfile />} />
        <Route path="/payments/done" element={<CustomerPaymentCompleted />} />
        <Route path="/admin/board" element={<AdminBoard />} />
        <Route path="/admin/board/:page" element={<AdminBoard />} />
        <Route path="/admin/board/:page/:subPage" element={<AdminBoard />} />
        <Route path="/about-us" element={<About />} />
        <Route path="/cookie-policy" element={<Cookie />} />
        <Route path="/privacy-policy" element={<Privacy />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/fair-housing" element={<FairHousing />} />
        <Route path="/procedures" element={<Procedure />} />
        <Route path="/support" element={<Support />} />
        <Route path="/appSupport" element={<SupportApp />} />
        <Route path="/appPrivacy" element={<PrivacyApp />} />
        <Route path="/appTerms" element={<TermsApp />} />
      </>
    )
  );

  return (
    <ReduxProvider store={store}>
       <AppProtectProvider>  
        <AuthProvider>
          <FirestoreProvider>
            <AlertProvider>
              <RouterProvider router={router} />
              <AlertModal />
            </AlertProvider>
          </FirestoreProvider>
        </AuthProvider>
       </AppProtectProvider>  
    </ReduxProvider>
  );
}

export default App;

export const extractAddressComponent = (googleGeoAddressComponents, mapGeoAddressComponents) => {
  const postal_code = googleGeoAddressComponents.find(component => component.types.includes("postal_code"))?.long_name ?? ''
  const stateComp = googleGeoAddressComponents.find(component => component.types.includes("administrative_area_level_1"))
  const cityComp = googleGeoAddressComponents.find(component => component.types.includes("administrative_area_level_2") || component.types.includes("city"))
  const neighborhoodComp = mapGeoAddressComponents.find(component => component.place_type.includes("neighborhood"))
  const localityComp = mapGeoAddressComponents.find(component => component.place_type.includes("locality"))
  const districtComp = mapGeoAddressComponents.find(component => component.place_type.includes("district"))

  const res = {
    postal_code,
    state: {
      long: stateComp?.long_name,
      short: stateComp?.short_name,
    },
    city: {
      long: cityComp?.long_name,
      short: cityComp?.short_name,
    },
    components: mapGeoAddressComponents?.map(comp => {
      return {
        types: comp.place_type,
        text: comp.text,
        bbox: comp.bbox,
        center: comp.center
      }
    })
  }

  if (localityComp) {
    res.locality = localityComp.text
  }

  if (neighborhoodComp) {
    res.neighborhood = neighborhoodComp.text
  }

  if (districtComp) {
    res.district = districtComp.text
  }

  return res
}

export const checkBoundingBoxContainsIn = (areaBox, subAreaBox) => {
  return (areaBox[0] - subAreaBox[0]) * (subAreaBox[0] - areaBox[2]) > 0 &&
    (areaBox[0] - subAreaBox[2]) * (subAreaBox[2] - areaBox[2]) > 0 &&
    (areaBox[1] - subAreaBox[1]) * (subAreaBox[1] - areaBox[3]) > 0 &&
    (areaBox[1] - subAreaBox[3]) * (subAreaBox[3] - areaBox[3]) > 0
}

export const extractStreetAddressFromFullAddress = (fullAddress) => {
  return fullAddress?.split(',')[0]
}

export const extractCityStateFromFullAddress = (fullAddress) => {
  return ', ' + fullAddress?.split(',')[1] ?? '' + ', ' + fullAddress?.split(',')[2] ?? '' + ', ' + fullAddress?.split(',')[3] ?? ''
}

export const getStaticMapUrl = (location, width, height) => {
  let url = "https://maps.googleapis.com/maps/api/staticmap?"
  url += `center=${location?.lat}, ${location?.lng}`
  url += '&zoom=14'
  url += `&size=${width}x${height}`
  url += `&markers=color:red|${location?.lat}, ${location?.lng}`
  url += `&key=${process.env.REACT_APP_googleApiKey}`
  return url
}

import { collection, doc, getDoc, getDocs, onSnapshot, orderBy, query, updateDoc, where } from 'firebase/firestore'
import moment from 'moment'
import React, { useEffect, useRef } from 'react'
import { useState } from 'react'
import { updateAlogliaObject } from 'src/algolia'
import Button from 'src/components/button/Button'
import TypeFace from 'src/components/typography/Typefaces'
import Flexbox from 'src/components/wrapper/Flexbox'
import { firestore } from 'src/firebase'
import { roleLabelFromValue } from 'src/model/types'
import { Colors } from 'src/theme/Colors'
import { useNavigate } from 'react-router'

const AdminAgencyDetails = ({
  agencyId
}) => {

  const unmountedRef = useRef(false)
  const navigate = useNavigate()
  const [agencyData, setAgencyData] = useState()
  const [agencyMembers, setAgencyMembers] = useState()

  const handleApprove = (userId) => {
    updateDoc(doc(firestore, `profiles/${userId}`), {
      agencyApproved: true,
      agencyReleased: false,
      releasedDate: '',
      activatedDate: new Date().getTime(),
    })
  }

  const handleBlock = (userId) => {
    updateDoc(doc(firestore, `profiles/${userId}`), {
      profileBlocked: true,
      activatedDate: '',
    })

    const q = query(collection(firestore, `listing`), where('by', '==', userId))
    getDocs(q).then(docs => {
      docs.forEach(doc => {
        const listingData = doc.data()
        updateDoc(doc(firestore, `listing/${listingData.id}`), {
          blocked: true
        })

        updateAlogliaObject(listingData.algoliaID, {
          status: 'Blocked'
        })
      })
    })
  }

  const handleUnblock = (userId) => {
    updateDoc(doc(firestore, `profiles/${userId}`), {
      profileBlocked: false,
      activatedDate: new Date().getTime(),
    })

    const q = query(collection(firestore, `listing`), where('by', '==', userId))
    getDocs(q).then(docs => {
      docs.forEach(doc => {
        const listingData = doc.data()
        updateDoc(doc(firestore, `listing/${listingData.id}`), {
          blocked: false
        })

        updateAlogliaObject(listingData.algoliaID, {
          status: doc.data().status
        })
      })
    })
  }

  const handleViewListing = (creatorId) => {
    navigate(`/admin/board/propertyListing/${creatorId}`)
  }

  useEffect(() => {
    unmountedRef.current = false

    getDoc(doc(firestore, `agencies/${agencyId}`)).then(agencyDoc => {
      setAgencyData(agencyDoc.data())
    })
console.log("agencyId=",agencyId)
    const snapshotListener = onSnapshot(query(collection(firestore, 'profiles'), where('agency', '==', agencyId) , orderBy('created', 'desc')), (querySnapshot) => {
      if (!unmountedRef.current) {
        const d = [];
        querySnapshot.forEach((doc) => {
          console.log("doc.data()=",doc.data())
          if(doc.data().role !== 'broker') {
            d.push({
              id: doc.id,
              ...doc.data()
            });
          }
          
        });
        setAgencyMembers(d)
        // setAgencyMembers(d.sort((a, b) => {
        //   if (a.role === 'broker' && b.role !== 'broker') {
        //     return -1
        //   }

        //   if (a.role !== 'broker' && b.role === 'broker') {
        //     return 1
        //   }

        //   return a.name > b.name ? -1 : 1
        // }))
      }
    })
    return () => {
      unmountedRef.current = true
      snapshotListener()
    }
  }, [agencyId])

  return (
    <Flexbox style={{ alignItems: 'flex-start', width: '100%' }}>
      <TypeFace bold>
        {agencyData?.name ?? "Agency Details"}
      </TypeFace>
      <Flexbox row style={{
        width: '100%',
        height: 30,
        marginTop: 20,
        backgroundColor: '#dfdfdf',
        alignItems: 'center',
      }}>
        <TypeFace size={10} style={{ flex: 1 }}>
          Name
        </TypeFace>
        <TypeFace size={10} style={{ flex: 1 }}>
          Email
        </TypeFace>
        <TypeFace size={10} style={{ flex: 1 }}>
          License Number
        </TypeFace>
        <TypeFace size={10} style={{ flex: 1 }}>
          License Name
        </TypeFace>
        <TypeFace size={10} style={{ flex: 1 }}>
          Role
        </TypeFace>
        <TypeFace size={10} style={{ flex: 1 }}>
          Status
        </TypeFace>
        <TypeFace size={10} style={{ flex: 1 }}>
          Created
        </TypeFace>
        <TypeFace size={10} style={{ flex: 1 }}>
          Actions
        </TypeFace>
        <TypeFace size={10} style={{ flex: 1 }}>
          Listings
        </TypeFace>
      </Flexbox>
      {agencyMembers?.map(item => (
        <Flexbox row key={item.id} style={{
          width: '100%',
          minHeight: 30,
          alignItems: 'center',
          borderBottom: '1px solid #dfdfdf'
        }}>
          <TypeFace size={10} style={{ flex: 1 }}>
            {item.name}
          </TypeFace>
          <TypeFace size={10} style={{ flex: 1 }}>
            {item.email}
          </TypeFace>
          <TypeFace size={10} style={{ flex: 1 }}>
            {item.licenseNumber}
          </TypeFace>
          <TypeFace size={10} style={{ flex: 1 }}>
            {item.licenseName}
          </TypeFace>
          <TypeFace size={10} style={{ flex: 1 }}>
            {roleLabelFromValue(item.role)}
          </TypeFace>
          <TypeFace size={10} style={{ flex: 1 }}>
            {item.role !== 'broker' ? (item.profileBlocked ? 'Blocked' : (item.agencyApproved ? 'Approved' : 'Not approved, yet')) : ''}
          </TypeFace>
          <TypeFace size={10} style={{ flex: 1 }}>
            {moment(new Date(item.created)).format('MMM DD, yyyy')}
          </TypeFace>
          <Flexbox row style={{ flex: 1, gap: 5, justifyContent: 'center' }}>
            {item.role !== 'broker' && (
              <>
                {item.agencyApproved !== true && (
                  <Button textSize={10} primary style={{ width: 60}} onClick={() => handleApprove(item.userId)}>
                    APPROVE
                  </Button>
                )}
                {item.profileBlocked ? (
                  <Button textSize={10} primary style={{ backgroundColor: Colors.primary, width: 44}} onClick={() => handleUnblock(item.userId)}>
                    Unblock
                  </Button>
                ) : (
                  <Button textSize={10} primary style={{ backgroundColor: 'red', width: 44}} onClick={() => handleBlock(item.userId)}>
                    BLOCK
                  </Button>
                )}
              </>
            )}
            
            {/* {(!agency.status || agency.status === "" || agency.status === 'submitted') && (
              <>
                <Button textSize={10} primary style={{ width: 60}} onClick={() => handleApprove(agency.id, agency.creator)}>
                  APPROVE
                </Button>
                <Button textSize={10} primary style={{ backgroundColor: 'red', width: 60}} onClick={() => handleBlock(agency.id, agency.creator)}>
                  DECLINE
                </Button>
              </>
            )}
            {agency.status === 'approved' && (
              <>
                <Button textSize={10} onClick={() => handleViewAgencyt(agency.id)}>
                  View
                </Button>
                <Button textSize={10} primary style={{ backgroundColor: 'red', width: 44}} onClick={() => handleBlock(agency.id, agency.creator)}>
                  BLOCK
                </Button>
              </>
            )}
            {agency.status === 'blocked' && (
              <>
                <Button textSize={10} primary style={{ width: 60}} onClick={() => handleApprove(agency.id, agency.creator)}>
                  APPROVE
                </Button>
              </>
            )} */}
          </Flexbox>
          <Flexbox row style={{ flex: 1, gap: 5, justifyContent: 'center' }}>
          <Button textSize={10} onClick={() => handleViewListing(item.userId)}>
                  View
           </Button>
          </Flexbox>
        </Flexbox>
      ))}
    </Flexbox>
  )
}

export default AdminAgencyDetails

const styles = {
  contentContainer: {
    width: '100%',
    gap: 20,
    flexWrap: 'wrap',
  },
  contentItem: {
    alignItems: 'flex-start',
    justifyContent: 'center',
    width: 160,
    height: 80,
    border: '1px solid #ededed',
    marginBottom: 20,
    padding: 20,
    borderRadius: 10,   
  },
  listingContentItem: {
    alignItems: 'flex-start',
    justifyContent: 'center',
    width: 270,
    height: 60,
    border: '1px solid #ededed',
    marginBottom: 20,
    padding: 20,
    borderRadius: 10,   
  },
  listingFullWidth: {
    alignItems: 'flex-start',
    justifyContent: 'center',
    width: 600,
    height: 60,
    border: '1px solid #ededed',
    marginBottom: 20,
    padding: 20,
    borderRadius: 10,   
  }
}
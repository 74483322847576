import React, { useMemo, useState } from 'react';
import HomeHeaderDesktop from 'src/components/home/HomeHeaderDesktop';
import TextInput from 'src/components/input/TextInput';

import PlacesAutocomplete from 'src/components/placeAutoComplete/PlaceAutoComplete';
import Flexbox from 'src/components/wrapper/Flexbox';
import TypeFace from 'src/components/typography/Typefaces';
import Button from 'src/components/button/Button';
import { createNewListing } from 'src/data/createListing'
import { useAuth } from 'src/hooks/auth/AuthContext';
import { useNavigate } from 'react-router-dom';
import Loading from 'react-fullscreen-loading';

const NewListing = () => {  

  const { user } = useAuth()
  const navigate = useNavigate()
  const [address, setAddress] = useState('')
  const [latitude, setLatitude] = useState(0)
  const [longitude, setLongitude] = useState(0)
  const [unit, setUnit] = useState('')
  const [processing, setProcessing] = useState(false)

  const createButtonEnabled = useMemo(() => {
    return address !== "" && latitude !== 0 && longitude !== 0 && unit !== ""
  }, [address, latitude, longitude, unit])

  const handleCreate = () => {
    setProcessing(true)
    createNewListing(user.uid, address, latitude, longitude, unit)
      .then(docId => {
        setProcessing(false)
        navigate(`/new/${docId}`)
      })
      .catch(err => {
        setProcessing(false)
      })
  }

  return (
    <div className="App">
      <div id="page-wrap">
        <div className="home-container">
          <HomeHeaderDesktop />
          <Flexbox style={styles.addressUnitInputBox}>
            <TypeFace semiBold>Address & Unit</TypeFace>
            <Flexbox row style={styles.addressUnit}>
              <PlacesAutocomplete style={{ flex: 1 }} onSelectAddress={data => {
                setAddress(data.address)
                setLatitude(data.lat)
                setLongitude(data.lng)
              }}/>
              <TextInput
                placeholder="Unit"
                style={styles.unitTextInput}
                value={unit}
                onChange={e => setUnit(e.target.value)}
              />
              <Button onClick={handleCreate} disabled={!createButtonEnabled} primary style={styles.createButton}>
                Create
              </Button>
            </Flexbox>
          </Flexbox>
        </div>
        {processing && <Loading loading background="#0005" loaderColor="white" />}
      </div>
    </div>
  )
}

export default NewListing

const styles = {
  unitTextInput: {
    marginLeft: 10,
    width: 200,
  },
  createButton: {
    height: 44,
    marginLeft: 10
  },
  addressUnitInputBox: {
    width: 1000, padding: 10, margin: 'auto', alignItems: 'flex-start'
  },
  addressUnit: {
    width: "100%", alignItems: 'flex-start', marginTop: 20
  }
}
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Colors } from 'src/theme/Colors';
import Modal from 'react-modal';
import Flexbox from 'src/components/wrapper/Flexbox';
import TypeFace from 'src/components/typography/Typefaces';
import Button from 'src/components/button/Button';
import useAreas from 'src/hooks/data/useAreas';
import { splitArrayIntoChunksArray } from 'src/utils/arrays';
import IconArrowDownGray from 'src/assets/images/icons/icon-arrow-down-gray.svg'
import IconArrowUpGray from 'src/assets/images/icons/icon-arrow-up-gray.svg'

const SelectNeighborhoodsModalMobile = (props) => {

  const { areas, subAreas } = useAreas()
  
  const [expandedAreaIds, setExpandedAreaIds] = useState([])

  const [selectedSubAreaNames, setSelectedSubAreaNames] = useState([])

  const handleApply = () => {
    props.onApply(selectedSubAreaNames)
    closeModal()
  }

  const subAreaChunksArray = (areaName) => {
    if (subAreas) {
      return splitArrayIntoChunksArray(subAreas[areaName], 2)
    }
  }

  const selectedSubAreasCountForArea = useCallback((area) => {
    return selectedSubAreaNames.filter(a => a.area === area.name)?.length ?? 0
  }, [selectedSubAreaNames])

  const afterOpenModal = () => {
    setSelectedSubAreaNames(props.selection ?? [])
  }

  function closeModal() {
    props.closeModal()
    document.body.style.overflow = 'auto'
  }

  const handleClear = () => {
    setSelectedSubAreaNames([])
  }

  return (
    <Modal
      isOpen={props.modalIsOpen}
      onRequestClose={closeModal}
      onAfterOpen={afterOpenModal}
      className="mymodal"
      overlayClassName="myoverlay"

    >
      <Flexbox style={styles.container}>
        <Flexbox style={styles.titleHeader}>
          <Button onClick={handleClear} secondary style={styles.clearButton} textColor='white' textSize={11}>
            CLEAR
          </Button>
          <TypeFace bold color="white" size={15}>
            Select Neighborhoods{selectedSubAreaNames.length > 0 ? `(${selectedSubAreaNames.length})` : ""}
          </TypeFace>
          <Button onClick={handleApply} secondary style={styles.applyButton} textColor='white' textSize={11}>
            DONE
          </Button>
        </Flexbox>
        <Flexbox style={styles.content}>
          {areas?.map((area, idx) => (
            <Flexbox style={styles.areas}>
              <Flexbox
                row
                key={`area-${idx}`}
                style={styles.areaButton}
                onClick={() => {
                  console.log("Area ", area.local, expandedAreaIds)
                  if (expandedAreaIds.includes(area.local)) {
                    setExpandedAreaIds([...expandedAreaIds.filter(id => id !== area.local)])
                  } else {
                    setExpandedAreaIds([...expandedAreaIds, area.local])
                  }
                }}>
                <TypeFace semiBold size={14} style={styles.areaText}>
                  {area.name}{selectedSubAreasCountForArea(area) !== 0 ? `(${selectedSubAreasCountForArea(area)})` : ''}
                </TypeFace>
                <div style={{ flex: 1 }} />
                <img src={expandedAreaIds.includes(area.local) ? IconArrowUpGray : IconArrowDownGray} width={16} height={16} />
              </Flexbox>
              {expandedAreaIds.includes(area.local) && (
                <Flexbox style={styles.subAreasBox}>
                  {subAreaChunksArray(area.name)?.map((subAreaChunk, index) => (
                    <Flexbox row key={`chunks-${index}`} style={{width: '100%'}}>
                      {subAreaChunk.map((subArea, idx) => (
                        <Flexbox row style={styles.subAreaItem} key={`subArea-${subArea.name}`}>
                          <input type="checkbox" id={`check${subArea.name}`} checked={selectedSubAreaNames.some(a => a.subArea === subArea.name)} onChange={e => {
                            if (e.target.checked) {
                              setSelectedSubAreaNames([...selectedSubAreaNames, {
                                area: area.name,
                                subArea: subArea.name,
                                bbox: subArea.component.bbox
                              }])
                            } else {
                              setSelectedSubAreaNames([...selectedSubAreaNames.filter(a => a.subArea !== subArea.name)])
                            }
                          }} />
                          <TypeFace for={`check${subArea.name}`} size={14}>
                            {subArea.name}
                          </TypeFace>
                        </Flexbox>
                      ))}
                    </Flexbox>
                  ))}
                </Flexbox> 
              )}
            </Flexbox>
          ))}
        </Flexbox>
        {/* <Flexbox row style={styles.areas}>
          {areas?.map((area, idx) => (
            <Flexbox onClick={() => {
              setSelectedArea(area)
            }} key={`area-${idx}`} style={{
              backgroundColor: area.name === selectedArea?.name ? Colors.optionBack : 'transparent',
              ...styles.areaButton,
            }}>
              <TypeFace semiBold size={14} style={styles.areaText}>
                {area.name}{selectedSubAreasCountForArea(area) !== 0 ? `(${selectedSubAreasCountForArea(area)})` : ''}
              </TypeFace>
            </Flexbox>
          ))}
        </Flexbox>
        <Flexbox style={styles.subAreasBox}>
          {subAreasChunks?.map((subAreaChunk, index) => (
            <Flexbox row key={`chunks-${index}`} style={{width: 'calc(100% - 20px)'}}>
              {subAreaChunk.map((subArea, idx) => (
                <Flexbox row style={styles.subAreaItem} key={`subArea-${subArea.name}`}>
                  <input type="checkbox" id={`check${subArea.name}`} checked={selectedSubAreaNames.some(a => a.subArea === subArea.name)} onChange={e => {
                    if (e.target.checked) {
                      setSelectedSubAreaNames([...selectedSubAreaNames, {
                        area: selectedArea.name,
                        subArea: subArea.name,
                        bbox: subArea.component.bbox
                      }])
                    } else {
                      setSelectedSubAreaNames([...selectedSubAreaNames.filter(a => a.subArea !== subArea.name)])
                    }
                  }} />
                  <TypeFace for={`check${subArea.name}`}>
                    {subArea.name}
                  </TypeFace>
                </Flexbox>
              ))}
            </Flexbox>
          ))}
        </Flexbox> */}
      </Flexbox>
    </Modal>
  )
}

export default SelectNeighborhoodsModalMobile

const styles = {
  container: {
    width: 'calc(100vw - 40px)',
    height: 'calc(100vh - 120px)',
  },
  mobileContainer: {
    width: 'calc(100% - 40px)',
    height: 'calc(100% - 40px)',
  },
  separator: {
    height: 1,
    width: '100%',
    backgroundColor: '#DFDFDF',
    marginTop: 20,
    marginBottom: 20
  },
  titleHeader: {
    width: '100%',
    height: 60,
    backgroundColor: Colors.primary,
    justifyContent: 'center',
  },
  applyButton: {
    position: 'absolute',
    width: 60,
    height: 44,
    right: 0,
    top: 8,
    backgroundColor: '#0000',
    borderRadius: 0,
  },
  clearButton: {
    position: 'absolute',
    width: 60,
    height: 44,
    left: 0,
    top: 8,
    backgroundColor: '#0000',
    borderRadius: 0,
  },
  content: {
    width: '100%',
    flex: 1,
    overflow: 'scroll'
  },
  areas: {
    width: '100%',
  },
  areaButton: {
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: 'calc(100% - 40px)',
    paddingLeft: 20,
    paddingRight: 20,
    height: 60,
    borderBottom: '1px solid #efefef',
    cursor: 'pointer',
  },
  areaText: {
    textTransform: 'uppercase',
    cursor: 'pointer'
  },
  subAreasBox: {
    width: 'calc(100% - 40px)',
    paddingLeft: 20,
    paddingRight: 20,
    marginTop: 20,
    alignItems: 'center'
  },
  subAreaItem: {
    flex: 1,
    height: 40,
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: 10,
  }
}
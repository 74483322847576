import { compareIfTwoStringArraySame } from "./arrays"
import { validateYoutubeURL } from "./strings"

const unitInfoUpdated = (unitInfo1, unitInfo2) => {
  if (unitInfo1 === undefined && unitInfo2 !== undefined) {
    return true
  }

  if (unitInfo1 !== undefined && unitInfo2 === undefined) {
    return true
  }

  if (unitInfo1 === undefined && unitInfo2 === undefined) {
    return false
  }

  if (unitInfo1.acTypes !== unitInfo2.acTypes) {
    return true
  }

  if (!compareIfTwoStringArraySame(unitInfo1.attics, unitInfo2.attics)) {
    return true
  }

  if (!compareIfTwoStringArraySame(unitInfo1.basements, unitInfo2.basements)) {
    return true
  }

  if (unitInfo1.desc !== unitInfo2.desc) {
    return true
  }

  if (!compareIfTwoStringArraySame(unitInfo1.features, unitInfo2.features)) {
    return true
  }

  if (!compareIfTwoStringArraySame(unitInfo1.fireplace, unitInfo2.fireplace)) {
    return true
  }

  if (unitInfo1.fullBaths !== unitInfo2.fullBaths) {
    return true
  }

  if (!compareIfTwoStringArraySame(unitInfo1.heatingFuels, unitInfo2.heatingFuels)) {
    return true
  }

  if (!compareIfTwoStringArraySame(unitInfo1.heatingTypes, unitInfo2.heatingTypes)) {
    return true
  }

  if (unitInfo1.legalBeds !== unitInfo2.legalBeds) {
    return true
  }

  if (unitInfo1.halfBaths !== unitInfo2.halfBaths) {
    return true
  }

  if (unitInfo1.lotSize !== unitInfo2.lotSize) {
    return true
  }

  if (unitInfo1.lotSizeText !== unitInfo2.lotSizeText) {
    return true
  }

  if (unitInfo1.marketAs !== unitInfo2.marketAs) {
    return true
  }

  if (unitInfo1.marketAs === 'other') {
    if (unitInfo1.otherMarketAs !== unitInfo2.otherMarketAs) {
      return true
    }
  }

  if (!compareIfTwoStringArraySame(unitInfo1.outdoor, unitInfo2.outdoor)) {
    return true
  }

  if (unitInfo1.size !== unitInfo2.size) {
    return true
  }

  if (unitInfo1.totalRooms !== unitInfo2.totalRooms) {
    return true
  }

  if (!compareIfTwoStringArraySame(unitInfo1.views, unitInfo2.views)) {
    return true
  }

  if (unitInfo1.dateAvailable !== unitInfo2.dateAvailable) {
    return true
  }

  if (unitInfo1.concession !== unitInfo2.concession) {
    return true
  }

  if (unitInfo1.monthsFree !== unitInfo2.monthsFree) {
    return true
  }

  if (unitInfo1.leaseTerm !== unitInfo2.leaseTerm) {
    return true
  }

  if (unitInfo1.saleType !== unitInfo2.saleType) {
    return true
  }

  if (unitInfo1.furnished !== unitInfo2.furnished) {
    return true;
  }

  // if (unitInfo1.furnishedRents !== unitInfo2.furnishedRents) {
  //   return true;
  // }

  return false
}

const buildingInfoUpdated = (buildingInfo1, buildingInfo2) => {
  if (buildingInfo1 === undefined && buildingInfo2 !== undefined) {
    return true
  }

  if (buildingInfo1 !== undefined && buildingInfo2 === undefined) {
    return true
  }

  if (buildingInfo1 === undefined && buildingInfo2 === undefined) {
    return false
  }

  if (buildingInfo1?.year !== buildingInfo2?.year) {
    return true
  }

  if (buildingInfo1?.type !== buildingInfo2?.type) { 
    return true
  }

  if (buildingInfo1?.doorMan !== buildingInfo2?.doorMan) {
    return true
  }

  if (buildingInfo1?.pets !== buildingInfo2?.pets) {
    return true
  }

  if (!compareIfTwoStringArraySame(buildingInfo1?.features, buildingInfo2?.features)) {
    return true
  }

  if (!compareIfTwoStringArraySame(buildingInfo1?.locations, buildingInfo2?.locations)) {
    return true
  }

  if (!compareIfTwoStringArraySame(buildingInfo1?.outdoor, buildingInfo2?.outdoor)) {
    return true
  }

  if (!compareIfTwoStringArraySame(buildingInfo1?.parking, buildingInfo2?.parking)) {
    return true
  }

  if (!compareIfTwoStringArraySame(buildingInfo1?.shared, buildingInfo2?.shared)) {
    return true
  }

  if (!compareIfTwoStringArraySame(buildingInfo1?.storage, buildingInfo2?.storage)) {
    return true
  }

  return false
}

export const compareIfListingDataUpdated = (oldListing, newListing) => {

  if (newListing !== undefined && oldListing === undefined) {
    return true
  }

  if (newListing === undefined && oldListing !== undefined) {
    return true
  }

  if (!newListing && !oldListing) {
    return false
  }

  if (newListing) {
    if (newListing.status !== oldListing?.status) {
      return true
    }

    if (newListing.exclusivity !== oldListing?.exclusivity) {
      return true
    }

    if (newListing.start !== oldListing?.start) {
      return true
    }

    if (newListing.end !== oldListing?.end) {
      return true
    }

    if (newListing.ownerPaysFee !== oldListing?.ownerPaysFee) {
      return true
    }

    if (newListing.collectYourOwnFee !== oldListing?.collectYourOwnFee) {
      return true
    }

    if (newListing.coBrokerSelected !== oldListing?.coBrokerSelected) {
      return true
    }

    if (newListing.grossCommission !== oldListing?.grossCommission) {
      return true
    }

    if (newListing.coBrokeCommission !== oldListing?.coBrokeCommission) {
      return true
    }

    if (!compareIfTwoStringArraySame(newListing.team, oldListing.team)) {
      return true
    }

    if (newListing.internalNotes !== oldListing.internalNotes) {
      return true
    }

    if (newListing.meetEnabled !== oldListing?.meetEnabled) {
      return true
    }

    if (newListing.showingPaymentAmount !== oldListing?.showingPaymentAmount) {
      return true
    }

    if (buildingInfoUpdated(newListing.building, oldListing?.building)) {
      return true
    }

    if (newListing.commonCharges !== oldListing?.commonCharges) {
      return true
    }

    if (newListing.maxFinancing !== oldListing?.maxFinancing) {
      return true
    }

    if (newListing.unit !== oldListing?.unit) {
      return true
    }

    if (newListing.monthlyTax !== oldListing?.monthlyTax) {
      return true
    }

    if (newListing.price !== oldListing?.price) {
      return true
    }

    if (newListing.maintenance !== oldListing?.maintenance) {
      return true
    }

    if (newListing.website !== oldListing?.website) {
      return true
    }

    if (newListing.video !== oldListing?.video) {
      return true
    }

    if (unitInfoUpdated(newListing.unitInfo, oldListing?.unitInfo)) {
      return true
    }

    return false

  } else {
    return false
  }
}


export const checkingListingErrors = (photosData, draftListing) => {
  let activeErrors = []

  if (photosData.length === 0) {
    activeErrors.push("You must upload at least 1 photo")
  }

  if (draftListing?.meetEnabled && (draftListing?.showingPaymentAmount ?? 0) < 25) {
    activeErrors.push("Payment amount to showing is $25 at minimum")
  }

  if (draftListing?.exclusivity === undefined || draftListing?.exclusivity === "") {
    activeErrors.push("Set exclusivity")
  }

  if (draftListing?.start === undefined) {
    activeErrors.push("Set exclusive start date")
  }

  if (draftListing?.end === undefined) {
    activeErrors.push("Set exclusive end date")
  }

  if (draftListing?.building?.year === undefined || draftListing?.building?.year === 0) {
    activeErrors.push("Please choose the year of the building")
  }

  if (draftListing?.building?.type === undefined || draftListing?.building?.type === "") {
    activeErrors.push("Please choose the type of the building")
  }

  if (draftListing?.type === 'rental') {
    if (draftListing?.ownerPaysFee === 0) {
      if (draftListing?.grossCommission === undefined || draftListing?.grossCommission === "") {
        activeErrors.push("Please set gross commission")
      }
     /*  if (draftListing?.coBrokerSelected === 1) {
          if (draftListing?.coBrokeCommission === undefined || draftListing?.coBrokeCommission === "") {
            activeErrors.push("Please set co-broke commission")
          }
      } */
    }

    if (draftListing?.coBrokerSelected === 1) {
      if (draftListing?.coBrokeCommission === undefined || draftListing?.coBrokeCommission === "") {
        activeErrors.push("Please set co-broke commission")
      }
    }

    if (draftListing?.unitInfo?.dateAvailable === undefined) {
      activeErrors.push("Please choose move in date")
    }

    if (draftListing?.unitInfo?.marketAs === undefined || draftListing?.unitInfo?.marketAs === '') {
      activeErrors.push("Please select market type")
    }

    if (draftListing?.unitInfo?.marketAs === 'building' || 
    draftListing?.unitInfo?.marketAs === 'condo' ||
    draftListing?.unitInfo?.marketAs === 'coop') {
    if (draftListing?.unit === undefined || draftListing?.unit === '') {
      activeErrors.push("Please enter unit #")
    }
  }

    if (draftListing?.grossRent === undefined || draftListing?.grossRent === "") {
      activeErrors.push("Please enter the gross rent")
    }

    if (draftListing?.unitInfo?.concession === undefined) {
      activeErrors.push("Please choose concession")
    }

    if (draftListing?.unitInfo?.concession === 1) {
      if (draftListing?.unitInfo?.leaseTerm === undefined) {
        activeErrors.push("Please enter a lease term")
      }

      if (draftListing?.unitInfo?.monthsFree === undefined) {
        activeErrors.push("Please enter months free")
      }
    }

    if (draftListing?.status === 'Rented') {
      if (draftListing?.rentedPrice === undefined || draftListing?.rentedPrice === '') {
        activeErrors.push("Please enter the rented price under unit tab")
      }
      if (draftListing?.rentedDate === undefined || draftListing?.rentedDate === '' || draftListing?.rentedDate === 0) {
        activeErrors.push("Please set rented date under unit tab")
      }
    }

    if (draftListing?.unitInfo?.furnished === undefined) {
      activeErrors.push("Please select furnished option")
    }

    // if (draftListing?.unitInfo?.furnished === 1) {
    //   if (draftListing?.unitInfo?.furnishedRents === undefined || draftListing?.unitInfo?.furnishedRents === "") {
    //     activeErrors.push("Please enter furnished rent value")
    //   }
    // }
  } else {
    if (draftListing?.grossCommission === undefined || draftListing?.grossCommission === "") {
      activeErrors.push("Please set gross commission")
    }
  
    if (draftListing?.coBrokeCommission === undefined || draftListing?.coBrokeCommission === "") {
      activeErrors.push("Please set co-broke commission")
    }

    if (draftListing?.unitInfo?.saleType === undefined || draftListing?.unitInfo?.saleType === "") {
      activeErrors.push("Please choose sale type")
    }
    if (draftListing?.unitInfo?.marketAs === undefined || draftListing?.unitInfo?.marketAs === "") {
      activeErrors.push("Please choose market type")
    }
    if (draftListing?.price === undefined || draftListing?.price === '') {
      activeErrors.push("Please enter the sales price")
    }
    if (draftListing?.status === 'Sold') {
      if (draftListing?.soldPrice === undefined || draftListing?.soldPrice === '') {
        activeErrors.push("Please enter the sold price under unit tab")
      }
      if (draftListing?.soldDate === undefined || draftListing?.soldDate === '' || draftListing?.soldDate === 0) {
        activeErrors.push("Please set sold date under unit tab")
      }
    }
    if (draftListing?.video !== undefined && draftListing?.video !== '') {
        if(!validateYoutubeURL(draftListing?.video)) {
          activeErrors.push("Please enter valid youtube video url")
        }
    }
    
    if (draftListing?.unitInfo?.marketAs === 'building' || 
    draftListing?.unitInfo?.marketAs === 'condo' ||
    draftListing?.unitInfo?.marketAs === 'coop') {
    if (draftListing?.unit === undefined || draftListing?.unit === '') {
      activeErrors.push("Please enter unit #")
    }
  }

    
    if (draftListing?.unitInfo?.marketAs === 'building' || 
      draftListing?.unitInfo?.marketAs === 'condo' ||
      draftListing?.unitInfo?.marketAs === 'singleFamily' ||
      draftListing?.unitInfo?.marketAs === 'multiFamily' ||
      draftListing?.unitInfo?.marketAs === 'townHouse') {
      if (draftListing?.monthlyTax === undefined || draftListing?.monthlyTax === '') {
        activeErrors.push("Please enter a tax amount")
      }
    }

    if (draftListing?.unitInfo?.marketAs === 'condo') {
      if (draftListing?.commonCharges === undefined || draftListing?.commonCharges === '') {
        activeErrors.push("Please enter a common charges")
      }
    }

    if (draftListing?.unitInfo?.marketAs === 'coop') {
      if (draftListing?.maintenance === undefined || draftListing?.maintenance === '') {
        activeErrors.push("Please enter a maintenance")
      }
    }
  }

  // if (draftListing?.unitInfo?.size === undefined || draftListing?.unitInfo?.size === "") {
  //   activeErrors.push("Please enter a size of unit.")
  // }

  // if (draftListing?.unitInfo?.totalRooms === undefined || draftListing?.unitInfo?.totalRooms === "") {
  //   activeErrors.push("Please enter total rooms count.")
  // }

  if (draftListing?.unitInfo?.legalBeds === undefined || draftListing?.unitInfo?.legalBeds === "") {
    activeErrors.push("Please enter legal beds.")
  }

  if (draftListing?.unitInfo?.fullBaths === undefined || draftListing?.unitInfo?.fullBaths === "") {
    activeErrors.push("Please enter full baths, if no full baths, just enter 0.")
  }

  if (draftListing?.unitInfo?.halfBaths === undefined || draftListing?.unitInfo?.halfBaths === "") {
    activeErrors.push("Please enter half baths, if no half baths, just enter 0.")
  }

  if (draftListing?.unitInfo?.marketAs === 'singleFamily' || draftListing?.unitInfo?.marketAs === "multiFamily" || draftListing?.unitInfo?.marketAs === "townHouse") {

    if (draftListing?.unitInfo?.acTypes === undefined || draftListing?.unitInfo?.acTypes === "") {
      activeErrors.push("Please set A/C type of the unit.")
    }

    if (draftListing?.unitInfo?.heatingTypes === undefined || draftListing?.unitInfo?.heatingTypes.length === 0) {
      activeErrors.push("Please choose at least 1 heating type of unit.")
    }

    if (draftListing?.unitInfo?.basements === undefined || draftListing?.unitInfo?.basements.length === 0) {
      activeErrors.push("Please choose at least 1 basement.")
    }

    if (draftListing?.unitInfo?.attics === undefined || draftListing?.unitInfo?.attics.length === 0) {
      activeErrors.push("Please choose at least 1 attic.")
    }

    if (draftListing?.unitInfo?.heatingFuels === undefined || draftListing?.unitInfo?.heatingFuels.length === 0) {
      activeErrors.push("Please choose heating fuel.")
    }

    if (draftListing?.unitInfo?.lotSize === undefined || draftListing.unitInfo.lotSize === 0) {
      activeErrors.push("Please enter a lot size of unit.")
    }
  }

  // setErrorList(activeErrors)

  return activeErrors
}

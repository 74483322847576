import React, { useEffect, useMemo, useRef, useState } from 'react'
import TypeFace from 'src/components/typography/Typefaces'
import Flexbox from 'src/components/wrapper/Flexbox'
import { useFirestore } from 'src/hooks/firestore/FirestoreContext'
import AdminPropertyListingCell from './AdminPropertyListingCell'
import Button from 'src/components/button/Button'
import { searchObjects } from 'src/algolia'
import { extractStreetAddressFromFullAddress } from 'src/utils/geoUtils'
import { priceFormat } from 'src/utils/strings'
import moment from 'moment'
import { useNavigate } from 'react-router'

const PropertyListing = ({
  creatorId
}) => {

    const { userProfile } = useFirestore()
    const navigate = useNavigate()
    const unmountedRef = useRef(false)
  
    const [rentals, setRentals] = useState(true)
    const [sales, setSales] = useState(true)
    const [actives, setActives] = useState(true)
    const [underContracts, setUnderContracts] = useState(false)
    const [offMarkets, setOffMarkets] = useState(false)
    const [delisted, setDelisted] = useState(false)
    const [blocked, setBlocked] = useState(false)
  
    const [hits, setHits] = useState()
  
    const handleUpdate = () => {
      let filters = ""
      //filters = "creatorId:rental "
      if (rentals && !sales) {
        filters = "type:rental "
      } else if (!rentals && sales) {
        filters = "type:sale "
      } else {
        filters = ""
      }
  
      if (actives || underContracts || offMarkets || delisted) {
        filters += (rentals && sales) ? "" : "AND ("
  
       
       
          filters += "status:Active"
          filters += " OR status:Contract"
          filters += " OR status:OffMarket"
          filters += " OR status:Delisted"
          filters += " OR status:Blocked"
           filters += " OR status:Deleted"
          filters += " AND creatorId:" + creatorId
          filters += (rentals && sales) ? "" : ")"
      }
      console.log('filters=',filters)
      searchObjects('', filters,'newest').then(res => {
        console.log('res.hits=',res.hits)
        setHits(res.hits)
      })
    }
  
    const fetchAvailable = useMemo(() => {
      if (!sales && !rentals) {
        return false
      }
  
      if (!actives && !underContracts && !offMarkets && !delisted && !blocked) {
        return false
      }
  
      return true
    }, [sales, rentals, actives, underContracts, offMarkets, delisted, blocked])
  
    useEffect(() => {
      unmountedRef.current = false
  
      handleUpdate()
      return () => {
        unmountedRef.current = true
      }
    }, [])
    const handleViewListing = (listingId) => {
      console.log('listingId=',listingId)
      navigate(`/listing/${listingId}`)
    }
    return (
      <Flexbox style={{ alignItems: 'flex-start', width: '100%' }}>
        <Flexbox row style={{ width: '100%', justifyContent: 'flex-start', alignItems: 'center'}}>
          <TypeFace bold>
            Property Listings
          </TypeFace>
        </Flexbox>
       
        <Flexbox row style={styles.headerRow}>
          <TypeFace size={10} style={{ flex: 1.4 }}>
            Address
          </TypeFace>
          <TypeFace size={10} style={{ flex: 1 }}>
            Price
          </TypeFace>
          <TypeFace size={10} style={{ flex: 1 }}>
            Date Created
          </TypeFace>
          <TypeFace size={10} style={{ flex: 1 }}>
            Type
          </TypeFace>
          <TypeFace size={10} style={{ flex: 1 }}>
            Status
          </TypeFace>
          <TypeFace size={10} style={{ flex: 1 }}>
            View
          </TypeFace>
        </Flexbox>
        {hits?.map((item, index) => (
         /*  <AdminPropertyListingCell
            key={index}
            algoliaListingData={item}
          /> */

          <Flexbox row key={index} style={{
            width: '100%',
            minHeight: 30,
            alignItems: 'center',
            borderBottom: '1px solid #dfdfdf'
          }}>
          <TypeFace size={10} style={{ flex: 1.4, cursor: 'pointer' }}>
            {item.unit !== '' ?
            <>
            {extractStreetAddressFromFullAddress(item.address.full)}, Unit {item.unit}
            </> : 
            <>
            {extractStreetAddressFromFullAddress(item.address.full)}
            </>}
            
          </TypeFace>
          <TypeFace size={10} style={{ flex: 1, cursor: 'pointer' }}>
            {item.type === 'sale' ? `$${priceFormat(item.price)}` : `$${priceFormat(item.grossRent)}`}
          </TypeFace>
          <TypeFace size={10} style={{ flex: 1, cursor: 'pointer' }}>
            {item.created !== undefined && 
            moment(new Date(item.created)).format('MMM DD, yyyy')
            }
          </TypeFace>
          <TypeFace size={10} style={{ flex: 1, cursor: 'pointer' }}>
            {item.type}
          </TypeFace>
          <TypeFace size={10} style={{ flex: 1, cursor: 'pointer' }}>
            {item.status}
          </TypeFace>
          <Flexbox row style={{ flex: 1, gap: 5, justifyContent: 'center' }}>
          <Button textSize={10} onClick={() => handleViewListing(item.dataId)}>
                  View
           </Button>
          </Flexbox>
        </Flexbox>
        ))}
      </Flexbox>
    )
  }
  
  export default PropertyListing
  
  const styles = {
    headerRow: {
      width: '100%',
      height: 30,
      marginTop: 20,
      backgroundColor: '#dfdfdf',
      alignItems: 'center',
    }
  }
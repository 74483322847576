import { collection, doc, onSnapshot, query, updateDoc, where } from 'firebase/firestore';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import TypeFace from 'src/components/typography/Typefaces';
import Flexbox from 'src/components/wrapper/Flexbox';
import { firestore } from 'src/firebase';
import { useFirestore } from 'src/hooks/firestore/FirestoreContext';
import MyScheduleEntry from './components/MyScheduleEntry';
import { UserRoleValue } from 'src/model/types';
import { Colors } from 'src/theme/Colors';

const Schedules = () => {

  const { userProfile, myAgency } = useFirestore()

  const [schedules, setSchedules] = useState()

  const schedulesSnapshotListner = useRef()
  const unmountedRef = useRef(false)

  const loadSchedulesList = () => {
    schedulesSnapshotListner.current?.()
    //const q1 = query(collection(firestore, 'schedules'), where("listingAgentId", "==", userProfile.userId))
    const q = query(collection(firestore, 'schedules'), where("team", "array-contains", userProfile.userId))
    schedulesSnapshotListner.current = onSnapshot(
      q,
      docsSnapshot => {
        let schedulesList = []
        docsSnapshot.forEach(doc => {
          schedulesList.push({
            ...doc.data(),
            id: doc.id
          })
        })
        if (!unmountedRef.current) {
          setSchedules(schedulesList.filter(schedule => !schedule.deleted && !schedule.canceled))
        }

        // updateDoc(doc(firestore, `profiles/${userProfile?.userId}`), {
        //   "notifications.schedules": 0,
        // })
      }
    )
  }

  const myAgencySchedules = useMemo(() => {
    return schedules?.filter(sch => {
      if (userProfile.role === UserRoleValue.ManageMent)
        return sch.agency === userProfile.userId
      else 
        return sch.agency === myAgency?.id
    })
  }, [schedules, myAgency])

  const upcomingSchedules = useMemo(() => {
    return myAgencySchedules?.filter(schedule => {
      return schedule.customer.when > new Date().getTime()
    }).sort((schedule1, schedule2) => schedule1.customer.when - schedule2.customer.when)
  }, [myAgencySchedules])

  const pastSchedules = useMemo(() => {
    return myAgencySchedules?.filter(schedule => schedule.customer.when < new Date().getTime()).sort((schedule1, schedule2) => schedule2.customer.when - schedule1.customer.when)
  }, [myAgencySchedules])

  useEffect(() => {
    if (userProfile?.userId) {
      loadSchedulesList()
    }
  }, [userProfile?.userId])

  useEffect(() => {
    unmountedRef.current = false
    return () => {
      schedulesSnapshotListner.current?.()
      unmountedRef.current = true
    }
  }, [])

  return (
    <Flexbox style={styles.container}>
      <TypeFace bold>
        My Schedules
      </TypeFace>
      {(upcomingSchedules?.length === 0 && pastSchedules?.length === 0) &&  
        <TypeFace semiBold style={styles.noMessageText} color={Colors.gray1}>
          No Schedules
        </TypeFace>
      }
      {(upcomingSchedules?.length ?? 0) > 0 && (
        <>
          <TypeFace semiBold style={{ marginTop: 20 }}>
          Upcoming Schedules({upcomingSchedules.length})
          </TypeFace>
          {upcomingSchedules?.map((schedule, index)=> (
        <MyScheduleEntry schedule={schedule} key={`${index}`} />
      ))}
        </>
      )}

      {(pastSchedules?.length ?? 0) > 0 && (
        <>
          <TypeFace semiBold style={{ marginTop: 20 }}>
            Past Schedules({pastSchedules.length})
          </TypeFace>
          {pastSchedules?.map((schedule, index)=> (
            <MyScheduleEntry schedule={schedule} key={`${index}`} isPastSchedule />
          ))}
        </>
      )}
    </Flexbox>
  )
}

export default Schedules

const styles = {
  container: {
    width: '100%',
    alignItems: 'flex-start',
    height: 'calc(100% - 20px)',
    paddingBottom: 20
  },
  content: {
    width: '100%',
    height: 'calc(100% - 20px)',
    alignItems: 'flex-start',
    marginTop: 20,
    border: '1px solid #EFEFEF'
  },
  midDivider: {
    width: 1, height: '100%', backgroundColor: '#EFEFEF'
  },
  roomContainer: {
    flex: 1,
    height: '100%'
  },
  noMessageText: {
    alignSelf: 'center',
    marginTop: 40,
    textAlign: "center",
  }
}

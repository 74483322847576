import debounce from "lodash.debounce";
import React, { forwardRef, useEffect, useMemo, useState } from "react";
import ReactDatePicker from "react-datepicker"
import ReactSelect, { components } from "react-select";
import { useFirestore } from "src/hooks/firestore/FirestoreContext";
import OptionSelectButton from "../button/OptionSelectButton";
import TextInput from "../input/TextInput";
import TypeFace from "../typography/Typefaces"
import Flexbox from "../wrapper/Flexbox"
import useMediaQuery from "src/hooks/useMediaQuery";
import IconDelete from "src/assets/images/icons/icon-delete-red.svg"
import { UserRoleValue } from 'src/model/types'

/* onUpdate function : will update only necessary fields */

const EditListingManagement = ({
  highlightErrorFields,
  listing,
  onUpdate
}) => {

  const { userProfile, myAgencyAgentsList } = useFirestore()
  const { isMobile } = useMediaQuery()

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [exclusivity, setExclusivity] = useState()

  const [ownerPaysFee, setOwnerPaysFee] = useState()
  const [collectYourOwnFee, setCollectYourOwnFee] = useState()

  const [coBrokerSelected, setCoBrokerSelected] = useState(0)

  const [grossCommission, setGrossCommission] = useState()
  const [coBrokeCommission, setCoBrokeCommission] = useState()

  const [selectedTeamMembers, setSelectedTeamMembers] = useState([])

  const changeGrossCommission = (event) => {
    const changedValue = event.target.value
    onUpdate({
      grossCommission: changedValue
    })
    setGrossCommission(changedValue)
  }

  const debouncedGrossCommissionHandler = useMemo(() => {
    return debounce(changeGrossCommission, 500);
  }, []);

  const changeCoBrokeCommission = (event) => {
    const changedValue = event.target.value
    onUpdate({
      coBrokeCommission: changedValue
    })
    setCoBrokeCommission(changedValue)
  }

  const debouncedCoBrokeCommissionHandler = useMemo(() => {
    return debounce(changeCoBrokeCommission, 500);
  }, []);

  const changeInternalNotes = (event) => {
    const changedValue = event.target.value
    onUpdate({
      internalNotes: changedValue
    })
  }

  const debouncedInternalNotesHandler = useMemo(() => {
    return debounce(changeInternalNotes, 500);
  }, []);

  const handleRemoveSelectedMemnber = (member) => {
    console.log("Selected team members => ", selectedTeamMembers)
    console.log("Member => ", member)
    const newTeamMembers = [...selectedTeamMembers.filter(mem => mem.userId !== member.userId)]
    setSelectedTeamMembers(newTeamMembers)
    onUpdate({ team: [userProfile?.userId, ...newTeamMembers.map(v => v.userId)] })
  }

  const DateCustomInput = forwardRef(({ value, style, onClick }, ref) => (
    <TextInput value={value} onClick={onClick} ref={ref} style={style} />
  ));

  const teamMemberOptions = useMemo(() => {
    return myAgencyAgentsList?.filter(user => user.userId !== userProfile?.userId)?.map(user => ({
      label: user.name,
      value: user
    }))
  }, [myAgencyAgentsList, userProfile])

  const InputOption = ({
    getStyles,
    isFocused,
    children,
    innerProps,
    value,
    ...rest
  }) => {
    const [isActive, setIsActive] = useState(false);
    const onMouseDown = () => setIsActive(true);
    const onMouseUp = () => setIsActive(false);
    const onMouseLeave = () => setIsActive(false);
  
    // styles
    let bg = "transparent";
    if (isFocused) bg = "#eee";
    if (isActive) bg = "#B2D4FF";
  
    const style = {
      alignItems: "center",
      backgroundColor: bg,
      color: "inherit",
      display: "flex "
    };
  
    // prop assignment
    const props = {
      ...innerProps,
      onMouseDown,
      onMouseUp,
      onMouseLeave,
      style
    };
  
    return (
      <components.Option
        {...rest}
        getStyles={getStyles}
        innerProps={props}
      >
        <input type="checkbox" checked={selectedTeamMembers.some(m => m.userId === value.userId)} style={{ marginRight: 10 }} />
        {children}
      </components.Option>
    );
  };

  const ValueContainer = ({
    children,
    ...props
  }) => {
    return (
    <components.ValueContainer {...props}>
      {props.selectProps.inputValue === '' && (
        <components.Placeholder {...props} isFocused={props.isFocused}>
          {props.selectProps.placeholder}
        </components.Placeholder>
      )}
      {React.Children.map(children, child => {
        return child && child.type === components.Input ? child : null
      })}
    </components.ValueContainer>
  )}

  useEffect(() => {
    if (listing) {
      setExclusivity(listing.exclusivity)
      setStartDate(listing.start ? new Date(listing.start) : undefined)
      setEndDate(listing.end ? new Date(listing.end) : undefined)
      if (listing.team) {
        setSelectedTeamMembers(listing.team.filter(m => m !== userProfile?.userId).map(memberId => {
          return myAgencyAgentsList?.find(user => user.userId === memberId)
        }))
      }
      if (listing.type === 'sale') {
        setGrossCommission(listing.grossCommission)
        setCoBrokeCommission(listing.coBrokeCommission)
      } else {
        document.getElementById("gross-commission-input").value = listing.grossCommission ?? ""
        if(listing.coBrokerSelected === 1) {
            document.getElementById("cobroke-commission-input").value = listing.coBrokeCommission ?? ""
        }
        setOwnerPaysFee(listing.ownerPaysFee)
        //setCollectYourOwnFee(listing.collectYourOwnFee)
        if(listing.coBrokerSelected === undefined) {
          setCoBrokerSelected(0)
        }
        else {
          setCoBrokerSelected(listing.coBrokerSelected)
        }
        
        setGrossCommission(listing.grossCommission)
        setCoBrokeCommission(listing.coBrokeCommission)
      }
      document.getElementById("textarea-internal-notes").value = listing.internalNotes ?? ""
    }
  }, [listing, myAgencyAgentsList])

  return (
    <Flexbox style={styles.container}>
      <TypeFace medium size={20}>
        Exclusivity
      </TypeFace>
      <div style={{ height: 20 }} />
      <TypeFace bold size={12} color={(highlightErrorFields && !exclusivity) ? "red" : "black"}>
      What type of exclusive is this?<TypeFace size={16}>*</TypeFace>
      </TypeFace>
      <Flexbox row style={{ alignItems: 'flex-start', marginTop: 5 }}>
        <select value={exclusivity} style={{ ...styles.dropdown, borderColor: (highlightErrorFields && !exclusivity) ? 'red' : 'black' }} onChange={e => {
          onUpdate({ exclusivity: e.target.value })
          setExclusivity(e.target.value)
        }}>
          <option value=""></option>
          <option value="exclusive">Exclusive Right</option>
          <option value="co-exclusive">Exclusive Agency</option>
        </select>
      </Flexbox>
      <TypeFace bold size={12} style={{ marginTop: 24}}>
      What is the agreement term?
      </TypeFace>
      <Flexbox row style={{ alignItems: 'flex-start', marginTop: 10 }}>
        <Flexbox style={{ alignItems: 'flex-start' }}>
          <TypeFace bold size={12} color={(highlightErrorFields && !startDate) ? "red" : "black"}>
             START DATE <TypeFace size={16}>*</TypeFace>
          </TypeFace>
          <ReactDatePicker
            placeholderText='MM/DD/YYYY'
            style={{
              height: 36,
            }}
            customInput={<DateCustomInput style={{ marginTop: 5, width: 150, borderColor: (highlightErrorFields && !startDate) ? 'red' : 'black' }} />}
            selected={startDate}
            onChange={(date) => {
              onUpdate({
                start: date.getTime()
              })
              setStartDate(date)
            }}
          />
        </Flexbox>
        <div style={{ width: isMobile ? 15 : 30 }} />
        <Flexbox style={{ alignItems: 'flex-start' }}>
          <TypeFace bold size={12} color={(highlightErrorFields && !endDate) ? "red" : "black"}>
             END DATE<TypeFace size={16}>*</TypeFace>
          </TypeFace>
          <ReactDatePicker
            placeholderText='MM/DD/YYYY'
            style={{
              height: 36,
            }}
            customInput={<DateCustomInput style={{ marginTop: 5, width: 150, borderColor: (highlightErrorFields && !endDate) ? 'red' : 'black' }} />}
            selected={endDate}
            onChange={(date) => {
              onUpdate({
                end: date.getTime()
              })
              setEndDate(date)
            }}
          />
        </Flexbox>
      </Flexbox>
      <TypeFace medium size={20} style={{ marginTop: 40, marginBottom: 10 }}>
        Commission
      </TypeFace>
      <TypeFace medium size={12} style={{ textAlign: 'left' }}>
        This data will be visible online to agents outside your brokerage, except where noted.
      </TypeFace>
      {listing?.type === 'sale' ? (
        <Flexbox row style={{ alignItems: 'flex-start', marginTop: 20 }}>
          <Flexbox style={{ alignItems: 'flex-start' }}>
            <TypeFace bold size={12} color={(highlightErrorFields && !grossCommission) ? 'red' : 'black'}>
              GROSS COMMISSION <TypeFace size={16}>*</TypeFace>
            </TypeFace>
            <Flexbox row style={{ alignItems: 'flex-start', marginTop: 5 }}>
            <TextInput
                id={'gross-commission-input'}
                style={{ ...styles.input, borderColor: (highlightErrorFields && !grossCommission) ? 'red' : 'black'}}
                onChange={e => {
                  setGrossCommission(e.target.value)
                  onUpdate({ grossCommission: e.target.value })
                }}
                value={grossCommission}
                maxLength={5} 
                onKeyDown={(event) => {
                  if (event.key !== "Tab" && event.key !== "Backspace" && event.key !== "ArrowLeft" && event.key !== "ArrowRight" && event.key !== "Delete" && !/[0-9]/.test(event.key) && !/[.]/.test(event.key)) {
                    event.preventDefault()
                  }
                }}
                placeholder="%"
              />
             {/*  <select value={grossCommission} style={{ ...styles.dropdown, borderColor: (highlightErrorFields && !grossCommission) ? 'red' : 'black' }} onChange={e => {
                setGrossCommission(e.target.value)
                onUpdate({ grossCommission: e.target.value })
              }}>
                <option value={""}></option>
                <option value={6}>6%</option>
                <option value={5.5}>5.5%</option>
                <option value={5}>5%</option>
                <option value={4.5}>4.5%</option>
                <option value={4}>4%</option>
                <option value={3.5}>3.5%</option>
                <option value={3}>3%</option>
                <option value={2.5}>2.5%</option>
                <option value={2}>2%</option>
                <option value={1.5}>1.5%</option>
                <option value={1}>1%</option>
                <option value={0.5}>0.5%</option>
                <option value={0}>0%</option>
              </select> */}
            </Flexbox>
          </Flexbox>
          <div style={{ width: 30 }} />
          <Flexbox style={{ alignItems: 'flex-start' }}>
            <TypeFace bold size={12} color={(highlightErrorFields && !coBrokeCommission) ? 'red' : 'black'}>
              CO-BROKE COMMISSION <TypeFace size={16}>*</TypeFace>
            </TypeFace>
            <Flexbox row style={{ alignItems: 'flex-start', marginTop: 0 }}>
            <TextInput
                id={'co-broke-commission-input'}
                style={{ ...styles.input, borderColor: (highlightErrorFields && !coBrokeCommission) ? 'red' : 'black'}}
                onChange={e => {
                  setCoBrokeCommission(e.target.value)
                  onUpdate({ coBrokeCommission: e.target.value })
                }}
                value={coBrokeCommission}
                maxLength={5} 
                onKeyDown={(event) => {
                  if (event.key !== "Tab" && event.key !== "Backspace" && event.key !== "ArrowLeft" && event.key !== "ArrowRight" && event.key !== "Delete" && !/[0-9]/.test(event.key) && !/[.]/.test(event.key)) {
                    event.preventDefault()
                  }
                }}
                placeholder="%"
              />
             {/*  <select value={coBrokeCommission} style={{ ...styles.dropdown, borderColor: (highlightErrorFields && !coBrokeCommission) ? 'red' : 'black' }} onChange={e => {
                setCoBrokeCommission(e.target.value)
                onUpdate({ coBrokeCommission: e.target.value })
              }}>
                <option value={""}></option>
                <option value={6}>6%</option>
                <option value={5.5}>5.5%</option>
                <option value={5}>5%</option>
                <option value={4.5}>4.5%</option>
                <option value={4}>4%</option>
                <option value={3.5}>3.5%</option>
                <option value={3}>3%</option>
                <option value={2.5}>2.5%</option>
                <option value={2}>2%</option>
                <option value={1.5}>1.5%</option>
                <option value={1}>1%</option>
                <option value={0.5}>0.5%</option>
                <option value={0}>0%</option>
              </select> */}
            </Flexbox>
          </Flexbox>
        </Flexbox>
      ) : (
        <>
          <Flexbox row style={{ alignItems: 'flex-start', marginTop: 20 }}>
            <Flexbox style={{ alignItems: 'flex-start' }}>
              <TypeFace bold size={12} color={(highlightErrorFields && ownerPaysFee === 0 && !grossCommission) ? "red" : "black"}>
                GROSS COMMISSION
              </TypeFace>
              <TextInput
                id={'gross-commission-input'}
                style={{ ...styles.input, borderColor: (highlightErrorFields && ownerPaysFee === 0 && !grossCommission) ? 'red' : 'black'}}
                onChange={debouncedGrossCommissionHandler}
                placeholder="%"
                onKeyDown={(event) => {
                  if (event.key !== "Tab" && event.key !== "Backspace" && event.key !== "ArrowLeft" && event.key !== "ArrowRight" && event.key !== "Delete" && !/[0-9]/.test(event.key)) {
                    event.preventDefault()
                  }
                }}
              />
            </Flexbox>
            <Flexbox style={{ alignItems: 'flex-start', marginLeft: 20 }}>
              <TypeFace bold size={12}>
                OWNER PAYS FEE
              </TypeFace>
              <OptionSelectButton
                style={{ marginTop: 5 }}
                selected={ownerPaysFee}
                onSelected={value => {
                  onUpdate({ ownerPaysFee: value })
                  setOwnerPaysFee(value)
                }}
              />
            </Flexbox>
          </Flexbox>
          <TypeFace size={11}>
            <TypeFace bold size={11}>Not{' '}</TypeFace>
            visible to agents
          </TypeFace>
          <Flexbox row style={{ alignItems: 'flex-start', marginTop: 20 }}>
            <Flexbox style={{ alignItems: 'flex-start' }}>
              <TypeFace bold size={12} color={(highlightErrorFields && ownerPaysFee === 0 && !coBrokeCommission) ? "red" : "black"}>
                Co-BROKE COMMISSION
              </TypeFace>
              <TextInput
                id={'cobroke-commission-input'}
                style={{ ...styles.input, borderColor: (highlightErrorFields && ownerPaysFee === 0 && !coBrokeCommission) ? "red" : "black" }}
                placeholder="%"
                onChange={debouncedCoBrokeCommissionHandler}
                disabled={coBrokerSelected === 0 ? true : false}
                onKeyDown={(event) => {
                  if (event.key !== "Tab" && event.key !== "Backspace" && event.key !== "ArrowLeft" && event.key !== "ArrowRight" && event.key !== "Delete" && !/[0-9]/.test(event.key)) {
                    event.preventDefault()
                  }
                }}
              />
            </Flexbox>
            <Flexbox style={{ alignItems: 'flex-start', marginLeft: 20 }}>
              <TypeFace bold size={isMobile ? 11 : 12}>
              Co-BROKE
              </TypeFace>
              <OptionSelectButton
                style={{ marginTop: 5 }}
                selected={coBrokerSelected}
                onSelected={value => {
                  onUpdate({coBrokerSelected: value})
                  document.getElementById("cobroke-commission-input").value = ""
                }}
              />
            </Flexbox>
           {/*  <Flexbox style={{ alignItems: 'flex-start', marginLeft: 20 }}>
              <TypeFace bold size={isMobile ? 11 : 12}>
                COLLECT YOUR OWN FEE
              </TypeFace>
              <OptionSelectButton
                style={{ marginTop: 5 }}
                selected={collectYourOwnFee}
                onSelected={value => {
                  onUpdate({collectYourOwnFee: value})
                  setCollectYourOwnFee(value)
                }}
              />
            </Flexbox> */}
          </Flexbox>
        </>
      )}
      {userProfile?.role !== UserRoleValue.ManageMent &&
      <>
      <TypeFace medium size={20} style={{ marginTop: 50, marginBottom: 10 }}>
        Listing Team
      </TypeFace>
      <Flexbox row style={{ width: '100%', marginBottom: 20 }}>
        {isMobile ? (
          <TypeFace medium size={12} style={{ flex: 1, marginRight: 40, textAlign: 'start' }}>
            Select team members
          </TypeFace>
        ) : (
          <TypeFace medium size={12} style={{ flex: 1, marginRight: 40, textAlign: 'start' }}>
            From the menu on the right, select which team members to add to the listing.<br />They will also appear on and be able to edit the listing
          </TypeFace>
        )}
        <div style={{ width: 160 }}>
          <ReactSelect
            options={teamMemberOptions}
            isClearable={false}
            onChange={v => {
              const value = v.value
              let newTeamMembers = []
              if (selectedTeamMembers?.some(m => m.userId === value.userId)) {
                newTeamMembers = [...selectedTeamMembers.filter(m => m.userId !== value.userId)]
              } else {
                newTeamMembers = [...selectedTeamMembers, value]
              }
              setSelectedTeamMembers(newTeamMembers)
              onUpdate({ team: [userProfile?.userId, ...newTeamMembers.map(v => v.userId)] })
            }}
            components={{
              Option: InputOption,
              ValueContainer
            }}
            styles={{
              valueContainer: (base) => {
                return {
                  ...base,
                  color: 'black',
                  width: '100%',
                  height: 40,
                }
              },
            }}
          />
        </div>
      </Flexbox>
      </>
      }
      {selectedTeamMembers?.map((member, index) => (
        <Flexbox row style={styles.teamMemberRow} key={`member-${index}`}>
          <TypeFace>{member?.name}</TypeFace>
          <div style={{ flex: 1 }} />
          <Flexbox row onClick={() => handleRemoveSelectedMemnber(member)} style={styles.deleteTeamMemberButton}>
            <img src={IconDelete} width={15} height={15} />
          </Flexbox>
        </Flexbox>
      ))}
      <TypeFace medium size={16} style={{ marginTop: 30, marginBottom: 10 }}>
        INTERNAL NOTES
      </TypeFace>
      <textarea
        id="textarea-internal-notes"
        style={{
          ...styles.internalnotes,
          width: isMobile ? '95%' : '100%',
        }}
        onChange={debouncedInternalNotesHandler}
      />
    </Flexbox>
  )
}

export default EditListingManagement

const styles = {
  container: {
    padding: "30px 0",
    alignItems: 'flex-start'
  },
  dropdown: {
    height: 36,
    width: 150,
  },
  input: {
    width: 200,
    height: 40,
    marginTop: 5,
    borderRadius: 0,
  },
  teamMemberRow: {
    width: '100%',
    height: 44,
    paddingLeft: 20,
    borderLeft: '2px solid #ccc',
    marginTop: 10,
    alignItems: 'center',
  },
  internalnotes: {
    width: '100%',
    height: 120,
  },
  deleteTeamMemberButton: {
    width: 30,
    height: 30,
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 30,
    cursor: 'pointer',
  }
}
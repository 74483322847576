const filterByParent = (areaData, area, parentName) => {
  return areaData[area]?.filter((areaItem) =>
    areaItem.parent?.toLowerCase() === parentName
  ) || [];
};

const findAllMatchingItems = (areaData, area, normalizedSubArea) => {
  const matchedItems = [];

  const initialMatches = filterByParent(areaData, area, normalizedSubArea);
  if (initialMatches.length > 0) {
    matchedItems.push(...initialMatches);

    initialMatches.forEach((matchingItem) => {
      const recursiveMatches = findAllMatchingItems(
        areaData,
        area,
        matchingItem.name.toLowerCase()
      );
      matchedItems.push(...recursiveMatches);
    });
  }

  return matchedItems;
};

const transformMatchingItems = (matchingItems) => {
  return matchingItems.map((areaItem) => ({
    area: areaItem.subarea,
    subArea: areaItem.name,
    bbox: areaItem.component?.bbox,
  }));
};

export const appendFilteredDataToFirstArray = (firstArray, areaData) => {
  return firstArray.flatMap((item) => {
    const { area, subArea } = item;
    const normalizedSubArea = subArea?.toLowerCase();
    const matchingItems = findAllMatchingItems(areaData, area, normalizedSubArea);

    const transformedItems = transformMatchingItems(matchingItems);

    return transformedItems.length > 0 ? [...transformedItems, item] : [item];
  });
};

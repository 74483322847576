import React, { useRef, useEffect, useMemo, useState } from 'react'
import { Colors } from 'src/theme/Colors'
import TypeFace from '../typography/Typefaces'
import IconSearch from 'src/assets/images/icons/icon-search-white.svg'
import Flexbox from '../wrapper/Flexbox'
import HomeAdvancedSearchFilterPopup from './search/HomeAdvancedSearchFilterPopup'
import RoomsSelectBox from './search/RoomsSelectBox'
import PriceRangeSelectBox from './search/PriceRangeSelectBox'
import ReactSelect from 'react-select'
import useAreas from 'src/hooks/data/useAreas'
import IconMapNeighborhoods from 'src/assets/images/icons/icon-map-neighborhoods-2.svg'
import SelectNeighborhoodsModal from './search/SelectNeighborhoodsModal'
import { useDispatch, useSelector } from 'react-redux'
import { selectSearchObject } from 'src/redux/search/selectors'
import { storeSearchObject } from 'src/redux/search/actions'
import { useNavigate } from 'react-router-dom'
import useMediaQuery from 'src/hooks/useMediaQuery'
import SelectNeighborhoodsModalMobile from './search/SelectNeighborhoodsModalMobile'
import Button from '../button/Button'
import { useFirestore } from 'src/hooks/firestore/FirestoreContext'
import ConnectProfileModal from '../auth/ConnectProfileModal'
import LoginModal from '../auth/LoginModal'
import { useAlert } from 'src/hooks/alert/AlertContext'
import { get } from "lodash"
import TagsInput from '../input/TagsInput'
import { Loader } from "@googlemaps/js-api-loader";
import Script from 'react-load-script';
import localStorage from 'redux-persist/es/storage'

const HomeSearchBox = (props) => {

  const [location, setLocation] = useState(null);
  const [userSelectedLocation, setUserSelectedLocation] = useState([])
  const [address, setAddress] = useState('')
  const [addressComponents, setAddressComponents] = useState({})
  const [latitude, setLatitude] = useState(0)
  const [longitude, setLongitude] = useState(0)

  const dispatch = useDispatch()

  const navigate = useNavigate()

  const { isMobile } = useMediaQuery()
  const { userProfile } = useFirestore()
  const { showAlert } = useAlert()
  const { areas: _areas, subAreas } = useAreas()
  const areas = _areas?.filter((arr) => arr.isState);
  const currentSearchObject = useSelector(selectSearchObject)

  const [selectedBedRoom, setSelectedBedRoom] = useState([])
  const [currentSelectedNeiborhoods, setCurrentSelectedNeighborhoods] = useState([])

  const [isOpeningAdvancedFilters, setIsOpeningAdvancedFilters] = useState(false)
  const [isOpeningNeighborhoodsModal, setIsOpeningNeighborhoodsModal] = useState(false)

  const [searchingRents, setSearchingRents] = useState(true)

  const [isLoginModalOpen, setIsLoginModalOpen] = React.useState(false);
  const [openingModalWithSignupMode, setOpeningModalWithSignupMode] = useState(false)
  const [isConnectProfileModalOpen, setIsConnectProfileModalOpen] = React.useState(false);

  let placesApiClient;

  async function getGoogleMapsPlacesApiClient() {
    if (placesApiClient) {
      return placesApiClient;
    }
    const loader = new Loader({
      apiKey: "AIzaSyChrrGNLT2GcGAZH1nwQ1urEveT-dhX3Rs" || "",
      version: "weekly",
    });
    placesApiClient = await loader.importLibrary("places");
    return placesApiClient;
  }

  const [value, setValue] = useState("");
  const [fetchingPredictions, setFetchingPredictions] = useState(false);
  const [fetchingDetails, setFetchingDetails] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [placeDetail, setPlaceDetail] = useState();

  const sessionTokenRef = useRef();
  const timeoutRef = useRef();

  const [tags, setTags] = useState([])

  function handleKeyDown(e) {
    if (e.key !== 'Enter') return
    const value = e.target.value
    if (!value.trim()) return
    setTags([...tags, value])
    e.target.value = ''
  }

  function removeTag(index) {
    console.log("index-REMOVAL=", tags[index])
    setTags(tags.filter((el, i) => i !== index))
    // need to remove from search array bBOX

    setUserSelectedLocation(userSelectedLocation.splice(index, 1))

    //update object

    setCurrentSelectedNeighborhoods(userSelectedLocation)
    const neighborhoods = userSelectedLocation.map(val => val.value)
    console.log('neighborhoods-AFTERREMOVAl=', neighborhoods)
    const filtered = appendFilteredDataToFirstArray(neighborhoods, subAreas);
    dispatch(storeSearchObject({
      ...currentSearchObject,
      locations: filtered,
      neighborhoods
    }))

  }

  const handleChange = (event) => {

    const newValue = event.target.value;
    setValue(newValue);

    if (!newValue || newValue.trim().length < 1) {
      setSuggestions([]);
      return;
    }
    let mapBoxApiUrl = ""
    mapBoxApiUrl = `https://api.mapbox.com/search/geocode/v6/forward?q=${newValue}&access_token=${process.env.REACT_APP_MAPBOX_API_TOKEN}& autocomplete=true& country=us& limit=10&types=region,postcode,district,locality,neighborhood,place,street&country=us`
    fetch(mapBoxApiUrl)
      .then(res => res.json())
      .then(result => {
        var propertyData = []
        console.log('result-ARRAY=', result)
        console.log('featured-ARRAY=', result?.features)
        result?.features.map((data) => {
          propertyData.push(data)
        })
        setSuggestions(propertyData);
      })
  }


  const handleChange1 = (event) => {
    const newValue = event.target.value;
    setValue(newValue);

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    if (!newValue || newValue.trim().length < 1) {
      setSuggestions([]);
      return;
    }

    timeoutRef.current = setTimeout(async () => {
      const places = await getGoogleMapsPlacesApiClient();

      if (!sessionTokenRef.current) {
        sessionTokenRef.current = new places.AutocompleteSessionToken();
      }

      setFetchingPredictions(true);
      // @see https://developers.google.com/maps/documentation/javascript/place-autocomplete
      const options = {
        types: ['locality,postal_code, neighborhood, administrative_area_level_1, administrative_area_level_2, administrative_area_level_3'],
        componentRestrictions: { country: "us" },
        fields: ["address_components", "geometry", "icon", "name"],
        strictBounds: true,
      };


      new places.AutocompleteService().getPlacePredictions(
        {
          input: newValue,
          sessionToken: sessionTokenRef.current,
          options
        },
        (predictions, status) => {

          console.log("predictions=", predictions);
          var suggestions = []
          setFetchingPredictions(false);
          if (status === places.PlacesServiceStatus.ZERO_RESULTS) {
            setSuggestions([]);
            return;
          }
          if (status !== places.PlacesServiceStatus.OK || !predictions) {
            alert(status);
            return;
          }

          // console.log('places-'+ JSON.stringify(places))


          predictions.map((data) => {
            //console.log('data-' + JSON.stringify(data))
            data.types.map((types) => {
              if (types == 'locality') {
                suggestions.push(data)
              }
              if (types == 'postal_code') {
                suggestions.push(data)
              }

              if (types == 'neighborhood') {
                suggestions.push(data)
              }

              if (types == 'administrative_area_level_1') {
                suggestions.push(data)
              }
              if (types == 'administrative_area_level_2') {
                suggestions.push(data)
              }
              if (types == 'administrative_area_level_3') {
                suggestions.push(data)
              }


            })

          })

          //console.log('suggestions-' + JSON.stringify(suggestions))
          setSuggestions(suggestions);

        }
      );

    }, 350);
  };

  const getLocationBoundingBox = async (strPropertyAddress) => {
    //console.log("address=", strPropertyAddress)
    //console.log("data-array=",userSelectedLocation);
    let apiURL = ""
    apiURL = `https://nominatim.openstreetmap.org/search.php?q=${strPropertyAddress}&polygon_geojson=1&format=json`

    fetch(apiURL)
      .then((res) => {
        return res.json();
      })
      .then(async data => {
        console.log('data-TARAN=', data)
        let locationArray = []
        if (userSelectedLocation.length !== 0) {
          locationArray = userSelectedLocation
        }


        let coordinatesArray = []
        //console.log("data-boundingbox=",data[0]['boundingbox']);

        if (data[0]['geojson']['type'] == 'Polygon') {
          let arr_Coordinates = data[0]['geojson']['coordinates']
          console.log('arr_Coordinates=', arr_Coordinates)
          var nums_CoordinatesPolygon = arr_Coordinates.map(function (arr_InnerCordinate) {
            var nums_Cord = arr_InnerCordinate.map(function (arr_Cord) {
              coordinatesArray.push(
                arr_Cord[1]
              )
              coordinatesArray.push(
                arr_Cord[0]
              )
            });
          });

          locationArray.push({
            label: strPropertyAddress,
            value: {
              area: strPropertyAddress,
              subArea: strPropertyAddress,
              polygon: coordinatesArray,
              bbox: null
            }
          })

          console.log('coordinatesArray=', coordinatesArray)
        } else {

          let arr_bBox = data[0]['boundingbox']
          var nums_Bbox = arr_bBox.map(function (str) {
            return parseFloat(str);
          });

          locationArray.push({
            label: strPropertyAddress,
            value: {
              area: strPropertyAddress,
              subArea: strPropertyAddress,
              polygon: null,
              bbox: nums_Bbox
            }
          })
          //console.log('locationArray=',locationArray)
        }




        // for 
        setUserSelectedLocation(locationArray)
        console.log("userSelectedLocation=", userSelectedLocation);

        setCurrentSelectedNeighborhoods(locationArray)
        const neighborhoods = locationArray.map(val => val.value)
        console.log('neighborhoods=', neighborhoods)
        const filtered = appendFilteredDataToFirstArray(neighborhoods, subAreas);
        dispatch(storeSearchObject({
          ...currentSearchObject,
          locations: filtered,
          neighborhoods
        }))

      });
    // console.log('res-pushNotification=',res)

  }

  const getLocationBoundingBoxOLD = async (strPropertyAddress) => {
    console.log("address=", strPropertyAddress)
    console.log("data-array=", userSelectedLocation);
    fetch('https://us1.locationiq.com/v1/search?key=pk.333c6f059908d388f53f1020798e646c&format=json&q=' + strPropertyAddress)
      .then((res) => {
        return res.json();
      })
      .then(async data => {
        let locationArray = []
        if (userSelectedLocation.length !== 0) {
          locationArray = userSelectedLocation
        }
        //console.log("data-boundingbox=",data[0]['boundingbox']);
        let arr_bBox = data[0]['boundingbox']
        var nums_Bbox = arr_bBox.map(function (str) {
          // using map() to convert array of strings to numbers

          return parseFloat(str);
        });

        locationArray.push({
          label: strPropertyAddress,
          value: {
            area: strPropertyAddress,
            subArea: strPropertyAddress,
            bbox: nums_Bbox
          }
        })
        setUserSelectedLocation(locationArray)
        console.log("userSelectedLocation=", userSelectedLocation);

        setCurrentSelectedNeighborhoods(locationArray)
        const neighborhoods = locationArray.map(val => val.value)
        console.log('neighborhoods=', neighborhoods)
        const filtered = appendFilteredDataToFirstArray(neighborhoods, subAreas);

        dispatch(storeSearchObject({
          ...currentSearchObject,
          locations: filtered,
          neighborhoods
        }))

      });
    // console.log('res-pushNotification=',res)

  }

  const handleSuggestionSelected = async (suggestion) => {

    let str_PropertyAddress = suggestion.properties.full_address.replace(', United States', '')
    setTags([...tags, str_PropertyAddress])
    setValue(str_PropertyAddress);
    setSuggestions([]);
    setValue('');
    //console.log('fullADRESS=',suggestion)
    getLocationBoundingBox(suggestion.properties.full_address)
    // create bounding box object here
    /* console.log("data-boundingbox=",suggestion.properties['bbox']);
   
   
     let locationArray = []
       if(userSelectedLocation.length !== 0)
       {
         locationArray = userSelectedLocation
       }
       let arr_bBox = suggestion.properties['bbox']
       var nums_Bbox = arr_bBox.map(function(str) {
         // using map() to convert array of strings to numbers
   
         return parseFloat(str); });
   
       locationArray.push({
         label: suggestion.properties.full_address,
         value: {
           area: suggestion.properties.full_address,
           subArea: suggestion.properties.full_address,
           bbox: nums_Bbox
         }
       })
       setUserSelectedLocation(locationArray)
       console.log("userSelectedLocation=",userSelectedLocation);
   
       setCurrentSelectedNeighborhoods(locationArray)
       const neighborhoods = locationArray.map(val => val.value)
      console.log('neighborhoods=',neighborhoods)
       dispatch(storeSearchObject({
             ...currentSearchObject,
            neighborhoods
       }))
   
       */

    //getLocationBoundingBox(suggestion.description)

  }
  const handleSuggestionSelected1 = async (suggestion) => {

    setTags([...tags, suggestion.description])
    setValue(suggestion.description);
    setSuggestions([]);
    setValue('');
    getLocationBoundingBox(suggestion.description)
    const places = await getGoogleMapsPlacesApiClient();

    const sessionToken = sessionTokenRef.current;
    sessionTokenRef.current = undefined;

    setFetchingDetails(true);

    // @see https://developers.google.com/maps/documentation/javascript/places
    new places.PlacesService(
      document.getElementById(
        "googlemaps-attribution-container"
      )
    ).getDetails(
      {
        placeId: suggestion.place_id,
        fields: [
          // @see https://developers.google.com/maps/documentation/javascript/place-data-fields
          "formatted_address",
          "name",
          "place_id",
          "geometry.location",
          "geometry.viewport"
        ],
        sessionToken,
      },
      (place, status) => {
        setFetchingDetails(false);
        if (status === places.PlacesServiceStatus.OK) {
          setPlaceDetail(place);
        }
      }
    );
  };

  const openLoginModal = () => {
    if (userProfile) {
      showAlert(true,
        'You have some unsaved schedule data. Once you login to another account, you will loose them. Please create profile to save unsaved data.',
        [
          {
            title: "Connect a Profile",
            onClick: () => {
              setIsConnectProfileModalOpen(true)
              showAlert(false)
            }
          },
          {
            title: "Continue Login",
            onClick: () => {
              showAlert(false)
              document.body.style.overflow = 'hidden'
              setIsLoginModalOpen(true)
            }
          },
        ], 1)
    } else {
      document.body.style.overflow = 'hidden'
      setIsLoginModalOpen(true)
    }
  }

  const handleRent = () => {
    setSearchingRents(true)
    dispatch(storeSearchObject({
      ...currentSearchObject,
      type: 'rental',
      price: {}
    }))
  }

  const handleBuy = () => {
    setSearchingRents(false)
    dispatch(storeSearchObject({
      ...currentSearchObject,
      type: 'sale',
      price: {}
    }))
  }

  const handleApplyNeighborSelections = (selections) => {
    setCurrentSelectedNeighborhoods(selections.map(v => ({
      label: v.subArea,
      value: v
    })))
  }



  useEffect(() => {
    if (currentSearchObject?.neighborhoods) {
      setCurrentSelectedNeighborhoods(currentSearchObject.neighborhoods.map(v => ({
        label: v.subArea,
        value: v
      })))
    } else {
      setCurrentSelectedNeighborhoods([])
    }
  }, [currentSearchObject?.neighborhoods])

  useEffect(() => {
    if (currentSearchObject) {
      setSearchingRents(get(currentSearchObject, "type", "rental") === "rental")
    }
  }, [currentSearchObject])

  const handleAdvancedOptions = () => {
    document.body.style.overflow = 'hidden'
    setIsOpeningAdvancedFilters(true)
  }

  const handleSearch = () => {
    //console.log('AFTER-DELETION=',userSelectedLocation)
    //handleLocationClick()
    //console.log("currentSearchObject-HOME=",currentSearchObject)
    navigate('/search')
  }

  const areasGroupOptions = useMemo(() => {
    let groupOptions = []
    areas?.forEach(area => {

      let options = []


      subAreas?.[area.name]?.forEach(subArea => {
        options.push({
          label: subArea.name,
          value: {
            area: area.name,
            subArea: subArea.name,
            bbox: subArea.component.bbox
          }
        })
      })

      let option = {
        label: area.name,
        options
      }

      groupOptions.push(option)
    })
    return groupOptions
  }, [areas, subAreas])

  const appendFilteredDataToFirstArray = (
    firstArray,
    areaData
  ) => {
    return firstArray.flatMap((item) => {
      const { area, subArea } = item;
      const normalizedSubArea = subArea?.toLowerCase();

      const initialMatchingItems = areaData[area]?.filter((areaItem) =>
        areaItem.parent?.toLowerCase() === normalizedSubArea
      ) || [];

      const additionalMatchingItems = areaData[area]?.filter((areaItem) =>
        initialMatchingItems.some((matchingItem) =>
          areaItem.parent?.toLowerCase() === matchingItem.name.toLowerCase()
        )
      ) || [];

      const matchingItems = [...initialMatchingItems, ...additionalMatchingItems].map((areaItem) => ({
        area: areaItem.subarea,
        subArea: areaItem.name,
        bbox: areaItem.component?.bbox,
      }));

      return matchingItems.length > 0 ? [...matchingItems, item] : [item];
    });
  };

  const CustomGroupHeading = (props) => {
    const handleClick = () => {
      const data = [{
        label: props.children,
        value: {
          area: props.children,
          bbox: [],
          subArea: props.children,
        }
      }]
      setCurrentSelectedNeighborhoods(data)
      const neighborhoods = data.map(val => val.value)
      const filtered = appendFilteredDataToFirstArray(neighborhoods, subAreas);
      const locations = currentSearchObject?.locations?.length ? [...filtered, ...currentSearchObject.locations] : filtered;

      const filteredNeigh = currentSearchObject.neighborhoods?.filter(({ area }) => area !== props.children);

      dispatch(storeSearchObject({
        ...currentSearchObject,
        locations: locations,
        neighborhoods: !!filteredNeigh ? [...filteredNeigh, ...neighborhoods] : neighborhoods
      }))
    };

    return (
      <div
        onClick={handleClick}
        style={{
          height: 44,
          display: 'flex',
          alignItems: 'center',
          backgroundColor: '#F1F1F1',
          fontWeight: 'bold',
          cursor: 'pointer', padding: '8px'
        }}
      >
        {props.children}
      </div>
    );
  };


  return (
    <Flexbox style={{
      ...styles.container,
      ...props.style,
      width: isMobile ? '80%' : '90%',
    }}>
      <div style={styles.buttons}>
        <Button textSize={16} primary={searchingRents} third={!searchingRents} onClick={handleRent}>
          Rent
        </Button>
        <Button textSize={16} primary={!searchingRents} third={searchingRents} onClick={handleBuy} style={{ marginLeft: 20 }}>
          Buy
        </Button>
      </div>
      <Flexbox style={{
        ...styles.mainRow,
        flexDirection: isMobile ? 'column' : 'row',
        alignItems: isMobile ? 'flex-start' : 'center',
        gap: isMobile ? 20 : 10,
      }}>
        <div style={isMobile ? styles.locationForMobile : styles.location}>
          <TypeFace size={20} semiBold>
            Location
          </TypeFace>
          <div style={styles.locationInput}>

            <ReactSelect
              id="location-input"
              options={areasGroupOptions}
              placeholder={"Select your neighborhood"}
              components={{
                DropdownIndicator: () => null, IndicatorSeparator: () => null,
                GroupHeading: CustomGroupHeading,
              }}
              style={{ width: '100%' }}
              isMulti
              value={currentSelectedNeiborhoods}
              onChange={values => {
                console.log('values=', values)
                setCurrentSelectedNeighborhoods(values)
                const neighborhoods = values.map(val => val.value)
                const filteredArr = appendFilteredDataToFirstArray(neighborhoods, subAreas)
                dispatch(storeSearchObject({
                  ...currentSearchObject,
                  locations: filteredArr,
                  neighborhoods
                }))
              }}
              styles={{
                placeholder: (base) => ({
                  ...base,
                  textAlign: 'start'
                }),
                valueContainer: (base) => ({
                  ...base,
                  textAlign: 'start',
                  border: 'none',
                  boxShadow: 'none'
                }),
                control: (base) => ({
                  ...base,
                  border: 'none',
                  borderRadius: 0
                }),
                option: (base) => ({
                  ...base,
                  textAlign: 'start'
                }),
                input: (base) => ({
                  ...base,
                  border: 'none',
                }),
                container: (base) => ({
                  ...base,
                  border: '1px solid black',
                  boxShadow: 'none'
                }),
                groupHeading: (base) => ({
                  ...base,
                  height: 44,
                  display: 'flex',
                  alignItems: 'center',
                  backgroundColor: '#F1F1F1',
                  fontWeight: 'bold'
                })
              }}
            />
            {/*   <Script
          url="https://maps.googleapis.com/maps/api/js?key=AIzaSyChrrGNLT2GcGAZH1nwQ1urEveT-dhX3Rs&libraries=places"
        />
          <div style={styles.tagInputContainer}>
            { tags.map((tag, index) => (
                <div style={styles.tagItem} key={index}>
                    <span>{tag}</span>
                    <span style={styles.tagItemClose} onClick={() => removeTag(index)}>x</span>
                </div>
            )) }
            {tags.length === 0 && (
           <input id="location-input"
          style={styles.tagsInput}
          //onKeyDown={handleKeyDown} 
          placeholder="City, Neighborhood, ZIP"
          onChange={handleChange}
          autocomplete="off"
          value={value}/>)}
        </div>

       
        {fetchingPredictions && <p>Searching for locations...</p>}
        {fetchingDetails && <p>Searching for locations...</p>}

        {suggestions.length > 0 && (
          //<div style={{ position: 'absolute',  zIndex: '5000 !important', backgroundColor:'#fff'}}>
          <div style={{ position: isMobile ? 'relative' : 'absolute',  zIndex: '5000 !important', backgroundColor:'#fff'}}>
            <ul style={{ listStyleType: "none", padding: "0", boxShadow: '0 0 6px #aaaaaa' }} role="listbox">
              {suggestions.map((suggestion) => (
                <li
                  key={suggestion.id}
                  style={{
                    fontSize: "16px",
                    padding: "0.5rem 0.75rem",
                    margin: "0.25rem 0",
                    backgroundColor: "rgba(255, 255, 255, 0.15)",
                    borderBottom: '0.5px solid #aaaaaa',
                    cursor: "pointer",
                  }}
                  tabIndex={-1}
                  role="option"
                  aria-selected="false"
                  onClick={() => handleSuggestionSelected(suggestion)}
                >
                  {suggestion.properties.full_address.replace(', United States', '')}
                </li>
              ))}
            </ul>
          </div>
        )}
 
        <div id="googlemaps-attribution-container"></div>*/}
          </div>

          {/* {isMobile && (
            <Flexbox row style={{ gap: 5, marginTop: 5, marginBottom: 5 }} onClick={() => {
              document.body.style.overflow = 'hidden'
              setIsOpeningNeighborhoodsModal(true)
            }}>
              <img src={IconMapNeighborhoods} width={18} height={18} />
              <TypeFace semiBold size={isMobile ? 14 : 18} style={{ cursor: 'pointer' }}>
                See All Neighborhoods
              </TypeFace>
            </Flexbox>
          )} */}
        </div>
        <Flexbox style={{
          alignItems: 'flex-start',
          marginLeft: isMobile ? 0 : 10,
          marginRight: 5,
          width: isMobile ? '100%' : 'unset',
        }}>
          <TypeFace size={20} semiBold>
            Price Range
          </TypeFace>
          <PriceRangeSelectBox
            isForSale={!searchingRents}
            minValue={currentSearchObject?.price?.from}
            maxValue={currentSearchObject?.price?.to}
            onValueChanged={(min, max) => {
              const priceValues = {}
              if (min) {
                priceValues.from = min
              }
              if (max) {
                priceValues.to = max
              }

              dispatch(storeSearchObject({
                ...currentSearchObject,
                price: priceValues
              }))
            }}
          />
        </Flexbox>
        <Flexbox style={{ alignItems: 'flex-start', justifyContent: 'flex-start' }}>
          <TypeFace size={20} semiBold>
            Bedrooms
          </TypeFace>
          <RoomsSelectBox
            lastValue={5}
            selected={currentSearchObject?.bedRooms ?? []}
            onUpdateSelection={rooms => {
              dispatch(storeSearchObject({
                ...currentSearchObject,
                bedRooms: rooms
              }))
            }}
          />
        </Flexbox>
        <div onClick={handleSearch} style={{
          ...styles.searchButton,
          marginLeft: isMobile ? 0 : 20,
          width: isMobile ? '100%' : 50,
          alignSelf: 'center'
        }}>
          <img src={IconSearch} width={20} height={20} alt='search-icon' />
        </div>
      </Flexbox>
      {!isMobile && (
        <Flexbox style={{
          width: '100%',
          flexDirection: isMobile ? 'column' : 'row',
          gap: isMobile ? 5 : 0,
          alignItems: isMobile ? 'flex-start' : 'center'
        }}>
          {/* <Flexbox row style={{ gap: 5 }} onClick={() => {
            document.body.style.overflow = 'hidden'
            setIsOpeningNeighborhoodsModal(true)
          }}>
            <img src={IconMapNeighborhoods} width={18} height={18} />
            <TypeFace semiBold size={isMobile ? 14 : 18} style={{ cursor: 'pointer' }}>
              See All Neighborhoods
            </TypeFace>
          </Flexbox> */}
          <Flexbox style={{ flex: 1 }} />
          <TypeFace semiBold size={isMobile ? 14 : 18} onClick={handleAdvancedOptions} style={{ cursor: 'pointer' }}>
            + Advanced Options
          </TypeFace>
        </Flexbox>
      )}
      {!userProfile ? (
        <TypeFace semiBold size={18} style={{ marginTop: 16, alignSelf: 'center' }}>
          Keep your search for the right property saved.{' '}
          <TypeFace onClick={() => {
            setOpeningModalWithSignupMode(true)
            openLoginModal()
          }} bold color='black' size={18} style={{ cursor: 'pointer' }}>
            Register
          </TypeFace>
          {' '}or{' '}
          <TypeFace onClick={() => {
            setOpeningModalWithSignupMode(false)
            openLoginModal()
          }} bold color='black' size={18} style={{ cursor: 'pointer' }}>
            Sign in
          </TypeFace>
        </TypeFace>
      ) : (
        <TypeFace semiBold size={18} style={{ marginTop: 16, alignSelf: 'center' }}>
          Keep your search for the right property saved.{' '}
        </TypeFace>
      )}

      <HomeAdvancedSearchFilterPopup
        modalIsOpen={isOpeningAdvancedFilters}
        filters={currentSearchObject}
        closeModal={() => setIsOpeningAdvancedFilters(false)}
      />
      <ConnectProfileModal
        modalIsOpen={isConnectProfileModalOpen}
        closeModal={() => {
          setIsConnectProfileModalOpen(false)
        }}
      />
      <LoginModal
        modalIsOpen={isLoginModalOpen}
        isSignup={openingModalWithSignupMode}
        closeModal={() => setIsLoginModalOpen(false)}
        onSignup={() => setIsLoginModalOpen(false)}
      />
      {isMobile ? (
        <SelectNeighborhoodsModalMobile
          modalIsOpen={isOpeningNeighborhoodsModal}
          onApply={handleApplyNeighborSelections}
          selection={currentSelectedNeiborhoods.map(v => v.value)}
          closeModal={() => setIsOpeningNeighborhoodsModal(false)}
        />
      ) : (
        <SelectNeighborhoodsModal
          modalIsOpen={isOpeningNeighborhoodsModal}
          onApply={handleApplyNeighborSelections}
          selection={currentSelectedNeiborhoods.map(v => v.value)}
          closeModal={() => setIsOpeningNeighborhoodsModal(false)}
        />
      )}
    </Flexbox>
  )
}

export default HomeSearchBox

const styles = {
  container: {
    width: '90%',
    maxWidth: 1178,
    borderRadius: 16,
    backgroundColor: 'white',
    boxShadow: '0 0 6px #aaaaaa',
    alignItems: 'flex-start',
    padding: '0px 20px 20px',
  },
  mainRow: {
    width: '100%',
    display: 'flex',
    paddingTop: 20,
    paddingBottom: 20,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  location: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  locationForMobile: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  locationInput: {
    marginTop: 5,
    width: '100%',
  },
  textInputLocation: {
    position: 'absolute',
    left: 0,
    right: 0,
    height: '100%',
    marginLeft: 0,
    paddingLeft: 5,
    border: '1px solid #ccc',
    borderRadius: 3,
  },
  price: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  textInputPrice: {
    paddingLeft: 10,
    paddingRight: 10,
    marginTop: 5,
    height: 36,
    shadowColor: Colors.gray1,
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.2,
    shadowRadius: 3,
    border: '1px solid #000000',
  },
  searchButton: {
    display: 'flex',
    backgroundColor: Colors.primary,
    borderRadius: 5,
    width: 50,
    height: 50,
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer'
  },
  mapIconInLocation: {
    cursor: 'pointer',
    display: 'flex',
    width: 30,
    height: 30,
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'center',
    marginTop: 20,
  },
  tagInputContainer: {
    border: '1px solid black',
    padding: '.5em',
    borderRadius: '3px',
    width: 'min(80vw, 600px)',
    //marginTop: '1em',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: '.5em',
    fontSize: '12px',
  },
  tagItem: {
    backgroundColor: '#F1F1F1',
    display: 'inline-block',
    padding: '.5em .75em',
    //border: '1px solid black',
    // boxShadow: 'none'
  },
  tagItemClose: {
    height: '10px',
    width: '10px',
    fontWeight: 'bold',
    color: '#000',
    borderRadius: '50%',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '.5em',
    fontSize: '15px',
    cursor: 'pointer',
  },
  tagsInput: {
    flexGrow: 1,
    padding: '.5em 0',
    border: 'none',
    outline: 'none',
    fontSize: '14px',
  }

}

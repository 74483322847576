import React, { useEffect, useState } from 'react';
import HomeHeaderDesktop from 'src/components/home/HomeHeaderDesktop';
import { useAuth } from 'src/hooks/auth/AuthContext';
import { useNavigate, useParams } from 'react-router-dom';
import ManageSideMenu from './ManageSideMenu';
import MyProperties from './myProperties/MyProperties';
import Settings from './settings/Settings';
import Agents from './agents/Agents';
import Messages from '../messages/Messages';
import Schedules from '../schedules/Schedules';
import useMediaQuery from 'src/hooks/useMediaQuery';
import Sidebar from 'src/components/sidebar/Sidebar';
import { useFirestore } from "src/hooks/firestore/FirestoreContext"
import { useAlert } from 'src/hooks/alert/AlertContext';
import { UserRoleValue } from 'src/model/types';

const Manage = () => {  

  const { page, messageId } = useParams()
  const { user } = useAuth()
  const navigate = useNavigate()
  const { userProfile,myAgency } = useFirestore()
  const [section, setSection] = useState(page ?? 'properties')
  const [mobileMenuOpened, setMobileMenuOpened] = useState(false)
  const {showAlert} = useAlert()
  const { isMobile } = useMediaQuery()

  const handleSelectSubPage = (subPage) => {
    setMobileMenuOpened(false)
   // console.log("subPage=",subPage)
   // console.log("userProfile?.agencyApproved=",userProfile?.agencyApproved)
   // console.log("userProfile?.suspended=",userProfile?.suspended)
    
    if(subPage !== 'settings') {
      if (userProfile?.profileBlocked) {
        //showAlert(true, 'Your profile is under review, contact support@listmeet.com to reinstate your profile.')
        if (userProfile?.role === UserRoleValue.ManageMent) {
          showAlert(true, 'Your company has been blocked, contact Listmeet support (support@listmeet.com) to reinstate your profile.')
        }
        else {
          showAlert(true, 'Your license has been blocked. Contact Listmeet support (support@listmeet.com) to reinstate your profile.')
        }
      }
      else {
        if (userProfile?.role === UserRoleValue.ManageMent) {
          console.log('userProfile.profileStatus=',userProfile.profileStatus)
          if (userProfile.profileStatus === undefined) {
            showAlert(true, 'Listmeet has not approved your company yet.')
          }
          else if (userProfile.profileStatus !== 'approved') {
            showAlert(true, 'Listmeet has not approved your company yet.')
          }
          else {
            return (
              navigate('/manage/' + subPage)
            )
          }
        }
        else {
          if (userProfile?.agencyApproved === false) {
            if (userProfile?.role === UserRoleValue.Broker) {
              showAlert(true, 'Listmeet has not approved your brokerage yet.')
            }
            else {
              showAlert(true, 'Sign up with a brokerage in your "My Profile" section before using ListMeet.')
            }
          } else if (userProfile?.suspended === true) {
            if (userProfile?.role === UserRoleValue.Broker) {
              showAlert(true, 'Your brokerage has been suspended.')
            }
            else {
              showAlert(true, 'Contact your broker to reinstate your account.')
            }
          }
          else {
            return (
              navigate('/manage/' + subPage)
            )
          }
        }
      } 
    }
    else {
      return (
        navigate('/manage/' + subPage)
      )
    }


    
   // navigate('/manage/' + subPage)
  }

  useEffect(() => {
    setSection(page ?? 'settings')
  }, [page])
  
  return (
    <div className="App">
      {isMobile && (
        <Sidebar
          isOpen={mobileMenuOpened}
          pageWrapId={"page-wrap"}
          outerContainerId={"App"}
          selected={section}
          onSelect={handleSelectSubPage}
          onStateChange={state => setMobileMenuOpened(state.isOpen)}
        />
      )}
      <div id="page-wrap">
        <HomeHeaderDesktop />
        <div style={styles.container}>
          <div style={styles.content}>
            {!isMobile && (
              <ManageSideMenu selected={section} onSelect={handleSelectSubPage} />
            )}
            {!isMobile && <div style={styles.vertSeparator} />}
            <div style={{
              ...styles.contentArea,
              padding: isMobile ? '20px 20px' : '30px 30px'
            }}>
              {section === 'properties' && <MyProperties />}
              {section === 'settings' && <Settings />}
              {section === 'agents' && <Agents />}
              {section === 'messages' && <Messages messageId={messageId} />}
              {section === 'schedules' && <Schedules />}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Manage

const styles = {
  container: {
    width: '100vw',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    height: 'calc(100vh - 70px)',
    width: '90%',
    maxWidth: 1178,
    borderTop: '1px solid #dfdfdf',
  },
  contentArea: {
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    padding: '30px 30px'
  },
  vertSeparator: {
    width: 1,
    height: '100%',
    backgroundColor: '#DFDFDF'
  }
}
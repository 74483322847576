import { doc, onSnapshot } from 'firebase/firestore'
import React, { useEffect, useRef } from 'react'
import { useState } from 'react'
import { searchObjects } from 'src/algolia'
import TypeFace from 'src/components/typography/Typefaces'
import Flexbox from 'src/components/wrapper/Flexbox'
import { firestore } from 'src/firebase'
import { Colors } from 'src/theme/Colors'

const AdminDashboard = () => {

  const unmountedRef = useRef(false)

  const [adminData, setAdminData] = useState()
  const [allListingsCount, setAllListingsCount] = useState()
  const [allSalesListingsCount, setAllSalesListingsCount] = useState()
  const [allRentalListingsCount, setAllRentalListingsCount] = useState()

  const loadAllListingsCount = () => {
    searchObjects("", "NOT status:Draft AND NOT status:Released AND NOT status:Deleted").then(res => {
      setAllListingsCount(res.nbHits)
    })

    searchObjects("", "NOT status:Draft AND NOT status:Released AND NOT status:Deleted AND type:sale").then(res => {
      setAllSalesListingsCount(res.nbHits)
    })

    searchObjects("", "NOT status:Draft AND NOT status:Released AND NOT status:Deleted AND type:rental").then(res => {
      setAllRentalListingsCount(res.nbHits)
    })
  }


  useEffect(() => {
    unmountedRef.current = false
    loadAllListingsCount()
    const adminSnapshotListner = onSnapshot(doc(firestore, `configs/admin`), (docSnapshot) => {
      if (!unmountedRef.current) {
        setAdminData(docSnapshot.data())
      }
    })
    return () => {
      unmountedRef.current = true
      adminSnapshotListner()
    }
  }, [])

  return (
    <Flexbox style={{ alignItems: 'flex-start', width: '100%' }}>
      <TypeFace bold>
        ListMeet Dashboard
      </TypeFace>
      <Flexbox row style={{...styles.contentContainer, marginTop: 20 }}>
        <Flexbox style={styles.contentItem}>
          <TypeFace bold size={40} color={Colors.primary}>
            {adminData?.customerTotal}
          </TypeFace>
          <TypeFace medium size={12}>
            ALL CUSTOMERS
          </TypeFace>
        </Flexbox>
        <Flexbox style={styles.contentItem}>
          <TypeFace bold size={40} color={Colors.primary}>
            {allListingsCount}
          </TypeFace>
          <TypeFace medium size={12}>
            ALL LISTINGS
          </TypeFace>
        </Flexbox>
        <Flexbox style={styles.contentItem}>
          <TypeFace bold size={40} color={Colors.primary}>
            {adminData?.totalAgencies}
          </TypeFace>
          <TypeFace medium size={12}>
            TOTAL AGENCIES
          </TypeFace>
        </Flexbox>
      </Flexbox>
      <Flexbox row style={styles.contentContainer}>
        <Flexbox style={styles.listingContentItem}>
          <TypeFace bold size={40} color={Colors.primary}>
            {allSalesListingsCount}
          </TypeFace>
          <TypeFace medium size={12}>
            SALE LISTINGS
          </TypeFace>
        </Flexbox>
        <Flexbox style={styles.listingContentItem}>
          <TypeFace bold size={40} color={Colors.primary}>
            {allRentalListingsCount}
          </TypeFace>
          <TypeFace medium size={12}>
            RENTAL LISTINGS
          </TypeFace>
        </Flexbox>
      </Flexbox>
      <Flexbox row style={styles.contentContainer}>
        <Flexbox style={styles.listingContentItem}>
          <TypeFace bold size={40} color={Colors.primary}>
            {adminData?.brokerTotal}
          </TypeFace>
          <TypeFace medium size={12}>
            BROKERS
          </TypeFace>
        </Flexbox>
        <Flexbox style={styles.listingContentItem}>
          <TypeFace bold size={40} color={Colors.primary}>
            {adminData?.managementTotal}
          </TypeFace>
          <TypeFace medium size={12}>
            MANAGEMENT COMPANIES
          </TypeFace>
        </Flexbox>
      </Flexbox>
      <Flexbox row style={styles.contentContainer}>
        <Flexbox style={styles.listingContentItem}>
          <TypeFace bold size={40} color={Colors.primary}>
            {adminData?.agentTotal}
          </TypeFace>
          <TypeFace medium size={12}>
            Associate Brokers
          </TypeFace>
        </Flexbox>
        <Flexbox style={styles.listingContentItem}>
          <TypeFace bold size={40} color={Colors.primary}>
            {adminData?.salespersonTotal}
          </TypeFace>
          <TypeFace medium size={12}>
            Licensed Real Estate Salespersons
          </TypeFace>
        </Flexbox>
      </Flexbox>
    </Flexbox>
  )
}

export default AdminDashboard

const styles = {
  contentContainer: {
    width: '100%',
    gap: 20,
    flexWrap: 'wrap',
  },
  contentItem: {
    alignItems: 'flex-start',
    justifyContent: 'center',
    width: 160,
    height: 80,
    border: '1px solid #ededed',
    marginBottom: 20,
    padding: 20,
    borderRadius: 10,   
  },
  listingContentItem: {
    alignItems: 'flex-start',
    justifyContent: 'center',
    width: 270,
    height: 60,
    border: '1px solid #ededed',
    marginBottom: 20,
    padding: 20,
    borderRadius: 10,   
  },
  listingFullWidth: {
    alignItems: 'flex-start',
    justifyContent: 'center',
    width: 600,
    height: 60,
    border: '1px solid #ededed',
    marginBottom: 20,
    padding: 20,
    borderRadius: 10,   
  }
}